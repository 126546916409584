import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useLoggedInUser } from 'src/services/api/auth';
import PropertyLabel from 'src/components/PropertyLabel';
import { useNotifications } from 'src/notifications';
import { isStandardErrorResponse } from 'src/common/api-utils';
import { type ILabel, LabelType } from 'src/services/api/labels';
import {
    useLeadLabels,
    useCreateLeadLabel,
    useUpdateLeadLabel,
    useDeleteLeadLabel,
    usePropertyLabels,
    useCreatePropertyLabel,
    useUpdatePropertyLabel,
    useDeletePropertyLabel,
} from 'src/services/api';
import { Transition } from 'src/components/ModalTransition';
import {
    BlackButtonWhiteText,
    ModalSuccessButton,
    RedButtonWhiteText
} from 'src/common/Buttons';
import Auth from 'src/services/api/auth/Auth';

import styles from 'src/components/edit-labels-modal/EditLabelsModal.module.css';
import modalButtonStyles from 'src/common/ModalButton.module.css';

interface ILabelRowProps {
    label: ILabel;
    onEdit(): void;
    onDelete(): void;
}

function LabelRow(props: ILabelRowProps) {
    return <div className={styles.propertyLabelRow}>
        <PropertyLabel label={props.label} />
        <div>
            <IconButton onClick={props.onEdit} data-testid="edit-label-button">
                <EditIcon />
            </IconButton>
            <IconButton onClick={props.onDelete} data-testid="delete-label-button">
                <DeleteIcon />
            </IconButton>
        </div>
    </div>;
}

interface IAddLabelFormProps {
    labelName: string;
    setLabelName(labelName: string): void;
    onSave(labelName: string): void;
}

function AddLabelForm(props: IAddLabelFormProps) {

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setLabelName(e.target.value);
    };

    const handleSave = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        props.onSave(props.labelName);
    };

    return <form onSubmit={handleSave}>
        <DialogContentText>Add a new property label. It will be visible to all of your team members.</DialogContentText>
        <TextField
            autoFocus
            margin="dense"
            label="New Label Name"
            name="label_name"
            value={props.labelName}
            type="text"
            fullWidth
            variant="standard"
            onChange={handleChangeName}
            data-testid="new-label-name-field"
        />
        <Button
            variant="contained"
            data-testid="new-label-add-button"
            disabled={props.labelName.trim() === ''}
            className={modalButtonStyles.modalButton}
            onClick={() => handleSave()}
        >Add</Button>
    </form>;
}

interface IEditLabelFormProps {
    label: ILabel;
    onSave: (labelId: number, labelName: string) => Promise<void>;
    onClose: () => void;
}

function EditLabelForm({ label, onClose, onSave }: IEditLabelFormProps) {
    const [labelName, setLabelName] = useState(label.name);

    const handleSave = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        await onSave(label.id, labelName);
        onClose();
    };

    return <form onSubmit={handleSave} className={styles.editForm}>
        <TextField
            id="label-name"
            label="Label Name"
            fullWidth
            variant="standard"
            margin="dense"
            value={labelName}
            onChange={(ev) => setLabelName(ev.target.value)}
            data-testid="edit-label-name-field"
        />
        <div className={modalButtonStyles.modalButtonContainer + ' ' + styles.modalButtonRow}>
            <BlackButtonWhiteText
                onClick={onClose}
            >
                Back
            </BlackButtonWhiteText>
            <ModalSuccessButton
                onClick={() => handleSave()}
                disabled={labelName.trim() === ''}
            >
                Save
            </ModalSuccessButton>
        </div>
    </form>;
}

interface IConfirmDeleteFormProps {
    label: ILabel;
    onCancel: () => void;
    onConfirm: () => Promise<void>;
}

function ConfirmDeleteForm({ label, onCancel, onConfirm }: IConfirmDeleteFormProps) {
    return <form className={styles.confirmDeleteForm}>
        <DialogContentText>
            Are you sure you want to delete label &quot;{label.name}&quot; for your entire team?
        </DialogContentText>
        <div className={modalButtonStyles.modalButtonContainer + ' ' + styles.modalButtonRow + ' mt-2'}>
            <BlackButtonWhiteText onClick={onCancel} data-testid="delete-label-cancel-button">Cancel</BlackButtonWhiteText>
            <RedButtonWhiteText onClick={onConfirm} data-testid="delete-label-confirm-button">Delete</RedButtonWhiteText>
        </div>
    </form>;
}

interface Props {
    type: LabelType;
    onClose: () => void;
}

export default function EditLabelsModal({ type, onClose }: Props) {
    const { addNotification } = useNotifications();

    const { data: loggedInUser } = useLoggedInUser();

    const { data: leadLabels } = useLeadLabels();
    const {
        mutateAsync: createLeadLabel,
        error: createLeadLabelError,
        reset: resetCreateLeadLabel,
    } = useCreateLeadLabel();
    const {
        mutateAsync: updateLeadLabel,
        error: updateLeadLabelError,
        reset: resetUpdateLeadLabel,
    } = useUpdateLeadLabel();
    const {
        mutateAsync: deleteLeadLabel,
        error: deleteLeadLabelError,
        reset: resetDeleteLeadLabel
    } = useDeleteLeadLabel();

    const { data: propertyLabels } = usePropertyLabels();
    const {
        mutateAsync: createPropertyLabel,
        error: createPropertyLabelError,
        reset: resetCreatePropertyLabel,
    } = useCreatePropertyLabel();
    const {
        mutateAsync: updatePropertyLabel,
        error: updatePropertyLabelError,
        reset: resetUpdatePropertyLabel,
    } = useUpdatePropertyLabel();
    const {
        mutateAsync: deletePropertyLabel,
        error: deletePropertyLabelError,
        reset: resetDeletePropertyLabel
    } = useDeletePropertyLabel();

    const resetErrors = () => {
        resetCreateLeadLabel();
        resetUpdateLeadLabel();
        resetDeleteLeadLabel();
        resetCreatePropertyLabel();
        resetUpdatePropertyLabel();
        resetDeletePropertyLabel();
    };

    const labels = type === LabelType.PROPERTY ? propertyLabels : leadLabels;
    const createLabel = type === LabelType.PROPERTY ? createPropertyLabel : createLeadLabel;
    const updateLabel = type === LabelType.PROPERTY ? updatePropertyLabel : updateLeadLabel;
    const deleteLabel = type === LabelType.PROPERTY ? deletePropertyLabel : deleteLeadLabel;

    const accessToken = Auth.accessToken;
    if (!accessToken) {
        throw new Error('access token must be set');
    }
    if (!!loggedInUser && !loggedInUser?.leasing_team_id) {
        throw new Error('user must have leasing team id');
    }

    const [newLabelName, setNewLabelName] = useState('');
    const [selectedLabelToEdit, setSelectedLabelToEdit] = useState<ILabel>();
    const [selectedLabelToDelete, setSelectedLabelToDelete] = useState<ILabel>();

    const handleCreate = async (labelName: string) => {
        try {
            await createLabel({ name: labelName });
            addNotification('Label created', 'success');
            setNewLabelName('');
            resetErrors();
        } catch (error) {
            if (isStandardErrorResponse(error)) {
                addNotification('Failed to create label', 'error');
            }
        }
    };

    const handleUpdate = async (labelId: number, labelName: string) => {
        try {
            await updateLabel({ id: labelId, name: labelName });
            addNotification('Label updated', 'success');
            setSelectedLabelToEdit(undefined);
            resetErrors();
        } catch (error) {
            if (isStandardErrorResponse(error)) {
                addNotification('Failed to update label', 'error');
            }
        }
    };

    const handleDelete = async (labelId: number) => {
        try {
            await deleteLabel(labelId);
            addNotification('Label deleted', 'success');
            setSelectedLabelToDelete(undefined);
        } catch (error) {
            if (isStandardErrorResponse(error)) {
                addNotification('Failed to delete label', 'error');
            }
        }
    };

    const existingLabelElems = labels?.map(label => {
        return (
            <LabelRow key={label.id}
                label={label}
                onEdit={() => setSelectedLabelToEdit(label)}
                onDelete={() => setSelectedLabelToDelete(label)}
            />
        );
    });

    let innerContent;
    if (selectedLabelToEdit) {
        innerContent = (
            <EditLabelForm
                label={selectedLabelToEdit}
                onClose={() => setSelectedLabelToEdit(undefined)}
                onSave={handleUpdate}
            />
        );
    } else if (selectedLabelToDelete) {
        innerContent = (
            <ConfirmDeleteForm
                label={selectedLabelToDelete}
                onCancel={() => setSelectedLabelToDelete(undefined)}
                onConfirm={() => handleDelete(selectedLabelToDelete.id)}
            />
        );
    } else {
        innerContent = (
            <>
                {/* form allows adding new labels */}
                <AddLabelForm
                    labelName={newLabelName}
                    setLabelName={setNewLabelName}
                    onSave={handleCreate}
                />

                {/* list of existing labels, which can be edited */}
                <div>
                    <Typography variant="h2" sx={{ my: 2 }}>Existing Labels</Typography>
                    {!labels?.length && <Alert severity="info">Your team does not have any property labels yet.</Alert>}
                    {!!labels?.length && <div>{existingLabelElems}</div>}
                </div>
            </>
        );
    }

    const title = type === LabelType.PROPERTY ? 'Manage Property Labels' : 'Manage Lead Labels';

    const error = createLeadLabelError ||
        updateLeadLabelError ||
        deleteLeadLabelError ||
        createPropertyLabelError ||
        updatePropertyLabelError ||
        deletePropertyLabelError;

    const errorMessage = error?.messages ? error.messages.map(o => o.msg).join(', ') : error?.msg;

    return (
        <Dialog open={true} onClose={onClose} TransitionComponent={Transition}>
            <DialogTitle>
                {title}
            </DialogTitle>

            <DialogContent>
                {innerContent}
                {!!errorMessage && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
            </DialogContent>

            <DialogActions>
                <BlackButtonWhiteText
                    className={modalButtonStyles.modalButton}
                    onClick={onClose}
                >
                    Close
                </BlackButtonWhiteText>
            </DialogActions>
        </Dialog>
    );
}
