import { blue, purple, red, orange, yellow, green, cyan, pink } from '@mui/material/colors';

export const colors = {
    blue,
    purple,
    red,
    orange,
    yellow,
    green,
    cyan,
    pink
};

export const orderedColors = [blue, purple, red, orange, green, cyan];