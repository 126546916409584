
import { useMutation, useQueryClient } from 'react-query';

import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';

const PATH = 'api/landlord/notifications';

export const useDismissNotification = (id: number) => {
    const queryClient = useQueryClient();
    const path = `${PATH}/${id}/read`;

    return useMutation(
        [queryKeys.notifications],
        async () => {
            await apiClient(path, { method: 'put' });
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.notifications);
                queryClient.invalidateQueries([queryKeys.notifications, id]);
            }
        }
    );
};
