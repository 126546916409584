import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import MapMarkerIcon from '@mui/icons-material/PlaceOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MoreVert from '@mui/icons-material/MoreVert';

import type { IProperty } from 'src/services/api';
import { CurrencyUtils, PropertyUtils } from 'src/utils';
import { Flex, FlexColumn } from 'src/components/flex';
import TableCell from 'src/components/table/TableCell';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

import styles from 'src/pages/landlord/properties/list/MobilePropertiesListItem.module.css';

type Props = {
    property: IProperty;
}

export default function MobilePropertiesListItem({
    property,
}: Props) {
    const { setMobileAnchorEl, setSelectedProperties } = usePropertiesPageContext();

    return (
        <TableRow className={clsx(styles.tableRow)}>
            <TableCell width="60%" className={styles.textWithEllipsis}>
                <FlexColumn rowGap={0.5}>
                    <Typography fontWeight={500}>
                        {property.name}
                    </Typography>

                    <Typography>
                        {property.type ?? 'Type not set'}
                    </Typography>

                    <Flex alignItems="center" columnGap={0.5}>
                        <MapMarkerIcon sx={{ fontSize: 16 }} />
                        <Typography variant="body2">
                            {PropertyUtils.formatFullAddress(property)}
                        </Typography>
                    </Flex>

                    {!!property.unit && (
                        <Flex alignItems="center" columnGap={0.5}>
                            <ApartmentIcon sx={{ fontSize: 16 }} />
                            <Typography variant="body2">
                                Unit {property.unit}
                            </Typography>
                        </Flex>
                    )}
                </FlexColumn>
            </TableCell>

            <TableCell width="40%" className={styles.textWithEllipsis}>
                <FlexColumn pr={1} rowGap={0.5}>
                    <IconButton
                        edge="end"
                        sx={{ alignSelf: 'end' }}
                        onClick={(e) => {
                            setMobileAnchorEl(e.currentTarget);
                            setSelectedProperties([property]);
                        }}
                    >
                        <MoreVert />
                    </IconButton>

                    <Typography textAlign="right">
                        {property.rent != null ?
                            CurrencyUtils.format(property.rent) :
                            'Rent not set'
                        }
                    </Typography>
                </FlexColumn>
            </TableCell>
        </TableRow>
    );
}
