import React from 'react';
import { titleCase } from 'title-case';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import type { ILead } from 'src/services/api';
import { FlexColumn } from 'src/components/flex';
import { formatSmartField } from 'src/components/lead-sidebar/format-smart-field';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';

function SmartField({
    field: propsField,
    value: propsValue,
}: {
    field: string;
    value: string;
}) {
    const {
        fieldLabel,
        value,
        valueLabel,
        isValueALink
    } = formatSmartField(propsField, propsValue);

    return (
        <FlexColumn rowGap={0}>
            <Typography fontWeight={500}>{fieldLabel}</Typography>

            {isValueALink && (
                <Link href={value} target="_blank" rel="noreferrer">
                    {valueLabel}
                </Link>
            )}

            {!isValueALink && <Typography variant="body2">{value}</Typography>}
        </FlexColumn>
    );
}

type Props = {
    lead: ILead;
    validSmartFields: [string, string | Record<string, string>][];
}

export default function LeadSidebarSmartFields({ lead, validSmartFields }: Props) {
    return (
        <ExpandableItem title="Smart Fields" >
            <FlexColumn>
                {lead.ingestion_source === 'apartmentlist' && (
                    validSmartFields.map(([key1, value1]) => {
                        if (typeof value1 === 'string') {
                            return <SmartField key={key1} field={key1} value={value1} />;
                        }

                        return (
                            <>
                                <Typography key={key1} mt={0.5} variant="h5">
                                    {titleCase(key1.replace(/_/g, ' '))}
                                </Typography>
                                {Object.entries(value1).map(([key2, value2]) => (
                                    <SmartField key={key2} field={key2} value={value2} />
                                ))}
                            </>
                        );
                    })
                )}

                {lead.ingestion_source !== 'apartmentlist' && (
                    validSmartFields.map(([key, value]) => {
                        if (typeof value !== 'string') {
                            return null;
                        }

                        return <SmartField key={key} field={key} value={value} />;
                    })
                )}
            </FlexColumn>
        </ExpandableItem>
    );
}
