import dayjs from 'dayjs';

import type { IConversation } from 'src/api/landlord-messages-api';
import { EConversationCategory } from 'src/pages/landlord/conversations/context/types';

/**
 * This is the ultimate source of truth for conversation categorization.
 * Reuse this as much as possible and avoid writing your own.
 */
export const getConversationCategory = (
    conversation: IConversation & { is_archived: boolean; },
    now?: dayjs.Dayjs,
) => {
    if (!now) {
        now = dayjs();
    }

    const isArchived = conversation.is_archived || false;
    if (isArchived) {
        return EConversationCategory.Archived;
    }

    const lastMessageDaysAgo = now.diff(dayjs(conversation.last_contact_at), 'day');

    const isLastMessageOlderThanAWeek = lastMessageDaysAgo >= 7;

    if (isLastMessageOlderThanAWeek) {
        return EConversationCategory.Stale;
    }

    const hasUnreadMessages = conversation.num_unread_messages > 0;
    if (hasUnreadMessages) {
        return EConversationCategory.Unread;
    }

    const isLastMessageFromLandlord = conversation.last_message_direction === 'out';
    const numOutboundMessages = conversation.num_outbound_messages;

    if (isLastMessageFromLandlord) {
        if (numOutboundMessages > 1 && lastMessageDaysAgo >= 3) {
            return EConversationCategory.Ghosted;
        }

        if (numOutboundMessages === 1 && lastMessageDaysAgo >= 1) {
            return EConversationCategory.Ghosted;
        }
    }

    return EConversationCategory.Active;
};

export const getConversationsByCategory = (
    conversations: Array<IConversation & { is_archived: boolean; }>,
    category: EConversationCategory,
) => {
    const now = dayjs();

    return conversations.filter((conversation) => {
        return getConversationCategory(conversation, now) === category;
    });
};