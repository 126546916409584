import React, { useCallback, useMemo, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import { isNonLeasedPropertyNotification, useProperties } from 'src/services/api';
import QuestionModal from 'src/components/inactive-properties-modal/InactivePropertiesQuestionModal';
import ConfirmationModal from 'src/components/inactive-properties-modal/InactivePropertiesConfirmationModal';

export default function InactivePropertiesModal() {
    const { data: properties } = useProperties();

    const { notifications: allNotifications } = useAuthenticatedState();

    const [activeModal, setActiveModal] = useState<'question' | 'confirmation'>('question');
    const [isClosed, _setIsClosed] = useState(localStorage.getItem('inactive-properties-closed') === 'true');
    const setIsClosed = useCallback((value: boolean) => {
        _setIsClosed(value);
        localStorage.setItem('inactive-properties-closed', value ? 'true' : 'false');
    }, []);

    const notifications = useMemo(() => {
        return allNotifications
            .filter(o => !o.is_read_by_user)
            .filter(isNonLeasedPropertyNotification)
            .filter(o => {
                const property = properties?.find(p => p.id === o.payload.property_id);
                // property has to exist
                return !!property;
            });
    }, [allNotifications, properties]);

    const currentNotification = notifications[0];

    if (isClosed || !properties || !notifications.length) {
        return null;
    }

    if (!currentNotification) {
        console.error('No notification found. This should not happen');
        return null;
    }

    const currentProperty = properties.find((p) => p.id === currentNotification.payload.property_id);
    if (!currentProperty) {
        console.error('No property found. This should not happen');
        return null;
    }

    const numNotifications = notifications.length;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {activeModal === 'question' && (
                <QuestionModal
                    notification={currentNotification}
                    property={currentProperty}
                    numNotifications={numNotifications}
                    onContinue={() => setActiveModal('confirmation')}
                    onClose={() => setIsClosed(true)}
                />
            )}

            {activeModal === 'confirmation' && (
                <ConfirmationModal
                    notification={currentNotification}
                    property={currentProperty}
                    numNotifications={numNotifications}
                    onContinue={() => setActiveModal('question')}
                />
            )}
        </LocalizationProvider>
    );
}