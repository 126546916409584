import React, { forwardRef } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';

export type FlexProps = Omit<BoxProps, 'display'> & {
    mobileFlexDirection?: 'row' | 'column';
}

const Flex = forwardRef(function Flex(
    {
        flexDirection,
        mobileFlexDirection,
        children,
        ...restProps
    }: FlexProps,
    ref
) {
    return (
        <Box
            ref={ref}
            display="flex"
            rowGap={1}
            columnGap={1}
            {...restProps}
            flexDirection={flexDirection}
            sx={{
                '@media (max-width: 900px)': {
                    flexDirection: mobileFlexDirection || flexDirection,
                },
                ...restProps.sx
            }}
        >
            {children}
        </Box>
    );
});

export default Flex;
