import React from 'react';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/UploadRounded';

import styles from 'src/components/input/file-upload/FileUpload.module.css';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export default function FileUpload({
    children,
    ...props
}: Props) {
    return (
        <Button
            component="label"
            variant="contained"
            startIcon={<UploadIcon />}
        >
            {children || 'Upload'}
            <input
                type="file"
                className={styles.input}
                {...props}
            />
        </Button>
    );
}