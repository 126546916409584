import { debounce } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isModalOpen } from 'src/utils/is-modal-open';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import Search from 'src/components/search/Search';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

const HELPER_TEXT = 'Search by lead uuid, name, email, phone number, or notes. Press "/" to focus.';

export default function ConversationsSearch() {
    const { search, setSearch } = useConversationsPageContext();

    const inputRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState('');
    const [isSearching, setSearching] = useState(false);

    const setSearchQueryDebounced = useCallback(debounce(setSearch, 300), []);

    useEffect(() => {
        setSearchQueryDebounced(value);
    }, [value, setSearchQueryDebounced]);

    useEffect(() => {
        if (value !== search) {
            setSearching(true);
        } else {
            setSearching(false);
        }
    }, [value, search]);

    useFocusOnSlash(inputRef);

    return (
        <Search
            inputRef={inputRef}
            placeholder="Search conversations"
            value={value}
            onChange={setValue}
            helperText={HELPER_TEXT}
            isLoading={isSearching}
            sx={{ width: 400, maxWidth: '100%', bgcolor: 'common.white' }}
            data-testid="conversations-search"
        />
    );
}

// focus on input when '/' key is pressed
const useFocusOnSlash = (inputRef: React.RefObject<HTMLInputElement>) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === '/') {
                if (isModalOpen()) {
                    return;
                }

                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Conversations',
                    key: '/',
                });

                event.preventDefault();
                inputRef.current?.focus();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);
};
