import React, { useState } from 'react';
import pluralize from 'pluralize';
import dayjs, { type Dayjs } from 'dayjs';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@mui/lab/LoadingButton';

import { Flex, FlexColumn } from 'src/components/flex';
import {
    type Notification,
    type IProperty,
    useDismissNotification,
    useUpdatePropertyOccupancy,
} from 'src/services/api';
import SelectLead from 'src/components/input/select-lead/SelectLead';


type Props = {
    notification: Notification;
    property: IProperty;
    numNotifications: number;
    onContinue: () => void;
}

export default function InactivePropertiesConfirmationModal({
    notification,
    property,
    numNotifications,
    onContinue
}: Props) {
    const [date, setDate] = useState<Dayjs | null>(dayjs());
    const [leadUuid, setLeadUuid] = useState<string>();

    const {
        mutateAsync: updatePropertyOccupancy,
        isLoading: isUpdating
    } = useUpdatePropertyOccupancy(property.id);
    const {
        mutateAsync: dismissNotification,
        isLoading: isDismissing
    } = useDismissNotification(notification.id);

    const handleContinue = async () => {
        await updatePropertyOccupancy({
            is_vacant: false,
            occupancy_start_date: date ? date.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
            tennant_lead_uuid: leadUuid,
        });

        await dismissNotification();

        if (numNotifications === 1) {
            localStorage.setItem('inactive-properties-closed', 'true');
        }

        onContinue();
    };

    return (
        <Dialog open>
            <DialogTitle>
                <Flex justifyContent="space-between" alignItems="center">
                    <Typography>
                        {numNotifications} {pluralize('property', numNotifications)} remaining
                    </Typography>
                </Flex>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h3">
                    When was <b>{property.name}</b> leased/taken off the market?
                </Typography>

                <FlexColumn mt={4} rowGap={2}>
                    <DatePicker label="Date" value={date} onChange={setDate} />
                    <SelectLead label="Tenant (optional)" value={leadUuid} onChange={setLeadUuid} />
                </FlexColumn>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    onClick={handleContinue}
                    loading={isUpdating || isDismissing}
                >
                    Continue
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}