import Joi from 'joi';

import { type ICreatePropertyRequest } from 'src/services/api';
import { PropertyType } from 'src/api/landlord-properties-api';
import { type ParsedCsv } from 'src/utils/csv';
import { getStateCodeByStateName } from 'src/services/location/constants/us';
import { type ProvinceCodeCA, provinceCodesCa } from 'src/services/location/constants/ca';

export const importPropertiesFields: string[] = [
    'type',
    'is_vacant',
    'rent',

    'street_address',
    'unit',
    'city',
    'state',
    'zipcode',

    'square_feet',
    'num_bedrooms',
    'num_bathrooms',
];

const NewPropertySchemaUS = Joi.object<ICreatePropertyRequest>({
    street_address: Joi.string().required(),
    unit: Joi.string().allow(''),
    city: Joi.string().required(),
    state: Joi.string().required().length(2),
    zipcode: Joi.string().required().length(5),

    type: Joi.string().valid(...Object.values(PropertyType)).required(),
    rent: Joi.number().allow(''),
    square_feet: Joi.number().allow(''),
    num_bedrooms: Joi.number().integer().required(),
    num_bathrooms: Joi.number().required(),

    is_vacant: Joi.bool().required(),
}).unknown(true);

const NewPropertySchemaCA = Joi.object<ICreatePropertyRequest>({
    street_address: Joi.string().required(),
    unit: Joi.string().allow(''),
    city: Joi.string().required(),
    state: Joi.string().required().length(2),
    zipcode: Joi.string().required().length(6),

    type: Joi.string().valid(...Object.values(PropertyType)).required(),
    rent: Joi.number().allow(''),
    square_feet: Joi.number().allow(''),
    num_bedrooms: Joi.number().integer().required(),
    num_bathrooms: Joi.number().required(),

    is_vacant: Joi.bool().required(),
}).unknown(true);

export type PropertyCsvRow = {
    [K in keyof ICreatePropertyRequest]: string;
};

const parseProperty = (row: PropertyCsvRow) => {
    // trim whitespace
    for (const key in row) {
        const value = row[key as keyof PropertyCsvRow];
        if (value) {
            row[key as keyof PropertyCsvRow] = value.trim();
        }
    }

    // conver to integers
    const squareFeetParsed = parseFloat(row.square_feet || '');
    if (isNaN(squareFeetParsed)) {
        // @ts-expect-error The operand of a 'delete' operator must be optional
        delete row.square_feet;
    } else {
        row.square_feet = String(Math.round(squareFeetParsed));
    }

    // delete square_feet if it's empty or if it's 0
    if (row.square_feet === '' || row.square_feet === '0') {
        // @ts-expect-error The operand of a 'delete' operator must be optional
        delete row.square_feet;
    }

    // delete unit if it's empty
    if (row.unit === '') {
        // @ts-expect-error The operand of a 'delete' operator must be optional
        delete row.unit;
    }

    // delete rent if it's empty or if it's 0
    if (row.rent === '' || row.rent === '0') {
        // @ts-expect-error The operand of a 'delete' operator must be optional
        delete row.rent;
    }

    // capitalize type
    row.type = row.type.charAt(0).toUpperCase() + row.type.slice(1).toLowerCase();

    // convert 'Townhome' to 'Townhouse'
    if (row.type === 'Townhome') {
        row.type = 'Townhouse';
    }

    // remove dollar sign and commas from rent
    if (typeof row.rent === 'string') {
        row.rent = row.rent.replace(/,/g, '');
        row.rent = row.rent.replace(/\$/g, '');
    }

    // replace state names with state codes
    row.state = getStateCodeByStateName(row.state) || row.state;

    // sanitize boolean values
    if (['true', 'yes'].includes(row.is_vacant?.toLowerCase())) {
        row.is_vacant = 'true';
    } else if (['false', 'no'].includes(row.is_vacant?.toLowerCase())) {
        row.is_vacant = 'false';
    }

    const country = provinceCodesCa.includes(row.state as ProvinceCodeCA) ?
        'Canada' :
        'United States';

    let validationResult = NewPropertySchemaUS.validate(row);
    if (country === 'Canada') {
        validationResult = NewPropertySchemaCA.validate(row);
    }

    const errors = validationResult.error?.details
        .map((o) => o.context?.key as string)
        .filter(Boolean);

    if (!validationResult.value) {
        return {
            errors,
        };
    }

    const property: ICreatePropertyRequest = {
        country,
        street_address: validationResult.value.street_address,
        unit: validationResult.value.unit,
        city: validationResult.value.city,
        state: validationResult.value.state,
        zipcode: validationResult.value.zipcode,

        type: validationResult.value.type,
        rent: validationResult.value.rent,
        square_feet: validationResult.value.square_feet,
        num_bedrooms: validationResult.value.num_bedrooms,
        num_bathrooms: validationResult.value.num_bathrooms,

        is_vacant: validationResult.value.is_vacant,
    };

    if (row.unit) {
        property.unit = row.unit;
    }

    return {
        errors,
        property,
    };
};

export const parseProperties = (parsedCsv: ParsedCsv<PropertyCsvRow>) => {
    const parsedProperties: Array<{
        errors: string[] | undefined;
        property: ICreatePropertyRequest;
    }> = [];

    parsedCsv.body
        .map(parseProperty)
        .forEach((parsedProperty) => {
            if (parsedProperty.property) {
                parsedProperties.push(parsedProperty);
            }
        });

    return parsedProperties;
};
export const getUnknownPropertyFields = (parsedCsv: ParsedCsv<PropertyCsvRow>) => {
    const unknownFields: string[] = [];

    parsedCsv.header.forEach((header) => {
        if (!importPropertiesFields.includes(header)) {
            unknownFields.push(header);
        }
    });

    return unknownFields;
};
