import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';

import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import { Flex, FlexColumn } from 'src/components/flex';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import Category from 'src/pages/landlord/conversations/components/list/ConversationsListCategory';
import NoSearchResults from 'src/pages/landlord/conversations/components/list/ConversationsNoSearchResults';

export default function ConversationsList() {
    const {
        showArchived,
        setShowArchived,
        conversationsUpdatedAt,
    } = useAuthenticatedState();
    const {
        isSearchOrFilterActive,
        filteredConversations,
        categories,
    } = useConversationsPageContext();

    const isSearchingWithoutResults = isSearchOrFilterActive && filteredConversations.length === 0;

    const [toggledArchivedAt, setToggledArchivedAt] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (
            isLoading &&
            toggledArchivedAt &&
            (toggledArchivedAt < new Date(conversationsUpdatedAt).toISOString())
        ) {
            setIsLoading(false);
        }
    }, [conversationsUpdatedAt, toggledArchivedAt, isLoading]);

    return (
        <FlexColumn pb={2}>
            {!isSearchingWithoutResults && categories.map((cat) => (
                <Category key={cat} category={cat} />
            ))}

            {isSearchingWithoutResults && (
                <NoSearchResults />
            )}

            <Flex width="100%" p={2} justifyContent="center" alignItems="center">
                <Typography >
                    Archived conversations are {showArchived ? 'visible' : 'hidden'}.
                </Typography>

                <LoadingButton
                    variant="text"
                    onClick={() => {
                        setToggledArchivedAt(new Date().toISOString());
                        setIsLoading(true);
                        setShowArchived(prev => !prev);
                    }}
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<ArchiveIcon />}
                    data-testid="show-archived-toggle"
                >
                    {showArchived ? 'Hide' : 'Show'} archived conversations
                </LoadingButton>
            </Flex>
        </FlexColumn>
    );
}
