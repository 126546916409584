import React, { useState } from 'react';
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { PropertyUtils } from 'src/utils';
import { useUpdateProperties } from 'src/services/api';
import { Flex, FlexColumn } from 'src/components/flex';
import Dialog from 'src/components/dialog/Dialog';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import Chip from 'src/components/chip';

export default function EditPropertyLabelsModal() {
    const {
        mutateAsync: updateProperties,
        isLoading: isUpdatingProperties,
    } = useUpdateProperties();

    const {
        propertyLabels,
        selectedProperties,
        setSelectedProperties,
        visibleProperties,
        setEditingPropertyLabels,
    } = usePropertiesPageContext();

    const initialValue = propertyLabels
        .filter((label) => selectedProperties.some((property) => property.labels.some((propertyLabel) => propertyLabel.id === label.id)))
        .map((label) => ({ value: label.id, label: label.name }));
    const [value, setValue] = useState(initialValue);

    const handleClose = () => {
        setEditingPropertyLabels(false);
        setSelectedProperties(visibleProperties.filter(o => selectedProperties.some(p => p.id === o.id)));
    };

    const handleSave = async () => {
        await updateProperties(selectedProperties.map((property) => {
            let vacantSince = property.vacant_since ? dayjs(property.vacant_since).format('YYYY-MM-DD') : null;
            if (!property.is_vacant) {
                vacantSince = null;
            }

            return {
                ...property,
                vacant_since: vacantSince,
            };
        }));

        setEditingPropertyLabels(false);
    };

    const numSelectedProperties = selectedProperties.length;
    const onePropertySelected = numSelectedProperties === 1;

    return (
        <Dialog open onClose={handleClose}>
            <DialogTitle>
                Edit Property Labels
            </DialogTitle>

            <DialogContent>
                {selectedProperties.length > 1 && (
                    <Accordion elevation={0} defaultExpanded sx={{ mb: 4 }}>
                        <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} sx={{ px: 0 }}>
                            Current Labels
                        </AccordionSummary>

                        <AccordionDetails>
                            {selectedProperties.map((property) => (
                                <FlexColumn key={property.id} rowGap={0}>
                                    <Typography>
                                        {property.name}
                                    </Typography>
                                    <Typography variant="body2">
                                        {property.labels.map((label) => label.name).join(', ') || 'No labels'}
                                    </Typography>
                                </FlexColumn>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                )}

                <Autocomplete
                    multiple
                    options={propertyLabels.map((label) => ({
                        value: label.id,
                        label: label.name,
                    }))}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search labels"
                            variant="standard"
                            autoFocus
                        />
                    )}
                    renderOption={(_props, option) => {
                        const someSelectedPropertiesHaveLabel = selectedProperties.some((property) => {
                            return property.labels.some((propertyLabel) => propertyLabel.id === option.value);
                        });
                        const someSelectedPropertiesDoNotHaveLabel = selectedProperties.some((property) => {
                            return !property.labels.some((propertyLabel) => propertyLabel.id === option.value);
                        });

                        const color = PropertyUtils.getLabelColor(propertyLabels, option.value);
                        const selected = value.some((o) => o.value === option.value);

                        return (
                            <MenuItem
                                key={option.value}
                                onClick={() => {
                                    const isAdding = !value.some((o) => o.value === option.value);
                                    const propertyLabel = propertyLabels.find((label) => label.id === option.value);
                                    if (!propertyLabel) {
                                        return;
                                    }

                                    if (isAdding) {
                                        setValue([...value, option]);
                                        setSelectedProperties((prev) => prev.map((property) => {
                                            if (!property.labels.some((o) => o.id === option.value)) {
                                                return {
                                                    ...property,
                                                    labels: [...property.labels, propertyLabel],
                                                };
                                            }
                                            return property;
                                        }));
                                    } else {
                                        setValue(value.filter((o) => o.value !== option.value));
                                        setSelectedProperties((prev) => prev.map((property) => {
                                            return {
                                                ...property,
                                                labels: property.labels.filter((o) => o.id !== option.value),
                                            };
                                        }));
                                    }
                                }}
                                sx={{ bgcolor: selected ? 'grey.200' : 'transparent' }}
                            >
                                {!onePropertySelected && (
                                    <Checkbox
                                        checked={someSelectedPropertiesHaveLabel}
                                        indeterminate={someSelectedPropertiesHaveLabel && someSelectedPropertiesDoNotHaveLabel}
                                        disabled
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                    />
                                )}

                                <Chip
                                    label={option.label}
                                    color={color?.[500]}
                                    size="small"
                                />
                            </MenuItem>
                        );
                    }}
                    renderTags={(tags, getTagProps) => tags.map((option, index) => {
                        const someSelectedPropertiesHaveLabel = selectedProperties.some((property) => {
                            return property.labels.some((propertyLabel) => propertyLabel.id === option.value);
                        });
                        const someSelectedPropertiesDoNotHaveLabel = selectedProperties.some((property) => {
                            return !property.labels.some((propertyLabel) => propertyLabel.id === option.value);
                        });

                        const color = PropertyUtils.getLabelColor(propertyLabels, option.value);

                        return (
                            <Chip
                                {...getTagProps({ index })}
                                key={option.value}
                                label={(
                                    <Flex alignItems="center" columnGap={0.5}>
                                        {!onePropertySelected && (
                                            <Checkbox
                                                checked={someSelectedPropertiesHaveLabel}
                                                indeterminate={someSelectedPropertiesHaveLabel && someSelectedPropertiesDoNotHaveLabel}
                                                disabled
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                            />
                                        )}

                                        <Typography variant="body2" sx={{ color: color?.[500] }}>
                                            {option.label}
                                        </Typography>
                                    </Flex>
                                )}
                                onDelete={() => {
                                    setValue(value.filter((o) => o.value !== option.value));
                                    setSelectedProperties((prev) => prev.map((property) => {
                                        return {
                                            ...property,
                                            labels: property.labels.filter((o) => o.id !== option.value),
                                        };
                                    }));
                                }}
                                color={color?.[500]}
                                size="small"
                            />
                        );
                    })}

                    sx={{
                        '& .MuiChip-root': { height: '24px' },
                        '& .MuiSvgIcon-root.MuiChip-deleteIcon': { mx: 0.5 },
                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isUpdatingProperties}
                    onClick={handleSave}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog >
    );
}
