import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead } from 'src/services/api/leads/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { getApiErrorMessage } from 'src/services/api/utils';

export const useDeleteLead = (leadUuid: string) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<unknown, IStandardErrorResponse>(
        async () => {
            const path = `${GET_LEADS_PATH}/${leadUuid}`;
            await apiClient(path, { method: 'delete' });
        }, {
            onSuccess: () => {
                queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                    if (!oldData) {
                        return [];
                    }

                    return oldData.filter((lead) => lead.uuid !== leadUuid);
                });

                queryClient.removeQueries([queryKeys.leads, leadUuid]);

                addNotification('Lead deleted', 'success');
            },
            onError: (error) => {
                const errorMessage = getApiErrorMessage(error) || 'Failed to delete lead';
                addNotification(errorMessage, 'error');
            }
        });
};
