import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead } from 'src/services/api/leads/types';
import { apiClient } from 'src/services/api/client';
import { useNotifications } from 'src/notifications';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { queryKeys } from 'src/services/api/constants';

type IUpdateLeadsArchivedStatusesRequest = Array<Pick<ILead, 'uuid' | 'is_archived'>>;

export const useUpdateLeadsStatuses = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        void,
        IStandardErrorResponse,
        IUpdateLeadsArchivedStatusesRequest
    >(async (payload) => {
        await Promise.all(payload.map(leadUpdate => {
            const path = `${GET_LEADS_PATH}/${leadUpdate.uuid}/status`;
            const json = { is_archived: leadUpdate.is_archived };
            return apiClient(path, { method: 'patch', json });
        }));
    }, {
        onSuccess: (_data, variables) => {
            queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                if (!oldData) {
                    return [];
                }

                return oldData.map((lead) => {
                    const leadUpdate = variables.find(o => o.uuid === lead.uuid);
                    if (leadUpdate) {
                        return {
                            ...lead,
                            is_archived: !!leadUpdate.is_archived,
                        };
                    }

                    return lead;
                });
            });

            for (const leadUpdate of variables) {
                const oldData = queryClient.getQueryData<ILead>([queryKeys.leads, leadUpdate.uuid]);
                if (oldData) {
                    queryClient.setQueryData<ILead>([queryKeys.leads, leadUpdate.uuid], {
                        ...oldData,
                        is_archived: !!leadUpdate.is_archived,
                    });
                }
            }

            addNotification('Lead status updated', 'success');
        },
        onError: (error) => {
            queryClient.invalidateQueries(queryKeys.leads);
            const errorMessage = error.msg || 'Failed to archive leads';
            addNotification(errorMessage, 'error');
        }
    });
};
