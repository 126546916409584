import React from 'react';

import ReffieGreenChip from 'src/components/ReffieGreenChip';
import type { ILabel } from 'src/services/api/labels';

interface Props {
    /**
     * Additional classes
     */
    className?: string;
    label: ILabel;
    onClick?: () => void;
    size?: 'small' | 'medium';
}

export default function PropertyLabel({
    className,
    label,
    onClick,
    size,
}: Props) {
    return (
        <ReffieGreenChip
            className={className}
            label={label.name}
            onClick={onClick}
            size={size}
        />
    );
}