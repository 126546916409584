import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ChartLine() {
    return (
        <SvgIcon>
            <svg width="20" height="18" viewBox="-1 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 11.5002V13.3C1 14.4201 1 14.9798 1.21799 15.4076C1.40973 15.7839 1.71547 16.0905 2.0918 16.2822C2.5192 16.5 3.07899 16.5 4.19691 16.5H19.0002M1 11.5002V1.5M1 11.5002L4.8534 8.28906L4.85658 8.28651C5.55366 7.70561 5.90288 7.41459 6.28154 7.29639C6.72887 7.15675 7.21071 7.17884 7.64355 7.3584C8.0105 7.51062 8.33225 7.83238 8.97576 8.47589L8.98222 8.48234C9.63571 9.13584 9.96333 9.46346 10.3362 9.61535C10.7774 9.79508 11.2685 9.81056 11.7207 9.66057C12.1041 9.5334 12.4542 9.22755 13.1543 8.61496L19 3.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}