import { type Option } from 'src/components/input/select/Select';

export enum ProvinceCodeCA {
    Alberta = 'AB',
    BritishColumbia = 'BC',
    Manitoba = 'MB',
    NewBrunswick = 'NB',
    Newfoundland = 'NL',
    NorthwestTerritories = 'NT',
    NovaScotia = 'NS',
    Nunavut = 'NU',
    Ontario = 'ON',
    PrinceEdwardIsland = 'PE',
    Quebec = 'QC',
    Saskatchewan = 'SK',
    Yukon = 'YT',
}

export const provinceCodesCa = Object.values(ProvinceCodeCA);

enum ProvinceNameCA {
    Alberta = 'Alberta',
    BritishColumbia = 'British Columbia',
    Manitoba = 'Manitoba',
    NewBrunswick = 'New Brunswick',
    Newfoundland = 'Newfoundland',
    NorthwestTerritories = 'Northwest Territories',
    NovaScotia = 'Nova Scotia',
    Nunavut = 'Nunavut',
    Ontario = 'Ontario',
    PrinceEdwardIsland = 'Prince Edward Island',
    Quebec = 'Quebec',
    Saskatchewan = 'Saskatchewan',
    Yukon = 'Yukon',
}

export const provinceNamesCa = {
    [ProvinceCodeCA.Alberta]: ProvinceNameCA.Alberta,
    [ProvinceCodeCA.BritishColumbia]: ProvinceNameCA.BritishColumbia,
    [ProvinceCodeCA.Manitoba]: ProvinceNameCA.Manitoba,
    [ProvinceCodeCA.NewBrunswick]: ProvinceNameCA.NewBrunswick,
    [ProvinceCodeCA.Newfoundland]: ProvinceNameCA.Newfoundland,
    [ProvinceCodeCA.NorthwestTerritories]: ProvinceNameCA.NorthwestTerritories,
    [ProvinceCodeCA.NovaScotia]: ProvinceNameCA.NovaScotia,
    [ProvinceCodeCA.Nunavut]: ProvinceNameCA.Nunavut,
    [ProvinceCodeCA.Ontario]: ProvinceNameCA.Ontario,
    [ProvinceCodeCA.PrinceEdwardIsland]: ProvinceNameCA.PrinceEdwardIsland,
    [ProvinceCodeCA.Quebec]: ProvinceNameCA.Quebec,
    [ProvinceCodeCA.Saskatchewan]: ProvinceNameCA.Saskatchewan,
    [ProvinceCodeCA.Yukon]: ProvinceNameCA.Yukon,
};

export const provinceOptionsCA = Object.entries(provinceNamesCa)
    .map(([value, label]) => ({ value, label } as Option<ProvinceCodeCA>));

export const getProvinceCodeByProvinceName = (provinceName?: string) => {
    if (!provinceName) {
        return;
    }

    return Object.entries(provinceNamesCa).find(([, name]) => name === provinceName)?.[0] as ProvinceCodeCA;
};