import React from 'react';

import type { IShowing } from 'src/api/landlord-showings-api';
import type { ILead } from 'src/services/api';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';
import Tour from 'src/components/lead-sidebar/LeadSidebarTour';

interface Props {
    lead: ILead,
    showings: IShowing[],
}

export default function LeadSidebarUpcomingTour({
    lead,
    showings,
}: Props) {
    const upcomingShowings = showings.filter((showing) => {
        return showing.lead_uuid === lead.uuid && showing.start_time >= new Date().toISOString();
    });

    const upcomingShowing = upcomingShowings[0];

    if (!upcomingShowing) {
        return null;
    }

    return (
        <ExpandableItem title="Upcoming Tour">
            <Tour
                lead={lead}
                showing={upcomingShowing}
                color="primary.main"
            />
        </ExpandableItem>
    );
}
