import React from 'react';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';

import Responsive from 'src/components/responsive';
import { Flex } from 'src/components/flex';
import {
    propertiesPageTabs,
    usePropertiesPageContext
} from 'src/pages/landlord/properties/provider';

export default function PropertiesPageTabs() {
    const {
        tab,
        setTab,
        filteredProperties,
        selectedProperties,
        setSelectedProperties,
        setFocusedProperty,
        rowsPerPage,
        page,
        setPage: _setPage,
        visibleProperties,
    } = usePropertiesPageContext();

    const numProperties = filteredProperties.length;
    const hasProperties = !!filteredProperties.length;

    const allSelected = hasProperties &&
        visibleProperties.every((property) => {
            return selectedProperties.some((selectedProperty) => {
                return selectedProperty.id === property.id;
            });
        });

    const selectAll = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (allSelected) {
            setSelectedProperties(prev => {
                return prev.filter((property) => {
                    return !visibleProperties.some((_selectedProperties) => {
                        return _selectedProperties.id === property.id;
                    });
                });
            });
        } else {
            setSelectedProperties(prev => {
                const newSelectedConversations = visibleProperties.filter((property) => {
                    return !prev.some((_selectedProperties) => {
                        return _selectedProperties.id === property.id;
                    });
                });

                return [...prev, ...newSelectedConversations];
            });
        }
    };

    const numPages = Math.ceil((filteredProperties?.length || 0) / rowsPerPage);

    let endIndex = page * rowsPerPage;
    if (endIndex > numProperties) {
        endIndex = numProperties;
    }
    const startIndex = (page - 1) * rowsPerPage + 1;

    const setPage = (newPage: number) => {
        _setPage(newPage);
        setSelectedProperties([]);
        setFocusedProperty(null);
    };

    return (
        <Flex
            px={1}
            justifyContent="space-between"
            alignItems="center"
            bgcolor="white"
            borderTop="1px solid var(--border-color)"
            borderBottom="1px solid var(--border-color)"
        >
            <Flex>
                <Responsive desktop>
                    {/* checkbox table cell width minus left padding and column gap*/}
                    <Flex alignItems="center" sx={{ width: 'calc(40px - 16px)' }}>
                        <Checkbox
                            checked={allSelected}
                            onClick={selectAll}
                            sx={{
                                padding: 0,
                                width: 20,
                                height: 20,
                                '& svg': { fontSize: 20 }
                            }}
                            data-testid="select-all-checkbox"
                        />
                    </Flex>
                </Responsive>

                <Tabs
                    variant="scrollable"
                    value={tab}
                    sx={{
                        maxWidth: 'calc(100vw - 16px)',
                        height: '100%',
                        columnGap: 0.5,
                        '& .MuiTabs-flexContainer': { height: '100%' }
                    }}
                >
                    {propertiesPageTabs.map((_tab) => {
                        return (
                            <Tab
                                key={_tab}
                                label={_tab}
                                value={_tab}
                                onClick={() => setTab(_tab)}
                                sx={{ minWidth: 'unset' }}
                                data-testid={`properties-tab-${_tab.toLowerCase()}`}
                            />
                        );
                    })}
                </Tabs>
            </Flex>

            {numPages > 0 && (
                <Flex py={0.5} alignItems="center" onClick={event => event.stopPropagation()}>
                    <Responsive desktop>
                        <Typography variant="caption">
                            {startIndex} - {endIndex} of {filteredProperties.length}
                        </Typography>
                    </Responsive>

                    <Responsive mobile>
                        <Typography variant="caption">
                            {startIndex} - {endIndex}
                        </Typography>
                    </Responsive>

                    <Flex columnGap={0} sx={{ '& .MuiIconButton-root': { p: 0 } }}>
                        <IconButton
                            onClick={() => setPage(page - 1)}
                            disabled={page === 1}
                            size="small"
                            data-testid="previous-page-button"
                        >
                            <ChevronLeftIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => setPage(page + 1)}
                            disabled={page === numPages}
                            size="small"
                            data-testid="next-page-button"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
}
