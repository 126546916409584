import React, { useMemo } from 'react';
import { createFilterOptions } from '@mui/material';

import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import Autocomplete from 'src/components/input/autocomplete/Autocomplete';

type Props = Omit<React.ComponentProps<typeof Autocomplete>, 'options'>;

export default function SelectLead({
    value,
    ...props
}: Props) {
    const { leads } = useAuthenticatedState();

    const options = useMemo(() => {
        if (!leads) {
            return [];
        }

        const filteredLeads = leads;

        // Add the selected property to the top of the list if it is not already in the list
        const selectedLead = leads.find((lead) => lead.uuid === value);
        const selectedLeadNotInOptions = !filteredLeads.find((o) => o.uuid === selectedLead?.uuid);
        if (selectedLead && selectedLeadNotInOptions) {
            filteredLeads.unshift(selectedLead);
        }

        return filteredLeads
            .map((lead) => ({
                value: lead.uuid,
                label: lead.name,
            }));
    }, [leads, value]);

    return (
        <Autocomplete
            {...props}
            value={value}
            options={options}
            filterOptions={filterOptions}
            getOptionKey={(option) => option.value || ''}
        />
    );
}

const filterOptions: Props['filterOptions'] = createFilterOptions({
    limit: 100,
});
