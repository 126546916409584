import { useQuery } from 'react-query';

import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api';
import type { IIntegration } from 'src/services/api/integrations/types';

const PATH = 'api/landlord/facebook-integration';

type Response = {
    facebook: IIntegration;
}

export const useFacebookIntegration = () => {
    return useQuery(
        [queryKeys.facebookIntegration],
        async () => {
            const response = await apiClient(PATH).json<Response>();
            return response?.facebook;
        }
    );
};
