import { useMutation, useQueryClient, } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IPropertyLabel } from 'src/services/api/properties/labels/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { LabelType } from 'src/services/api/labels/types';
import { GET_PROPERTY_LABELS_PATH } from 'src/services/api/properties/labels/constants';

type ICreatePropertyLabelResponse = {
    label: IPropertyLabel;
}
type ICreatePropertyLabelRequest = Omit<IPropertyLabel, 'id' | 'type' | 'color'>;

// TODO: Move notifcation logic to this function
export const useCreatePropertyLabel = () => {
    const queryClient = useQueryClient();

    return useMutation<
        IPropertyLabel,
        IStandardErrorResponse,
        ICreatePropertyLabelRequest
    >(
        async (payload) => {
            const json = { ...payload, type: LabelType.PROPERTY };
            const res = await apiClient(GET_PROPERTY_LABELS_PATH, {
                method: 'post',
                json,
            }).json<ICreatePropertyLabelResponse>();

            return res.label;
        }, {
            onSuccess: (data) => {
                queryClient.setQueryData<IPropertyLabel[]>(queryKeys.propertyLabels, (oldData) => {
                    if (!oldData) {
                        return [data];
                    }

                    return [...oldData, data];
                });
            }
        }
    );
};
