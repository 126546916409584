/**
 * Supported property types
 * Should mirror the PropertyType enum in models.py
 * Except backend does not support the empty string one (that's just for UI)
 */
export enum PropertyType {
    None = 'None',
    Apartment = 'Apartment',
    ApartmentBuilding = 'Apartment Building',
    Condo = 'Condo',
    House = 'House',
    Room = 'Room',
    Townhouse = 'Townhouse',
}

export const propertyTypes = Object.values(PropertyType);
export const propertyTypeOptions = propertyTypes.map((type) => ({
    label: type,
    value: type,
}));

export interface IShowingSchedulingLink {
    id: number;
    property_id: number;
    leasing_team_id: number;
    link: string;
    created_at: Date;
    modified_at: Date;
}

export interface IPropertyApplicationLink {
    id: number;
    property_id: number;
    leasing_team_id: number;
    link: string;
    created_at: Date;
    modified_at: Date;
}
