import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import VerticalDotsIcon from '@mui/icons-material/MoreVert';
import FilterIcon from '@mui/icons-material/FilterListRounded';

import { Flex, FlexColumn } from 'src/components/flex';
import Search from 'src/pages/landlord/conversations/components/header/ConversationsSearch';
import FilterStatus from 'src/pages/landlord/conversations/components/header/ConversationsFilterStatus';
import FilterButton from 'src/pages/landlord/conversations/components/header/ConversationsFilterButton';
import Responsive from 'src/components/responsive';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

export default function ConversationsHeader() {
    const { setFilterOpen } = useConversationsPageContext();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    return (
        <>
            <FlexColumn p={2}>
                <Flex alignItems="center">
                    <Search />
                    <Responsive desktop>
                        <div>
                            <FilterButton />
                        </div>
                    </Responsive>
                    <Responsive mobile>
                        <div>
                            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} edge="end">
                                <VerticalDotsIcon />
                            </IconButton>
                        </div>
                    </Responsive>
                </Flex>

                <Flex
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiChip-root': { maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis' },
                        '& .MuiSvgIcon-root': { ml: 1, fontSize: 20 }
                    }}
                >
                    <FilterStatus />
                </Flex>
            </FlexColumn>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                onClick={() => setAnchorEl(undefined)}
            >
                <MenuItem onClick={() => setFilterOpen(true)}>
                    <ListItemIcon>
                        <FilterIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>Filter</Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
