import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Responsive from 'src/components/responsive/Responsive';
import { Flex } from 'src/components/flex';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import { conversationTabs } from 'src/pages/landlord/conversations/context/types';

export default function ConversationsNavigation() {
    const {
        tab,
        setTab,
        filteredConversations,
        selectedConversations,
        setSelectedConversations,
        visibleConversationsByCategory
    } = useConversationsPageContext();

    const nOfConversations = filteredConversations.length;
    const hasConversations = !!nOfConversations;
    const visibleConversations = Object.values(visibleConversationsByCategory).flat();

    const allSelected = hasConversations &&
        visibleConversations.every((conversation) => {
            return selectedConversations.some((
                selectedConversation
            ) => selectedConversation.lead_uuid === conversation.lead_uuid);
        });

    const selectAll = () => {
        if (allSelected) {
            setSelectedConversations(prev => {
                return prev.filter((conversation) => {
                    return !visibleConversations.some((
                        selectedConversation
                    ) => selectedConversation.lead_uuid === conversation.lead_uuid);
                });
            });
        } else {
            setSelectedConversations(prev => {
                const newSelectedConversations = visibleConversations.filter((conversation) => {
                    return !prev.some((
                        selectedConversation
                    ) => selectedConversation.lead_uuid === conversation.lead_uuid);
                });

                return [...prev, ...newSelectedConversations];
            });
        }
    };

    return (
        <Flex
            borderTop="1px solid var(--border-color)"
            bgcolor="common.white"
            justifyContent="space-between"
            alignItems="stretch"
        >
            <Flex alignItems="stretch" columnGap={0}>
                <Responsive desktop>
                    <Flex pl={1} py={1} justifyContent="center" alignItems="center">
                        <Checkbox
                            checked={allSelected}
                            onClick={selectAll}
                            sx={{
                                width: 20,
                                height: 20,
                                '& svg': { fontSize: 20 }
                            }}
                        />
                    </Flex>
                </Responsive>

                <Tabs
                    variant="scrollable"
                    value={tab}
                    sx={{
                        maxWidth: 'calc(100vw - 16px)',
                        height: '100%',
                        columnGap: 0.5,
                        '& .MuiTabs-flexContainer': { height: '100%' }
                    }}
                >
                    {conversationTabs.map((_tab) => (
                        <Tab
                            key={_tab}
                            label={_tab}
                            value={_tab}
                            onClick={() => setTab(_tab)}
                            iconPosition="end"
                            sx={{ minWidth: 'unset' }}
                        />
                    ))}
                </Tabs>
            </Flex>
        </Flex>
    );
}
