import { useContext } from 'react';

import { LeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/context';

export const useLeadMessagesPageContext = () => {
    const context = useContext(LeadMessagesPageContext);

    if (!context) {
        throw new Error('useLeadMessagesPageContext must be used within a LeadMessagesPageProvider');
    }

    return context;
};