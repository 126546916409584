import Joi from 'joi';

import type { ICreateLeadRequest } from 'src/services/api';
import { type ParsedCsv } from 'src/utils/csv';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export const importLeadsFields = [
    'name',
    'email',
    'phone_number',
    'ingestion_source',
    'property',
];

const NewLeadSchema = Joi.object<ICreateLeadRequest>({
    name: Joi.string().required(),
    email: Joi.string().email({ tlds: false }),
    phone_number: Joi.string().custom((value, helper) => {
        const checkPhone = (number: string) => {
            return isPossiblePhoneNumber(number, 'US');
        };
        if (!checkPhone(value)) {
            return helper.error('any.invalid');
        }
        return value;
    }),
    ingestion_source: Joi.string().required(),
    property: Joi.string().required(),
}).unknown(true);

export type LeadCsvRow = {
    [K in keyof ICreateLeadRequest]: string;
};

function parseLead(row: LeadCsvRow) {
    // trim whitespace
    for (const key in row) {
        const value = row[key as keyof LeadCsvRow];
        if (value) {
            row[key as keyof LeadCsvRow] = value.trim();
        }
    }

    // delete phone number if it's empty
    if (row.phone_number === '') {
        delete row.phone_number;
    }

    const validationResult = NewLeadSchema.validate(row);

    const lead: ICreateLeadRequest = {
        name: row.name,
        email: row.email,
        phone_number: row.phone_number,
        ingestion_source: row.ingestion_source,
        property: row.property,
    };

    const errors = validationResult.error?.details
        .map((o) => o.context?.key as string)
        .filter(Boolean);

    return {
        errors,
        lead,
    };
}

export function parseLeads(parsedCsv: ParsedCsv<LeadCsvRow>) {
    return parsedCsv.body.map(parseLead);
}

export const getUnknownLeadFields = (parsedCsv: ParsedCsv<LeadCsvRow>) => {
    const unknownFields: string[] = [];

    parsedCsv.header.forEach((header) => {
        if (!importLeadsFields.includes(header)) {
            unknownFields.push(header);
        }
    });

    return unknownFields;
};
