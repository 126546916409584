/**
 * NOTE: to get all showings, use react-query's use-showings instead
 */

import ky from 'ky';

import { type IStandardResponse, createBearerHeader } from 'src/common/api-utils';
import { Config } from 'src/config';

/**
 * Mirrors the showing class in `models.py`
 */
export interface IShowing {
    id: number;

    landlord_user_id: number;
    property_id: number;
    lead_uuid: string;
    lead: {
        name: string;
    };

    start_time: string;

    source_message_uuid: string | null;
    has_google_calendar_event: boolean;
    google_calendar_link: string | null;
    has_outlook_calendar_event: boolean;
}

interface IUpdateShowingResponse {
    status: 'success';
    msg: string;
    updated_showing: IShowing;
}

export async function updateShowing(accessToken: string, updatedShowing: IShowing): Promise<IUpdateShowingResponse> {
    if (!accessToken) {
        throw new Error('No access token provided');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/showings/${updatedShowing.id}`;
    const headers = createBearerHeader(accessToken);

    // massage the start_time to a string that includes timezone
    const showingData = Object.assign({}, updatedShowing) as any;
    showingData.start_time = updatedShowing.start_time;
    const data = {
        showing: showingData,
    };
    return await ky.patch(url.toString(), {
        headers: headers,
        json: data,
    }).json() as IUpdateShowingResponse;
}

export async function deleteShowing(accessToken: string, showingId: number): Promise<IStandardResponse> {
    if (!accessToken) {
        throw new Error('No access token provided');
    }
    if (showingId <= 0) {
        throw new Error('Invalid showing ID');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/showings/${showingId}`;
    const headers = createBearerHeader(accessToken);
    return ky.delete(url.toString(), {
        headers: headers,
    }).json() as Promise<IStandardResponse>;
}


export default {
    updateShowing,
    deleteShowing,
};