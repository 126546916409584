import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import VerticalDotsIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/AddRounded';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import FilterIcon from '@mui/icons-material/FilterListRounded';

import { SortIcon } from 'src/icons';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

export default function PropertiesMobileMenu() {
    const {
        setAddingProperty,
        setEditingAllPropertyLabels,
        setFilterOpen,
        setSortingOpen
    } = usePropertiesPageContext();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} edge="end">
                <VerticalDotsIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                onClick={() => setAnchorEl(undefined)}
            >
                <MenuItem onClick={() => setAddingProperty(true)}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                        New Property
                    </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => setEditingAllPropertyLabels(true)}>
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Labels
                    </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => setFilterOpen(true)}>
                    <ListItemIcon>
                        <FilterIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Filter
                    </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => setSortingOpen(true)}>
                    <ListItemIcon>
                        <SortIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Sort
                    </ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}
