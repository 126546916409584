import { useMutation, useQueryClient } from 'react-query';
import pluralize from 'pluralize';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IProperty } from 'src/services/api/properties/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';
import { getApiErrorMessage } from 'src/services/api/utils';

export type IUpdatePropertyOccupanciesRequest = Array<{
    id: number;
    is_vacant: boolean;
    tennant_lead_uuid?: string;
    occupancy_start_date?: string;
    occupancy_end_date?: string;
    vacant_start_date?: string;
}>

export const useUpdatePropertyOccupancies = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IProperty[],
        IStandardErrorResponse,
        IUpdatePropertyOccupanciesRequest
    >(
        async (payload) => {
            const res = await Promise.allSettled(payload.map((payloadItem) => {
                const path = `${GET_PROPERTIES_PATH}/${payloadItem.id}/occupancy`;
                return apiClient(path, {
                    method: 'patch',
                    json: payloadItem,
                });
            }));


            const oldProperties = queryClient.getQueryData<IProperty[]>(queryKeys.properties) || [];

            const payloadItemsByPropertyIdArr = payload.map((payloadItem) => ([
                payloadItem.id,
                payloadItem,
            ] as const));

            const successfullPayloadItemsByPropertyId = payloadItemsByPropertyIdArr.map((o, i) => {
                const isSuccess = res[i].status === 'fulfilled';
                return isSuccess ? o : null;
            }).filter(Boolean).reduce((acc, curr) => {
                if (!curr) { return acc; }
                const [id, payloadItem] = curr;
                acc[id] = payloadItem;
                return acc;
            }, {} as Record<number, IUpdatePropertyOccupanciesRequest[0]>);

            const updatedProperties = oldProperties.map((property) => {
                const propertyUpdate = successfullPayloadItemsByPropertyId[property.id];

                if (!propertyUpdate) {
                    return property;
                }

                return {
                    ...property,
                    is_vacant: propertyUpdate.is_vacant,
                };
            });

            queryClient.setQueryData<IProperty[]>(queryKeys.properties, updatedProperties);
            for (const property of updatedProperties) {
                queryClient.setQueryData<IProperty>([queryKeys.properties, property.id], property);
            }

            const errors = res.filter((r) => r.status === 'rejected');
            if (errors.length > 0) {
                throw errors.map(o => o.reason);
            } else {
                addNotification(`Property ${pluralize('occupancy', payload.length)} updated`, 'success');
            }

            return updatedProperties;
        }, {
            onError: (errors) => {
                const errorMessage = getApiErrorMessage(errors) || 'Failed to update property occupancies';
                addNotification(errorMessage, 'error');
            }
        });
};
