const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export class CurrencyUtils {
    static format(amount: number) {
        return currencyFormatter.format(amount);
    }
}
