import type { Dayjs } from 'dayjs';

import type { ILeadLabel } from 'src/services/api';

export enum LeadFormField {
  Name = 'name',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  IngestionSource = 'ingestionSource',
  PropertyId = 'propertyId',
  FirstContactDate = 'firstContactDate',
  Labels = 'labels',
  OwnerId = 'ownerId',
  FacebookUserId = 'facebookUserId',
}

export const leadFormFields = Object.values(LeadFormField) as LeadFormField[];

export type LeadForm = {
  [LeadFormField.Name]: string;
  [LeadFormField.Email]: string;
  [LeadFormField.PhoneNumber]: string;
  [LeadFormField.IngestionSource]: string;
  [LeadFormField.PropertyId]: string;
  [LeadFormField.FirstContactDate]: Dayjs | null;
  [LeadFormField.Labels]: ILeadLabel[];
  [LeadFormField.OwnerId]: string;
  [LeadFormField.FacebookUserId]: string;
};
