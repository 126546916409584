import { useQuery } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IMessage } from 'src/services/api/messages/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';

type IGetLeadMessagesResponse = {
    messages: IMessage[];
}

type IGetLeadMessagesOptions ={
    isArchived?: boolean;
    refetchInterval?: number;
}

const GET_MESSAGES_PATH = 'api/landlord/messages/from-lead';

export const useLeadMessages = (
    leadUuid: string,
    options?: IGetLeadMessagesOptions,
) => {
    return useQuery<
        IGetLeadMessagesResponse['messages'],
        IStandardErrorResponse
    >(
        [queryKeys.messages, leadUuid],
        async () => {
            const response = await apiClient(GET_MESSAGES_PATH, {
                searchParams: { lead_uuid: leadUuid },
            }).json<IGetLeadMessagesResponse>();

            return response.messages;
        }, {
            enabled: !!leadUuid,
            refetchInterval: options?.refetchInterval,
        }
    );
};
