export enum LabelType {
    PROPERTY = 'property',
    LEAD = 'lead',
}

export type ILabel = {
    id: number;
    name: string;
    color: string;
    type: LabelType;
};
