import { styled } from '@mui/material/styles';
import MuiTableCell from '@mui/material/TableCell';

const TableCell = styled(MuiTableCell)(({ theme, component, padding }) => {
    const base = {
        border: 'none',
        padding: padding === 'none' ? 0 : theme.spacing(1),
        verticalAlign: 'top',

        '& .MuiTypography-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    const isHead = component === 'th';

    if (isHead) {
        return {
            ...base,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontWeight: 700,
        };
    }

    return base;
});

export default TableCell;