import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

import { useNotifications } from 'src/notifications';
import Auth from 'src/services/api/auth/Auth';
import { redirectToLandlordLogin } from 'src/api';
import type { ILead } from 'src/services/api';
import showingsAPI, { type IShowing } from 'src/api/landlord-showings-api';
import {
    type ICreateShowingRequest,
    queryKeys,
    useCreateShowing,
    useProperties,
    useShowings
} from 'src/services/api';
import { BlackButtonWhiteText } from 'src/common/Buttons';
import { FlexColumn } from 'src/components/flex';
import { Transition } from 'src/components/ModalTransition';
import ReffieLoadingButton from 'src/components/reffie-loading-button/ReffieLoadingButton';

import modalButtonStyles from 'src/common/ModalButton.module.css';

interface Props {
    open: boolean;
    onClose: () => void;
    /**
     * The lead that is initially selected
     * Do not pass anything (undefined) if none are selected
     */
    selectedLeadUuid?: string;
    /**
     * Necessary to edit the showing
     */
    landlordUserId?: number;
    /**
     * Array of leads to choose from
     * Necessary for the user to be able to switch between leads
     * If you do not want the user to switch between leads, simply pass a single lead
     */
    leads: ILead[];
    /**
     * Houses both the date and time
     */
    initDateTime?: string;
    initPropertyId: number | null;
    /**
     * If this is an existing showing, this will be the ID of the showing
     */
    showingId?: number;
    /**
     * If this showing was created from message contents, this is the UUID of the message
     */
    sourceMessageUuid?: string;
    onCreated?: (showing: ICreateShowingRequest) => void;
}

export default function CreateOrUpdateTourModal(props: Props) {
    const accessToken = Auth.accessToken;
    if (!accessToken) {
        console.error('Failed to load access token from React context');
        redirectToLandlordLogin('/landlord/inner/rental-applications');
        return <></>;
    }

    const queryClient = useQueryClient();

    const { addNotification } = useNotifications();

    const initSelectedLead = props.leads.find((lead) => lead.uuid === props.selectedLeadUuid) || null;
    const initDateVal = props.initDateTime ? dayjs(props.initDateTime) : null;

    const { refetch: refetchShowings } = useShowings();
    const { mutateAsync: createShowing } = useCreateShowing();
    const { data: properties } = useProperties();

    const [lead, setLead] = useState<{ value: string, label: string | null } | null>(
        initSelectedLead ? { value: initSelectedLead.uuid, label: initSelectedLead.name } : null
    );
    const [property, setProperty] = useState<{ value: number, label: string } | null>();

    const [date, setDate] = useState<dayjs.Dayjs | null>(initDateVal);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [isSaveOrUpdateLoading, setIsSaveOrUpdateLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleCreate = async () => {
        if (!lead) {
            setErrorMessage('please select a lead');
            return;
        }
        if (!property) {
            setErrorMessage('please select a property');
            return;
        }
        if (!date) {
            setErrorMessage('please select a date');
            return;
        }
        const payload: ICreateShowingRequest = {
            property_id: property?.value,
            lead_uuid: lead.value,
            start_time: date.toDate(),
            source_message_uuid: props.sourceMessageUuid || null,
        };
        try {
            await createShowing(payload);
            props.onClose();
            props?.onCreated?.(payload);
        } catch (err) {
            console.error(err);
        }
    };

    const handleEdit = async () => {
        // TODO
        if (!property) {
            setErrorMessage('please select a property');
            return;
        }
        if (!date) {
            setErrorMessage('please select a date');
            return;
        }

        const updatedShowing = {
            id: props.showingId,
            landlord_user_id: props.landlordUserId,
            property_id: property.value,
            lead_uuid: lead?.value,
            start_time: date.toISOString(),
            source_message_uuid: props.sourceMessageUuid || null,
        } as IShowing;
        // TODO catch any errors from the API call
        await showingsAPI.updateShowing(accessToken, updatedShowing);
        queryClient.invalidateQueries(queryKeys.showings);
        addNotification('Showing updated successfully!', 'success');
        await refetchShowings();
        props.onClose();
    };

    const handleSave = async () => {
        setIsSaveOrUpdateLoading(true);

        try {
            if (props.showingId) {
                await handleEdit();
            } else {
                await handleCreate();
            }
        } finally {
            setIsSaveOrUpdateLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!props.showingId) {
            throw new Error('props.showingId is null');
        }
        try {
            setIsDeleteLoading(true);
            await showingsAPI.deleteShowing(accessToken, props.showingId);
            queryClient.invalidateQueries(queryKeys.showings);
            addNotification('Showing deleted successfully!', 'success');
            await refetchShowings();
            props.onClose();
        } finally {
            setIsDeleteLoading(false);
        }
    };

    const leadOptions = useMemo(() => {
        return props.leads.map((o) => {
            if (!o.name) {
                console.error(`Lead ${o.uuid} has no name`);
            }
            return {
                value: o.uuid,
                label: o.name,
            };
        });
    }, [props.leads]);

    const propertyOptions = useMemo(() => {
        if (!properties) {
            return [];
        }

        return properties?.map((o) => ({
            value: o.id,
            label: o.name,
        }));
    }, [properties]);

    useEffect(() => {
        const leadProperty = propertyOptions?.find((o) => o.value === props.initPropertyId);
        setProperty(prev => {
            if (prev === undefined) {
                return leadProperty;
            } else {
                return prev;
            }
        });
    }, [props.leads, propertyOptions, props.initPropertyId]);

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="sm"
            TransitionComponent={Transition}
        >
            <DialogTitle>{props.showingId ? 'Edit Tour' : 'Create a New Tour'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can save tour dates in Reffie to help you keep track of where your tours are.
                </DialogContentText>

                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

                <form>
                    <FlexColumn>
                        <div>
                            <FormControl fullWidth>
                                {initSelectedLead ?
                                    <TextField variant="filled"
                                        fullWidth
                                        label="Lead Name"
                                        value={initSelectedLead.name || ''}
                                        aria-readonly
                                        inputProps={{ readOnly: true }}
                                        data-testid="lead-name-field"
                                    /> :
                                    <Autocomplete
                                        options={leadOptions}
                                        value={lead}
                                        onChange={(_event, newValue) => setLead(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Lead" />}
                                        data-testid="lead-select"
                                    />
                                }
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <Autocomplete
                                    options={propertyOptions}
                                    value={property || null}
                                    onChange={(_event, newValue) => setProperty(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Property" />}
                                    data-testid="property-select"
                                />
                            </FormControl>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div data-testid="date-select">
                                <DateTimePicker
                                    label="Date"
                                    value={date}
                                    onChange={(newDate) => setDate(newDate)}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                        },
                                    }}
                                    disablePast
                                />
                            </div>
                        </LocalizationProvider>
                    </FlexColumn>
                </form>
            </DialogContent>
            <DialogActions>
                <BlackButtonWhiteText
                    className={modalButtonStyles.modalButton}
                    onClick={props.onClose}
                    data-testid="cancel-showing-button"
                >Cancel</BlackButtonWhiteText>
                {props.showingId &&
                    <ReffieLoadingButton
                        color="error"
                        disabled={isSaveOrUpdateLoading}
                        loading={isDeleteLoading}
                        style={isDeleteLoading ? { paddingLeft: '32px' } : {}}
                        onClick={handleDelete}
                        data-testid="delete-showing-button"
                    >Delete</ReffieLoadingButton>
                }
                <ReffieLoadingButton
                    color="success"
                    disabled={isDeleteLoading}
                    loading={isSaveOrUpdateLoading}
                    style={isDeleteLoading ? { paddingLeft: '32px' } : {}}
                    onClick={handleSave}
                    data-testid="save-showing-button"
                >{props.showingId ? 'Update' : 'Create'}</ReffieLoadingButton>
            </DialogActions>
        </Dialog>
    );
}
