import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';

const NumberField = styled(TextField)({
    '& input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
});

export default NumberField;
