import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';
import type {
    IProperty,
    IUpdatePropertyRequest,
    IUpdatePropertyResponse
} from 'src/services/api/properties/types';

export const useUpdateProperty = (id?: number) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IProperty,
        IStandardErrorResponse,
        IUpdatePropertyRequest
    >(
        async (payload) => {
            const path = `${GET_PROPERTIES_PATH}/${id}`;
            const res = await apiClient(path, {
                method: 'patch',
                json: { property: payload },
            }).json<IUpdatePropertyResponse>();

            return res.property;
        }, {
            onSuccess: (data) => {
                addNotification('Property updated', 'success');

                queryClient.setQueryData<IProperty[]>(queryKeys.properties, (oldData) => {
                    if (!oldData) {
                        return [];
                    }

                    return oldData.map((property) => {
                        return property.id === data.id ? data : property;
                    });
                });

                queryClient.setQueryData<IProperty>([queryKeys.properties, data.id], data);
            }, onError: (error) => {
                const errorMessage = getApiErrorMessage(error) || 'Failed to update property';
                addNotification(errorMessage, 'error');
            }
        });
};
