import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import type { IOtherUser } from 'src/api/users-api';
import type { PropertyType } from 'src/api/landlord-properties-api';
import {
    type IProperty,
    type IPropertyLabel,
    useLoggedInUser,
    useProperties,
    usePropertyLabels
} from 'src/services/api';
import { filterProperties } from 'src/pages/landlord/properties/utils/filter-properties';
import { sortProperties } from 'src/pages/landlord/properties/utils/sort-properties';

// Helper types to make the code more concise.
// Must not be exported!
type Action<T> = React.Dispatch<React.SetStateAction<T>>;

export enum EPropertiesPageTab {
    ALL = 'All',
    VACANT = 'Vacant',
    OCCUPIED = 'Occupied',
}

export const propertiesPageTabs = Object.values(EPropertiesPageTab);

export type IPropertiesFilter = {
    assigneeIds: string[];
    propertyTypes: PropertyType[];
    propertyLabelIds: string[];
    hasTourLink: boolean | null;
    hasApplicationLink: boolean | null;
};

const INITIAL_FILTER_VALUES: IPropertiesFilter = {
    assigneeIds: [],
    propertyTypes: [],
    propertyLabelIds: [],
    hasTourLink: null,
    hasApplicationLink: null,
};

export enum EPropertiesSortKey {
    NAME = 'Name',
    ADDRESS = 'Address',
    PROPERTY_TYPE = 'Property Type',
    PRICE = 'Price',
    SQUARE_FOOTAGE = 'Square Footage',
    CREATED_AT = 'Created At',
}

export const propertiesSortKeys = Object.values(EPropertiesSortKey);
export const propertiesSortOptions = propertiesSortKeys.map((key) => ({
    value: key,
    label: key,
}));

const PROPERTIES_PER_PAGE = 25;

type IPropertiesPageContext = {
    teamMembers: IOtherUser[];
    /**
     * All properties, before filters are applied.
     */
    properties: IProperty[];

    /**
     * Properties after filters are applied.
     */
    filteredProperties: IProperty[];

    /**
     * Properties that are visible on the page.
     * This is a subset of `filteredProperties`.
     * It comes from pagination.
     */
    visibleProperties: IProperty[];

    /**
     * Properties that are selected with checkboxes.
     */
    selectedProperties: IProperty[];
    setSelectedProperties: Action<IPropertiesPageContext['selectedProperties']>;

    /**
     * Property that is focused with the keyboard.
     * User can use the up and down arrow keys to navigate through properties.
     */
    focusedProperty: IProperty | null;
    setFocusedProperty: Action<IPropertiesPageContext['focusedProperty']>;

    propertyLabels: IPropertyLabel[];

    tab: EPropertiesPageTab;
    setTab: Action<IPropertiesPageContext['tab']>;

    search: string;
    setSearch: Action<IPropertiesPageContext['search']>;

    isFilterOpen: boolean;
    setFilterOpen: Action<IPropertiesPageContext['isFilterOpen']>;
    filter: IPropertiesFilter;
    setFilter: Action<IPropertiesPageContext['filter']>;
    resetFilter: () => void;

    isSearchOrFilterActive: boolean;

    isSortingOpen: boolean;
    setSortingOpen: Action<IPropertiesPageContext['isSortingOpen']>;
    sortBy: EPropertiesSortKey;
    setSortBy: Action<IPropertiesPageContext['sortBy']>;
    sortingDirection: 'asc' | 'desc';
    setSortingDirection: Action<IPropertiesPageContext['sortingDirection']>;

    rowsPerPage: number;
    page: number;
    setPage: Action<IPropertiesPageContext['page']>;

    isAddingProperty: boolean;
    setAddingProperty: Action<boolean>;
    editingProperty?: IProperty;
    setEditingProperty: Action<IPropertiesPageContext['editingProperty']>;
    isImportingProperties: boolean;
    setImportingProperties: Action<boolean>;
    /**
     * Underlying labels that can be assigned to properties.
     */
    isEditingAllPropertyLabels: boolean;
    setEditingAllPropertyLabels: Action<boolean>;
    /**
     * Adding or removing labels from properties.
     */
    isEditingPropertyLabels: boolean;
    setEditingPropertyLabels: Action<IPropertiesPageContext['isEditingPropertyLabels']>;
    lastCreatedProperty?: IProperty;
    setLastCreatedProperty: Action<IPropertiesPageContext['lastCreatedProperty']>;
    editingLabelsForProperties: IProperty[];
    setEditingLabelsForProperties: Action<IPropertiesPageContext['editingLabelsForProperties']>;
    isEditingTourLink: boolean;
    setEditingTourLink: Action<IPropertiesPageContext['isEditingTourLink']>;
    isEditingApplicationLink: boolean;
    setEditingApplicationLink: Action<IPropertiesPageContext['isEditingApplicationLink']>;
    isMarkingPropertiesAsOccupied: boolean;
    setMarkingPropertiesAsOccupied: Action<IPropertiesPageContext['isMarkingPropertiesAsOccupied']>;

    mobileAchorEl?: HTMLElement;
    setMobileAnchorEl: Action<IPropertiesPageContext['mobileAchorEl']>;
};

// @ts-expect-error lazy init
const PropertiesPageContext = createContext<IPropertiesPageContext>();

export const usePropertiesPageContext = () => {
    const context = useContext(PropertiesPageContext);

    if (!context) {
        throw new Error('usePropertiesPageContext must be used within a PropertiesPageProvider');
    }
    return context;
};

export default function PropertiesPageProvider({
    children
}: {
    children: React.ReactNode
}) {
    const [tab, setTab] = useState(EPropertiesPageTab.ALL);

    const [search, setSearch] = useState('');
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [filter, setFilter] = useState<IPropertiesFilter>(INITIAL_FILTER_VALUES);
    const [isSortingOpen, setSortingOpen] = useState(false);
    const [sortBy, setSortBy] = useState(EPropertiesSortKey.CREATED_AT);
    const [sortingDirection, setSortingDirection] = useState<'asc' | 'desc'>('desc');

    const [rowsPerPage] = useState(PROPERTIES_PER_PAGE);
    const [page, setPage] = useState(1);

    const [selectedProperties, setSelectedProperties] = useState<IProperty[]>([]);
    const [focusedProperty, setFocusedProperty] = useState<IProperty | null>(null);

    const [mobileAchorEl, setMobileAnchorEl] = useState<HTMLElement>();

    const isSearchOrFilterActive = useMemo(() => {
        if (search) {
            return true;
        }

        if (filter.propertyLabelIds.length > 0 || filter.assigneeIds.length > 0) {
            return true;
        }

        return false;
    }, [search, filter]);

    const resetFilter = useCallback(() => {
        setFilter(INITIAL_FILTER_VALUES);
    }, []);

    const [isAddingProperty, setAddingProperty] = useState(false);
    const [editingProperty, setEditingProperty] = useState<IProperty>();
    const [isImportingProperties, setImportingProperties] = useState(false);
    const [isEditingAllPropertyLabels, setEditingAllPropertyLabels] = useState(false);
    const [lastCreatedProperty, setLastCreatedProperty] = useState<IProperty>();
    const [editingLabelsForProperties, setEditingLabelsForProperties] = useState<IProperty[]>([]);
    const [isEditingTourLink, setEditingTourLink] = useState(false);
    const [isEditingApplicationLink, setEditingApplicationLink] = useState(false);
    const [isEditingPropertyLabels, setEditingPropertyLabels] = useState(false);
    const [isMarkingPropertiesAsOccupied, setMarkingPropertiesAsOccupied] = useState(false);

    const { data: loggedInUser } = useLoggedInUser();
    const { data: properties } = useProperties();
    const { data: propertyLabels } = usePropertyLabels();

    const teamMembers = loggedInUser?.leasing_team_members || [];

    const filteredProperties = useMemo(() => {
        if (!properties) {
            return [];
        }

        const _filteredProperties = filterProperties(properties, tab, filter, search);
        return [...sortProperties(_filteredProperties, sortBy, sortingDirection)];
    }, [properties, tab, filter, search, sortBy, sortingDirection]);

    const visibleProperties = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;

        return [...filteredProperties].slice(startIndex, endIndex);
    }, [filteredProperties, rowsPerPage, page]);

    useEffect(() => {
        setSelectedProperties(prev => {
            return visibleProperties.filter(visibleProperty => {
                return prev.find(selectedProperty => selectedProperty.id === visibleProperty.id);
            });
        });
    }, [visibleProperties, setSelectedProperties]);

    // Reset page when filtered properties change
    useEffect(() => {
        setPage(1);
    }, [filteredProperties.map(({ id }) => id).join(',')]);

    const isLoading = !loggedInUser || !properties || !propertyLabels;
    if (isLoading) {
        return (
            <LinearProgress sx={{ position: 'fixed', top: 0 }} />
        );
    }

    return (
        <PropertiesPageContext.Provider
            value={{
                teamMembers,
                properties,
                filteredProperties,
                visibleProperties,
                selectedProperties,
                setSelectedProperties,
                focusedProperty,
                setFocusedProperty,

                propertyLabels,

                tab,
                setTab,

                search,
                setSearch,

                isFilterOpen,
                setFilterOpen,
                filter,
                setFilter,
                resetFilter,
                isSearchOrFilterActive,

                isSortingOpen,
                setSortingOpen,
                sortBy,
                setSortBy,
                sortingDirection,
                setSortingDirection,

                rowsPerPage,
                page,
                setPage,

                isAddingProperty,
                setAddingProperty,
                editingProperty,
                setEditingProperty,
                isImportingProperties,
                setImportingProperties,
                isEditingAllPropertyLabels,
                setEditingAllPropertyLabels,
                lastCreatedProperty,
                setLastCreatedProperty,
                editingLabelsForProperties,
                setEditingLabelsForProperties,
                isEditingTourLink,
                setEditingTourLink,
                isEditingApplicationLink,
                setEditingApplicationLink,
                isEditingPropertyLabels,
                setEditingPropertyLabels,
                isMarkingPropertiesAsOccupied,
                setMarkingPropertiesAsOccupied,

                mobileAchorEl,
                setMobileAnchorEl,
            }}
        >
            {children}
        </PropertiesPageContext.Provider>
    );
}
