import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';

import Search from 'src/components/search/Search';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';

const HELPER_TEXT = 'Search by lead uuid, name, email, phone number, stage, notes, ingestion source, as well as property id, name, and vacancy status.';

export default function LeadsSearch() {
    const [value, setValue] = useState('');
    const [isSearching, setSearching] = useState(false);

    const { search, setSearch } = useLeadsPageContext();

    const setSearchQueryDebounced = useCallback(debounce(setSearch, 300), []);

    useEffect(() => {
        setSearchQueryDebounced(value);
    }, [value, setSearchQueryDebounced]);

    useEffect(() => {
        if (value !== search) {
            setSearching(true);
        } else {
            setSearching(false);
        }
    }, [value, search]);

    return (
        <Search
            type="search"
            placeholder="Search leads"
            value={value}
            onChange={setValue}
            size="small"
            fullWidth
            isLoading={isSearching}
            helperText={HELPER_TEXT}
            sx={{
                width: 400,
                maxWidth: '100%',
                bgcolor: 'common.white',
                '@media (max-width: 1200px)': {
                    width: '100%',
                },
            }}
            data-testid="leads-search-field"
        />
    );
}