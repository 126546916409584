import { useMutation, useQueryClient, } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';
import { GET_PROPERTY_LABELS_PATH } from 'src/services/api/properties/labels/constants';

// TODO: Move notifcation logic to this function
// TODO: Move argument out of the request payload
// TODO: Don't invalidate all properties, just the one that was updated
export const useDeletePropertyLabel = () => {
    const queryClient = useQueryClient();

    return useMutation<void, IStandardErrorResponse, number>(
        async (labelId) => {
            const path = `${GET_PROPERTY_LABELS_PATH}/${labelId}`;
            await apiClient(path, { method: 'delete' });
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.propertyLabels);
                queryClient.invalidateQueries(queryKeys.properties);
            }
        }
    );
};
