import React from 'react';
import MuiChip, { type ChipProps } from '@mui/material/Chip';

import styles from 'src/components/chip/Chip.module.css';

type Props = Omit<ChipProps, 'color'> & {
    color?: string,
};

export default function Chip({ color, ...props }: Props) {
    const bgcolor = `${color}22`;
    const borderColor = color;

    return (
        <MuiChip
            {...props}
            classes={{ root: styles.root, label: styles.label }}
            sx={{ borderColor, bgcolor, color }}
        />
    );
}