import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IProperty } from 'src/services/api/properties/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';

type IUpdatePropertyOccupancyRequest = {
  is_vacant: boolean;
  tennant_lead_uuid?: string;
  occupancy_start_date?: string;
  occupancy_end_date?: string;
  vacant_start_date?: string;
};

export const useUpdatePropertyOccupancy = (id: number) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        void,
        IStandardErrorResponse,
        IUpdatePropertyOccupancyRequest
    >(
        async (payload) => {
            const path = `${GET_PROPERTIES_PATH}/${id}/occupancy`;
            await apiClient(path, {
                method: 'patch',
                json: payload,
            }).json<IUpdatePropertyOccupancyRequest>();
        }, {
            onSuccess: (_data, variables) => {
                addNotification('Property updated', 'success');
                queryClient.invalidateQueries(queryKeys.notifications);
                queryClient.setQueryData<IProperty[]>(queryKeys.properties, (_oldData) => {
                    const oldData = _oldData || [];
                    return oldData.map((property) => {
                        if (property.id === id) {
                            return {
                                ...property,
                                is_vacant: variables.is_vacant,
                            };
                        }

                        return property;
                    });
                });
                queryClient.invalidateQueries(queryKeys.leads);
            }, onError: (error) => {
                const errorMessage = getApiErrorMessage(error) || 'Failed to update property';
                addNotification(errorMessage, 'error');
            }
        });
};
