import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import type { ILead } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import { EDateRange } from 'src/pages/landlord/analytics/types';

const getSelectedWeekIndex = (dateRangeEnum: EDateRange) => {
    switch (dateRangeEnum) {
        case EDateRange.ThreeWeeksAgo:
            return 0;
        case EDateRange.TwoWeeksAgo:
            return 1;
        case EDateRange.LastWeek:
            return 2;
        case EDateRange.CurrentWeek:
            return 3;
    }
};

dayjs.extend(weekOfYear);

/**
 * Takes a date and generates a string representing the year and week of the year
 * Note: this is to avoid cases where a date can be misinterpreted as past or present
 */
export const getBucketKey = (date: string): string => {
    const weekNum = dayjs(date).week();
    const year = dayjs(date).year();
    const sortKey = `${year}_${weekNum}`;
    return sortKey;
};

/**
 * Used to generate an array of keys (strings representing week of the year) to to display on graph
 * @param numKeys the number of keys to generate
 * @returns an array of key strings
 */
export const generateRecentKeys = (numKeys: number): string[] => {
    const keys: string[] = [];
    let date = new Date().toISOString();
    for (let i = 0; i < numKeys; i++) {
        const key = getBucketKey(date);
        keys.unshift(key);
        // go back a week
        date = dayjs(date).subtract(7, 'day').toISOString();
    }
    return keys;
};

/**
 * @returns an array of lead arrays from the last {numWeeks} weeks.
 * Each array contains all leads for that week.
 * Order of the arrays is from oldest to newest.
 */
export const getLeadsByWeek = (leads: ILead[], numWeeks = 4) => {
    const leadsByYearAndWeek = leads.reduce<Record<string, ILead[]>>((acc, lead) => {
        const yearAndWeek = getBucketKey(lead.inserted_at);
        if (!acc[yearAndWeek]) {
            acc[yearAndWeek] = [];
        }

        acc[yearAndWeek].push(lead);

        return acc;
    }, {});

    const includedWeeks = generateRecentKeys(numWeeks);

    const leadsByWeek = Array(numWeeks).fill(null).map((_, i) => {
        const week = includedWeeks[i];
        return leadsByYearAndWeek[week] || [];
    });

    return leadsByWeek;
};

export const getLeadsFromSelectedWeek = (leadsByWeek: ILead[][], dateRangeEnum: EDateRange) => {
    const index = getSelectedWeekIndex(dateRangeEnum);
    return leadsByWeek[index];
};

export const getLeadsFromWeekBeforeSelectedWeek = (leadsByWeek: ILead[][], dateRangeEnum: EDateRange) => {
    const index = getSelectedWeekIndex(dateRangeEnum);
    return leadsByWeek[index - 1] || [];
};

/**
 * @returns an array of showing arrays from the last {numWeeks} weeks.
 * Each array contains all leads for that week.
 * Order of the arrays is from oldest to newest.
 */
export function getShowingsByWeek(showings: IShowing[], numWeeks: number): IShowing[][] {
    const showingsByYearAndWeek = showings.reduce<Record<string, IShowing[]>>((acc, showing) => {
        const yearAndWeek = getBucketKey(showing.start_time);
        if (!acc[yearAndWeek]) {
            acc[yearAndWeek] = [];
        }

        acc[yearAndWeek].push(showing);

        return acc;
    }, {});

    const includedWeeks = generateRecentKeys(numWeeks);

    const showingsByWeek = Array(numWeeks).fill(null).map((_, i) => {
        const week = includedWeeks[i];
        return showingsByYearAndWeek[week] || [];
    });

    return showingsByWeek;
}

export const getShowingsFromSelectedWeek = (showingsByWeek: IShowing[][], dateRangeEnum: EDateRange) => {
    const index = getSelectedWeekIndex(dateRangeEnum);
    return showingsByWeek[index];
};

export const getShowingsFromWeekBeforeSelectedWeek = (showingsByWeek: IShowing[][], dateRangeEnum: EDateRange) => {
    const index = getSelectedWeekIndex(dateRangeEnum);
    return showingsByWeek[index - 1] || [];
};
