import { useMutation, useQueryClient, } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILabel } from 'src/services/api/labels/types';
import { apiClient, queryKeys } from 'src/services/api';
import { LabelType } from 'src/services/api/labels/types';
import { GET_LEAD_LABELS_PATH } from 'src/services/api/leads/labels/constants';

type ICreateLeadLabelRequest = Omit<ILabel, 'id' | 'type' | 'color'>;

export const useCreateLeadLabel = () => {
    const queryClient = useQueryClient();

    return useMutation<void, IStandardErrorResponse, ICreateLeadLabelRequest>(
        async (payload) => {
            const json = { ...payload, type: LabelType.LEAD };
            await apiClient(GET_LEAD_LABELS_PATH, { method: 'post', json });
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.leadLabels);
                queryClient.invalidateQueries(queryKeys.leads);
            }
        }
    );
};
