import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { isStandardErrorResponse } from 'src/common/api-utils';
import type { ILead } from 'src/services/api';
import { useCreateLead, useProperties, useUpdateLead } from 'src/services/api';
import { leadFormFields, type LeadForm } from 'src/components/create-or-update-lead-modal/types';
import {
    getDefaultValues,
    getCreateLeadPayload,
    getUpdateLeadPayload
} from 'src/components/create-or-update-lead-modal/utils';

export const useLeadForm = (onSuccess: () => void, lead?: ILead) => {
    const { data: properties } = useProperties();
    const { mutateAsync: createLead } = useCreateLead();
    const { mutateAsync: updateLead } = useUpdateLead(lead?.uuid || '');

    const form = useForm<LeadForm>({
        mode: 'onChange',
    });

    useEffect(() => {
        const defaultValues = getDefaultValues(lead);
        form.reset(defaultValues);
    }, [lead]);

    const submit = async (values: LeadForm) => {
        try {
            if (lead) {
                const payload = getUpdateLeadPayload(lead, values);
                await updateLead(payload);
            } else {
                const propertyName = properties?.find((o) => o.id === Number(values['propertyId']))?.name;
                const payload = getCreateLeadPayload(values, propertyName);
                await createLead(payload);
            }

            onSuccess();
        } catch (error) {
            if (isStandardErrorResponse(error)) {
                const { messages } = error;
                if (messages) {
                    messages.forEach(({ loc, msg }) => {
                        const field = loc[0] as keyof LeadForm;
                        if (leadFormFields.includes(field)) {
                            form.setError(field, { message: msg });
                        }
                    });
                }
            }
        }
    };

    return { form, submit };
};
