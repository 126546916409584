import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BlackButtonWhiteText, ModalSuccessButton } from 'src/common/Buttons';
import type { IProperty } from 'src/services/api';
import { Transition } from 'src/components/ModalTransition';
import Alert from '@mui/material/Alert';

import styles from 'src/components/NewPropertyAutomationModal.module.css';

interface INewPropertyAutomationModalProps {
    open: boolean;
    property: IProperty;
    successMsg: string;
    onClose(): void;
    onConfirmation(userAnswer: boolean, propertyId: number): void;
}
export default function NewPropertyAutomationModal(props: INewPropertyAutomationModalProps) {
    const { open, property, successMsg, onClose, onConfirmation } = props;

    return (
        <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Create Automation?
                </Typography>
            </DialogTitle>
            <DialogContent>
                {successMsg ?
                    <Alert severity="success" className={styles.automationModalAlert}>{successMsg}</Alert> :
                    null}
                Do you want to create an automation for the newly created property?
            </DialogContent>
            <DialogActions>
                <BlackButtonWhiteText onClick={() => onConfirmation(false, property.id)}>No</BlackButtonWhiteText>
                <ModalSuccessButton onClick={() => onConfirmation(true, property.id)}>Yes</ModalSuccessButton>
            </DialogActions>
        </Dialog>
    );
}