import { useEffect } from 'react';

import { isModalOpen } from 'src/utils/is-modal-open';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

/**
 * Select or unselect the focused conversation when space bar is pressed.
 */
export const useSelectConversationWithSpaceBar = () => {
    const {
        focusedConversation,
        setSelectedConversations,
    } = useConversationsPageContext();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === ' ' && focusedConversation && !isModalOpen()) {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Conversations',
                    key: 'Space',
                });

                event.preventDefault();

                setSelectedConversations(prev => {
                    const isFocusedSelected = prev.some(o => o.lead_uuid === focusedConversation.lead_uuid);
                    if (isFocusedSelected) {
                        return prev.filter(o => o.lead_uuid !== focusedConversation.lead_uuid);
                    } else {
                        return [...prev, focusedConversation];
                    }
                });
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [focusedConversation, setSelectedConversations]);
};
