import { useQuery } from 'react-query';

import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import type { IProperty } from 'src/services/api/properties/types';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';

type IGetPropertyResponse = {
    property: IProperty;
};

export const useProperty = (id?: number | null) => {
    return useQuery(
        [queryKeys.properties, id],
        async () => {
            const path = id ? `${GET_PROPERTIES_PATH}/${id}` : '';
            const res = await apiClient(path).json<IGetPropertyResponse>();
            return res.property;
        },
        {
            enabled: !!id
        }
    );
};
