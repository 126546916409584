import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';

import { usePropertyLabels } from 'src/services/api';
import { PropertiesIcon, UsersIcon } from 'src/icons';
import Chip from 'src/components/chip/Chip';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

export default function PropertiesFilterStatus() {
    const { data: propertyLabels } = usePropertyLabels();

    const { teamMembers, filter, setFilter } = usePropertiesPageContext();

    const filteringByAssignees = !!filter.assigneeIds.length;
    const assigneeFilterLabel = `Assignee is ${filter.assigneeIds
        .map(assigneeId => `"${teamMembers?.find(member => member.id === Number(assigneeId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByPropertyTypes = !!filter.propertyTypes.length;
    const propertyTypesFilterLabel = `Property type is ${filter.propertyTypes.join(' or ')}`;

    const filteringByPropertyLabels = !!filter.propertyLabelIds.length;
    const propertyLabelsFilterLabel = `Property label is ${filter.propertyLabelIds
        .map(labelId => `"${propertyLabels?.find(label => label.id === Number(labelId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByTourLink = filter.hasTourLink !== null;
    const filteringByApplicationLink = filter.hasApplicationLink !== null;

    return (
        <>
            {filteringByAssignees && (
                <Tooltip title={assigneeFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={assigneeFilterLabel}
                            icon={<UsersIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, assigneeIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByPropertyTypes && (
                <Tooltip title={propertyTypesFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={propertyTypesFilterLabel}
                            icon={<PropertiesIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, propertyTypes: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByPropertyLabels && (
                <Tooltip title={propertyLabelsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={propertyLabelsFilterLabel}
                            icon={<PropertiesIcon />}
                            onDelete={() => setFilter(prev => ({ ...prev, propertyLabelIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByTourLink && (
                filter.hasTourLink ? (
                    <Tooltip title="Has tour link">
                        <div>
                            <Chip
                                variant="outlined"
                                label="Has tour link"
                                icon={<LinkIcon />}
                                onDelete={() => setFilter(prev => ({ ...prev, hasTourLink: null }))}
                            />
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip title="Does not have tour link">
                        <div>
                            <Chip
                                variant="outlined"
                                label="Does not have tour link"
                                icon={<LinkIcon />}
                                onDelete={() => setFilter(prev => ({ ...prev, hasTourLink: null }))}
                            />
                        </div>
                    </Tooltip>
                )
            )}

            {filteringByApplicationLink && (
                filter.hasApplicationLink ? (
                    <Tooltip title="Has application link">
                        <div>
                            <Chip
                                variant="outlined"
                                label="Has application link"
                                icon={<LinkIcon />}
                                onDelete={() => setFilter(prev => ({ ...prev, hasApplicationLink: null }))}
                            />
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip title="Does not have application link">
                        <div>
                            <Chip
                                variant="outlined"
                                label="Does not have application link"
                                icon={<LinkIcon />}
                                onDelete={() => setFilter(prev => ({ ...prev, hasApplicationLink: null }))}
                            />
                        </div>
                    </Tooltip>
                )
            )}
        </>
    );
}
