import React, { useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/ChevronRightRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { type PropertyType, propertyTypeOptions } from 'src/api/landlord-properties-api';
import { Flex, FlexColumn } from 'src/components/flex';
import { PropertiesIcon } from 'src/icons';
import SelectLabels from 'src/components/input/select-labels/SelectLabels';
import AutocompleteMultiple from 'src/components/input/autocomplete-multiple/AutocompleteMultiple';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

enum FilteringBy {
    Assignee = 'Assignee',
    PropertyType = 'Property Type',
    PropertyLabel = 'Property Label',
    HasTourLink = 'Has Tour Link',
    HasApplicationLink = 'Has Application Link',
}

// const teamMemberFilters = [FilteringBy.Assignee];
const propertyFilters = [
    FilteringBy.PropertyType,
    FilteringBy.PropertyLabel,
    FilteringBy.HasTourLink,
    FilteringBy.HasApplicationLink,
];

export default function PropertiesFilterModal() {
    const {
        teamMembers,
        isFilterOpen,
        setFilterOpen,
        filter,
        setFilter,
        resetFilter,
    } = usePropertiesPageContext();

    const searchRef = useRef<HTMLInputElement>(null);

    const [filteringBy, setFilteringBy] = useState<FilteringBy>();

    const handleClose = () => {
        setFilterOpen(false);
        setFilteringBy(undefined);
    };

    const teamMemberOptions = useMemo(() => {
        return teamMembers.map(member => ({
            value: String(member.id),
            label: member.name,
        }));
    }, [teamMembers]);

    // Focus on the search input when the modal is opened
    useEffect(() => {
        if (isFilterOpen) {
            setTimeout(() => searchRef.current?.focus(), 0);
        }
    }, [isFilterOpen]);

    return (
        <Popover
            onClose={handleClose}
            open={isFilterOpen}
            slotProps={{
                root: { sx: { p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                paper: { sx: { position: 'static', width: 500, maxWidth: '100%' } }
            }}
        >
            <Flex alignItems="center" p={2}>
                {!!filteringBy && (
                    <IconButton onClick={() => setFilteringBy(undefined)} edge="start">
                        <ArrowBackRounded />
                    </IconButton>
                )}

                <Typography>
                    Filter
                </Typography>
                <Typography>
                    {filteringBy}
                </Typography>
            </Flex>

            {!filteringBy && (
                <>
                    {/* TODO: Add later */}
                    {/* {teamMemberFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider /> */}

                    {propertyFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <PropertiesIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}
                </>
            )}

            {!!filteringBy && (
                <>
                    {filteringBy === FilteringBy.Assignee && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select Assignees"
                                options={teamMemberOptions}
                                value={filter.assigneeIds}
                                onChange={(assigneeIds) => {
                                    setFilter(prev => ({
                                        ...prev,
                                        assigneeIds: assigneeIds || [],
                                        hasNoProperty: false,
                                    }));
                                }}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.PropertyType && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select property types..."
                                options={propertyTypeOptions}
                                value={filter.propertyTypes}
                                onChange={(propertyTypes) => setFilter(prev => ({
                                    ...prev,
                                    propertyTypes: (propertyTypes as PropertyType[]) || [],
                                }))}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.PropertyLabel && (
                        <Flex p={1}>
                            <SelectLabels
                                type="property"
                                placeholder="Select property labels..."
                                value={filter.propertyLabelIds}
                                onChange={(propertyLabelIds) => setFilter(prev => ({ ...prev, propertyLabelIds: propertyLabelIds || [] }))}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.HasTourLink && (
                        <Flex px={2} py={1}>
                            <RadioGroup
                                value={filter.hasTourLink}
                                onChange={(_, value) => setFilter(prev => ({
                                    ...prev,
                                    hasTourLink: value === 'null' ? null : value === 'true'
                                }))}
                            >
                                <FlexColumn rowGap={0}>
                                    <FormControlLabel value="null" label="All" control={<Radio />} />
                                    <FormControlLabel value="true" label="Has Tour Link" control={<Radio />} />
                                    <FormControlLabel value="false" label="Doesn't have Tour Link" control={<Radio />} />
                                </FlexColumn>
                            </RadioGroup>
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.HasApplicationLink && (
                        <Flex px={2} py={1}>
                            <RadioGroup
                                value={filter.hasApplicationLink}
                                onChange={(_, value) => setFilter(prev => ({
                                    ...prev,
                                    hasApplicationLink: value === 'null' ? null : value === 'true'
                                }))}
                            >
                                <FlexColumn rowGap={0}>
                                    <FormControlLabel value="null" label="All" control={<Radio />} />
                                    <FormControlLabel value="true" label="Has Application Link" control={<Radio />} />
                                    <FormControlLabel value="false" label="Doesn't have Application Link" control={<Radio />} />
                                </FlexColumn>
                            </RadioGroup>
                        </Flex>
                    )}
                </>
            )}

            <Divider />

            <Flex width="100%" p={1} justifyContent="space-between">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        resetFilter();
                        handleClose();
                    }}
                    size="small"
                    fullWidth
                >
                    Clear All
                </Button>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={handleClose}
                    size="small"
                    fullWidth
                >
                    Close
                </Button>
            </Flex>
        </Popover>
    );
}
