import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowRightIcon from '@mui/icons-material/EastRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import VerticalDotsIcon from '@mui/icons-material/MoreVert';

import type { IConversation } from 'src/api/landlord-messages-api';
import { type IProperty, EMessageChannel } from 'src/services/api';
import { LeadUtils } from 'src/utils';
import { Flex, FlexColumn } from 'src/components/flex';
import TableCell from 'src/components/table/TableCell';
import { StageChip } from 'src/components/input/select-lead-stage/SelectLeadStage';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

import styles from 'src/pages/landlord/conversations/components/list/ConversationListItem.module.css';

dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(timezone);

function ChannelIcon({ channel }: { channel: EMessageChannel }) {
    switch (channel) {
        case EMessageChannel.EMAIL:
            return <MailOutlineIcon color="action" sx={{ mb: '0 !important' }} />;
        case EMessageChannel.SMS:
            return <ChatBubbleOutlineIcon color="action" sx={{ mb: '-3px !important' }} />;
        case EMessageChannel.FACEBOOK_MESSENGER:
            return <FacebookIcon color="action" sx={{ mb: '0 !important' }} />;
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _assertNever: never = channel;
        }
    }
}

type DesktopConversationListItemProps = {
    conversation: IConversation;
    property?: IProperty;
}

export function DesktopConversationListItem({
    conversation,
    property,
}: DesktopConversationListItemProps) {
    const {
        loggedInUser,
        leadsByUuid,
        selectedConversations,
        setSelectedConversations,
        focusedConversation,
        openConversation,
    } = useConversationsPageContext();

    const channel = conversation.latest_message_channel;
    const lead = leadsByUuid.get(conversation.lead_uuid);

    const selected = selectedConversations.some((c) => c.lead_uuid === conversation.lead_uuid);

    const selectConversation = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (selected) {
            setSelectedConversations(selectedConversations.
                filter((c) => c.lead_uuid !== conversation.lead_uuid)
            );
        } else {
            setSelectedConversations([...selectedConversations, conversation]);
        }
    };

    let leadOwnerName = '';
    if (loggedInUser.leasing_team_members?.length) {
        if (lead?.owner === loggedInUser.id) {
            leadOwnerName = 'You';
        } else {
            const owner = loggedInUser.leasing_team_members.find((o) => o.id === lead?.owner);
            leadOwnerName = owner?.name || '';
        }
    }

    const hasUnreadMessages = conversation.num_unread_messages > 0;
    const totalTeamMembers = loggedInUser.leasing_team_members?.length || 1;
    const hasOtherTeamMembers = totalTeamMembers > 1;

    const firstContact = formatDateTime(conversation.first_contact_at);
    const lastContact = formatDateTime(conversation.last_contact_at);

    const isFocused = focusedConversation?.lead_uuid === conversation.lead_uuid;
    const checkboxDisplay = selectedConversations.length > 0 ? 'block' : 'none';

    return (
        <>
            <TableRow
                data-testid="conversation-row"
                onClick={() => openConversation(conversation)}
                className={clsx(styles.tableRow, hasUnreadMessages && styles.hasUnreadMessages)}
                sx={{
                    backgroundColor: isFocused ? 'var(--color-primary-50) !important' : 'inherit',
                    '& .MuiCheckbox-root': { display: checkboxDisplay },
                    '&:hover .MuiCheckbox-root': { display: 'block' },
                }}
            >
                <TableCell
                    width={40}
                    className={styles.tableCellWithCheckbox}
                    data-testid="checkbox-column"
                    onClick={(event) => event.stopPropagation()}
                >
                    <Checkbox
                        className={styles.checkbox}
                        checked={selected}
                        onClick={selectConversation}
                    />
                </TableCell>

                <TableCell width="40%" className={clsx(styles.textWithEllipsis, styles.leadNameTableCell)} data-testid="lead-name-column">
                    <Typography>{LeadUtils.getLeadName(lead)}</Typography>
                </TableCell>

                <TableCell width="20%" className={clsx(styles.textWithEllipsis, styles.leadNameTableCell)} data-testid="stage-column">
                    {lead?.stage && <StageChip value={lead.stage} clickable={false} />}
                </TableCell>

                <TableCell width="50%" className={styles.textWithEllipsis} data-testid="message-preview-column">
                    <Flex alignItems="center">
                        <ChannelIcon channel={channel} />
                        <Typography>
                            {conversation.latest_message_contents}
                        </Typography>
                    </Flex>
                </TableCell>

                <TableCell width="20%" className={styles.textWithEllipsis} data-testid="property-name-column">
                    <Typography variant="body2">
                        {property?.name || 'Property not set'}
                    </Typography>
                </TableCell>

                {hasOtherTeamMembers && (
                    <TableCell width="20%" className={styles.textWithEllipsis} data-testid="lead-owner-column">
                        <Typography>{leadOwnerName}</Typography>
                    </TableCell>
                )}

                <TableCell width={200} align="right" className={styles.textWithEllipsis} data-testid="date-column">
                    <Flex justifyContent="flex-end" alignItems="center" columnGap={1}>
                        <Typography variant="body2">{firstContact}</Typography>
                        {firstContact !== lastContact && (
                            <>
                                <ArrowRightIcon fontSize="small" />
                                <Typography variant="body2">{lastContact}</Typography>
                            </>
                        )}
                    </Flex>
                </TableCell>

                <TableCell width={40} data-testid="unread-indicator-column">
                    {hasUnreadMessages && <CircleIcon color="primary" className={styles.unreadIndicator} />}
                </TableCell>
            </TableRow>
        </>
    );
}

type MobileConversationListItemProps = {
    conversation: IConversation;
    property?: IProperty;
}

export function MobileConversationListItem({
    conversation,
    property,
}: MobileConversationListItemProps) {
    const {
        leads,
        openConversation,
        setMobileMenuAnchorEl,
        setSelectedConversations
    } = useConversationsPageContext();

    const channel = conversation.latest_message_channel;
    const lead = leads.find((o) => o.uuid === conversation.lead_uuid);

    const hasUnreadMessages = conversation.num_unread_messages > 0;

    const firstContact = formatDateTime(conversation.first_contact_at);
    const lastContact = formatDateTime(conversation.last_contact_at);

    return (
        <>
            <FlexColumn
                className={clsx(styles.mobileListItem, hasUnreadMessages && styles.hasUnreadMessages)}
                onClick={() => openConversation(conversation)}
            >
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <ChannelIcon channel={channel} />
                        {lead?.stage && <StageChip value={lead.stage} clickable={false} />}
                        <Typography>{LeadUtils.getLeadName(lead)}</Typography>
                    </Flex>

                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            setSelectedConversations([conversation]);
                            setMobileMenuAnchorEl(event.currentTarget);
                        }}
                    >
                        <VerticalDotsIcon />
                    </IconButton>
                </Flex>


                <Flex alignItems="center" columnGap={1}>
                    <Typography variant="body2">{firstContact}</Typography>
                    {firstContact !== lastContact && (
                        <>
                            <ArrowRightIcon fontSize="small" />
                            <Typography variant="body2">{lastContact}</Typography>
                        </>
                    )}
                </Flex>

                <Typography className={styles.textWithEllipsis}>
                    {conversation.latest_message_contents}
                </Typography>

                <Typography className={styles.textWithEllipsis} variant="body2">
                    {property?.name || 'Property not set'}
                </Typography>
            </FlexColumn>
        </>
    );
}

const formatDateTime = (date: string) => {
    return dayjs.utc(date).tz().calendar(null, {
        sameDay: 'h:mm A',
        lastDay: 'MMM D',
        lastWeek: 'MMM D',
        sameElse: 'MMM D'
    });
};