import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';

import { propertyTypeOptions } from 'src/api/landlord-properties-api';
import { Flex, FlexColumn } from 'src/components/flex';
import { EPropertyFormField, type PropertyForm } from 'src/components/create-or-update-property-modal/types';
import Select from 'src/components/input/select/Select';
import NumberField from 'src/components/input/number-field/NumberField';
import SearchAddressField from 'src/components/input/address/SearchAddressField';
import AddressFields from 'src/components/input/address/AddressFields';
import SelectLabels from 'src/components/input/select-labels/SelectLabels';

type Props = {
    form: UseFormReturn<PropertyForm>;
    submit: (values: PropertyForm) => Promise<void>;
};

const SELECTABLE_COUNTRIES = [
    {
        label: 'United States',
        value: 'United States',
    },
    {
        label: 'Canada',
        value: 'Canada',
    }
];

export default function PropertyForm({
    form,
    submit,
}: Props) {
    const { register, setValue, watch, formState: { errors } } = form;

    const isVacant = watch(EPropertyFormField.IsVacant);
    const name = watch(EPropertyFormField.Name);
    const address = watch(EPropertyFormField.Address);
    const country = watch(EPropertyFormField.Country);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={form.handleSubmit(submit)}>
                <FlexColumn rowGap={6}>
                    <FlexColumn>
                        <Controller
                            name={EPropertyFormField.IsVacant}
                            control={form.control}
                            rules={{
                                validate: (value) => {
                                    return value != null || 'Is vacant is required';
                                }
                            }}
                            render={({ field }) => (
                                <FormControl
                                    error={!!errors[EPropertyFormField.IsVacant]}
                                >
                                    <FormLabel>
                                        <Typography variant="body2">Is the property vacant?</Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        value={field.value ? 'true' : 'false'}
                                        onChange={(_, value) => setValue(EPropertyFormField.IsVacant, value === 'true', { shouldValidate: true })}
                                        data-testid="is-vacant-radio-group"
                                    >
                                        <Flex>
                                            <FormControlLabel value="false" label="No" control={<Radio />} />
                                            <FormControlLabel value="true" label="Yes" control={<Radio />} />
                                        </Flex>
                                    </RadioGroup>
                                    <FormHelperText>
                                        {errors[EPropertyFormField.IsVacant]?.message}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />

                        {isVacant && (
                            <Controller
                                name={EPropertyFormField.VacantSince}
                                control={form.control}
                                rules={{ required: 'Field is required' }}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Vacant since"
                                        value={field.value}
                                        onChange={(date) => setValue(EPropertyFormField.VacantSince, date, { shouldValidate: true })}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!errors[EPropertyFormField.VacantSince],
                                                helperText: errors[EPropertyFormField.VacantSince]?.message
                                            }
                                        }}
                                    />
                                )}
                            />
                        )}
                    </FlexColumn>

                    <TextField
                        {...register(EPropertyFormField.Name)}
                        label="Property name (optional)"
                        error={!!errors[EPropertyFormField.Name]}
                        helperText={
                            errors[EPropertyFormField.Name]?.message ||
                            (!name && 'If the property name is not specified, it will be automatically generated based on the address.')
                        }
                        size="small"
                        fullWidth
                        data-testid="property-name-field"
                    />

                    <Controller
                        name={EPropertyFormField.Labels}
                        control={form.control}
                        render={({ field }) => (
                            <SelectLabels
                                type="property"
                                label="Labels"
                                value={field.value}
                                onChange={(value) => field.onChange(value)}
                                error={!!errors[EPropertyFormField.Labels]}
                                helperText={errors[EPropertyFormField.Labels]?.message}
                                size="small"
                                fullWidth
                                data-testid="labels-select"
                            />
                        )}
                    />

                    <Controller
                        name={EPropertyFormField.Country}
                        control={form.control}
                        render={({ field }) => (
                            <Select
                                label="Country"
                                options={SELECTABLE_COUNTRIES}
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange(value);

                                    if (value === 'Canada') {
                                        form.setValue(EPropertyFormField.Address, {
                                            streetAddress: '',
                                            unit: '',
                                            city: '',
                                            state: '',
                                            zipcode: '',
                                        });
                                        return;
                                    }

                                    if (value === 'United States') {
                                        form.setValue(EPropertyFormField.Address, null);
                                        return;
                                    }
                                }}
                                error={!!errors[EPropertyFormField.Country]}
                                helperText={errors[EPropertyFormField.Country]?.message}
                                size="small"
                                fullWidth
                                data-testid="country-select"
                            />
                        )}
                    />

                    {(!address && country === 'United States') && (
                        <Controller
                            name={EPropertyFormField.Address}
                            control={form.control}
                            rules={{
                                required: 'Address is required',
                            }}
                            render={({ field }) => (
                                <SearchAddressField
                                    onChange={(newAddress) => field.onChange(newAddress)}
                                    error={!!errors[EPropertyFormField.Address]}
                                    helperText={errors[EPropertyFormField.Address]?.message}
                                    data-testid="address-field"
                                />
                            )}
                        />
                    )}

                    {!!address && (
                        <AddressFields
                            country={country}
                            form={form}
                            value={address}
                            errors={errors[EPropertyFormField.Address]}
                        />
                    )}

                    <FlexColumn rowGap={2}>
                        <Flex justifyContent="space-between">
                            <NumberField
                                {...register(EPropertyFormField.Rent, {
                                    min: {
                                        value: 1,
                                        message: 'Rent must be greater than 0',
                                    },
                                    max: {
                                        value: 1000000,
                                        message: 'Rent must be less than 1,000,000',
                                    }
                                })}
                                type="number"
                                label="Rent (optional)"
                                error={!!errors[EPropertyFormField.Rent]}
                                helperText={errors[EPropertyFormField.Rent]?.message}
                                size="small"
                                fullWidth
                                data-testid="rent-field"
                                InputLabelProps={{ shrink: true }}
                            />

                            <Controller
                                name={EPropertyFormField.Type}
                                control={form.control}
                                rules={{
                                    required: 'Type is required',
                                }}
                                render={({ field }) => (
                                    <Select
                                        label="Type"
                                        options={propertyTypeOptions}
                                        value={field.value}
                                        onChange={(value) => field.onChange(value)}
                                        error={!!errors[EPropertyFormField.Type]}
                                        helperText={errors[EPropertyFormField.Type]?.message}
                                        size="small"
                                        fullWidth
                                        data-testid="property-type-select"
                                    />
                                )}
                            />
                        </Flex>

                        <Flex justifyContent="space-between">
                            <NumberField
                                {...register(
                                    EPropertyFormField.NumberOfBedrooms,
                                    {
                                        required: 'Number of bedrooms is required',
                                        min: {
                                            value: 0,
                                            message: 'Number of bedrooms must be greater or equal to 0',
                                        },
                                        max: {
                                            value: 100,
                                            message: 'Number of bedrooms must be less than 100',
                                        }
                                    }
                                )}
                                type="number"
                                label="Number of bedrooms"
                                error={!!errors[EPropertyFormField.NumberOfBedrooms]}
                                helperText={errors[EPropertyFormField.NumberOfBedrooms]?.message}
                                size="small"
                                fullWidth
                                data-testid="numb-bedrooms-field"
                                InputLabelProps={{ shrink: true }}
                            />

                            <NumberField
                                {...register(
                                    EPropertyFormField.NumberOfBathrooms,
                                    {
                                        required: 'Number of bathrooms is required',
                                        min: {
                                            value: 0,
                                            message: 'Number of bathrooms must be greater or equal to 0',
                                        },
                                        max: {
                                            value: 100,
                                            message: 'Number of bathrooms must be less than 100',
                                        }
                                    }
                                )}
                                type="number"
                                label="Number of bathrooms"
                                error={!!errors[EPropertyFormField.NumberOfBathrooms]}
                                helperText={errors[EPropertyFormField.NumberOfBathrooms]?.message}
                                size="small"
                                fullWidth
                                data-testid="numb-bathrooms-field"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Flex>

                        <NumberField
                            {...register(
                                EPropertyFormField.SquareFeet,
                                {
                                    min: {
                                        value: 0,
                                        message: 'Square feet must be greater than 0',
                                    },
                                    max: {
                                        value: 1000000,
                                        message: 'Square feet must be less than 1,000,000',
                                    }
                                }
                            )}
                            type="number"
                            label="Square feet (optional)"
                            error={!!errors[EPropertyFormField.SquareFeet]}
                            helperText={errors[EPropertyFormField.SquareFeet]?.message}
                            size="small"
                            fullWidth
                            data-testid="square-feet-field"
                            InputLabelProps={{ shrink: true }}
                        />
                    </FlexColumn>
                </FlexColumn>
            </form>
        </LocalizationProvider>
    );
}
