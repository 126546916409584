import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardRounded';
import SpaceBarIcon from '@mui/icons-material/SpaceBarRounded';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import Responsive from 'src/components/responsive/Responsive';
import Flex from 'src/components/flex/Flex';
import Hotkey from 'src/components/hotkey/Hotkey';

export default function ConversationsHotkeys() {
    return (
        <Responsive desktop>
            <div>
                <Flex p={1} pt={0} justifyContent="center" alignItems="center" columnGap={2}>
                    <Hotkey label="Next">
                        <ArrowDownwardIcon />
                    </Hotkey>

                    <Hotkey label="Previous">
                        <ArrowUpwardIcon />
                    </Hotkey>

                    <Hotkey label="Select">
                        <SpaceBarIcon />
                    </Hotkey>

                    <Hotkey label="Deselect All">
                        Esc
                    </Hotkey>

                    <Hotkey label="Open">
                        <KeyboardReturnIcon />
                    </Hotkey>

                    <Hotkey label="Search">
                        /
                    </Hotkey>

                    <Hotkey label="Filter">
                        F
                    </Hotkey>
                </Flex>
            </div>
        </Responsive>
    );
}
