import React from 'react';

import type { ILead } from 'src/services/api';
import Responsive from 'src/components/responsive';
import MobileLeadsTable from 'src/pages/landlord/leads/components/table/MobileLeadsTable';
import DesktopLeadsTable from 'src/pages/landlord/leads/components/table/DesktopLeadsTable';

interface Props {
    title?: string,
    leads?: ILead[],
}

export default function LeadsTable({
    title,
    leads,
}: Props) {
    return (
        <>
            <Responsive mobile>
                <div>
                    <MobileLeadsTable title={title} leads={leads} />
                </div>
            </Responsive>

            <Responsive desktop>
                <div>
                    <DesktopLeadsTable title={title} leads={leads} />
                </div>
            </Responsive>
        </>
    );
}
