import { useMutation, useQueryClient, } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { apiClient, queryKeys } from 'src/services/api';
import { GET_LEAD_LABELS_PATH } from 'src/services/api/leads/labels/constants';

export const useDeleteLeadLabel = () => {
    const queryClient = useQueryClient();

    return useMutation<void, IStandardErrorResponse, number>(
        async (labelId) => {
            const path = `${GET_LEAD_LABELS_PATH}/${labelId}`;
            await apiClient(path, { method: 'delete' });
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.leadLabels);
                queryClient.invalidateQueries(queryKeys.leads);
            }
        }
    );
};
