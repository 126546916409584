import type { IProperty } from 'src/services/api';
import { EPropertiesSortKey } from 'src/pages/landlord/properties/provider';

export const sortProperties = (
    properties: IProperty[],
    sortBy: EPropertiesSortKey,
    sortDirection: 'asc' | 'desc'
) => {
    return properties.sort((a, b) => {
        switch (sortBy) {
            case EPropertiesSortKey.NAME:
                return sortDirection === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            case EPropertiesSortKey.ADDRESS: {
                const aAddress = [a.street_address, a.unit, a.zipcode, a.city, a.state].filter(Boolean).join(' ');
                const bAddress = [b.street_address, b.unit, b.zipcode, b.city, b.state].filter(Boolean).join(' ');

                return sortDirection === 'asc' ? aAddress.localeCompare(bAddress) : bAddress.localeCompare(aAddress);
            }
            case EPropertiesSortKey.PROPERTY_TYPE: {
                const aType = a.type ?? '';
                const bType = b.type ?? '';

                return sortDirection === 'asc' ? aType.localeCompare(bType) : bType.localeCompare(aType);
            }
            case EPropertiesSortKey.PRICE: {
                const aRent = a.rent ?? 0;
                const bRent = b.rent ?? 0;

                return sortDirection === 'asc' ? aRent - bRent : bRent - aRent;
            }
            case EPropertiesSortKey.SQUARE_FOOTAGE: {
                const aSqft = a.square_feet ?? 0;
                const bSqft = b.square_feet ?? 0;

                return sortDirection === 'asc' ? aSqft - bSqft : bSqft - aSqft;
            }
            case EPropertiesSortKey.CREATED_AT: {
                const aCreatedAt = a.created_at;
                const bCreatedAt = b.created_at;

                return sortDirection === 'asc' ? aCreatedAt.localeCompare(bCreatedAt) : bCreatedAt.localeCompare(aCreatedAt);
            }
            default: {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _assertNever: never = sortBy;
            }
        }

        return 0;
    });
};