import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ICreatePropertyRequest } from 'src/services/api/properties/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';

const CREATE_PROPERTIES_PATH = `${GET_PROPERTIES_PATH}/bulk`;

type ICreatePropertiesResponse = {
    num_added: number;
    num_failed: number;
    failed_property_names: string[];
}

type ICreatePropertiesRequest = {
    properties: ICreatePropertyRequest[];
}

/**
 * Doesn't show notification.
 * The caller is responsive for showing the appropriate notification to the user.
 */
export const useCreateProperties = () => {
    const queryClient = useQueryClient();

    return useMutation<
        ICreatePropertiesResponse,
        IStandardErrorResponse,
        ICreatePropertiesRequest
    >(
        (payload) => {
            return apiClient(CREATE_PROPERTIES_PATH, {
                method: 'post',
                json: payload,
                timeout: 60000
            }).json<ICreatePropertiesResponse>();
        }, {
            onSuccess: () => {
                // We have to invalidate the properties query to refetch the data
                // because we don't get the created properties in the response
                queryClient.invalidateQueries(queryKeys.properties);
            }
        }
    );
};