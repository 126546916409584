import React, { useEffect } from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

const PageTitle: React.FC<Props> = ({ title, children }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <>{children}</>;
};

export default PageTitle;