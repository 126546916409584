import React, { useEffect, useRef } from 'react';
import Alert from '@mui/material/Alert';

import type { ILead } from 'src/services/api';
import { isEmailValid } from 'src/utils/is-email-valid';
import { EMessageChannel } from 'src/services/api';
import { FlexColumn } from 'src/components/flex';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';
import Message from 'src/pages/landlord/leads/messages/components/messages-container/Message';

function InvalidEmailAlert({ lead }: { lead: ILead }) {
    const { channel } = useLeadMessagesPageContext();

    // Show an alert if the channel is email and the lead email is invalid
    if (channel !== EMessageChannel.EMAIL || isEmailValid(lead.email)) {
        return null;
    }

    const name = lead.name || 'this lead';
    const email = lead.email;

    const errorMessage = email ?
        `You cannot reply to ${name} via email because ${email} is not a valid email address.` :
        'Invalid email address.';
    return (
        <Alert severity="error">
            {errorMessage}
        </Alert>
    );
}

export default function LeadMessages() {
    const { lead, messages } = useLeadMessagesPageContext();

    const messageContainerRef = useRef<HTMLDivElement>(null);

    // Scroll to the bottom of the message container when a new message is added
    useEffect(() => {
        setTimeout(() => {
            const messageContainer = messageContainerRef.current;
            if (messageContainer) {
                messageContainer.scrollTop = messageContainer.scrollHeight;
            }
        }, 0);
    }, [messages]);

    return (
        <FlexColumn
            ref={messageContainerRef}
            className="prettyScrollbar"
            flex={1}
            p={1}
            rowGap={0}
            sx={{ overflowY: 'scroll' }}
        >
            {messages.map((message) => (
                <Message key={message.uuid} message={message} />
            ))}
            <InvalidEmailAlert lead={lead} />
        </FlexColumn>
    );
}
