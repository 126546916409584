import React, { useState } from 'react';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import Typography, { type TypographyProps } from '@mui/material/Typography';

import { useToggle } from 'src/hooks/use-toggle';
import { copyToClipboard } from 'src/components/copy-text-button/utils';

import styles from 'src/components/copy-text/CopyText.module.css';

interface Props extends TypographyProps {
    component?: React.ElementType;
    tooltip?: string;
    text?: string;
    children: React.ReactNode;
}

/**
 * Wrapper for the Typography component that allows to copy the text to the clipboard.
 */
const CopyText: React.FC<Props> = ({
    className: propsClassName,
    tooltip,
    text,
    children,
    ...props
}) => {
    const [isOpen, { setTrue: setOpen, setFalse: setClose}] = useToggle();
    const [isCopied, setCopied] = useState(false);

    const className = clsx(styles.root, propsClassName);

    if (!text) {
        return <Typography className={className} {...props}>{children}</Typography>;
    }

    const handleCopy = async () => {
        const isSuccess = await copyToClipboard(text);
        if (isSuccess) {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };

    let title = tooltip || 'Copy to clipboard';
    if (isCopied) {
        title = 'Copied';
    }

    return (
        <Tooltip title={title} open={isOpen} onOpen={setOpen} onClose={setClose}>
            <Typography className={className} {...props} onClick={handleCopy}>
                {children}
            </Typography>
        </Tooltip>
    );
};

export default CopyText;