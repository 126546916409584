import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IMessage, ISendMessageResponse } from 'src/services/api/messages/types';
import { EMessageChannel, EMessageDirection, EMessageSentStatus } from 'src/services/api/messages/types';
import { useNotifications } from 'src/notifications';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';

export const SEND_MESSAGE_PATH = 'api/landlord/messages/send';

export type ISendMessageRequest = {
    leadUuid: string;
    channel: EMessageChannel;
    message: string;
}

export const useSendMessage = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        ISendMessageResponse['new_message'],
        IStandardErrorResponse,
        ISendMessageRequest
    >(
        async (payload) => {
            const json = {
                lead_uuid: payload.leadUuid,
                channel: payload.channel,
                contents: payload.message
            };

            // Optimistically update the cache
            queryClient.setQueryData<IMessage[]>([queryKeys.messages, payload.leadUuid], (_oldMessages) => {
                const oldMessages = _oldMessages || [];

                return [...oldMessages, {
                    uuid: `temp-${new Date().getTime()}`,
                    channel: payload.channel,
                    contents: payload.message,
                    direction: EMessageDirection.OUT,
                    sent_status: payload.channel === EMessageChannel.SMS ? EMessageSentStatus.SENT : undefined,
                    is_sent_by_automation: false,
                    is_rental_application_request: false,
                    inserted_at: new Date(),
                }];
            });

            const res = await apiClient(SEND_MESSAGE_PATH, {
                method: 'post',
                json,
            }).json<ISendMessageResponse>();

            return res.new_message;
        }, {
            onSuccess: (_data, variables) => {
                addNotification('Message sent', 'success');
                queryClient.invalidateQueries(queryKeys.conversations);
                queryClient.invalidateQueries([queryKeys.messages, variables.leadUuid]);
            },
            onError: (error) => {
                const errorMessage = error.msg || 'Failed to send message';
                addNotification(errorMessage, 'error');
            }
        }
    );
};
