import React from 'react';
import type { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';

import EmptyFolderIcon from 'src/components/empty-page/EmptyFolderIcon';

import styles from 'src/components/empty-page/EmptyPage.module.css';

type Props = {
    sx?: SxProps;
    children: React.ReactNode;
}

export default function EmptyPage({
    sx,
    children
}: Props) {
    return (
        <Box className={styles.outer} sx={sx}>
            <div className={styles.inner}>
                <EmptyFolderIcon />

                {children}
            </div>
        </Box>
    );
}
