import * as chrono from 'chrono-node';

export interface IRegion {
    startIndex: number;
    length: number;
    parsedDate: Date | null;
    key: string;
    text: string;
}

export function parseMessageLineIntoRegions(line: string, lineIndex: number): IRegion[] {
    const o = chrono.parse(line);
    if (o.length > 0) {
        const textRegions = [] as IRegion[];
        let prevStartIndex = 0;
        for (let dateI = 0; dateI < o.length; dateI++) {
            const dateRange = o[dateI];
            const parsedDate = dateRange.date();

            // add a little bit of intelligence to the date parsing
            // specifically we want the times to default to likely user intention
            // so if the user types "tomorrow at 2" we want to default to 2pm
            if (!dateRange.text.toLowerCase().includes('am') && !dateRange.text.toLowerCase().includes('pm')) {
                // am/pm not explicitly specified
                // so we need to guess
                const hour = parsedDate.getHours();
                if (hour <= 6) {
                    // assume anything before 6am is pm
                    parsedDate.setHours(hour + 12);
                }
            }

            if (prevStartIndex < dateRange.index) {
                const beforeDateText = line.substring(prevStartIndex, dateRange.index);
                textRegions.push({
                    key: `before-date-text-${dateI}`,
                    text: beforeDateText,
                    startIndex: prevStartIndex,
                    length: beforeDateText.length,
                    parsedDate: null,
                });
            }

            textRegions.push({
                key: `date-${dateI}`,
                text: dateRange.text,
                startIndex: dateRange.index,
                length: dateRange.text.length,
                parsedDate: parsedDate,
            });

            // update this
            prevStartIndex = dateRange.index + dateRange.text.length;
            // console.debug('prevStartIndex', prevStartIndex);
        }

        if (prevStartIndex < line.length) {
            const afterDateText = line.substring(prevStartIndex);
            textRegions.push({
                key: `after-date-text-${lineIndex}}`,
                text: afterDateText,
                startIndex: prevStartIndex,
                length: afterDateText.length,
                parsedDate: null,
            });
        }
        return textRegions;
    } else {
        return [{
            key: `line-section-${lineIndex}`,
            text: line,
            startIndex: 0,
            length: line.length,
            parsedDate: null,
        }];
    }
}