import React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/EditRounded';

import type { ILead } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import { useToggle } from 'src/hooks/use-toggle';
import { FlexColumn } from 'src/components/flex';
import CreateOrUpdateTourModal from 'src/components/create-or-update-tour-modal/CreateOrUpdateTourModal';

import styles from 'src/components/lead-sidebar/LeadSidebarTour.module.css';

interface Props {
    lead: ILead;
    showing: IShowing;
    color: string;
}

export default function LeadSidebarTour({
    lead,
    showing,
    color,
}: Props) {
    const [isEditing, { setTrue: startEditing, setFalse: stopEditing }] = useToggle();

    const showingDate = dayjs(showing.start_time);
    const isEditable = showingDate.isAfter(dayjs());

    const handleClick = () => {
        if (!isEditable) {
            return;
        }

        startEditing();
    };

    return (
        <>
            <Box
                className={styles.showing}
                sx={{
                    borderLeft: '4px solid',
                    borderColor: color,
                    cursor: isEditable ? 'pointer' : 'default',
                    '& svg': { display: 'none', fontSize: 20 },
                    '&:hover svg': { display: isEditable ? 'block' : 'none' },
                }}
                onClick={handleClick}
            >
                <FlexColumn p={1} rowGap={0.5}>
                    <Typography variant="body2">
                        {lead.name}
                    </Typography>

                    <Typography variant="body2">
                        {showingDate.format('ddd, MMM D, YYYY')} at {showingDate.format('h:mm A')}
                    </Typography>
                </FlexColumn>

                <EditIcon color="action" sx={{ position: 'absolute', right: 8, top: 8 }} />
            </Box>

            {!!isEditing && (
                <CreateOrUpdateTourModal
                    open={isEditing}
                    onClose={stopEditing}
                    leads={[lead]}
                    selectedLeadUuid={showing.lead_uuid}
                    landlordUserId={showing.landlord_user_id}
                    initDateTime={showing.start_time}
                    initPropertyId={showing.property_id}
                    showingId={showing.id}
                    sourceMessageUuid={showing.source_message_uuid || undefined}
                />
            )}
        </>
    );
}
