import { useEffect } from 'react';

import { isModalOpen } from 'src/utils/is-modal-open';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

/**
 * Open the focused conversation when the enter key is pressed.
 */
export const useOpenConversationWithEnterKey = () => {
    const { focusedConversation, openConversation } = useConversationsPageContext();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !isModalOpen()) {
                event.preventDefault();

                if (focusedConversation) {
                    analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                        page: 'Conversations',
                        key: 'Enter',
                    });

                    openConversation(focusedConversation);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [focusedConversation, openConversation]);
};
