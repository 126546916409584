import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardRounded';
import SpaceBarIcon from '@mui/icons-material/SpaceBarRounded';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import { Flex, FlexColumn } from 'src/components/flex';
import Hotkey from 'src/components/hotkey/Hotkey';
import { EHelpSidebarView } from 'src/components/layout/landlord/help-sidebar/types';

function ShortcutGroupTitle({
    children
}: {
    children: React.ReactNode;
}) {
    return (
        <Typography p={0.5} pl={1} fontWeight="bold" bgcolor="grey.200">
            {children}
        </Typography>
    );
}

function Shortcut({
    title,
    children
}: {
    title: string;
    children: React.ReactNode;
}) {
    return (
        <Flex pl={1} justifyContent="space-between" alignItems="center">
            <Typography variant="body2">{title}</Typography>
            {children}
        </Flex>
    );
}

type Props = {
    setView: (view: EHelpSidebarView) => void;
}

export default function HelpSidebarKeyboardShortcuts({ setView }: Props) {
    return (
        <>
            <FlexColumn p={2} pt={1} rowGap={2}>
                <Flex alignItems="center">
                    <IconButton onClick={() => setView(EHelpSidebarView.Main)} edge="start">
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">Keyboard shortcuts</Typography>
                </Flex>

                <FlexColumn px={1} rowGap={2}>
                    <FlexColumn rowGap={1}>
                        <ShortcutGroupTitle>Conversations</ShortcutGroupTitle>
                        <Shortcut title="Next conversation">
                            <Hotkey><ArrowDownwardIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Previous conversation">
                            <Hotkey><ArrowUpwardIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Select conversation">
                            <Hotkey><SpaceBarIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Deselect all conversations">
                            <Hotkey>Esc</Hotkey>
                        </Shortcut>
                        <Shortcut title="Open conversation">
                            <Hotkey><KeyboardReturnIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Open search">
                            <Hotkey>/</Hotkey>
                        </Shortcut>
                        <Shortcut title="Open filter">
                            <Hotkey>F</Hotkey>
                        </Shortcut>
                    </FlexColumn>

                    <FlexColumn rowGap={1}>
                        <ShortcutGroupTitle>Chat</ShortcutGroupTitle>
                        <Shortcut title="Send message">
                            <Hotkey><KeyboardReturnIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Next conversation">
                            <Hotkey><ArrowDownwardIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Previous conversation">
                            <Hotkey><ArrowUpwardIcon /></Hotkey>
                        </Shortcut>
                        <Shortcut title="Go to all conversations">
                            <Hotkey>Esc</Hotkey>
                        </Shortcut>
                    </FlexColumn>
                </FlexColumn>
            </FlexColumn>
        </>
    );
}
