import React, { useState, useMemo } from 'react';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DownloadIcon from '@mui/icons-material/DownloadRounded';

import { useNotifications } from 'src/notifications';
import { StringFormat, formatString } from 'src/utils/format-string';
import { useCreateLeads } from 'src/services/api/leads';
import { Flex, FlexColumn } from 'src/components/flex';
import ButtonLink from 'src/components/button-link/ButtonLink';
import FileUpload from 'src/components/input/file-upload/FileUpload';
import SimpleTable from 'src/components/table/simple-table/SimpleTable';
import { type ParsedCsv, parseCsvFromFile } from 'src/utils/csv';
import {
    type LeadCsvRow,
    parseLeads,
    importLeadsFields,
    getUnknownLeadFields
} from 'src/components/bulk-import/parse-leads';

type Props = {
    info?: string;
    isOpened: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

export default function ImportLeads({
    info,
    isOpened,
    onClose,
    onSuccess,
}: Props) {
    const { addNotification } = useNotifications();

    const { mutateAsync: createLeads, isLoading: isCreatingLeads } = useCreateLeads();

    const [parsedFile, setParsedFile] = useState<ParsedCsv<LeadCsvRow>>();
    const [parsedFileCreatedAt, setParsedFileCreatedAt] = useState<string>();
    const [newLeads, setNewLeads] = useState<ReturnType<typeof parseLeads>>([]);
    const [unknownFields, setUnknownFields] = useState<string[]>([]);
    const [leadErrors, setLeadErrors] = useState<string>('');

    const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e.target.files) {
                const newFile = e.target.files[0];
                const newParsedFile = await parseCsvFromFile<LeadCsvRow>(newFile);

                setParsedFile(newParsedFile);
                setParsedFileCreatedAt(new Date().toISOString());
                setNewLeads(parseLeads(newParsedFile));

                setUnknownFields(getUnknownLeadFields(newParsedFile));
            }
        } catch (error) {
            if (error instanceof Error) {
                addNotification(error.message, 'error');
            } else {
                addNotification('An error occurred while parsing the file', 'error');
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const leads = newLeads
                .filter(o => !o.errors?.length)
                .map(o => o.lead);

            const {
                num_added: numberOfAddedLeads,
                num_failed: numberOfFailedLeads,
                errors: errors
            } = await createLeads({ leads });
            if (numberOfAddedLeads > 0) {
                const nSuccess = numberOfAddedLeads;
                onSuccess?.();

                if (numberOfFailedLeads === 0) {
                    addNotification(`${nSuccess} ${pluralize('lead', nSuccess)} imported successfully`, 'success');
                } else {
                    addNotification(`Only ${nSuccess}/${newLeads.length} leads imported successfully`, 'warning');
                    setParsedFileCreatedAt(undefined);
                }
            } else {
                addNotification('Failed to import any leads', 'error');
            }
            if (errors) {
                const rowsWithErrors = Object.keys(errors);
                const errorMsgs = Object.values(errors).map((error, index) => `Lead ${Number(rowsWithErrors[index]) + 1}: ${error.msg}`).join('\n');
                setLeadErrors(errorMsgs);
            }
        } catch (error) {
            addNotification('Failed to import any leads', 'error');
        }
    };

    const handleRemoveFile = () => {
        setParsedFile(undefined);
        setNewLeads([]);
        setUnknownFields([]);
        setLeadErrors('');
    };

    const previewTableData = useMemo(() => {
        if (!newLeads.length || !parsedFile) {
            return;
        }

        const rows = parsedFile.body.map((row, index) => {
            const errors = newLeads[index].errors;

            return importLeadsFields.map((col) => {
                const label = row[col as keyof LeadCsvRow] as string;
                const hasError = errors?.includes(col) ?? false;

                return {
                    label,
                    hasError,
                };
            });
        });

        const columns = importLeadsFields.map(column => {
            return formatString(column, StringFormat.SnakeCase, StringFormat.SentenceCase);
        });

        const errors = newLeads
            .filter((o) => !!o.errors?.length)
            .map((o) => o.errors?.map(error => formatString(error, StringFormat.SnakeCase, StringFormat.SentenceCase)))
            .map((o, i) => [i, o] as [number, string[]]);

        return {
            columns,
            rows,
            errors,
        };
    }, [parsedFile]);

    const numValidLeads = newLeads.filter(o => !o.errors?.length).length;

    return (
        <Dialog open={isOpened} fullScreen onClose={onClose}>
            <DialogTitle textAlign="center">
                Import Leads
            </DialogTitle>

            <DialogContent>
                <FlexColumn rowGap={2}>
                    <FlexColumn>
                        {!!info && <Alert severity="info">{info}</Alert>}
                        <Typography>
                            Download the provided CSV template, fill it out with the necessary lead details, and then upload the completed CSV file back to Reffie to get started.
                        </Typography>
                    </FlexColumn>
                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel>
                                Download the CSV template
                            </StepLabel>
                            <StepContent>
                                <ButtonLink
                                    href="/csv-templates/lead-import-template.csv"
                                    variant="contained"
                                    startIcon={<DownloadIcon />}
                                    download
                                    data-testid="lead-csv-template-download-button"
                                >
                                    Download
                                </ButtonLink>
                            </StepContent>
                        </Step>

                        <Step active>
                            <StepLabel>
                                Upload the filled-in CSV template
                            </StepLabel>
                            <StepContent>
                                <Flex>
                                    <FileUpload
                                        key={parsedFileCreatedAt}
                                        onChange={handleUploadFile}
                                        data-testid="lead-csv-upload-button"
                                    >
                                        {parsedFile ? 'Change file' : 'Upload'}
                                    </FileUpload>
                                    {parsedFile && (
                                        <Button
                                            variant="outlined"
                                            onClick={handleRemoveFile}
                                            data-testid="lead-csv-remove-button"
                                        >
                                            Remove file
                                        </Button>
                                    )}
                                </Flex>
                            </StepContent>
                        </Step>

                        <Step active>
                            <StepLabel>
                                Preview the leads to be imported
                            </StepLabel>
                            <StepContent>
                                {unknownFields.length > 0 && (
                                    <Alert severity="warning" sx={{ mb: 2 }}>
                                        The following fields are not recognized: {unknownFields.join(', ')}
                                    </Alert>
                                )}

                                {!!leadErrors && (
                                    <Alert severity="error" sx={{ mb: 2, whiteSpace: 'pre-line' }} >
                                        <AlertTitle>The following leads could not be imported:</AlertTitle>
                                        {leadErrors}
                                    </Alert>
                                )}

                                {previewTableData && (
                                    <SimpleTable key={parsedFileCreatedAt} {...previewTableData} />
                                )}
                            </StepContent>
                        </Step>
                    </Stepper>
                </FlexColumn>
            </DialogContent>

            <DialogActions>
                {previewTableData && (
                    <Typography mr={2}>
                        {numValidLeads}/{newLeads.length} leads can be imported.
                    </Typography>
                )}
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isCreatingLeads}
                    data-testid="lead-import-cancel-button"
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!numValidLeads}
                    loading={isCreatingLeads}
                    data-testid="lead-import-submit-button"
                >
                    Import {numValidLeads ?
                        `${numValidLeads} ${pluralize('lead', numValidLeads)}` : ''}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}