import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Flex, FlexColumn } from 'src/components/flex';
import PageTitle from 'src/components/page-title/PageTitle';
import type { IUser } from 'src/api/users-api';

interface Props {
    user: IUser
}

function Paywall({
    user,
}: Props) {
    const userFirstName = user.name.split(' ')[0];
    return (
        <FlexColumn p={2} pt="20vh" alignItems="center" rowGap={4}>
            <Flex alignItems="center">
                <img src="/img/paywall/paywall-image.png" alt="connie-holding-credit-card" />
            </Flex>
            <FlexColumn>
                <Typography alignContent={'flex-start'}>
                    Hey {userFirstName},
                </Typography>

                <Typography maxWidth={600}>
                    We&apos;re so happy that you love using Reffie. Unfortunately, our records indicate that you are behind on your billing.
                </Typography>

                <Typography maxWidth={600}>
                    Until your billing is fixed, you will not be able to access your leads from within Reffie or send messages. Your playbooks have been disabled.
                </Typography>

                <Typography maxWidth={600}>
                    Don&apos;t worry, we will continue to ingesting your leads so they will be there once your billing issue is resolved.
                </Typography>

                <Typography maxWidth={600}>
                    Please contact <strong>connie@reffie.me</strong> to resolve your billing issue.
                </Typography>
            </FlexColumn>

            <Flex justifyContent="center" columnGap={8}>
                <Button
                    variant="contained"
                    href="mailto:connie@reffie.me"
                    size="large"
                >
                    Contact Billing
                </Button>
            </Flex>
        </FlexColumn>
    );
}

interface Props {
    user: IUser
}

export default function PaywallPage({
    user,
}: Props) {
    return (
        <PageTitle title="Reffie">
            <Paywall user={user} />
        </PageTitle>
    );
}