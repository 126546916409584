import { PlaybookAction, BackgroundTrigger, LeadFilter, type IPlaybookTemplate } from 'src/api/playbooks-api';

export enum PlaybookUUID {
    NewLeadSendMessage = 'bg-welcome-message-ingested-from-listing-site-all-properties',
    NewLeadSpecificPropertySendMessage = 'bg-welcome-message-ingested-from-listing-site-specific-property',
    NewSmsFromNewLead = 'welcome-sms-bg-all',
    NewSmsFromNewLeadForSpecificProperty = 'welcome-sms-bg-specific-property',
    NewEmailFromNewLead = 'welcome-email-bg-all',
    NewEmailFromNewLeadFromSpecificProperty = 'welcome-email-bg-specific-property',
    ManualSendSmsAll = 'c',
    ManualSendEmailAll = 'd',
    ManualSendSmsLastMessageTwoDaysAgo = 'e',
    ManualSendEmailNotBookedShowing = 'open-house-fg-email',
    ManualSendSmsNotBookedShowing = 'open-house-fg-sms',
    LeadHasAShowingScheduled = 'lead-has-a-showing-scheduled',
    UnresponsiveLead = 'unresponsive-lead',

    IncomingEmail = 'incoming-email',
    IncomingEmailSpecificProperty = 'incoming-email-specific-property',
    IncomingSms = 'incoming-sms',
    IncomingSmsSpecificProperty = 'incoming-sms-specific-property',
    IncomingFacebookMessage = 'incoming-facebook-message',
    IncomingFacebookMessageSpecificProperty = 'incoming-facebook-message-specific-property',
}

export enum PlaybookText {
    NewLeadSendMessage = 'When a lead is ingested from a listing site (for the first time), respond with a template answer right away over a preferred channel',
    NewLeadSpecificPropertySendMessage = 'When a lead is ingested from a listing site (for the first time) inquiring about a specific property, respond with a template answer right away over a preferred channel',
    NewSmsFromNewLead = 'When a lead sends you a text message for the first time, respond with a template answer right away',
    NewSmsFromNewLeadForSpecificProperty = 'When a lead sends you a text message for the first time inquiring about a specific property, respond with a template answer right away',
    NewEmailFromNewLead = 'When a lead sends you an email for the first time, respond with a template answer right away',
    NewEmailFromNewLeadFromSpecificProperty = 'When a lead sends you an email for the first time inquiring about a specific property, respond with a template answer right away',
    ManualSendSmsAll = 'Send a welcome text message to all leads with no message history and who have a valid phone number',
    ManualSendEmailAll = 'Send a welcome email to all leads with no message history and who have a valid email',
    ManualSendSmsLastMessageTwoDaysAgo = 'If the last message between us and a lead came from us and was sent over 2 days ago then respond with a template text message',
    ManualSendEmailNotBookedShowing = 'Send an email to all leads with a valid email who have not booked a showing for a specific property notifying them of an open house',
    ManualSendSmsNotBookedShowing = 'Send a text message to all leads with a valid email who have not booked a showing for a specific property notifying them of an open house',
    LeadHasAShowingScheduled = 'When a lead has a showing scheduled, send them a message before or after the showing time.',
    UnresponsiveLead = 'If a lead has not replied to our message for a specified amount of time, then send them a follow-up message',

    IncomingEmail = 'When a new email is received directly from a lead, respond with a template answer right away',
    IncomingEmailSpecificProperty = 'When a new email is received directly from a lead inquiring about a specific property, respond with a template answer right away',
    IncomingSms = 'When a new text message is received, respond with a template answer right away',
    IncomingSmsSpecificProperty = 'When a new text message is received inquiring about a specific property, respond with a template answer right away',
    IncomingFacebookMessage = 'When a new Facebook message is received, respond with a template answer right away',
    IncomingFacebookMessageSpecificProperty = 'When a new Facebook message is received inquiring about a specific property, respond with a template answer right away',
}


/**
 * TODO: move these to the server
 * TODO: give these meaningful UUIDs so they can be referenced later
 */
export const PLAYBOOK_TEMPLATES = [
    // background
    {
        uuid: PlaybookUUID.NewLeadSendMessage,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingEmailFromListingSite,
        text: PlaybookText.NewLeadSendMessage,
        primary_action: PlaybookAction.SendMessageResponse,
        lead_filters: [
            LeadFilter.IsNewLead,
            LeadFilter.IsLeadSourceListingSite,
        ],
        requires_text_template: true,
        required_primary_action_params: ['channel'],
        /**
         * This is 1 higher than welcome-email-bg-specific-property
         */
        priority: 3,

        tags: ['welcome-message'],
    },
    {
        uuid: PlaybookUUID.NewLeadSpecificPropertySendMessage,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingEmailFromListingSite,
        text: PlaybookText.NewLeadSpecificPropertySendMessage,
        primary_action: PlaybookAction.SendMessageResponse,
        lead_filters: [
            LeadFilter.IsNewLead,
            LeadFilter.IsLeadSourceListingSite,
            LeadFilter.HasProperty,
            LeadFilter.SpecificProperty,
        ],
        requires_text_template: true,
        requires_property_selection: true,
        required_primary_action_params: ['channel'],
        /**
         * This is 1 higher than bg-welcome-message-ingested-from-listing-site-all-properties
         */
        priority: 4,

        tags: ['welcome-message'],
    },
    {
        uuid: PlaybookUUID.NewSmsFromNewLead,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingSms,
        text: PlaybookText.NewSmsFromNewLead,
        primary_action: PlaybookAction.SendTextMessageResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.IsFirstMessageInThread, LeadFilter.ValidPhoneNumber],
        requires_property_selection: false,

        tags: ['welcome-message'],
    },
    {
        uuid: PlaybookUUID.NewSmsFromNewLeadForSpecificProperty,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingSms,
        text: PlaybookText.NewSmsFromNewLeadForSpecificProperty,
        primary_action: PlaybookAction.SendTextMessageResponse,
        lead_filters: [
            LeadFilter.IsFirstMessageInThread,
            LeadFilter.ValidPhoneNumber,
            LeadFilter.HasProperty,
            LeadFilter.SpecificProperty,
        ],
        requires_text_template: true,
        requires_property_selection: true,
        // it's necessary for the priority to be higher than default so supercedes the default welcome email automation
        priority: 2,

        tags: ['welcome-message'],
    },
    {
        uuid: PlaybookUUID.NewEmailFromNewLead,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingEmail,
        text: PlaybookText.NewEmailFromNewLead,
        primary_action: PlaybookAction.SendEmailResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.IsFirstMessageInThread, LeadFilter.ValidEmail],
        requires_property_selection: false,

        tags: ['welcome-message'],
    },
    {
        uuid: PlaybookUUID.NewEmailFromNewLeadFromSpecificProperty,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingEmail,
        text: PlaybookText.NewEmailFromNewLeadFromSpecificProperty,
        primary_action: PlaybookAction.SendEmailResponse,
        lead_filters: [
            LeadFilter.IsFirstMessageInThread,
            LeadFilter.ValidEmail,
            LeadFilter.HasProperty,
            LeadFilter.SpecificProperty,
        ],
        requires_text_template: true,
        requires_property_selection: true,
        // it's necessary for the priority to be higher than default so supercedes the default welcome email automation
        priority: 2,

        tags: ['welcome-message'],
    },
    // foreground
    {
        uuid: PlaybookUUID.ManualSendSmsAll,
        is_background: false,
        text: PlaybookText.ManualSendSmsAll,
        primary_action: PlaybookAction.SendTextMessage,
        requires_text_template: true,
        lead_filters: [LeadFilter.NoMessageHistory, LeadFilter.ValidPhoneNumber],
        requires_property_selection: false,
    },
    {
        uuid: PlaybookUUID.ManualSendEmailAll,
        is_background: false,
        text: PlaybookText.ManualSendEmailAll,
        primary_action: PlaybookAction.SendEmail,
        requires_text_template: true,
        lead_filters: [LeadFilter.NoMessageHistory, LeadFilter.ValidEmail],
        requires_property_selection: false,
    },
    {
        uuid: PlaybookUUID.ManualSendSmsLastMessageTwoDaysAgo,
        is_background: false,
        text: PlaybookText.ManualSendSmsLastMessageTwoDaysAgo,
        primary_action: PlaybookAction.SendTextMessage,
        requires_text_template: true,
        lead_filters: [LeadFilter.IsLastMessageFromLandlord, LeadFilter.LastMessageSentOver2DaysAgo],
        requires_property_selection: false,
    },
    {
        uuid: PlaybookUUID.ManualSendEmailNotBookedShowing,
        is_background: false,
        text: PlaybookText.ManualSendEmailNotBookedShowing,
        primary_action: PlaybookAction.SendEmail,
        lead_filters: [
            LeadFilter.ValidEmail,
            LeadFilter.NoShowingsBooked,
            LeadFilter.HasProperty,
            LeadFilter.SpecificProperty,
        ],
        requires_text_template: true,
        requires_property_selection: true,
    },
    {
        uuid: PlaybookUUID.ManualSendSmsNotBookedShowing,
        is_background: false,
        text: PlaybookText.ManualSendSmsNotBookedShowing,
        primary_action: PlaybookAction.SendTextMessage,
        lead_filters: [
            LeadFilter.ValidPhoneNumber,
            LeadFilter.NoShowingsBooked,
            LeadFilter.HasProperty,
            LeadFilter.SpecificProperty,
        ],
        requires_text_template: true,
        requires_property_selection: true,
    },
    {
        uuid: PlaybookUUID.LeadHasAShowingScheduled,
        text: PlaybookText.LeadHasAShowingScheduled,
        is_background: true,
        background_trigger: BackgroundTrigger.TimeBasedSchedule,
        primary_action: PlaybookAction.SendMessageResponse,
        required_primary_action_params: ['channel'],
        requires_text_template: true,
        lead_filters: [LeadFilter.ShowingScheduled],
    },
    {
        uuid: PlaybookUUID.UnresponsiveLead,
        text: PlaybookText.UnresponsiveLead,
        is_background: true,
        background_trigger: BackgroundTrigger.TimeBasedSchedule,
        primary_action: PlaybookAction.SendMessageResponse,
        required_primary_action_params: ['channel'],
        requires_text_template: true,
        lead_filters: [LeadFilter.LastMessageFromLandlordWasSpecificTimeAgo],
    },
    {
        uuid: PlaybookUUID.IncomingEmail,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingEmail,
        text: PlaybookText.IncomingEmail,
        primary_action: PlaybookAction.SendMessageResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.ValidEmail],
        requires_property_selection: false,
    },
    {
        uuid: PlaybookUUID.IncomingEmailSpecificProperty,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingEmail,
        text: PlaybookText.IncomingEmail,
        primary_action: PlaybookAction.SendMessageResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.ValidEmail, LeadFilter.HasProperty, LeadFilter.SpecificProperty],
        requires_property_selection: true,
    },
    {
        uuid: PlaybookUUID.IncomingSms,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingSms,
        text: PlaybookText.IncomingSms,
        primary_action: PlaybookAction.SendMessageResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.ValidPhoneNumber],
        requires_property_selection: false,
    },
    {
        uuid: PlaybookUUID.IncomingSmsSpecificProperty,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingSms,
        text: PlaybookText.IncomingSms,
        primary_action: PlaybookAction.SendMessageResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.ValidPhoneNumber, LeadFilter.HasProperty, LeadFilter.SpecificProperty],
        requires_property_selection: true,
    },
    {
        uuid: PlaybookUUID.IncomingFacebookMessage,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingFacebookMessage,
        text: PlaybookText.IncomingFacebookMessage,
        primary_action: PlaybookAction.SendMessageResponse,
        requires_text_template: true,
        lead_filters: [],
        requires_property_selection: false,
    },
    {
        uuid: PlaybookUUID.IncomingFacebookMessageSpecificProperty,
        is_background: true,
        background_trigger: BackgroundTrigger.IncomingFacebookMessage,
        text: PlaybookText.IncomingFacebookMessage,
        primary_action: PlaybookAction.SendMessageResponse,
        requires_text_template: true,
        lead_filters: [LeadFilter.HasProperty, LeadFilter.SpecificProperty],
        requires_property_selection: true,
    },
] as IPlaybookTemplate[];
