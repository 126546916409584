import React from 'react';
import Link from '@mui/material/Link';
import Button, { type ButtonProps } from '@mui/material/Button';

interface Props extends ButtonProps {
    href: string,
    target?: string,
    rel?: string,
    download?: boolean | string,
}

export default function ButtonLink({
    href,
    target,
    rel,
    download,
    children,
    ...props
}: Props) {
    return (
        <Link href={href} target={target} rel={rel} download={download}>
            <Button {...props}>{children}</Button>
        </Link>
    );
}
