import React from 'react';
import Box from '@mui/material/Box';

import Page from 'src/components/page/PageV2';
import Provider from 'src/pages/landlord/conversations/context/Provider';
import KeyListener from 'src/pages/landlord/conversations/components/key-listener';
import NotificationAlert from 'src/pages/landlord/conversations/components/NotificationAlert';
import Header from 'src/pages/landlord/conversations/components/header';
import Navigation from 'src/pages/landlord/conversations/components/navigation';
import List from 'src/pages/landlord/conversations/components/list';
import Toolbar from 'src/pages/landlord/conversations/components/toolbar';
import Hotkeys from 'src/pages/landlord/conversations/components/hotkeys';
import MobileMenu from 'src/pages/landlord/conversations/components/mobile-menu';

export default function ConversationsPage() {
    return (
        <Page title="Reffie | Conversations">
            <Provider>
                <KeyListener />
                <Box flex={1}>
                    <NotificationAlert />
                    <Header />
                    <Navigation />
                    <List />
                    <Toolbar />
                    <MobileMenu />
                    <Hotkeys />
                </Box>
            </Provider>
        </Page>
    );
}