import React from 'react';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';

import { useDeleteProperties, useUpdateProperties } from 'src/services/api';
import { Flex } from 'src/components/flex';
import { useLandlordLayoutContext } from 'src/components/layout/landlord/LandlordLayoutProvider';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import Button from '@mui/material/Button';
import EditTourLinkModal from 'src/pages/landlord/properties/components/toolbar/EditTourLinkModal';
import EditApplicationLinkModal from 'src/pages/landlord/properties/components/toolbar/EditApplicationLinkModal';
import EditPropertyLabelsModal from 'src/pages/landlord/properties/components/toolbar/EditPropertyLabelsModal';
import MarkPropertiesAsOccupiedModal from 'src/pages/landlord/properties/components/toolbar/MarkPropertiesAsOccupiedModal';

export default function PropertiesToolbar() {
    const { isSidebarCollapsed } = useLandlordLayoutContext();

    const {
        selectedProperties,
        setSelectedProperties,
        setEditingProperty,
        isEditingTourLink,
        setEditingTourLink,
        isEditingApplicationLink,
        setEditingApplicationLink,
        isEditingPropertyLabels,
        setEditingPropertyLabels,
        isMarkingPropertiesAsOccupied,
        setMarkingPropertiesAsOccupied,
    } = usePropertiesPageContext();

    const {
        mutateAsync: updateProperties,
        isLoading: isUpdatingProperties,
    } = useUpdateProperties();
    const {
        mutateAsync: deleteProperties,
        isLoading: isDeletingProperties,
    } = useDeleteProperties(selectedProperties.map(({ id }) => id));

    const isAnyPropertyVacant = selectedProperties.some((o) => o.is_vacant);
    const isAnyPropertyOccupied = selectedProperties.some((o) => !o.is_vacant);

    const numSelectedProperties = selectedProperties.length;
    if (numSelectedProperties === 0) {
        return null;
    }

    const handleChangeVacancy = async (isVacant: boolean) => {
        const today = new Date().toISOString().split('T')[0];
        await updateProperties(selectedProperties.map((property) => ({
            ...property,
            is_vacant: isVacant,
            vacant_since: isVacant ? today : null,
        })));
    };

    const left = isSidebarCollapsed ? 60 : 160;

    const disabled = isUpdatingProperties || isDeletingProperties;

    return (
        <Flex
            position="fixed"
            bottom={16}
            left={left}
            width={`calc(100vw - ${left}px)`}
            justifyContent="center"
        >
            <Flex
                component={Paper}
                py={0.5}
                px={1}
                alignItems="center"
                bgcolor="common.white"
                border="1px solid var(--border-color)"
            >
                <IconButton
                    onClick={() => setSelectedProperties([])}
                    size="small"
                    sx={{ borderRadius: 1 }}
                    data-testid="cancel-button"
                >
                    <CloseIcon />
                </IconButton>

                {numSelectedProperties === 1 && (
                    <Button
                        onClick={() => setEditingProperty(selectedProperties[0])}
                        disabled={disabled}
                    >
                        Edit Property
                    </Button>
                )}

                <Button
                    onClick={() => setEditingPropertyLabels(true)}
                    disabled={disabled}
                >
                    Labels
                </Button>

                {isEditingPropertyLabels && (
                    <EditPropertyLabelsModal />
                )}

                <Button
                    onClick={() => setEditingTourLink(true)}
                    disabled={disabled}
                >
                    Tour Link
                </Button>
                {isEditingTourLink && (
                    <EditTourLinkModal />
                )}

                <Button
                    onClick={() => setEditingApplicationLink(true)}
                    disabled={disabled}
                >
                    Application Link
                </Button>
                {isEditingApplicationLink && (
                    <EditApplicationLinkModal />
                )}

                {isAnyPropertyVacant && (
                    <LoadingButton
                        onClick={() => setMarkingPropertiesAsOccupied(true)}
                        disabled={disabled}
                    >
                        Set Occupied
                    </LoadingButton>
                )}
                {isMarkingPropertiesAsOccupied && (
                    <MarkPropertiesAsOccupiedModal />
                )}

                {isAnyPropertyOccupied && (
                    <LoadingButton
                        onClick={() => handleChangeVacancy(true)}
                        disabled={disabled}
                    >
                        Set Vacant
                    </LoadingButton>
                )}

                <LoadingButton
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteProperties()}
                    loading={isDeletingProperties}
                    disabled={disabled}
                    size="small"
                >
                    Delete
                </LoadingButton>
            </Flex>
        </Flex>
    );
}
