import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material/utils';
import TextField from '@mui/material/TextField';

import type { ILead } from 'src/services/api';
import { useNotifications } from 'src/notifications';
import { useUpdateLeadNotes } from 'src/services/api';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';

interface Props {
    lead: ILead;
}

export default function LeadSidebarNotes({
    lead,
}: Props) {
    const { addNotification } = useNotifications();

    const { mutateAsync: updateNotes } = useUpdateLeadNotes(lead.uuid);

    const [notes, setNotes] = useState(lead.notes?.contents);

    const handleSave = useCallback(debounce(async (_notes) => {
        if (lead.notes?.contents !== _notes) {
            await updateNotes(_notes || '');
            addNotification('Notes saved', 'success');
        }
    }, 1000), [lead]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value);
    };

    useEffect(() => {
        handleSave(notes);
    }, [notes]);

    return (
        <ExpandableItem title="Notes">
            <TextField
                variant="filled"
                placeholder="Write your notes here"
                value={notes}
                onChange={handleChange}
                multiline
                rows={5}
                fullWidth
                data-testid="lead-notes-field"
            />
        </ExpandableItem>
    );
}
