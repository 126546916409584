import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardRounded';

import type { ILead } from 'src/services/api';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import Responsive from 'src/components/responsive';
import Flex from 'src/components/flex/Flex';
import Link from 'src/components/link/Link';
import Kbd from 'src/components/kbd/Kbd';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context';

import styles from 'src/pages/landlord/leads/messages/components/header/NavigationButtons.module.css';
import { LeadUtils } from 'src/utils';

const CONVERSATIONS_INDEX_PATH = '/landlord/inner/messages';

function ConversationNavigationTooltip({
    type,
    text,
}: {
    type: 'previous' | 'next',
    text: string;
}) {
    if (!text) {
        return null;
    }

    return (
        <Flex alignItems="center">
            <Typography variant="body2" color="common.white">
                {text}
            </Typography>

            <Kbd>
                {type === 'previous' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
            </Kbd>
        </Flex>
    );
}

type Props = {
    lead: ILead;
};

export default function NavigationButtons({ lead }: Props) {
    const {
        showNavigation,
        conversations,
        previousLead,
        navigateToPreviousConversation,
        nextLead,
        navigateToNextConversation,
    } = useLeadMessagesPageContext();

    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const isUserTyping = document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA';
            if (isUserTyping) {
                return;
            }

            if (event.key === 'Escape') {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Lead messages',
                    key: 'Escape',
                });

                navigate(CONVERSATIONS_INDEX_PATH);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    const currentIndex = conversations.findIndex((o) => o.lead_uuid === lead.uuid);

    const leadName = LeadUtils.getLeadName(lead);
    const prevLeadName = LeadUtils.getLeadName(previousLead);
    const nextLeadName = LeadUtils.getLeadName(nextLead);

    return (
        <Flex height="100%" alignItems="center" columnGap={2}>
            <Paper className={styles.navigationButtons}>
                <Tooltip
                    title={
                        <Flex alignItems="center">
                            <Typography variant="body2" color="common.white">All conversations</Typography>
                            <Kbd>Esc</Kbd>
                        </Flex>
                    }
                >
                    <div>
                        <Flex alignItems="center">
                            <Link href={CONVERSATIONS_INDEX_PATH}>
                                <ArrowLeftIcon color="action" />
                            </Link>
                        </Flex>
                    </div>
                </Tooltip>
            </Paper>

            {showNavigation && (
                <Flex height="100%" alignItems="center" columnGap={2}>
                    <Paper className={styles.navigationButtons}>
                        <Tooltip
                            title={!!previousLead && (
                                <ConversationNavigationTooltip
                                    type="previous"
                                    text={prevLeadName}
                                />
                            )}
                        >
                            <div>
                                <Flex alignItems="center">
                                    <IconButton
                                        onClick={navigateToPreviousConversation}
                                        disabled={!previousLead}
                                    >
                                        <ArrowUpIcon color={previousLead ? 'action' : 'disabled'} />
                                    </IconButton>
                                </Flex>
                            </div>
                        </Tooltip>

                        <Tooltip
                            title={!!nextLead && (
                                <ConversationNavigationTooltip
                                    type="next"
                                    text={nextLeadName}
                                />
                            )}
                        >
                            <div>
                                <Flex alignItems="center">
                                    <IconButton
                                        onClick={navigateToNextConversation}
                                        disabled={!nextLead}
                                    >
                                        <ArrowDownIcon color={nextLead ? 'action' : 'disabled'} />
                                    </IconButton>
                                </Flex>
                            </div>
                        </Tooltip>
                    </Paper>

                    <Responsive desktop>
                        <Typography variant="body2" color="grey.700" sx={{ whiteSpace: 'nowrap' }}>
                            {currentIndex + 1} / {conversations.length}
                        </Typography>
                    </Responsive>
                </Flex>
            )}

            <Typography className="ellipsisText" sx={{ whiteSpace: 'nowrap' }}>
                {leadName}
            </Typography>
        </Flex>
    );
}
