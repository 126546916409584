import { useMutation, useQueryClient, } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IPropertyLabel } from 'src/services/api/properties/labels/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { LabelType } from 'src/services/api/labels/types';
import { GET_PROPERTY_LABELS_PATH } from 'src/services/api/properties/labels/constants';

type IUpdatePropertyLabelRequest = Partial<Omit<IPropertyLabel, 'type'>>;

// TODO: Move notifcation logic to this function
// TODO: Move argument out of the request payload
// TODO: Don't invalidate all properties, just the one that was updated
export const useUpdatePropertyLabel = () => {
    const queryClient = useQueryClient();

    return useMutation<void, IStandardErrorResponse, IUpdatePropertyLabelRequest>(
        async (payload) => {
            const path = `${GET_PROPERTY_LABELS_PATH}/${payload.id}`;
            const json = { ...payload, type: LabelType.PROPERTY };
            await apiClient(path, { method: 'patch', json });
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.propertyLabels);
                queryClient.invalidateQueries(queryKeys.properties);
            }
        }
    );
};
