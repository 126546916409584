import { useMemo } from 'react';
import type { ILead } from 'src/services/api';

export type GroupedLeads = Map<number, ILead[]>;

export const useGroupedByPropertyLeads = (leads: ILead[]) => {
    return useMemo(() => {
        const groupedLeads = new Map<number, ILead[]>();

        for (const lead of leads) {
            const propertyId = lead.property_id || -1;

            if (!groupedLeads.has(propertyId)) {
                groupedLeads.set(propertyId, []);
            }

            groupedLeads.get(propertyId)?.push(lead);
        }

        return groupedLeads;
    }, [leads]);
};