import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IShowing } from 'src/api/landlord-showings-api';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';

type ICreateShowingResponse = {
    showing: Omit<IShowing, 'start_date'> & { start_date: string };
};

export type ICreateShowingRequest = {
    property_id: number;
    lead_uuid: string;
    start_time: Date;
    /**
     * If the showing was created from a message, this is the UUID of that message
     */
    source_message_uuid: string | null;
}

const PATH = 'api/landlord/showings';

export const useCreateShowing = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IShowing,
        IStandardErrorResponse,
        ICreateShowingRequest
    >(
        async (payload) => {
            const res = await apiClient(PATH, {
                method: 'post',
                json: payload,
            }).json<ICreateShowingResponse>();

            return res.showing;
        }, {
            onSuccess: () => {
                addNotification('Showing created', 'success');
                queryClient.invalidateQueries(queryKeys.showings);
            }, onError: (error) => {
                const errorMessage = error.msg || 'Failed to create showing';
                addNotification(errorMessage, 'error');
            }
        });
};
