import React from 'react';
import clsx from 'clsx';

import styles from 'src/components/responsive/Responsive.module.css';

type BaseProps = {
  children: React.ReactNode;
}

type MobileProps = BaseProps & {
  mobile: true;
}

type DesktopProps = BaseProps & {
  desktop: true;
}

type Props = MobileProps | DesktopProps;

/**
 * A component that conditionally displays its children based on the screen size.
 */
export default function Responsive({ children, ...props }: Props) {
    const isMobile = (props as MobileProps).mobile;
    const isDesktop = (props as DesktopProps).desktop;

    if (React.Children.count(children) !== 1) {
        throw new Error('Responsive component must have exactly one child');
    }

    const child = React.Children.only(children);

    if (!React.isValidElement(child)) {
        throw new Error('Responsive component must have a valid child');
    }

    return React.cloneElement(child as React.ReactElement, {
        className: clsx(child.props.className, {
            [styles.mobile]: isMobile,
            [styles.desktop]: isDesktop,
        }),
    });
}