import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IShowingSchedulingLink } from 'src/api/landlord-properties-api';
import type { IProperty } from 'src/services/api/properties/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';
import { getApiErrorMessage } from 'src/services/api/utils';

export type UpdatePropertyTourLinksPayload = Array<{ id: number; link: string;}>

type IUpdatePropertyTourLinksResponse = {
    showing_scheduling_link: IShowingSchedulingLink
}
type IUpdatePropertyTourLinksRequest = Array<{ id: number; link: string;}>

export const useUpdatePropertyTourLinks = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IProperty[],
        IStandardErrorResponse,
        IUpdatePropertyTourLinksRequest
    >(
        async (payload) => {
            const res = await Promise.allSettled(payload.map((property) => {
                const path = `${GET_PROPERTIES_PATH}/${property.id}/showing-scheduling-link`;
                return apiClient(path, {
                    method: 'post',
                    json: property,
                }).json<IUpdatePropertyTourLinksResponse>();
            }));

            const oldProperties = queryClient.getQueryData<IProperty[]>(queryKeys.properties) || [];

            const updatedTourLinks = res
                .filter((o) => o.status === 'fulfilled')
                .map((o) => o.value.showing_scheduling_link);

            const updatedProperties = oldProperties.map((property) => {
                const updatedTourLink = updatedTourLinks
                    .find((link) => link.property_id === property.id);

                if (!updatedTourLink) {
                    return property;
                }

                return {
                    ...property,
                    showing_scheduling_link: updatedTourLink,
                };
            });

            queryClient.setQueryData<IProperty[]>(queryKeys.properties, updatedProperties);
            for (const property of updatedProperties) {
                queryClient.setQueryData<IProperty>([queryKeys.properties, property.id], property);
            }

            const errors = res.filter((r) => r.status === 'rejected');
            if (errors.length > 0) {
                throw errors.map(o => o.reason);
            } else {
                addNotification('Property tour link(s) updated', 'success');
            }

            return updatedProperties;
        }, {
            onError: (errors) => {
                const errorMessage = getApiErrorMessage(errors) || 'Failed to update property tour link(s)';
                addNotification(errorMessage, 'error');
            }
        });
};
