import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardRounded';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import Responsive from 'src/components/responsive/Responsive';
import Flex from 'src/components/flex/Flex';
import Hotkey from 'src/components/hotkey/Hotkey';

export default function LeadMessagesHotkeys() {
    return (
        <Responsive desktop>
            <div>
                <Flex p={1} pt={0} alignItems="center">
                    <Hotkey label="All Conversations">
                        Esc
                    </Hotkey>

                    <Hotkey label="Next Conversation">
                        <ArrowDownwardIcon />
                    </Hotkey>

                    <Hotkey label="Previous Conversation">
                        <ArrowUpwardIcon />
                    </Hotkey>

                    <Hotkey label="Send Message">
                        <KeyboardReturnIcon />
                    </Hotkey>
                </Flex>
            </div>
        </Responsive>
    );
}
