import React, { createRef } from 'react';
import clsx from 'clsx';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/SearchRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import { Flex } from 'src/components/flex';

import styles from 'src/components/search/Search.module.css';

export type SearchProps = Omit<TextFieldProps<'outlined'>, 'variant' | 'value' | 'onChange'> & {
    value: string;
    onChange: (value: string) => void;
    isLoading?: boolean;
}

export default function Search({
    className,
    value,
    onChange,
    helperText,
    isLoading,
    ...props
}: SearchProps) {
    const ref = createRef<HTMLInputElement>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const handleClear = () => {
        onChange('');
    };

    return (
        <TextField
            {...props}
            ref={ref}
            type="search"
            className={clsx(styles.root, className)}
            autoComplete="off"
            size="small"
            fullWidth
            value={value}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Flex alignItems="center">
                            {isLoading && <CircularProgress size={20} />}
                            {!isLoading && !!value && (
                                <IconButton sx={{ padding: 0 }} onClick={handleClear}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                            {!!helperText && (
                                <Tooltip title={helperText}>
                                    <HelpIcon />
                                </Tooltip>
                            )}
                        </Flex>
                    </InputAdornment>
                ),
            }}
        />
    );
}