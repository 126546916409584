import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Conversation() {
    return (
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 -2 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 5H18C18.5523 5 19 5.44772 19 6V17L15.667 14.231C15.4875 14.0818 15.2608 14 15.0273 14H7C6.44771 14 6 13.5523 6 13V10M14 5V2C14 1.44772 13.5523 1 13 1H2C1.44772 1 1 1.44772 1 2V13.0003L4.33301 10.2308C4.51255 10.0817 4.73924 10 4.97266 10H6M14 5V9C14 9.55229 13.5523 10 13 10H6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}