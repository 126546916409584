import React from 'react';
import clsx from 'clsx';
import Box, { type BoxProps } from '@mui/material/Box';

import { ThemeProvider } from 'src/theme';
import PageTitle from 'src/components/page-title/PageTitle';
import LoadingWrapper from 'src/components/page/loading/LoadingWrapper';
import { useLandlordLayoutContext } from 'src/components/layout/landlord/LandlordLayoutProvider';

import styles from 'src/components/page/PageV2.module.css';

interface Props {
    className?: string,
    classes?: {
        outer?: string,
        inner?: string,
    },
    sx?: BoxProps['sx'],
    title?: string,
    isLoading?: boolean,
    children?: React.ReactNode
}

/**
 * Base component for all pages. Version 2.
 * It will show a loading bar if isLoading is true.
 * Otherwise, it will show the children.
 *
 * @param {string} props.title - The title of the page.
 * @param {boolean} props.isLoading - If true, show loading bar. Set tu `true` is data is loading.
 *
 */
export default function PageV2({
    className,
    classes,
    sx,
    title = 'Reffie',
    isLoading,
    children,
}: Props) {
    const { sidebarWidth } = useLandlordLayoutContext();

    return (
        <ThemeProvider>
            <PageTitle title={title}>
                <Box
                    className={clsx(styles.outer, className, classes?.outer)}
                    sx={{
                        width: `calc(100vw - ${sidebarWidth}px)`,
                        overflowX: 'auto',
                        overflowY: 'scroll',
                        ...sx,
                    }}
                >
                    <Box
                        className={clsx(styles.inner, classes?.inner)}

                    >
                        <LoadingWrapper isLoading={isLoading}>
                            {children}
                        </LoadingWrapper>
                    </Box>
                </Box>
            </PageTitle>
        </ThemeProvider>
    );
}
