import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead } from 'src/services/api/leads/types';
import { useNotifications } from 'src/notifications';
import { apiClient } from 'src/services/api/client';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { getApiErrorMessage } from 'src/services/api/utils';
import { queryKeys } from 'src/services/api/constants';
import { LeadUtils } from 'src/utils';

export type IUpdateLeadResponse = {
    lead: ILead;
};

type IUpdateLeadRequest = Partial<ILead>;

export const useUpdateLead = (leadUuid: string) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        ILead,
        IStandardErrorResponse,
        IUpdateLeadRequest
    >(async (payload) => {
        const path = `${GET_LEADS_PATH}/${leadUuid}`;
        const res = await apiClient(path, {
            method: 'patch',
            json: LeadUtils.normalizeLeadInput(payload),
        }).json<IUpdateLeadResponse>();

        return res.lead;
    }, {
        onSuccess: (data) => {
            queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                if (!oldData) {
                    return [];
                }

                return oldData.map((lead) => {
                    if (lead.uuid === leadUuid) {
                        return data;
                    }

                    return lead;
                });
            });
            queryClient.setQueryData<ILead>([queryKeys.leads, leadUuid], data);

            addNotification('Lead updated', 'success');
        },
        onError: (error) => {
            const errorMessage = getApiErrorMessage(error) || 'Failed to update lead';
            addNotification(errorMessage, 'error');
        },
    });
};
