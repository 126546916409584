import dayjs from 'dayjs';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import React from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';

import type { IMessage } from 'src/services/api';
import { LeadUtils } from 'src/utils';
import { EMessageDirection } from 'src/services/api';
import { Flex } from 'src/components/flex';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';

import styles from 'src/pages/landlord/leads/messages/components/messages-container/MessageHeader.module.css';

dayjs.extend(relativeTime);

type Props = {
    message: IMessage;
}

/**
 * This is the top part of the Message component
 */
export default function MessageHeader({ message }: Props) {
    const { lead } = useLeadMessagesPageContext();
    const sentTime = dayjs(message.inserted_at).fromNow();

    let className;
    if (message.direction === 'out') {
        className = [styles.fromAgent];
    } else {
        className = [styles.fromLead];
    }

    return (
        <div className={styles.messageHeader + ' ' + className}>
            <div className={styles.name}>
                {message.direction === 'out' ? 'You' : LeadUtils.getLeadName(lead)}
            </div>
            <Flex justifyContent="space-between">
                <span className={styles.sentTime}>{sentTime}</span>
                {message.direction === EMessageDirection.OUT && message.is_sent_by_automation && (
                    <span>
                        <span className={styles.desktopOnly}>sent by Reffie playbook</span>
                        <span className={styles.mobileOnly}>
                            <SmartToyOutlinedIcon className={styles.mobileOnly} />
                        </span>
                    </span>
                )}
            </Flex>
        </div>
    );
}