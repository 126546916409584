import { useQuery } from 'react-query';

import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { PATH } from 'src/services/api/teams/settings/constants';
import type { GetTeamSettingsResponse, TeamSettings } from 'src/services/api/teams/settings/types';

export const QUERY_KEY = [queryKeys.teamSettings];

export const useTeamSettings = () => {
    return useQuery<TeamSettings>(
        QUERY_KEY,
        async () => {
            const response = await apiClient(PATH).json<GetTeamSettingsResponse>();
            return response.settings;
        },
    );
};