import React from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import { Flex, FlexColumn } from 'src/components/flex';
import Responsive from 'src/components/responsive';
import ButtonLink from 'src/components/button-link';
import { isFacebookIntegrationNotification } from 'src/services/api/notifications';

export default function FacebookIntegrationAlert() {
    const { pathname } = useLocation();

    const { notifications } = useAuthenticatedState();

    if (
        pathname.startsWith('/landlord/inner/messages/from-lead') ||
        pathname.startsWith('/landlord/inner/playbooks')
    ) {
        return null;
    }

    const facebookIntegrationNotifications = notifications
        .filter(isFacebookIntegrationNotification)
        .filter((notification) => !notification.is_read_by_user);

    if (facebookIntegrationNotifications.length === 0) {
        return null;
    }

    const integration = facebookIntegrationNotifications[0];

    const isSettingsPage = pathname.startsWith('/landlord/inner/settings');

    return (
        <Responsive desktop>
            <Alert
                severity="error"
                sx={{
                    '& .MuiAlert-message': { width: '100%' },
                    '& .MuiAlert-icon': { paddingTop: '10px' },
                }}
            >
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <FlexColumn>
                        <Typography fontWeight="bold" color="error">
                            Facebook Integration Error
                        </Typography>
                        {!!integration.is_informational && <Typography variant="body2" color="error">
                            {integration.text}
                        </Typography>}
                        {!integration.is_informational && <Typography variant="body2" color="error">
                            There was an error with your Facebook integration.
                            {isSettingsPage ? ' Scroll down to the Facebook section to re-connect.' : ' Please review your settings.'}
                        </Typography>}
                    </FlexColumn>

                    {!integration.is_informational && !isSettingsPage && (
                        <ButtonLink variant="outlined" color="error" href="/landlord/inner/settings">
                            Go to account settings
                        </ButtonLink>
                    )}
                </Flex>
            </Alert>
        </Responsive>
    );
}
