import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';

import Search from 'src/components/search/Search';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

const HELPER_TEXT = 'Search properties by id, name, address, type, square footage, rent, tour scheduling link, application link, and labels.';

export default function PropertiesSearch() {
    const [value, setValue] = useState('');
    const [isSearching, setSearching] = useState(false);

    const { search, setSearch } = usePropertiesPageContext();

    const setSearchQueryDebounced = useCallback(debounce(setSearch, 300), []);

    useEffect(() => {
        setSearchQueryDebounced(value);
    }, [value, setSearchQueryDebounced]);

    useEffect(() => {
        if (value !== search) {
            setSearching(true);
        } else {
            setSearching(false);
        }
    }, [value, search]);

    return (
        <Search
            type="search"
            placeholder="Search properties"
            value={value}
            onChange={setValue}
            size="small"
            fullWidth
            isLoading={isSearching}
            helperText={HELPER_TEXT}
            sx={{
                width: 400,
                maxWidth: '100%',
                bgcolor: 'common.white',
                '@media (max-width: 1200px)': {
                    width: '100%',
                },
            }}
            data-testid="properties-search-field"
        />
    );
}
