import { LeadStage } from 'src/services/api';

export const leadStageColors: Record<LeadStage, {backgroundColor: string, textColor: string}> = {
    [LeadStage.NEW]: {backgroundColor: '#99D117', textColor: 'white'},
    [LeadStage.CONTACTED]: {backgroundColor: '#8A65EE', textColor: 'white'},
    [LeadStage.TOUR_SCHEDULED]: {backgroundColor: '#E6F9FF', textColor: '#0076AC'},
    [LeadStage.TOUR_COMPLETED]: {backgroundColor: '#0093D7', textColor: 'white'},
    [LeadStage.INVITED_TO_APPLY]: {backgroundColor: '#EDFCF7', textColor: '#078D61'},
    [LeadStage.APPLICATION_COMPLETED]: {backgroundColor: '#EDFCF7', textColor: '#078D61'},
    [LeadStage.APPLICATION_APPROVED]: {backgroundColor: '#07BA80', textColor: 'white'},
    [LeadStage.NOT_QUALIFIED]: {backgroundColor: '#D50200', textColor: 'white'},
    [LeadStage.NOT_SET]: {backgroundColor: 'lightgray', textColor: 'black'},
};