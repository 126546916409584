import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import { Flex, FlexColumn } from 'src/components/flex';
import { propertiesSortOptions, usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import Select from 'src/components/input/select/Select';

export default function PropertiesSortModal() {
    const {
        isSortingOpen,
        setSortingOpen,
        sortBy,
        setSortBy,
        sortingDirection,
        setSortingDirection,
    } = usePropertiesPageContext();

    return (
        <Popover
            onClose={() => setSortingOpen(false)}
            open={isSortingOpen}
            slotProps={{
                root: { sx: { p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' } },
                paper: { sx: { position: 'static', width: 500, maxWidth: '100%' } }
            }}
        >
            <Flex alignItems="center" p={2}>
                <Typography>
                    Sort
                </Typography>
            </Flex>

            <FlexColumn px={1} py={2} rowGap={2}>
                <Select
                    label="Sort by"
                    placeholder="Select"
                    options={propertiesSortOptions}
                    value={sortBy}
                    onChange={(value) => setSortBy(value)}
                    fullWidth
                    size="small"
                />

                <Select
                    label="Direction"
                    placeholder="Select"
                    options={[
                        { value: 'asc', label: 'Ascending' },
                        { value: 'desc', label: 'Descending' },
                    ]}
                    value={sortingDirection}
                    onChange={(value) => setSortingDirection(value)}
                    fullWidth
                    size="small"
                />
            </FlexColumn>

            <Divider />

            <Flex width="100%" p={1} justifyContent="end">
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={() => setSortingOpen(false)}
                    size="small"
                >
                    Close
                </Button>
            </Flex>
        </Popover>
    );
}
