import dayjs, { type Dayjs } from 'dayjs';
import parsePhoneNumberFromString from 'libphonenumber-js';

import type { ILead, ICreateLeadRequest } from 'src/services/api';
import {
    type LeadForm,
    LeadFormField,
} from 'src/components/create-or-update-lead-modal/types';

export const sharedLeadFormOptions = {
    [LeadFormField.Name]: {
        required: 'Name is required',
        maxLength: {
            value: 100,
            message: 'Name must be less than 100 characters'
        },
    },
    [LeadFormField.Email]: {
        pattern: {
            value: /^\S+@\S+\.\S+$/,
            message: 'Please use a valid email address',
        },
        validate: (value: string, values: LeadForm) => {
            if (!value && !values.phoneNumber && !values.facebookUserId) {
                return 'Email or phone number is required';
            }

            return true;
        },
        deps: [LeadFormField.PhoneNumber],
    },
    [LeadFormField.PhoneNumber]: {
        validate: (value: string, values: LeadForm) => {
            if (!value && !values.email && !values.facebookUserId) {
                return 'Email or phone number is required';
            }

            if (!value || value === '+') {
                return true;
            }

            const phoneNumberParsed = parsePhoneNumberFromString(value, 'US');
            if (!phoneNumberParsed || !phoneNumberParsed.isPossible()) {
                return 'Please use a valid US phone number.';
            }

            return true;
        },
        deps: [LeadFormField.Email],
    }
};

export const createLeadFormOptions = {
    ...sharedLeadFormOptions,
    ingestionSource: {
        required: 'Ingestion source is required',
    },
    firstContactDate: {
        required: 'First contact date is required',
        validate: (value: Dayjs | null) => {
            return value?.isValid ? true : 'Invalid date';
        }
    },
};

export const updateLeadFormOptions = {
    ...sharedLeadFormOptions,
    ownerId: {
        required: 'Owner is required',
    },
};

export const getDefaultValues = (lead?: ILead): LeadForm => {
    return {
        name: lead?.name || '',
        email: lead?.email || '',
        phoneNumber: lead?.phone_number || '',
        ingestionSource: lead?.ingestion_source || '',
        propertyId: String(lead?.property_id),
        firstContactDate: dayjs(),
        labels: lead?.labels || [],
        ownerId: String(lead?.owner),
        facebookUserId: lead?.facebook_user_id || '',
    };
};

export const getCreateLeadPayload = (values: LeadForm, propertyName?: string): ICreateLeadRequest => {
    return {
        name: values.name,
        email: values.email || null,
        phone_number: values.phoneNumber || null,
        ingestion_source: values.ingestionSource,
        property: propertyName,
        labels: values.labels,
        is_synthetic: false,
    };
};

export const getUpdateLeadPayload = (lead: ILead, values: LeadForm) => {
    const phoneNumber = (!values.phoneNumber || values.phoneNumber === '+') ? null : values.phoneNumber;

    return {
        uuid: lead.uuid,
        name: values.name,
        email: values.email || null,
        phone_number: phoneNumber,
        property_id: Number(values.propertyId),
        owner: Number(values.ownerId),
        labels: values.labels,
    };
};
