import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { SortIcon } from 'src/icons';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

export default function PropertiesSortButton() {
    const { setSortingOpen } = usePropertiesPageContext();

    const handleClick = () => {
        setSortingOpen(true);
    };

    return (
        <Button
            color="dark"
            startIcon={<SortIcon />}
            onClick={handleClick}
            data-testid="sort-button"
        >
            <Typography mt={0.25} variant="body2">Sort</Typography>
        </Button>
    );
}
