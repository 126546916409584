import React from 'react';

import type { IShowing } from 'src/api/landlord-showings-api';
import type { ILead } from 'src/services/api';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';
import Tour from 'src/components/lead-sidebar/LeadSidebarTour';

interface Props {
    lead: ILead,
    showings: IShowing[],
}

export default function LeadSidebarPastTours({
    lead,
    showings,
}: Props) {
    const pastShowings = showings.filter((showing) => {
        return showing.lead_uuid === lead.uuid && showing.start_time < new Date().toISOString();
    });

    if (pastShowings.length === 0) {
        return null;
    }

    return (
        <ExpandableItem
            title="Past Tours"
            numShowings={pastShowings.length}
        >
            {pastShowings.map((showing) => (
                <Tour
                    key={showing.id}
                    lead={lead}
                    showing={showing}
                    color="primary.main"
                />
            ))}
        </ExpandableItem>
    );
}
