import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

/**
 * Ask the user whether they want to use an automation after they have created a showing
 */
export default function OpenHouseAutomationModal({ open, onClose }:
    { open: boolean, onClose(goToAutomations: boolean): void }) {


    return <Dialog open={open} onClose={onClose} maxWidth="sm">
        <DialogTitle>{'Would you like make this showing into an open house?'}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Now that you have created a showing, would you like to use an automation to make this showing time into an open house?
            </DialogContentText>

        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color="error" onClick={() => onClose(false)}>No</Button>
            <Button variant="contained" color="success" onClick={() => onClose(true)}>Yes</Button>
        </DialogActions>
    </Dialog>;
}