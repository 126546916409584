import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link';

import styles from 'src/components/link/Link.module.css';

type Props = MuiLinkProps & {
    href: string;
    underline?: 'hover' | 'always';
}

export default function Link({
    className,
    href,
    underline,
    children,
    ...props
}: Props) {
    return (
        <MuiLink
            className={clsx({
                className,
                [styles.underlineHover]: underline === 'hover',
                [styles.underlineAlways]: underline === 'always',
            })}
            component={RouterLink}
            to={href}
            {...props}
        >
            {children}
        </MuiLink>
    );
}