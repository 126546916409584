import React, { useRef } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterListRounded';

import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import FilterModal from 'src/pages/landlord/leads/components/header/LeadsFilterModal';

export default function LeadsFilterButton() {
    const { setFilterOpen } = useLeadsPageContext();

    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClick = () => {
        setFilterOpen(true);
    };

    return (
        <>
            <Button
                ref={buttonRef}
                color="dark"
                startIcon={<FilterIcon />}
                onClick={handleClick}
                data-testid="filter-button"
                sx={{ height: '100%' }}
            >
                <Typography mt={0.25} variant="body2">Filter</Typography>
            </Button>

            <FilterModal anchorEl={buttonRef.current} />
        </>
    );
}
