import { useMutation, useQueryClient, } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { apiClient, queryKeys } from 'src/services/api';
import { LabelType, type ILabel } from 'src/services/api/labels/types';
import { GET_LEAD_LABELS_PATH } from 'src/services/api/leads/labels/constants';

type Request = Partial<Omit<ILabel, 'type'>>;

export const useUpdateLeadLabel = () => {
    const queryClient = useQueryClient();

    return useMutation<void, IStandardErrorResponse, Request>(
        async (payload) => {
            const path = `${GET_LEAD_LABELS_PATH}/${payload.id}`;
            await apiClient(path, {
                method: 'patch',
                json: { ...payload, type: LabelType.LEAD },
            });
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKeys.leadLabels);
                queryClient.invalidateQueries(queryKeys.leads);
            }
        }
    );
};
