import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IPropertyApplicationLink } from 'src/api/landlord-properties-api';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';
import type { IProperty } from 'src/services/api/properties/types';
import { getApiErrorMessage } from 'src/services/api/utils';

type IUpdatePropertyApplicationLinksResponse = {
    property_application_link: IPropertyApplicationLink
}
type IUpdatePropertyApplicationLinksRequest = Array<{ id: number; link: string;}>

export const useUpdatePropertyApplicationLinks = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IProperty[],
        IStandardErrorResponse[],
        IUpdatePropertyApplicationLinksRequest
    >(
        async (payload) => {
            const res = await Promise.allSettled(payload.map((property) => {
                const path = `${GET_PROPERTIES_PATH}/${property.id}/application-link`;
                return apiClient(path, {
                    method: 'post',
                    json: property,
                }).json<IUpdatePropertyApplicationLinksResponse>();
            }));

            const oldProperties = queryClient.getQueryData<IProperty[]>(queryKeys.properties) || [];

            const updatedApplicationLinks = res
                .filter((o) => o.status === 'fulfilled')
                .map((o) => o.value.property_application_link);

            const updatedProperties = oldProperties.map((property) => {
                const updatedApplicationLink = updatedApplicationLinks
                    .find((link) => link.property_id === property.id);

                if (!updatedApplicationLink) {
                    return property;
                }

                return {
                    ...property,
                    application_link: updatedApplicationLink,
                };
            });

            queryClient.setQueryData<IProperty[]>(queryKeys.properties, updatedProperties);
            for (const property of updatedProperties) {
                queryClient.setQueryData<IProperty>([queryKeys.properties, property.id], property);
            }

            const errors = res.filter((r) => r.status === 'rejected');
            if (errors.length > 0) {
                throw errors.map(o => o.reason);
            } else {
                addNotification('Property application link(s) updated', 'success');
            }

            return updatedProperties;
        }, {
            onError: (errors) => {
                const errorMessage = getApiErrorMessage(errors) || 'Failed to update property application link(s)';
                addNotification(errorMessage, 'error');
            }
        });
};
