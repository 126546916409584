import React, { useMemo } from 'react';
import clsx from 'clsx';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import MuiAutocomplete, { type AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

import { type Option } from 'src/components/input/select/Select';

import styles from 'src/components/input/autocomplete/Autocomplete.module.css';

type BaseProps<T = string | undefined> = MuiAutocompleteProps<Option<T>, false, false, false>;

export type AutocompleteProps<T = string | undefined> = Omit<BaseProps<T>, 'value' | 'onChange' | 'renderInput'> & {
    variant?: TextFieldProps['variant'],
    label?: string,
    placeholder?: string,
    value?: T,
    onChange: (value?: T) => void,
    error?: TextFieldProps['error'],
    helperText?: TextFieldProps['helperText'],
    required?: TextFieldProps['required'],
    /**
     * Text to display for the option that clears the value.
     * If not provided, the option will not be displayed.
     */
    clearOption?: string,
    /**
     * If true, the placeholder will be faded.
     * By default, the placeholder is not faded.
     */
    withFadedPlaceholder?: boolean,
}

export default function Autocomplete({
    variant,
    label,
    placeholder,
    options: propsOptions,
    value,
    onChange,
    error,
    helperText,
    required,
    clearOption,
    withFadedPlaceholder,
    ...props
}: AutocompleteProps) {
    const options = useMemo(() => {
        if (!clearOption) {
            return propsOptions;
        }

        return [
            {
                label: clearOption,
                value: undefined,
            },
            ...propsOptions,
        ];
    }, [propsOptions, clearOption]);

    const selectedOption = useMemo(() => {
        return options.find((option) => option.value === value) || null;
    }, [options, value]);

    const handleChange: BaseProps['onChange'] = (event, option) => {
        onChange?.(option?.value);
    };

    return (
        <MuiAutocomplete
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    className={clsx(styles.root, withFadedPlaceholder && styles.fadedPlaceholder)}
                    label={label}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                    required={required}
                />
            )}
            {...props}
            options={options}
            value={selectedOption}
            onChange={handleChange}
        />
    );
}
