import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import type { ILead } from 'src/services/api';
import { Flex } from 'src/components/flex';
import LeadsTablePagination from 'src/pages/landlord/leads/components/table/LeadsTablePagination';
import MobileLeadsTableRow from 'src/pages/landlord/leads/components/table/MobileLeadsTableRow';

const LEADS_ROWS_PER_PAGE = 25;

interface Props {
    title?: string,
    leads?: ILead[],
}

export default function MobileLeadsTable({
    title,
    leads,
}: Props) {
    const [page, setPage] = useState(1);

    const numberOfLeads = leads?.length || 0;

    const startIndex = (page - 1) * LEADS_ROWS_PER_PAGE;

    let endIndex = page * LEADS_ROWS_PER_PAGE;
    if (endIndex > numberOfLeads) {
        endIndex = numberOfLeads;
    }

    const currentPageLeads = useMemo(() => {
        if (!leads) {
            return [];
        }

        return leads.slice(startIndex, endIndex);
    }, [leads, page, LEADS_ROWS_PER_PAGE, startIndex, endIndex]);

    // Reset page when leads change
    useEffect(() => {
        setPage(1);
    }, [leads?.map(lead => lead.uuid).join(',')]);

    return (
        <div>
            <Flex
                py={1}
                justifyContent="space-between"
                borderTop="1px solid var(--border-color)"
                borderBottom="1px solid var(--border-color)"
                bgcolor="white"
            >
                <Typography variant="h3">{title}</Typography>
                <LeadsTablePagination
                    leads={leads}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={LEADS_ROWS_PER_PAGE}
                />
            </Flex>

            <TableContainer component={Paper} elevation={0} square>
                <Table sx={{ tableLayout: 'fixed' }}>
                    <TableBody>
                        {currentPageLeads.map((lead) => (
                            <MobileLeadsTableRow key={lead.uuid} lead={lead} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
