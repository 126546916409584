import React, { useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { type ILead, useUpdateLeads } from 'src/services/api';
import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import { FlexColumn } from 'src/components/flex';
import Dialog from 'src/components/dialog/Dialog';
import SelectTeamMember from 'src/components/input/select-team-member';

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    leads: ILead[];
};

export default function BulkChangeLeadOwnerModal({
    open,
    onClose,
    onSuccess,
    leads
}: Props) {
    const { loggedInUser } = useAuthenticatedState();
    const { mutateAsync: updateLeads, isLoading: isUpdatingLeads } = useUpdateLeads();

    const [selectedOwnerUuid, setSelectedOwnerUuid] = useState<string>();
    const selectedOwner = useMemo(() => {
        return loggedInUser.leasing_team_members?.find(o => o.uuid === selectedOwnerUuid);
    }, [loggedInUser, selectedOwnerUuid]);

    const save = async () => {
        if (!selectedOwner) {
            return;
        }

        await updateLeads(leads.map(lead => ({
            ...lead,
            owner: selectedOwner.id
        })));

        onSuccess?.();
        onClose();
    };

    const areAllLeadsOwnedBySelectedOwner = leads.every(o => o.owner === selectedOwner?.id);

    // If all leads are owned by the same owner, pre-select that owner
    useEffect(() => {
        const ownerIds = leads.map(lead => lead.owner);
        const ownerId = ownerIds[0];
        const areAllLeadsOwnedBySameOwner = new Set(ownerIds).size === 1;

        if (areAllLeadsOwnedBySameOwner) {
            const ownerUuid = loggedInUser.leasing_team_members?.find(o => o.id === ownerId)?.uuid;
            setSelectedOwnerUuid(ownerUuid);
        }
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Change Owner</DialogTitle>
            <DialogContent>
                <FlexColumn>
                    <SelectTeamMember
                        variant="standard"
                        label="Owner"
                        value={selectedOwnerUuid}
                        onChange={setSelectedOwnerUuid}
                    />

                    <DialogContentText mt={2}>
                        This action will change the owner of {leads.length} {pluralize('leads', leads.length)}.
                    </DialogContentText>
                </FlexColumn>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                    disabled={isUpdatingLeads}
                >
                    Cancel
                </Button>

                <LoadingButton
                    variant="contained"
                    onClick={save}
                    disabled={!selectedOwner || areAllLeadsOwnedBySelectedOwner}
                    loading={isUpdatingLeads}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
