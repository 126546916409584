import type { ILead } from 'src/services/api';
import type { IProperty } from 'src/services/api';

const getLeadSearchTokens = (leads: ILead[] | undefined, properties: IProperty[] | undefined) => {
    if (!leads) {
        return [];
    }

    return leads.reduce<[ILead, string[]][]>((acc, lead) => {
        const phoneNumber = lead.phone_number || '';

        const uuid = lead.uuid;
        const name = lead.name || '';
        const email = lead.email || '';
        const phoneNumberNormalized = phoneNumber.replace(/\D/g, '');
        const notes = lead.notes?.contents || '';
        const stage = lead.stage.split('_');

        const property = properties?.find((o) => o.id === lead.property_id);
        const propertyId = property?.id ? String(property.id) : '';
        const isPropertyVacant = property?.is_vacant;
        const propertyVacancyStatus = isPropertyVacant == null ? '' : isPropertyVacant ? 'vacant' : 'occupied';
        const propertyName = property?.name || '';
        const labels = lead.labels?.map((label) => label.name);

        const tokens = [
            uuid,
            name,
            email,
            phoneNumber,
            phoneNumberNormalized,
            notes,
            ...stage,
            propertyId,
            propertyVacancyStatus,
            propertyName,
            ...labels,
        ].filter(Boolean).map((token) => token.toLowerCase());

        acc.push([lead, tokens]);

        return acc;
    }, []);
};

export const searchLeads = (
    leads: ILead[] | undefined,
    properties: IProperty[] | undefined,
    searchQuery: string,
) => {
    if (!leads) {
        return [];
    }

    if (!searchQuery) {
        return leads;
    }

    const leadSearchTokens = getLeadSearchTokens(leads, properties);

    const filteredLeads: ILead[] = [];

    const searchQueryTokens = searchQuery
        .toLowerCase()
        .split(' ')
        .map((token) => token.trim())
        .filter(Boolean);

    for (const [lead, leadTokens] of leadSearchTokens) {
        let foundAllSearchTokens = true;

        for (const searchToken of searchQueryTokens) {
            let foundSearchToken = false;

            for (const leadToken of leadTokens) {
                if (leadToken.includes(searchToken)) {
                    foundSearchToken = true;
                    break;
                }
            }

            if (!foundSearchToken) {
                foundAllSearchTokens = false;
                break;
            }
        }

        if (foundAllSearchTokens) {
            filteredLeads.push(lead);
        }
    }

    return filteredLeads;
};