import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayOfYear from 'dayjs/plugin/dayOfYear';

import { type ILead } from 'src/services/api';
import { type IProperty } from 'src/services/api';
import { type ILeadFilters, LeadsDateFilter } from 'src/pages/landlord/leads/provider';
import { normalizeSource } from 'src/pages/landlord/analytics/utils';

dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);

export const filterLeads = (
    leads: ILead[] | undefined,
    properties: IProperty[] | undefined,
    filters: ILeadFilters,
) => {
    if (!leads) {
        return [];
    }

    let _filteredLeads = leads;

    if (filters.hasNoProperty) {
        _filteredLeads = _filteredLeads.filter((lead) => !lead.property_id);
    }

    if (filters.assigneeIds.length > 0) {
        _filteredLeads = _filteredLeads
            .filter((lead) => filters.assigneeIds.includes(String(lead.owner)));
    }

    if (filters.ingestionSources.length > 0) {
        const ingestionSources = filters.ingestionSources;

        _filteredLeads = _filteredLeads
            .filter((lead) => ingestionSources.includes(
                normalizeSource(lead.ingestion_source)
            ));
    }

    if (filters.ingestionMethods.length > 0) {
        _filteredLeads = _filteredLeads
            .filter((lead) => filters.ingestionMethods.includes(lead.ingestion_method));
    }

    if (filters.leadStages.length > 0) {
        _filteredLeads = _filteredLeads
            .filter((lead) => filters.leadStages.includes(lead.stage));
    }

    if (filters.leadLabelIds.length > 0) {
        _filteredLeads = _filteredLeads.filter((lead) => {
            return filters.leadLabelIds.some((labelId) => {
                return lead.labels.some((label) => label.id === Number(labelId));
            });
        });
    }

    if (filters.propertyIds.length > 0) {
        _filteredLeads = _filteredLeads
            .filter((lead) => !!lead.property_id)
            .filter((lead) => filters.propertyIds.includes(String(lead.property_id)));
    }

    if (filters.propertyLabelIds.length > 0) {
        _filteredLeads = _filteredLeads
            .filter((lead) => !!lead.property_id)
            .filter((lead) => {
                const property = properties?.find((o) => o.id === lead.property_id);
                return filters.propertyLabelIds.every((labelId) => {
                    return property?.labels.map((label) => label.id).includes(Number(labelId));
                });
            });
    }

    const dateRange = filters.dateRange;
    if (dateRange) {
        const now = dayjs();
        const currentMonth = now.month();
        const currentWeek = now.week();
        const currentDay = now.dayOfYear();

        _filteredLeads = _filteredLeads.filter((lead: ILead) => {
            const insertedAt = dayjs(lead.inserted_at);

            switch (dateRange) {
                case LeadsDateFilter.Month: {
                    const month = insertedAt.month();
                    return month === currentMonth;
                }
                case LeadsDateFilter.Week: {
                    const week = insertedAt.week();
                    return week === currentWeek;
                }
                case LeadsDateFilter.Day: {
                    const day = insertedAt.dayOfYear();
                    return day === currentDay;
                }
                default: {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const _assertNever: never = dateRange;
                }
            }
        });
    }

    return _filteredLeads;
};