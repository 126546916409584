import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ReadStatus } from 'src/api/landlord-messages-api';
import type { IStandardResponse } from 'src/common/api-utils';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';

const PATH = 'api/landlord/messages/read-status';

type Response = IStandardResponse;

type UpdateMessagesRequest = {
    status: ReadStatus;
    messageUuids: string[];
};

type UpdateLeadsRequest = {
    status: ReadStatus;
    leadUuids: string[];
};

type Request = UpdateMessagesRequest | UpdateLeadsRequest;

export const useUpdateConversationsReadStatus = () => {
    const queryClient = useQueryClient();

    return useMutation<Response, IStandardErrorResponse, Request>(
        (payload) => {
            const json = {
                read_status: payload.status,
                message_uuids: (payload as UpdateMessagesRequest).messageUuids,
                lead_uuids: (payload as UpdateLeadsRequest).leadUuids,
            };

            return apiClient(PATH, { method: 'patch', json }).json<IStandardResponse>();
        }, {
            onSuccess: () => queryClient.invalidateQueries(queryKeys.conversations),
        }
    );
};