import type { Dayjs } from 'dayjs';

import type { PropertyType } from 'src/api/landlord-properties-api';
import type { Address } from 'src/services/location/types';

export enum EPropertyFormField {
  IsVacant = 'is_vacant',
  VacantSince = 'vacant_since',
  Name = 'name',
  Country = 'country',
  Address = 'address',
  Labels = 'labels',
  Rent = 'rent',
  Type = 'type',
  NumberOfBedrooms = 'num_bedrooms',
  NumberOfBathrooms = 'num_bathrooms',
  SquareFeet = 'square_feet',
}

export const propertyFormFields = Object.values(EPropertyFormField) as EPropertyFormField[];

export type PropertyForm = {
  [EPropertyFormField.IsVacant]: boolean;
  [EPropertyFormField.VacantSince]: Dayjs | null;
  [EPropertyFormField.Name]?: string;
  [EPropertyFormField.Country]: string;
  [EPropertyFormField.Address]: Address | null;
  [EPropertyFormField.Labels]: string[];
  [EPropertyFormField.Rent]: string;
  [EPropertyFormField.Type]: PropertyType;
  [EPropertyFormField.NumberOfBedrooms]: string;
  [EPropertyFormField.NumberOfBathrooms]: string;
  [EPropertyFormField.SquareFeet]?: string;
};
