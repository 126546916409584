import React, { useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ArrowRightIcon from '@mui/icons-material/ChevronRightRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';

import type { LeadStage } from 'src/services/api';
import { LEAD_STAGES } from 'src/services/api';
import { Flex } from 'src/components/flex';
import { LeadsIcon, PropertiesIcon, UsersIcon } from 'src/icons';
import { StageChip } from 'src/components/input/select-lead-stage/SelectLeadStage';
import SelectLabels from 'src/components/input/select-labels/SelectLabels';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import AutocompleteMultiple from 'src/components/input/autocomplete-multiple/AutocompleteMultiple';

enum FilteringBy {
    Assignee = 'Assignee',
    LeadStage = 'Lead Stage',
    LeadLabel = 'Lead Label',
    Property = 'Property',
    PropertyLabel = 'Property Label',
}

const teamMemberFilters = [FilteringBy.Assignee];
const leadFilters = [FilteringBy.LeadStage, FilteringBy.LeadLabel];
const propertyFilters = [FilteringBy.Property, FilteringBy.PropertyLabel];

type Props = {
    anchorEl?: HTMLElement | null;
}

export default function ConversationsFilterModal({ anchorEl }: Props) {
    const {
        teamMembers,
        properties,
        isFilterOpen,
        setFilterOpen,
        filter,
        setFilter,
        resetFilter,
    } = useConversationsPageContext();

    const searchRef = useRef<HTMLInputElement>(null);

    const [filteringBy, setFilteringBy] = useState<FilteringBy>();

    const handleClose = () => {
        setFilterOpen(false);
        setFilteringBy(undefined);
    };

    const handleToggleLeadStage = (stage: LeadStage) => {
        setFilter(prev => {
            if (prev.leadStages.includes(stage)) {
                return { ...prev, leadStages: prev.leadStages.filter(o => o !== stage) };
            } else {
                return { ...prev, leadStages: [...prev.leadStages, stage] };
            }
        });
    };

    // Focus on the search input when the modal is opened
    useEffect(() => {
        if (isFilterOpen) {
            setTimeout(() => searchRef.current?.focus(), 0);
        }
    }, [isFilterOpen]);

    const teamMemberOptions = useMemo(() => {
        return teamMembers.map(member => ({
            value: String(member.id),
            label: member.name,
        }));
    }, [teamMembers]);

    const propertyOptions = useMemo(() => {
        const _propertyOptions = properties?.map(property => ({
            value: String(property.id),
            label: property.name,
        })) || [];

        return [
            { value: 'none', label: 'None' },
            ..._propertyOptions,
        ];
    }, [properties]);

    return (
        <Menu
            onClose={handleClose}
            open={isFilterOpen && !!anchorEl}
            anchorEl={anchorEl}
            slotProps={{
                root: {
                    sx: {
                        '@media (max-width: 900px)': {
                            p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }
                    }
                },
                paper: {
                    sx: {
                        width: 400,
                        maxWidth: '100%',
                        '@media (max-width: 900px)': {
                            position: 'static', width: 500, maxWidth: '100%'
                        }
                    }
                }
            }}
        >
            <Flex alignItems="center" p={2}>
                {!!filteringBy && (
                    <IconButton onClick={() => setFilteringBy(undefined)} edge="start">
                        <ArrowBackRounded />
                    </IconButton>
                )}

                <Typography>
                    Filter by
                </Typography>
                <Typography>
                    {filteringBy}
                </Typography>
            </Flex>

            {!filteringBy && (
                <div>
                    {teamMemberFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider />

                    {leadFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <LeadsIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}

                    <Divider />

                    {propertyFilters.map((item) => (
                        <MenuItem key={item} onClick={() => setFilteringBy(item)}>
                            <ListItemIcon>
                                <PropertiesIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>
                                    {item}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <ArrowRightIcon />
                            </ListItemIcon>
                        </MenuItem>
                    ))}
                </div>
            )}

            {!!filteringBy && (
                <div>
                    {filteringBy === FilteringBy.Assignee && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select Assignees"
                                options={teamMemberOptions}
                                value={filter.assigneeIds}
                                onChange={(assigneeIds) => {
                                    setFilter(prev => ({
                                        ...prev,
                                        assigneeIds: assigneeIds || [],
                                    }));
                                }}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.LeadStage && LEAD_STAGES.map(stage => {
                        return (
                            <MenuItem key={stage} onClick={() => handleToggleLeadStage(stage)}>
                                <Flex alignItems="center">
                                    <Checkbox
                                        checked={filter.leadStages.includes(stage)}
                                        sx={{ padding: 0, paddingLeft: 1 }}
                                    />
                                    <StageChip value={stage} />
                                </Flex>
                            </MenuItem>
                        );
                    })}

                    {filteringBy === FilteringBy.LeadLabel && (
                        <Flex p={1}>
                            <SelectLabels
                                type="lead"
                                placeholder="Select lead labels..."
                                value={filter.leadLabelIds}
                                onChange={(leadLabelIds) => setFilter(prev => ({ ...prev, leadLabelIds: leadLabelIds || [] }))}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.Property && (
                        <Flex p={1}>
                            <AutocompleteMultiple
                                placeholder="Select properties"
                                options={propertyOptions}
                                value={filter.propertyIds}
                                onChange={(propertyIds) => {
                                    if (propertyIds?.includes('none')) {
                                        setFilter(prev => ({
                                            ...prev,
                                            propertyIds: [],
                                            hasNoProperty: true,
                                        }));

                                        handleClose();
                                    } else {
                                        setFilter(prev => ({
                                            ...prev,
                                            propertyIds: propertyIds || [],
                                            hasNoProperty: false,
                                        }));
                                    }
                                }}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}

                    {filteringBy === FilteringBy.PropertyLabel && (
                        <Flex p={1}>
                            <SelectLabels
                                type="property"
                                placeholder="Select property labels..."
                                value={filter.propertyLabelIds}
                                onChange={(propertyLabelIds) => setFilter(prev => ({ ...prev, propertyLabelIds: propertyLabelIds || [] }))}
                                size="small"
                                fullWidth
                            />
                        </Flex>
                    )}
                </div>
            )}

            <Divider />

            <Flex width="100%" p={1} justifyContent="space-between">
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        resetFilter();
                        handleClose();
                    }}
                    size="small"
                    fullWidth
                >
                    Clear All
                </Button>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={handleClose}
                    size="small"
                    fullWidth
                >
                    Close
                </Button>
            </Flex>
        </Menu>
    );
}
