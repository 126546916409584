import { createContext, useContext } from 'react';

import type { TLandlordConversationsContext } from 'src/pages/landlord/conversations/context/types';

// @ts-expect-error lazy init
export const LandlordConversationsContext = createContext<TLandlordConversationsContext>();

export const useConversationsPageContext = () => {
    const context = useContext(LandlordConversationsContext);

    if (!context) {
        throw new Error('useConversationsPageContext must be used within a LandlordConversationsProvider');
    }

    return context;
};