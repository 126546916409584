import React from 'react';

import { stateOptionsUS } from 'src/services/location/constants/us';
import Autocomplete from 'src/components/input/autocomplete/Autocomplete';

import styles from 'src/components/input/address/SelectStateUS.module.css';

type Props = Omit<React.ComponentProps<typeof Autocomplete>, 'options'>;

export default function SelectStateUS(props: Props) {
    return (
        <Autocomplete
            classes={{ root: styles.root, endAdornment: styles.endAdornment }}
            options={stateOptionsUS}
            {...props}
            data-testid="state-field"
        />
    );
}
