import React from 'react';

import { Flex } from 'src/components/flex';
import Chip from 'src/components/chip/Chip';

type Props = {
    chips: {
        label: string,
        color: string,
    }[],
};

export default function ChipsRow({ chips }: Props) {
    const firstTwoChips = chips.slice(0, 2);
    const remainingChips = chips.slice(2);

    return (
        <Flex alignItems="center" flexWrap="wrap">
            {firstTwoChips.map((chip,) => (
                <Chip key={chip.label} label={chip.label} color={chip.color} size="small" data-testid="lead-label-chip" />
            ))}

            {remainingChips.length > 0 && (
                <Chip label={`+${remainingChips.length}`} size="small" />
            )}
        </Flex>
    );
}