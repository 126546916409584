import React, { useMemo, useRef, useState } from 'react';
import pluralize from 'pluralize';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';

import {
    type ILead,
    EMessageChannel,
    useSendMessages,
    useUpdateConversationsReadStatus
} from 'src/services/api';
import { useUpdateLeads } from 'src/services/api';
import { ReadStatus } from 'src/api/landlord-messages-api';
import Dialog from 'src/components/dialog/Dialog';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import { Flex, FlexColumn } from 'src/components/flex';

type Props = {
    open: boolean,
    onClose: () => void,
};

export default function BulkMessageLeadsModal({
    open,
    onClose,
}: Props) {
    const { mutateAsync: sendMessages } = useSendMessages();

    const {
        mutateAsync: updateReadStatus,
        isLoading: isUpdatingReadStatus,
        variables: updateReadStatusVariables,
    } = useUpdateConversationsReadStatus();

    const { mutateAsync: updateLeads, isLoading: isUpdatingLeads } = useUpdateLeads();

    const {
        loggedInUser,
        leads,
        leadsByUuid,
        selectedConversations,
        setSelectedConversations,
    } = useConversationsPageContext();

    const numEmails = selectedConversations.filter((conversation) => {
        return conversation.latest_message_channel === EMessageChannel.EMAIL;
    }).length;
    const numSms = selectedConversations.filter((conversation) => {
        return conversation.latest_message_channel === EMessageChannel.SMS;
    }).length;
    const numFacebookMessages = selectedConversations.filter((conversation) => {
        return conversation.latest_message_channel === EMessageChannel.FACEBOOK_MESSENGER;
    }).length;

    const formRef = useRef<HTMLFormElement>(null);

    const [message, setMessage] = useState('');
    const [isSending, setSending] = useState(false);

    const unreadConversations = useMemo(() => {
        return selectedConversations.filter((conversation) => {
            return conversation.num_unread_messages > 0;
        });
    }, [selectedConversations]);

    const unownedConversations = useMemo(() => {
        return selectedConversations.filter((conversation) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            if (!lead) { return false; }

            const isOwnedByCurrentUser = lead.owner === loggedInUser.id;
            return !isOwnedByCurrentUser;
        });
    }, [selectedConversations, leads]);

    const handleSendMessage = async (event: React.FormEvent) => {
        event.preventDefault();
        setSending(true);

        try {
            const validMessages = selectedConversations.filter((conversation) => {
                const lead = leadsByUuid.get(conversation.lead_uuid);
                if (lead?.email?.endsWith('@example.com')) {
                    console.debug('Not sending an email to example.com');
                    return false;
                }

                return true;
            });

            await sendMessages(validMessages.map((conversation) => {
                return {
                    leadUuid: conversation.lead_uuid,
                    channel: conversation.latest_message_channel,
                    message,
                };
            }));

            setSelectedConversations([]);
            onClose();
        } finally {
            setMessage('');
            setSending(false);
        }
    };

    const setReadStatus = async (status: ReadStatus) => {
        const leadUuids = unreadConversations.map((o) => o.lead_uuid);

        await updateReadStatus({
            leadUuids,
            status,
        });
    };

    const setOwnership = async () => {
        const leadsToUpdate: ILead[] = [];
        unownedConversations.forEach((conversation) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            if (!lead) { return; }
            leadsToUpdate.push(lead);
        });

        if (!leadsToUpdate.length) {
            return;
        }

        await updateLeads(leadsToUpdate.map(lead => ({
            ...lead,
            owner: loggedInUser.id
        })));
    };

    const deselectUnreadMessages = () => {
        setSelectedConversations(
            selectedConversations.filter((conversation) => {
                return !unreadConversations.includes(conversation);
            })
        );
    };

    const deselectUnownedLeads = () => {
        setSelectedConversations(
            selectedConversations.filter((conversation) => {
                return !unownedConversations.includes(conversation);
            })
        );
    };

    const hasUnreadConversations = unreadConversations.length > 0;
    const hasUnownedConversations = unownedConversations.length > 0;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Send a bulk message</DialogTitle>

            <DialogContent>
                {hasUnreadConversations &&
                    <FlexColumn mb={2}>
                        <Alert
                            severity="warning"
                        >
                            You have selected {unreadConversations.length} unread {pluralize('conversation', unreadConversations.length)}. How do you want to proceed?
                        </Alert>
                        <Flex justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                color="dark"
                                onClick={deselectUnreadMessages}
                            >
                                Deselect unread
                            </Button>
                            <LoadingButton
                                variant="outlined"
                                loading={updateReadStatusVariables?.status === ReadStatus.READ && isUpdatingReadStatus}
                                onClick={() => setReadStatus(ReadStatus.READ)}
                            >
                                Mark read
                            </LoadingButton>
                        </Flex>
                    </FlexColumn>
                }

                {hasUnownedConversations && !hasUnreadConversations &&
                    <FlexColumn mb={2}>
                        <Alert
                            severity="warning"
                        >
                            You have selected {unownedConversations.length} {pluralize('conversation', unownedConversations.length)} that you do not own. How do you want to proceed?
                        </Alert>
                        <Flex justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                color="dark"
                                onClick={deselectUnownedLeads}
                            >
                                Deselect unowned
                            </Button>
                            <LoadingButton
                                variant="outlined"
                                // color="dark"
                                loading={isUpdatingLeads}
                                onClick={setOwnership}
                            >
                                Take Ownership
                            </LoadingButton>
                        </Flex>
                    </FlexColumn>
                }

                <form ref={formRef} onSubmit={handleSendMessage}>
                    <TextField
                        placeholder="Your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        multiline
                        minRows={8}
                        required
                        fullWidth
                        disabled={hasUnreadConversations || hasUnownedConversations}
                    />
                </form>

                <DialogContentText mt={2}>
                    {getActionSummaryText(numEmails, numSms, numFacebookMessages)}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                    disabled={isSending}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={() => formRef.current?.requestSubmit()}
                    loading={isSending}
                    disabled={hasUnreadConversations || hasUnownedConversations}
                >
                    Send
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

const getActionSummaryText = (
    numEmails: number,
    numSms: number,
    numFacebookMessages: number,
) => {
    if (!numEmails && !numSms && !numFacebookMessages) {
        return null;
    }

    return `This action will send ${numEmails} ${pluralize('email', numEmails)}, ${numSms} SMS ${pluralize('message', numSms)}, and ${numFacebookMessages} Facebook Messenger ${pluralize('message', numFacebookMessages)}.`;
};