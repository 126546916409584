import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import styles from 'src/components/table/simple-table/SimpleTable.module.css';

type Props = {
    columns: string[];
    rows: {
        label: string;
        hasError: boolean;
    }[][];
}

/**
 * This component displays a simple table with the given columns and rows.
 * Both columns and rows are simple string arrays or arrays of string arrays respectively.
 * Because of this, this component is not suitable for data that can be updated, filtered or sorted.
 * It is the responsibility of the parent component to render a new SimpleTable when the data changes.
 */
export default function SimpleTable({ columns, rows }: Props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const visibleRows = React.useMemo(() => {
        return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [page, rowsPerPage]);

    return (
        <div className={styles.root}>
            <TableContainer>
                <Table size="small">
                    <TableHead sx={{ backgroundColor: 'primary.main' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white' }}>
                                #
                            </TableCell>
                            {columns.map((column, index) => {
                                const isFirst = index === 0;
                                const align = isFirst ? 'left' : 'right';
                                return (
                                    <TableCell key={column} align={align} sx={{ color: 'white' }}>
                                        {column}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, rowIndex) => {
                            const rowHasErrors = row.some((cell) => cell.hasError);

                            return (
                                <TableRow key={rowIndex} sx={{ bgcolor: rowHasErrors ? 'error.50' : 'transparent' }}>
                                    <TableCell>
                                        <Typography>
                                            {rowIndex + 1}
                                        </Typography>
                                    </TableCell>
                                    {row.map((cell, cellIndex) => {
                                        const isFirst = cellIndex === 0;
                                        const align = isFirst ? 'left' : 'right';
                                        const color = cell.hasError ? 'error.main' : 'initial';
                                        const fontWeight = cell.hasError ? 'bold' : 'normal';

                                        return (
                                            <TableCell key={cellIndex} align={align} >
                                                <Typography fontWeight={fontWeight} color={color}>
                                                    {cell.hasError && 'Error: '}
                                                    {cell.label || (cell.hasError && 'required')}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}