import Papa from 'papaparse';

export interface ParsedCsv<T extends object> {
  header: string[];
  body: T[];
}

export async function parseCsvFromFile<T extends object>(file: File) {
    const fileContent = await file.text();
    return parseCsvFromString<T>(fileContent);
}

export async function parseCsvFromString<T extends object>(text: string): Promise<ParsedCsv<T>> {
    const textWithoutEmptyLines = text
        .split('\n')
        .map(line => line.trim())
        .filter(Boolean)
        .join('\n');

    const parseResult = Papa.parse<T>(textWithoutEmptyLines, {
        header: true,
    });

    const header = parseResult.meta.fields;
    const body = parseResult.data;

    if (!header) {
        throw new Error('CSV file has no header');
    }

    return {
        header,
        body,
    };
}
