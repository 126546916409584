import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IProperty, ICreatePropertyRequest } from 'src/services/api/properties/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';

type ICreatePropertyResponse = {
    property: IProperty;
};

export const useCreateProperty = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IProperty,
        IStandardErrorResponse,
        ICreatePropertyRequest
    >(
        async (payload) => {
            const res = await apiClient(GET_PROPERTIES_PATH, {
                method: 'post',
                json: payload,
            }).json<ICreatePropertyResponse>();

            return res.property;
        }, {
            onSuccess: (property) => {
                addNotification('Property created successfully', 'success');

                queryClient.setQueryData<IProperty[]>(queryKeys.properties, (oldData) => {
                    if (!oldData) {
                        return [property];
                    }

                    return [...oldData, property];
                });

                queryClient.setQueryData<IProperty>([queryKeys.properties, property.id], property);
            },
            onError: (error) => {
                const errorMessage = getApiErrorMessage(error) || 'Failed to create property';
                addNotification(errorMessage, 'error');
            }
        });
};
