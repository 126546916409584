import React, { useMemo } from 'react';
import { createFilterOptions } from '@mui/material';

import Autocomplete, { type AutocompleteProps } from 'src/components/input/autocomplete/Autocomplete';
import { useProperties, useShowings } from 'src/services/api';

type Props = Omit<AutocompleteProps, 'options'> & {
    withShowings?: boolean,
    isVacant?: boolean,
    /**
     * Show an option to clear the value.
     */
    isClearable?: boolean,
    /**
     * Text to show in the clearable option.
     * If `isClearable` is `false`, this prop is ignored.
     * @default "None"
     */
    clearableText?: string,
}

export default function SelectProperty({
    value,
    withShowings,
    isVacant,
    isClearable,
    clearableText,
    ...props
}: Props) {
    const { data: properties } = useProperties();
    const { data: showings } = useShowings();

    const options = useMemo(() => {
        if (!properties) {
            return [];
        }

        let filteredProperties = properties;

        if (withShowings) {
            const propertiesWithAShowing = new Set(showings?.map((showing) => {
                return showing.property_id;
            }));

            filteredProperties = filteredProperties.filter((property) => {
                return propertiesWithAShowing.has(property.id);
            });
        }

        if (isVacant) {
            filteredProperties = filteredProperties.filter((property) => {
                return property.is_vacant;
            });
        }

        // Add the selected property to the top of the list if it is not already in the list
        const selectedProperty = properties.find((property) => property.id === Number(value));
        const selectedPropertyNotInOptions = !filteredProperties.find((o) => o.id === selectedProperty?.id);
        if (selectedProperty && selectedPropertyNotInOptions) {
            filteredProperties.unshift(selectedProperty);
        }


        const _options = filteredProperties.map((property) => ({
            value: String(property.id),
            label: property.name,
        }));

        if (isClearable) {
            _options.unshift({ value: '', label: clearableText || 'None' });
        }

        return _options;
    }, [properties, showings, withShowings, isVacant, isClearable, clearableText, value]);

    return (
        <Autocomplete
            {...props}
            value={value}
            options={options}
            filterOptions={filterOptions}
            data-testid="property-select"
        />
    );
}

const filterOptions: Props['filterOptions'] = createFilterOptions({
    limit: 100,
});
