import React from 'react';
import pluralize from 'pluralize';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';
import LoadingButton from '@mui/lab/LoadingButton';

import { Flex, FlexColumn } from 'src/components/flex';
import { type IProperty, type NonLeasedPropertyNotification, useSnoozeNotification } from 'src/services/api';
import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';

type Props = {
    notification: NonLeasedPropertyNotification
    property: IProperty;
    numNotifications: number;
    onContinue: () => void;
    onClose: () => void;
}

export default function InactivePropertiesQuestion({
    notification,
    property,
    numNotifications,
    onContinue,
    onClose
}: Props) {
    const { leads } = useAuthenticatedState();

    const {
        mutateAsync: snoozeNotification,
        isLoading: isSnoozing
    } = useSnoozeNotification(notification.id);

    const propertyLeads = leads
        .filter((lead) => lead.property_id === property.id)
        .sort((a, b) => a.inserted_at.localeCompare(b.inserted_at));

    const firstLeadAt = propertyLeads[0]?.inserted_at;
    const lastLeadAt = propertyLeads.at(-1)?.inserted_at;
    let lastLeadAtDiffUnit: 'month' | 'day' | 'hour' = 'month';
    let lastLeadAtDiff = dayjs().diff(dayjs(lastLeadAt), lastLeadAtDiffUnit);
    if (lastLeadAtDiff < 1) {
        lastLeadAtDiffUnit = 'day';
        lastLeadAtDiff = dayjs().diff(dayjs(lastLeadAt), lastLeadAtDiffUnit);
    }
    if (lastLeadAtDiff < 1) {
        lastLeadAtDiffUnit = 'hour';
        lastLeadAtDiff = dayjs().diff(dayjs(lastLeadAt), lastLeadAtDiffUnit);
    }
    const numLeads = propertyLeads.length;

    return (
        <Dialog open>
            <DialogTitle>
                <Flex justifyContent="space-between" alignItems="center">
                    <Typography>
                        {numNotifications} {pluralize('property', numNotifications)} remaining
                    </Typography>

                    <IconButton onClick={onClose} edge="end">
                        <CloseRounded />
                    </IconButton>
                </Flex>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h3">
                    Is the vacancy status of <b>{property.name}</b> up to date?
                </Typography>

                <FlexColumn mt={4}>
                    <Flex alignItems="center">
                        <Typography variant="body2">
                            Total leads ingested:
                        </Typography>
                        <Typography fontWeight={400}>
                            {numLeads}
                        </Typography>
                    </Flex>

                    {numLeads > 0 && (
                        <>
                            <Flex alignItems="center">
                                <Typography variant="body2">
                                    First lead:
                                </Typography>
                                <Typography fontWeight={400}>
                                    {dayjs(firstLeadAt).format('MMM D, YYYY')}
                                </Typography>
                            </Flex>

                            <Flex alignItems="center">
                                <Typography variant="body2">
                                    Last lead:
                                </Typography>
                                <Typography fontWeight={400}>
                                    {dayjs(lastLeadAt).format('MMM D, YYYY')},
                                </Typography>
                                <Typography fontWeight={400}>
                                    {lastLeadAtDiff} {pluralize(lastLeadAtDiffUnit, lastLeadAtDiff)} ago
                                </Typography>
                            </Flex>
                        </>
                    )}

                    <Typography variant="caption">
                        (Includes only non-archived leads from the past 28 days)
                    </Typography>
                </FlexColumn>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="outlined"
                    onClick={() => {
                        snoozeNotification({ snooze_until: dayjs().add(14, 'days').toISOString().replace('Z', '') });
                    }}
                    loading={isSnoozing}
                >
                    It&apos;s still vacant / I&apos;m not sure
                </LoadingButton>
                <Button
                    variant="contained"
                    onClick={onContinue}
                    disabled={isSnoozing}
                >
                    It&apos;s no longer vacant
                </Button>
            </DialogActions>
        </Dialog>
    );
}