import React from 'react';

import LoadingBar from 'src/components/page/loading/LoadingBar';

interface Props {
    isLoading?: boolean,
    children: React.ReactNode
}

/**
 * Component to wrap the children of the Page component.
 * It will show a loading bar if isLoading is true.
 * Otherwise, it will show the children.
 *
 * @see src/components/page/index.tsx
 *
 */
const PageLoadingWrapper: React.FC<Props> = ({ isLoading, children }) => {
    return (
        <>
            {isLoading && <LoadingBar />}
            {!isLoading && children}
        </>
    );
};

export default PageLoadingWrapper;
