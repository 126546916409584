import React from 'react';
import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton';

interface Props extends LoadingButtonProps {
    className?: string;
}

const ReffieLoadingButton: React.FC<Props> = ({ children, ...props }) => {
    return (
        <LoadingButton
            {...props}
            variant="contained"
            type="button"
            loadingPosition={props.startIcon ? 'start' : undefined}
        >
            {children}
        </LoadingButton>
    );
};

export default ReffieLoadingButton;