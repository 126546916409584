import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs, { type Dayjs } from 'dayjs';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import MuiToggleButton, { type ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import { type IUpdatePropertyOccupanciesRequest, useUpdatePropertyOccupancies } from 'src/services/api';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import { Flex, FlexColumn } from 'src/components/flex';
import SelectLead from 'src/components/input/select-lead/SelectLead';

type IFormValues = {
    allProperties: Dayjs | null;
    individualProperties: Record<number, {
        date: Dayjs | null;
        leadUuid: string;
    }>;
};

export default function MarkPropertiesAsOccupiedModal() {

    const {
        mutateAsync: updatePropertyOccupancies,
        isLoading: isUpdatingPropertyOccupancy,
    } = useUpdatePropertyOccupancies();

    const {
        selectedProperties,
        setSelectedProperties,
        setMarkingPropertiesAsOccupied,
    } = usePropertiesPageContext();

    const [type, setType] = React.useState<'individual' | 'bulk'>(
        selectedProperties.length > 1 ? 'bulk' : 'individual'
    );

    const today = dayjs();

    const form = useForm<IFormValues>({
        defaultValues: {
            allProperties: today,
            individualProperties: selectedProperties.reduce((acc, property) => {
                acc[property.id] = {
                    date: today,
                    leadUuid: '',
                };
                return acc;
            }, {} as Record<number, { date: Dayjs | null; leadUuid: string }>),
        }
    });

    const handleClose = () => {
        setSelectedProperties([]);
        setMarkingPropertiesAsOccupied(false);
    };

    const submit = async (values: IFormValues) => {
        const payload: IUpdatePropertyOccupanciesRequest = [];

        selectedProperties.forEach((property) => {
            const propertyUpdate = values.individualProperties[property.id];
            if (!propertyUpdate) { return; }
            payload.push({
                id: property.id,
                is_vacant: false,
                tennant_lead_uuid: propertyUpdate.leadUuid,
                occupancy_start_date: propertyUpdate.date?.format('YYYY-MM-DD'),
            });
        });

        await updatePropertyOccupancies(payload);

        handleClose();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                component="form"
                open
                onClose={handleClose}
                onSubmit={form.handleSubmit(submit)}
            >
                <DialogTitle>
                    Set Date of Occupancy
                </DialogTitle>

                <DialogContent sx={{ minHeight: '50vh' }}>
                    {selectedProperties.length > 1 && (
                        <Flex mb={4} justifyContent="center">
                            <Box border="2px solid var(--color-primary)" borderRadius={2} p={0.5}>
                                <ToggleButtonGroup
                                    value={type}
                                    exclusive
                                    onChange={(_e, value) => setType(value)}
                                    sx={{ columnGap: 1 }}
                                >
                                    <ToggleButton value="individual" selected={type === 'individual'}>
                                        Individual
                                    </ToggleButton>
                                    <ToggleButton value="bulk" selected={type === 'bulk'}>
                                        Bulk
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Flex>
                    )}

                    {type === 'bulk' && (
                        <FlexColumn>
                            <Typography mb={2} variant="body2">
                                Set the same date of occupancy for all properties.
                            </Typography>

                            <Controller
                                name="allProperties"
                                control={form.control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="Date of Occupancy"
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange(value);
                                            selectedProperties.forEach((property) => {
                                                // @ts-expect-error dynamic key
                                                form.setValue(`individualProperties.${property.id}.date`, value);
                                            });
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                )}
                            />
                        </FlexColumn>
                    )}

                    {type === 'individual' && (
                        <FlexColumn>
                            <Typography mb={2} variant="body2">
                                Set the date of occupancy and tenant for each property.
                            </Typography>

                            <FlexColumn rowGap={2}>
                                {selectedProperties.map((property) => (
                                    <FlexColumn key={property.id}>
                                        <Typography variant="body2">
                                            {property.name}
                                        </Typography>

                                        <Flex mt={2} mobileFlexDirection="column" rowGap={2}>
                                            <Controller
                                                // @ts-expect-error dynamic key
                                                name={`individualProperties.${property.id}.date`}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        label="Date"
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        sx={{ width: '100%' }}
                                                        slotProps={{ textField: { size: 'small' } }}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                // @ts-expect-error dynamic key
                                                name={`individualProperties.${property.id}.leadUuid`}
                                                control={form.control}
                                                render={({ field }) => (
                                                    <SelectLead
                                                        label="Tenant"
                                                        // @ts-expect-error dynamic key
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        placeholder="Select tenant"
                                                        sx={{ width: '100%' }}
                                                        size="small"
                                                        isClearable
                                                    />
                                                )}
                                            />
                                        </Flex>
                                    </FlexColumn>
                                ))}
                            </FlexColumn>
                        </FlexColumn>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isUpdatingPropertyOccupancy}
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </LocalizationProvider >
    );
}

function ToggleButton(
    {
        selected,
        ...props
    }: ToggleButtonProps & { selected: boolean }
) {
    return (
        <MuiToggleButton
            size="small"
            {...props}
            sx={{
                color: selected ? 'white !important' : 'var(--color-primary) !important',
                border: 'none !important',
                borderRadius: '6px !important',
                bgcolor: selected ? 'var(--color-primary) !important' : 'white !important',
                textTransform: 'none !important',
                fontWeight: 'bold !important',
                p: '6px 12px !important',
            }}
        />
    );
}
