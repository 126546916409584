import React, { useMemo, useState } from 'react';
import Chip from 'src/components/chip/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete, { type AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete';
import CheckIcon from '@mui/icons-material/CheckRounded';

import type { ILead } from 'src/services/api';
import { type ILeadLabel, useLeadLabels } from 'src/services/api';
import { Flex } from 'src/components/flex';

import styles from 'src/components/input/select-lead-labels/SelectLeadLabels.module.css';

const filterOptions = createFilterOptions<ILeadLabel>({
    stringify: (option) => option.name,
});

type BaseProps = AutocompleteProps<ILeadLabel, true, false, false>;

type Props = Omit<BaseProps, 'renderInput' | 'options' | 'value' | 'onChange'> & {
    label?: string,
    lead?: ILead,
    onChange: (value: ILeadLabel[]) => void,
}

export default function SelectLeadLabels({
    label,
    lead,
    onChange,
    ...props
}: Props) {
    const { data: leadLabels } = useLeadLabels();

    const [value, setValue] = useState(lead?.labels || []);

    const handleChange = (newValue: ILeadLabel[]) => {
        setValue(newValue);
        onChange(newValue);
    };

    const handleDelete = (option: ILeadLabel) => {
        const newLabels = value.filter((o) => o.name !== option.name);
        handleChange(newLabels);
    };

    const options = useMemo(() => {
        return leadLabels || [];
    }, [leadLabels]);

    return (
        <Autocomplete
            {...props}
            classes={{ root: styles.root, endAdornment: styles.endAdornment }}
            options={options}
            value={value}
            onChange={(_, newValue) => handleChange(newValue)}
            multiple
            size="small"
            disableCloseOnSelect
            forcePopupIcon={false}

            filterOptions={filterOptions}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder="Search labels"
                    variant="standard"
                    autoFocus
                />
            )}

            isOptionEqualToValue={(option, _value) => {
                return option.name === _value.name;
            }}

            getOptionLabel={(option) => option.name || ''}

            renderOption={({ key, ..._props }, option, { selected }) => (
                <div key={key} className={styles.listItem}>
                    <li {..._props}>
                        <Flex width="100%" justifyContent="space-between">
                            <Chip
                                label={option.name}
                                color={option.color}
                                size="small"
                            />
                            {selected && <CheckIcon fontSize="small" />}
                        </Flex>

                    </li>
                </div>
            )}

            renderTags={(tags, getTagProps) => tags.map((option, index) => (
                <Chip
                    {...getTagProps({ index })}
                    key={option.name}
                    label={option.name}
                    color={option.color}
                    size="small"
                    onDelete={() => handleDelete(option)}
                />
            ))}
            data-testid="select-lead-labels"
        />
    );
}
