import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Flex } from 'src/components/flex';
import Page from 'src/components/page/PageV2';
import { ImportLeads } from 'src/components/bulk-import';
import EmptyPage from 'src/components/empty-page/EmptyPage';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal/CreateOrUpdateLeadModal';

export default function EmptyLeadsPage() {
    const [isAddingALead, setAddingALead] = useState(false);
    const [isImportingLeads, setImportingLeads] = useState(false);

    return (
        <Page title="Reffie | Leads">
            <EmptyPage sx={{ mt: '5%' }}>
                <Typography variant="h2" align="center">
                    You have no leads at the moment.
                </Typography>

                <Flex>
                    <Button
                        variant="contained"
                        onClick={() => setAddingALead(true)}
                        color="primary"
                        size="large"
                    >
                        Create Your First Lead
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => setImportingLeads(true)}
                        color="primary"
                        size="large"
                    >
                        Import Leads
                    </Button>
                </Flex>
            </EmptyPage>

            {isAddingALead && (
                <CreateOrUpdateLeadModal
                    onClose={() => setAddingALead(false)}
                    onSave={() => setAddingALead(false)}
                />
            )}

            {isImportingLeads && (
                <ImportLeads
                    isOpened={isImportingLeads}
                    onClose={() => setImportingLeads(false)}
                />
            )}
        </Page>
    );
}
