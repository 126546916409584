import React from 'react';

import Select from 'src/components/input/select/Select';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import { type ILeadFilters, leadsDateFilterOptions } from 'src/pages/landlord/leads/provider';

const SelectDateRange: React.FC = () => {
    const { filters, setFilters } = useLeadsPageContext();

    const handleChange = (dateRange: ILeadFilters['dateRange']) => {
        setFilters({ ...filters, dateRange });
    };

    return (
        <Select
            label="Filter by"
            emptyText="All time"
            options={leadsDateFilterOptions}
            value={filters.dateRange as string}
            onChange={(value) => handleChange(value as ILeadFilters['dateRange'])}
            isClearable
            clearableText="All time"
            size="small"
            fullWidth
            data-test-id="filter-by-date-range-select"
        />
    );
};

export default SelectDateRange;