import React from 'react';
import { Controller, type FieldError, type UseFormReturn } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import type { Address } from 'src/services/location/types';
import { Flex, FlexColumn } from 'src/components/flex';
import SelectStateUS from 'src/components/input/address/SelectStateUS';
import SelectProvinceCA from 'src/components/input/address/SelectProvinceCA';
import { EPropertyFormField, type PropertyForm } from 'src/components/create-or-update-property-modal/types';

interface Props {
    country: string;
    form: UseFormReturn<PropertyForm>;
    value: Address;
    errors?: {
        streetAddress?: FieldError;
        unit?: FieldError;
        city?: FieldError;
        state?: FieldError;
        zipcode?: FieldError;
    };
}

export default function AddressFields({
    country,
    form,
    value,
    errors,
}: Props) {
    return (
        <FlexColumn width="100%" rowGap={2}>
            <Flex width="100%" mobileFlexDirection="column">
                <TextField
                    {...form.register('address.streetAddress', { required: 'Street address is required' })}
                    label="Street address"
                    placeholder="Main St"
                    error={!!errors?.streetAddress}
                    helperText={errors?.streetAddress?.message}
                    fullWidth
                    size="small"
                    required
                    data-testid="street-address-field"
                />

                <TextField
                    {...form.register('address.unit')}
                    label="Unit (optional)"
                    placeholder="Apt 4B"
                    size="small"
                    data-testid="unit-field"
                />
            </Flex>

            <Flex width="100%" mobileFlexDirection="column">
                <TextField
                    {...form.register('address.city', { required: 'City is required' })}
                    label="City"
                    placeholder={country === 'Canada' ? 'Toronto' : 'San Francisco'}
                    fullWidth
                    size="small"
                    required
                    error={!!errors?.city}
                    helperText={errors?.city?.message}
                    data-testid="city-field"
                />

                <TextField
                    {...form.register('address.zipcode')}
                    label={country === 'Canada' ? 'Postal code' : 'Zip code'}
                    placeholder={country === 'Canada' ? 'M5H 3G8' : '94103'}
                    inputMode="numeric"
                    // inputProps={{ pattern: '[0-9]{5}' }}
                    value={value.zipcode || ''}
                    fullWidth
                    required
                    size="small"
                    error={!!errors?.zipcode}
                    helperText={errors?.zipcode?.message}
                    data-testid="zipcode-field"
                />
            </Flex>


            {country === 'United States' && (
                <Controller
                    name="address.state"
                    control={form.control}
                    rules={{ required: 'State is required' }}
                    render={({ field }) => (
                        <SelectStateUS
                            size="small"
                            label="State"
                            placeholder="California"
                            value={field.value}
                            onChange={(state) => field.onChange(state)}
                            onBlur={() => form.trigger('address.state')}
                            fullWidth
                            required
                            error={!!errors?.state}
                            helperText={errors?.state?.message}
                        />
                    )}
                />
            )}

            {country === 'Canada' && (
                <Controller
                    name="address.state"
                    control={form.control}
                    rules={{ required: 'Province is required' }}
                    render={({ field }) => (
                        <SelectProvinceCA
                            size="small"
                            label="Province"
                            placeholder="Alberta"
                            value={field.value}
                            onChange={(province) => field.onChange(province)}
                            fullWidth
                            required
                            error={!!errors?.state}
                            helperText={errors?.state?.message}
                        />
                    )}
                />
            )}

            <Flex justifyContent="flex-end">
                <Button
                    color="error"
                    size="small"
                    onClick={() => {
                        if (country === 'United States') {
                            form.setValue(EPropertyFormField.Address, null);
                        } else if (country === 'Canada') {
                            // @ts-expect-error ts expects an enum
                            form.setValue('address.streetAddress', '');
                            // @ts-expect-error ts expects an enum
                            form.setValue('address.unit', '');
                            // @ts-expect-error ts expects an enum
                            form.setValue('address.city', '');
                            // @ts-expect-error ts expects an enum
                            form.setValue('address.state', '');
                            // @ts-expect-error ts expects an enum
                            form.setValue('address.zipcode', '');
                        }
                    }}
                >
                    Clear address
                </Button>
            </Flex>
        </FlexColumn>
    );
}
