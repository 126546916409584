
import React from 'react';

import {
    AnalyticsIcon,
    ConversationIcon,
    LeadsIcon,
    PlaybookIcon,
    PropertiesIcon,
    SettingsIcon,
    ShowingIcon,
    TeamIcon,
} from 'src/icons';

export const links = [
    { text: 'Leads', icon: <LeadsIcon />, link: '/landlord/inner/leads/all'},
    { text: 'Playbooks', icon: <PlaybookIcon />, link: '/landlord/inner/playbooks' },
    { text: 'Conversations', icon: <ConversationIcon />, link: '/landlord/inner/messages' },
    { text: 'Properties', icon: <PropertiesIcon />, link: '/landlord/inner/properties' },
    { text: 'Tours', icon: <ShowingIcon />, link: '/landlord/inner/tours' },
    { text: 'Analytics', icon: <AnalyticsIcon />, link: '/landlord/inner/analytics' },
    { text: 'Team', icon: <TeamIcon />, link: '/landlord/inner/team' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/landlord/inner/settings' },
];