import * as React from 'react';
import Chip from '@mui/material/Chip';
import styles from 'src/components/ReffieGreenChip.module.css';

type Props = {
    label: string,
    className?: string,
    onDelete?: () => void,
    onClick?: () => void,
    size?: 'small' | 'medium';
}

export default function ReffieGreenChip({
    label,
    className,
    onDelete,
    onClick,
    size,
} : Props) {
    return (
        <Chip label={label}
            color="success"
            className={className}
            classes={{root: styles.chips}}
            onDelete={onDelete}
            onClick={onClick}
            size={size}
        />
    );
}