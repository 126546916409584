import React from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useDeleteProperties, useUpdateProperties } from 'src/services/api';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import Responsive from 'src/components/responsive/Responsive';
import DesktopPropertiesListItem from 'src/pages/landlord/properties/list/DesktopPropertiesListItem';
import MobilePropertiesListItem from 'src/pages/landlord/properties/list/MobilePropertiesListItem';
import NoSearchResults from 'src/pages/landlord/properties/list/PropertiesNoSearchResults';
import EditApplicationLinkModal from 'src/pages/landlord/properties/components/toolbar/EditApplicationLinkModal';
import EditTourLinkModal from 'src/pages/landlord/properties/components/toolbar/EditTourLinkModal';
import EditPropertyLabelsModal from 'src/pages/landlord/properties/components/toolbar/EditPropertyLabelsModal';

export default function PropertiesList() {
    const {
        filteredProperties,
        visibleProperties,
        isSearchOrFilterActive,
        mobileAchorEl,
        setMobileAnchorEl,
        selectedProperties,
        setSelectedProperties,
        setEditingProperty,
        isEditingTourLink,
        setEditingTourLink,
        isEditingApplicationLink,
        setEditingApplicationLink,
        isEditingPropertyLabels,
        setEditingPropertyLabels,
        setMarkingPropertiesAsOccupied,
    } = usePropertiesPageContext();

    const { mutateAsync: updateProperties } = useUpdateProperties();
    const {
        mutateAsync: deleteProperties,
        isLoading: isDeletingProperties,
    } = useDeleteProperties(selectedProperties.map(({ id }) => id));

    const handleChangeVacancy = async (isVacant: boolean) => {
        const today = new Date().toISOString().split('T')[0];
        await updateProperties(selectedProperties.map((property) => ({
            ...property,
            is_vacant: isVacant,
            vacant_since: isVacant ? today : null,
        })));
    };

    const isSearchingWithoutResults = isSearchOrFilterActive && filteredProperties.length === 0;
    const selectedProperty = selectedProperties[0];

    return (
        <div>
            <Responsive desktop>
                <TableContainer component={Paper} elevation={0} square>
                    <Table
                        size="small"
                        sx={{
                            tableLayout: 'fixed',
                            '& td': { verticalAlign: 'top' }
                        }}
                    >
                        <TableBody>
                            {visibleProperties.map((property) => (
                                <DesktopPropertiesListItem
                                    key={property.id}
                                    property={property}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Responsive>

            <Responsive mobile>
                <TableContainer component={Paper} elevation={0} square>
                    <Table size="small" sx={{ tableLayout: 'fixed' }}>
                        <TableBody>
                            {visibleProperties.map((property) => (
                                <MobilePropertiesListItem
                                    key={property.id}
                                    property={property}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Responsive>

            <Responsive mobile>
                <Menu
                    anchorEl={mobileAchorEl}
                    open={!!mobileAchorEl}
                    onClose={() => {
                        setMobileAnchorEl(undefined);
                        setSelectedProperties([]);
                    }}
                    onClick={() => setMobileAnchorEl(undefined)}
                >
                    <MenuItem onClick={() => setEditingProperty(selectedProperties[0])}>
                        <ListItemText>
                            Edit Property
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => setEditingPropertyLabels(true)}>
                        <ListItemText>
                            Edit Property Labels
                        </ListItemText>
                    </MenuItem>

                    <Divider />

                    <MenuItem
                        onClick={() => {
                            if (selectedProperty?.is_vacant) {
                                setMarkingPropertiesAsOccupied(true);
                            } else {
                                handleChangeVacancy(true);
                            }
                        }}
                    >
                        <ListItemText>
                            {selectedProperty?.is_vacant ? 'Set as Occupied' : 'Set as Vacant'}
                        </ListItemText>
                    </MenuItem>

                    <Divider />

                    <MenuItem onClick={() => setEditingTourLink(true)}>
                        <ListItemText>
                            Edit Tour Link
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => setEditingApplicationLink(true)}>
                        <ListItemText>
                            Edit Application Link
                        </ListItemText>
                    </MenuItem>

                    <Divider />

                    <MenuItem
                        onClick={async () => {
                            await deleteProperties();
                            setSelectedProperties([]);
                        }}
                        disabled={isDeletingProperties}
                    >
                        <ListItemText>
                            <Typography color="error">
                                {isDeletingProperties ? 'Deleting...' : 'Delete'}
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Responsive>

            <Responsive mobile>
                <div>
                    {isEditingPropertyLabels && (
                        <EditPropertyLabelsModal />
                    )}

                    {isEditingTourLink && (
                        <EditTourLinkModal />
                    )}

                    {isEditingApplicationLink && (
                        <EditApplicationLinkModal />
                    )}
                </div>
            </Responsive>

            {isSearchingWithoutResults && (
                <NoSearchResults />
            )}
        </div>
    );
}
