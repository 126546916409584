import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch, { type SwitchProps } from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

type Props = Omit<SwitchProps, 'onChange'> & {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    helperText?: string;
    size?: 'small' | 'medium';
}

export default function Switch({
    label,
    value: propsValue,
    onChange,
    helperText,
    size,
    ...props
}: Props) {
    const handleChange: SwitchProps['onChange'] = (_e, checked) => {
        onChange(checked);
    };

    const value = propsValue || false;

    return (
        <FormGroup>
            <FormControlLabel
                label={label}
                control={<MuiSwitch checked={value} onChange={handleChange} size={size} {...props} />}
            />
            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormGroup>
    );
}