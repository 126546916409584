import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Calendar() {
    return (
        <SvgIcon>
            <svg width="18" height="21" viewBox="-1 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7.5H17M1 7.5V16.3002C1 17.4203 1 17.9801 1.21799 18.4079C1.40973 18.7842 1.71547 19.0905 2.0918 19.2822C2.5192 19.5 3.07899 19.5 4.19691 19.5H13.8031C14.921 19.5 15.48 19.5 15.9074 19.2822C16.2837 19.0905 16.5905 18.7842 16.7822 18.4079C17 17.9805 17 17.4215 17 16.3036V7.5M1 7.5V6.7002C1 5.58009 1 5.01962 1.21799 4.5918C1.40973 4.21547 1.71547 3.90973 2.0918 3.71799C2.51962 3.5 3.08009 3.5 4.2002 3.5H5M17 7.5V6.69691C17 5.57899 17 5.0192 16.7822 4.5918C16.5905 4.21547 16.2837 3.90973 15.9074 3.71799C15.4796 3.5 14.9203 3.5 13.8002 3.5H13M13 1.5V3.5M13 3.5H5M5 1.5V3.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}