import type { IConversation } from 'src/api/landlord-messages-api';
import { isNotificationApiSupported } from 'src/utils/browser-api';

export const sendNewMessageNotification = (
    conversation: IConversation,
    leadName: string,
) => {
    if (isNotificationApiSupported()) {
        if (window.Notification && Notification.permission === 'granted') {
            // tag is needed to not receive duplicate notifications
            const tag = conversation.lead_uuid + conversation.latest_message_contents;

            if (leadName) {
                new Notification(`New messages from ${leadName}`, {
                    tag,
                    body: conversation.latest_message_contents
                });
            } else {
                new Notification('New messages', {
                    tag,
                    body: conversation.latest_message_contents
                });
            }
        }
    }
};
