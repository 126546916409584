import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import type { IConversation } from 'src/api/landlord-messages-api';
import type { ILead, Notification, TeamSettings } from 'src/services/api';
import type { IUser } from 'src/api/users-api';
import {
    Auth,
    useConversations,
    useLeads,
    useLoggedInUser,
    useNotifications,
    useProperties,
    useTeamSettings
} from 'src/services/api';
import { normalizeSource } from 'src/pages/landlord/analytics/utils';
import LandlordLayout from 'src/components/layout/landlord/LandlordLayout';

type IAuthenticatedState = {
    /**
     * If true, archived leads and conversations will be fetched.
     * Warning: The response size can get very large.
     */
    showArchived: boolean;
    setShowArchived: Action<boolean>;

    loggedInUser: IUser;
    teamSettings: TeamSettings;

    leads: ILead[];
    leadsUpdatedAt: number;
    leadsByUuid: Map<string, ILead>;
    leadIngestionSources: string[];

    conversations: IConversation[];
    conversationsUpdatedAt: number;
    conversationsByLeadUuid: Map<string, IConversation>;

    notifications: Notification[];
};

// @ts-expect-error lazy init
export const AuthenticatedStateContext = createContext<IAuthenticatedState>();

export const useAuthenticatedState = () => {
    const context = useContext(AuthenticatedStateContext);

    if (!context) {
        throw new Error('useAuthenticatedState must be used within an AuthenticatedStateProvider');
    }
    return context;
};

type Props = {
    children: React.ReactNode;
};

export default function AuthenticatedStateProvider({ children }: Props) {
    const isLoggedIn = !!Auth.accessToken;
    const init = useRef(false);

    const navigate = useNavigate();

    const [showArchived, setShowArchived] = useState(false);

    const { data: loggedInUser } = useLoggedInUser();
    const { data: teamSettings } = useTeamSettings();

    const {
        data: leads,
        dataUpdatedAt: leadsUpdatedAt,
        refetch: refetchLeads,
    } = useLeads({
        disabled: !loggedInUser?.is_onboarding_complete,
        isArchived: showArchived ? true : false,
        refetchInterval: 20000,
    });
    const leadIngestionSources = useMemo(() => {
        if (!leads) { return []; }

        const sources = new Set<string>();
        leads.forEach((lead) => {
            sources.add(normalizeSource(lead.ingestion_source));
        });

        return Array.from(sources).sort();
    }, [leads]);

    const leadsByUuid = useMemo(() => {
        if (!leads) { return new Map(); }

        return leads.reduce((acc, lead) => {
            acc.set(lead.uuid, lead);
            return acc;
        }, new Map<string, ILead>());
    }, [leads]);

    const {
        data: conversations,
        dataUpdatedAt: conversationsUpdatedAt,
        refetch: refetchConversations,
    } = useConversations({
        disabled: !loggedInUser?.is_onboarding_complete,
        isArchived: showArchived ? undefined : false,
    });

    const conversationsByLeadUuid = useMemo(() => {
        if (!conversations) { return new Map(); }

        return conversations.reduce((acc, conversation) => {
            acc.set(conversation.lead_uuid, conversation);
            return acc;
        }, new Map<string, IConversation>());
    }, [conversations]);

    // prefetch properties
    useProperties();

    // Refetch leads and conversations when showArchived changes
    useEffect(() => {
        if (!Auth.accessToken) { return; }

        if (!loggedInUser?.is_onboarding_complete) { return; }

        if (!init.current) {
            init.current = true;
            return;
        }

        refetchLeads();
        refetchConversations();
    }, [loggedInUser, showArchived]);

    const { data: notifications } = useNotifications(!isLoggedIn);

    if (!Auth.accessToken) {
        navigate('/landlord/login');
        return null;
    }

    if (!loggedInUser || !teamSettings) {
        return (
            <LandlordLayout isLoading />
        );
    }

    if (!loggedInUser?.is_onboarding_complete) {
        return <Navigate to="/landlord/onboard" replace />;
    }

    const isLoading = isLoggedIn && (!loggedInUser || !teamSettings || !notifications || !leads || !conversations);

    return (
        <AuthenticatedStateContext.Provider
            value={{
                showArchived,
                setShowArchived,

                loggedInUser,
                teamSettings,

                leads: leads || [],
                leadsUpdatedAt,
                leadsByUuid,
                leadIngestionSources,

                conversations: conversations || [],
                conversationsUpdatedAt,
                conversationsByLeadUuid,

                notifications: notifications || [],
            }}
        >
            {(isLoading || !loggedInUser) ? (
                <LandlordLayout isLoading />
            ) : (
                children
            )}
        </AuthenticatedStateContext.Provider>
    );
}

type Action<T> = React.Dispatch<React.SetStateAction<T>>;
