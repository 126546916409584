import { useQuery } from 'react-query';

import { type OSMSearchResult } from 'src/services/location/types';
import { client } from 'src/services/location/client';
import { transformOSMAddress } from 'src/services/location/utils/transform-osm-address';

const QUERY_KEY = 'location';

export const useSearchAddress = (searchQuery?: string) => {
    return useQuery(
        QUERY_KEY,
        async () => {
            if (!searchQuery) {
                return [];
            }
            const res = await client.search({ query: searchQuery }) as unknown as OSMSearchResult[];
            return res.map(transformOSMAddress);
        },
        {
            enabled: !!searchQuery,
            cacheTime: 0,
        }
    );
};