import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BedIcon from '@mui/icons-material/BedOutlined';
import ShowerIcon from '@mui/icons-material/ShowerOutlined';

import type { IProperty } from 'src/services/api';

import { Flex } from 'src/components/flex';
import { PropertiesIcon } from 'src/icons';

interface Props {
    property?: Pick<IProperty, 'id' | 'name'> & Partial<Pick<IProperty, 'num_bathrooms' | 'num_bedrooms'>>;
}

export default function LeadSidebarPropertyDetails({ property }: Props) {
    return (
        <Box mb={1}>
            <Flex alignItems="center">
                <PropertiesIcon />
                <Typography variant="body2" noWrap>
                    {property ? property.name : 'Not associated with any property'}
                </Typography>
            </Flex>

            {property?.num_bedrooms != null && property?.num_bathrooms != null && (
                <Flex alignItems="center">
                    <Flex alignItems="center">
                        <BedIcon />
                        <Typography variant="body2">{property.num_bedrooms} bed</Typography>
                    </Flex>

                    <Flex alignItems="center" columnGap={0}>
                        <ShowerIcon />
                        <Typography variant="body2">{property.num_bathrooms} bath</Typography>
                    </Flex>
                </Flex>
            )}
        </Box>
    );
}
