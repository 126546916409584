import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import type { IProperty } from 'src/services/api';
import { usePropertyForm } from 'src/components/create-or-update-property-modal/use-property-form';
import PropertyForm from 'src/components/create-or-update-property-modal/PropertyForm';

type Props = {
    title?: string;
    property?: IProperty;
    defaultValues?: Partial<IProperty>;
    opened: boolean;
    onClose: () => void;
    onSave?: (property: IProperty) => Promise<void> | void;
};

export default function CreateOrUpdatePropertyModal({
    title: propsTitle,
    property,
    defaultValues,
    opened,
    onClose,
    onSave,
}: Props) {

    const onSuccess = (_property: IProperty) => {
        onClose();
        onSave?.(_property);
    };

    const { form, submit } = usePropertyForm({
        onSuccess,
        property,
        defaultValues,
    });
    const isEditing = !!property;
    const title = propsTitle || (isEditing ? 'Edit Property' : 'Create Property');

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                open={opened}
                onClose={onClose}
                PaperProps={{
                    sx: {
                        width: '100%',
                        maxWidth: 'md',
                        minHeight: '80%',
                    }
                }}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <PropertyForm form={form} submit={submit} />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        data-testid="cancel-property-button"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={form.formState.isSubmitting}
                        onClick={form.handleSubmit(submit)}
                        data-testid="save-property-button"
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
}
