import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';

// mirror the MUI tooltip placement (note that in the MUI code they're explicit strings not an enum)
type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left' | 'top-start' | 'top-end' | 'right-start' | 'right-end' | 'bottom-start' | 'bottom-end' | 'left-start' | 'left-end';

/**
 * A nice wrapper around the MUI tooltip with our theme attached
 * @param title The text to show in the tooltip
 */
export default function ReffieTextTooltip({ title, children, className, placement }: { title: string, children: React.ReactElement, className?: string, placement?: TooltipPlacement }) {
    /**
     * enterTouchDelay={0} is a hack to make tooltips work on mobile
     */
    return (
        <Tooltip title={<span className={className}>{title}</span>} enterTouchDelay={0} placement={placement}>
            { children }
        </Tooltip>
    );
}