import React, { useMemo } from 'react';

import { useLoggedInUser } from 'src/services/api';
import Autocomplete from 'src/components/input/autocomplete/Autocomplete';

type Props = Omit<React.ComponentProps<typeof Autocomplete>, 'options'>;

export default function SelectTeamMember({
    value,
    ...props
}: Props) {
    const { data: loggedInUser } = useLoggedInUser();

    const options = useMemo(() => {
        const teamMembers = loggedInUser?.leasing_team_members;
        if (!teamMembers) {
            return [];
        }

        return teamMembers
            .map((teamMember) => ({
                value: teamMember.uuid,
                label: teamMember.name,
            }));
    }, [loggedInUser]);

    return (
        <Autocomplete {...props} value={value} options={options} />
    );
}
