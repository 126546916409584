import { useEffect } from 'react';

import { isModalOpen } from 'src/utils/is-modal-open';
import { isUserTyping } from 'src/utils/is-user-typing';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

/**
 * Open or close the filter modal when the 'F' key is pressed.
 */
export const useToggleFilterModalWithFKey = () => {
    const { setFilterOpen } = useConversationsPageContext();
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey || event.altKey || event.metaKey) {
                return;
            }

            if (isUserTyping() || isModalOpen()) {
                return;
            }

            if (event.key === 'f' || event.key === 'F') {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Conversations',
                    key: 'F',
                });

                event.preventDefault();
                setFilterOpen(true);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);
};
