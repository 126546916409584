import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import DrawIcon from '@mui/icons-material/DrawRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import IconButton from '@mui/material/IconButton';

import { isEmailValid } from 'src/utils/is-email-valid';
import { isMobileUser } from 'src/common/utils';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { EMessageChannel, useUpdateLead } from 'src/services/api';
import ThemeProvider from 'src/theme/ThemeProvider';
import { Flex, FlexColumn } from 'src/components/flex';
import TextField from 'src/components/input/text-field/TextField';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';
import SelectChannelButton from 'src/pages/landlord/leads/messages/components/input/SelectChannelButton';
import SmartReplies from 'src/pages/landlord/leads/messages/components/input/SmartReplies';
import { isSupportedSignatureChannel } from 'src/services/api/users/settings/types';

/**
 * The pane used to reply to messages from a lead.
 * Includes surrounding UI elements like the channel selector, smart replies, and keyboard shortcuts sections.
 */
export default function LeadMessagesInput() {
    const {
        loggedInUser,
        userSettings,
        channel,
        lead,
        sendMessage,
    } = useLeadMessagesPageContext();

    const { mutate: updateLead, isLoading: isUpdatingLead } = useUpdateLead(lead.uuid);
    const assignLeadToSelf = async () => {
        updateLead({
            // uuid, name, email, and phone_number are required for the request, even though we are not changing them
            uuid: lead.uuid,
            name: lead.name,
            email: lead.email,
            phone_number: lead.phone_number,
            property_id: lead.property_id,
            owner: loggedInUser.id
        });
    };

    const inputRef = useRef<HTMLTextAreaElement>(null);

    const signature = isSupportedSignatureChannel(channel) ? userSettings.signatures[channel] : undefined;
    const isSignatureEnabled = signature?.is_enabled;
    const formattedSignature = isSignatureEnabled ? `\n---\n${signature.text}` : '';

    const [replyInput, setReplyInput] = useState('');

    const isOwnLead = loggedInUser.id === lead.owner;
    const isEmailChannelAndEmailIsInvalid = channel === EMessageChannel.EMAIL && !isEmailValid(lead.email);
    const isInputDisabled = isEmailChannelAndEmailIsInvalid || !isOwnLead;

    // empty means that the input is empty or contains only the signature
    const isEmptyInput = !replyInput.trim() || replyInput.trim() === formattedSignature.trim();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
            page: 'Lead messages',
            key: 'Enter',
        });

        const isEnterPressed = event.key === 'Enter';
        const isHoldingShift = event.shiftKey;
        const isEnterWithoutShiftPressed = isEnterPressed && !isHoldingShift;

        if (isEnterWithoutShiftPressed && isEmptyInput) {
            event.preventDefault();
            return;
        }

        if (isEnterWithoutShiftPressed && !isEmptyInput && !isMobileUser()) {
            handleSendReply();
            event.preventDefault();
        }
    };

    const handleSendReply = () => {
        sendMessage(replyInput);
        setReplyInput(formattedSignature);
        // set the cursor to the beginning of the input
        setTimeout(() => inputRef.current?.setSelectionRange(0, 0), 0);
    };

    const toggleSignature = () => {
        if (!formattedSignature) {
            return;
        }

        if (replyInput.includes(formattedSignature)) {
            setReplyInput(replyInput.replace(formattedSignature, ''));
        } else {
            setReplyInput(replyInput + formattedSignature);
        }
    };

    const insertSmartReply = (smartReply: string) => {
        setReplyInput(smartReply + '\n' + formattedSignature);
    };

    // set the reply input to the signature when the signature loads
    // or when the lead changes
    useEffect(() => {
        setReplyInput(formattedSignature);
    }, [signature, formattedSignature, lead.uuid]);

    if (!isOwnLead) {
        return (
            <Box p={2} borderTop="1px solid" borderColor="var(--border-color)">
                <Alert
                    severity="warning"
                    variant="outlined"
                    action={
                        <LoadingButton
                            variant="contained"
                            color="warning"
                            onClick={assignLeadToSelf}
                            size="small"
                            loading={isUpdatingLead}
                            sx={{ color: 'white' }}
                        >
                            Assign lead to yourself
                        </LoadingButton>
                    }
                >
                    This lead does not belong to you so you cannot send a reply.
                </Alert>
            </Box>
        );
    }

    return (
        <Box p={1} borderTop="1px solid" borderColor="var(--border-color)">
            <FlexColumn rowGap={1}>
                {/* Only show smart replies if the input is empty */}
                {isEmptyInput && (
                    <SmartReplies onClick={insertSmartReply} />
                )}

                <TextField
                    inputRef={inputRef}
                    autoComplete="off"
                    placeholder="Write your reply here"
                    multiline
                    minRows={4}
                    maxRows={12}
                    value={replyInput}
                    onChange={setReplyInput}
                    onKeyDown={handleKeyDown}
                    disabled={isInputDisabled}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <ThemeProvider>
                                <Flex
                                    position="absolute"
                                    bottom={0}
                                    left="8px"
                                    right="8px"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    columnGap={1}
                                >
                                    <SelectChannelButton />

                                    {/* signature button */}
                                    <Tooltip title={!isSignatureEnabled ? 'Signature is not enabled' : 'Toggle signature'}>
                                        <div>
                                            <IconButton
                                                onClick={toggleSignature}
                                                disabled={!isSignatureEnabled}
                                                sx={{ p: 0.5 }}
                                            >
                                                <DrawIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>

                                    {/* send button */}
                                    <IconButton
                                        color="primary"
                                        onClick={handleSendReply}
                                        disabled={isEmptyInput || isInputDisabled}
                                        sx={{ p: 0.5 }}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                </Flex>
                            </ThemeProvider>
                        )
                    }}
                />
            </FlexColumn>
        </Box>
    );
}
