import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { IConversation } from 'src/api/landlord-messages-api';
import type { EConversationCategory } from 'src/pages/landlord/conversations/context/types';
import { saveConversationsToLocalStorage } from 'src/pages/landlord/leads/messages/context/utils';

const getLink = (conversation: IConversation) => {
    const path = '/landlord/inner/messages/from-lead';
    const leadUuidSearchParam = `lead_uuid=${conversation.lead_uuid}`;
    const channelSearchParam = `channel=${conversation.latest_message_channel}`;
    const showNavigationSearchParam = 'show_navigation=true';

    return encodeURI(`${path}?${leadUuidSearchParam}&${channelSearchParam}&${showNavigationSearchParam}`);
};

export const useOpenConversation = (conversationsByCategory: Record<EConversationCategory, IConversation[]>) => {
    const navigate = useNavigate();

    return useCallback((conversation: IConversation) => {
        const currentCategory = Object.entries(conversationsByCategory).reduce((acc, curr) => {
            const _currentCategory = acc;
            const [category, conversationsFromCategory] = curr;
            const isCurrentCategory = conversationsFromCategory.some(o => o.lead_uuid === conversation.lead_uuid);

            if (isCurrentCategory) {
                return category as EConversationCategory;
            } else {
                return _currentCategory;
            }
        }, undefined as EConversationCategory | undefined);

        if (!currentCategory) {
            return;
        }


        saveConversationsToLocalStorage(conversationsByCategory[currentCategory]);
        navigate(getLink(conversation));
    }, [conversationsByCategory]);
};
