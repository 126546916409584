import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead, ICreateLeadRequest } from 'src/services/api/leads/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { LeadUtils } from 'src/utils';

export interface ICreateLeadResponse {
    lead: ILead;
}

export const useCreateLead = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        ILead,
        IStandardErrorResponse,
        ICreateLeadRequest
    >(
        async (payload) => {
            const res = await apiClient(GET_LEADS_PATH, {
                method: 'post',
                json: LeadUtils.normalizeLeadInput(payload),
            }).json<ICreateLeadResponse>();

            return res.lead;
        }, {
            onSuccess: (data) => {
                addNotification('Lead created', 'success');

                queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                    if (!oldData) {
                        return [data];
                    }

                    return [...oldData, data];
                });

                queryClient.setQueryData<ILead>([queryKeys.leads, data.uuid], data);
            }, onError: (error) => {
                const schemaValidationError = error?.messages?.map(message => message.msg).join(', ');
                console.error('Failed to create lead', error);
                const errorMessage = schemaValidationError || error.msg || 'Failed to create lead';
                addNotification(errorMessage, 'error');
            }
        });
};
