import React from 'react';
import Typography from '@mui/material/Typography';

import { Flex } from 'src/components/flex';
import ButtonLink from 'src/components/button-link';
import EmptyPage from 'src/components/empty-page/EmptyPage';

export default function EmptyConversationsList() {
    return (
        <EmptyPage sx={{ mt: '5%' }}>
            <Typography variant="h2" align="center">
                You have no conversations at the moment.
            </Typography>

            <Typography align="center" paragraph>
                You can send a message to a lead directly or create a playbook to automate your workflow.
            </Typography>

            <Flex>
                <ButtonLink
                    href="/landlord/inner/leads/all"
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Go to Leads
                </ButtonLink>

                <ButtonLink
                    href="/landlord/inner/playbooks"
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Go to Playbooks
                </ButtonLink>
            </Flex>
        </EmptyPage>
    );
}
