import React, { useEffect, useState } from 'react';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { useUpdatePropertyApplicationLinks } from 'src/services/api';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import { FlexColumn } from 'src/components/flex';

export default function EditApplicationLinkModal() {
    const {
        mutateAsync: updatePropertyApplicationLinks,
        isLoading: isUpdatingApplicationLinks,
    } = useUpdatePropertyApplicationLinks();

    const {
        selectedProperties,
        setEditingApplicationLink,
    } = usePropertiesPageContext();

    const initialValue = selectedProperties.length === 1 ? selectedProperties[0].application_link?.link || '' : '';
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        const newValue = selectedProperties.length === 1 ? selectedProperties[0].application_link?.link || '' : '';
        setValue(newValue);
    }, [selectedProperties]);

    const handleClose = () => {
        setValue('');
        setEditingApplicationLink(false);
    };

    const handleChange = async () => {
        await updatePropertyApplicationLinks(selectedProperties.map((property) => ({
            id: property.id,
            link: value,
        })));
        setValue('');
        setEditingApplicationLink(false);
    };

    return (
        <Dialog open onClose={handleClose}>
            <DialogTitle>
                Edit Application Link
            </DialogTitle>

            <DialogContent>
                {selectedProperties.length > 1 && (
                    <Accordion elevation={0} defaultExpanded>
                        <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} sx={{ px: 0 }}>
                            Current Application Links
                        </AccordionSummary>

                        <AccordionDetails>
                            {selectedProperties.map((property) => (
                                <FlexColumn key={property.id} rowGap={0}>
                                    <Typography>
                                        {property.name}
                                    </Typography>
                                    <Typography variant="body2">
                                        {property.application_link?.link || 'Not set'}
                                    </Typography>
                                </FlexColumn>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                )}

                <TextField
                    label="Application Link"
                    placeholder="https://calendly.com/name/application"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    fullWidth
                    sx={{ my: 2 }}
                />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    loading={isUpdatingApplicationLinks}
                    onClick={handleChange}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
