import { useQuery, } from 'react-query';

import type { ILeadLabel } from 'src/services/api/leads/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { GET_LEAD_LABELS_PATH } from 'src/services/api/leads/labels/constants';


export type IGetLeadLabelsResponse = {
    labels: ILeadLabel[];
};

export const useLeadLabels = () => {
    return useQuery(
        queryKeys.leadLabels,
        async () => {
            const res = await apiClient(GET_LEAD_LABELS_PATH)
                .json<IGetLeadLabelsResponse>();
            return res.labels;
        }
    );
};
