import React from 'react';
import clsx from 'clsx';

import type { ILead } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import type { IProperty, IMessage } from 'src/services/api';
import { ThemeProvider } from 'src/theme';
import Responsive from 'src/components/responsive/Responsive';
import { FlexColumn } from 'src/components/flex';
import Header from 'src/components/lead-sidebar/LeadSidebarHeader';
import PropertyDetails from 'src/components/lead-sidebar/LeadSidebarPropertyDetails';
import QuickActions from 'src/components/lead-sidebar/LeadSidebarQuickActions';
import UpcomingTour from 'src/components/lead-sidebar/LeadSidebarUpcomingTour';
import PastTours from 'src/components/lead-sidebar/LeadSidebarPastTours';
import Notes from 'src/components/lead-sidebar/LeadSidebarNotes';
import SmartFields from 'src/components/lead-sidebar/LeadSidebarSmartFields';
import ToursFromOtherLeads from 'src/components/lead-sidebar/LeadSidebarToursFromOtherLeads';
import LeadInfo from 'src/components/lead-sidebar/LeadInfo';

import styles from 'src/components/lead-sidebar/LeadSidebar.module.css';

type BaseProps = {
    lead: ILead;
    showings: IShowing[];
};

type LeadsPageProps = BaseProps & {
    property?: Pick<IProperty, 'id' | 'name'>;
    /**
     * Callback to close the sidebar.
     * If not provided, the close button will not be rendered
     */
    onClose: () => void;
};

type LeadMessagesPageProps = BaseProps & {
    property?: IProperty;
    messages: IMessage[];
    sendMessage: (message: string) => Promise<void>;
};

const isLeadMessagesPageProps = (props: Props): props is LeadMessagesPageProps => {
    return (props as LeadMessagesPageProps).messages !== undefined;
};

type Props = LeadsPageProps | LeadMessagesPageProps;

export default function LeadSidebar(props: Props) {
    const { lead, showings } = props;

    const validSmartFields = Object.entries(lead.smart_fields_data || {})
        .filter(([key, value]) => {
            if (typeof key !== 'string') {
                return false;
            } else if (lead.ingestion_source !== 'apartmentlist' && typeof value !== 'string') {
                // apartmentlist has a different format for smart fields
                return false;
            } else if (key.startsWith('_')) {
                return false;
            } else if (key === 'listing_name' && !!lead.property_id) {
                return false;
            }

            return true;
        });

    return (
        <ThemeProvider>
            <Responsive desktop>
                <div>
                    <FlexColumn className={clsx(styles.root, 'prettyScrollbar')}>
                        <Header lead={lead} onClose={(props as LeadsPageProps).onClose} />

                        <FlexColumn p={1} pt={0} pr={0}>
                            <PropertyDetails property={props.property} />

                            {isLeadMessagesPageProps(props) && !!props.property && (
                                <QuickActions
                                    lead={lead}
                                    property={props.property}
                                    messages={props.messages}
                                    sendMessage={props.sendMessage}
                                />
                            )}

                            <FlexColumn className={styles.sections}>
                                <UpcomingTour showings={showings} lead={lead} />
                                <PastTours showings={showings} lead={lead} />
                                <Notes key={lead.uuid} lead={lead} />
                                {!!validSmartFields.length &&
                                    <SmartFields lead={lead} validSmartFields={validSmartFields} />
                                }
                                <ToursFromOtherLeads showings={showings} lead={lead} />
                                <LeadInfo lead={lead} />
                            </FlexColumn>
                        </FlexColumn>
                    </FlexColumn>
                </div>
            </Responsive>
        </ThemeProvider>
    );
}
