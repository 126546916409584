import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReadStatus } from 'src/api/landlord-messages-api';
import {
    EMessageChannel,
    isMessageChannel,
    useLead,
    useLoggedInUser,
    useLeadMessages,
    useSendMessage,
    useShowings,
    useUpdateConversationsReadStatus,
    useFacebookIntegration,
    useProperty,
    useTeamSettings
} from 'src/services/api';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { LeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/context';
import {
    getConversationsFromLocalStorage,
    saveConversationsToLocalStorage
} from 'src/pages/landlord/leads/messages/context/utils';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { useUserSettings } from 'src/services/api/users';
import { LeadUtils } from 'src/utils/LeadUtils';

const basePath = '/landlord/inner/messages/from-lead';

const getConversationPath = (channel: EMessageChannel, leadUuid?: string) => {
    if (!leadUuid) {
        return;
    }

    return `${basePath}?lead_uuid=${leadUuid}&channel=${channel}&show_navigation=true`;
};

const REFETCH_INTERVAL = 3000;

type Props = {
    children: React.ReactNode;
};

export default function LeadMessagesPageProvider({ children }: Props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const leadUuid = searchParams.get('lead_uuid') as string;
    const channel = searchParams.get('channel') as EMessageChannel;
    const showNavigation = Boolean(searchParams.get('show_navigation'));

    const { data: facebookIntegration } = useFacebookIntegration();

    const setChannel = (newChannel: EMessageChannel) => {
        setSearchParams(prev => {
            const newSearchParams = new URLSearchParams(prev);
            newSearchParams.set('channel', newChannel);
            return newSearchParams.toString();
        });
    };

    if (!leadUuid) {
        console.error('Lead UUID must be set in URL params');

        return (
            <Alert severity="warning">
                Lead UUID must be set
            </Alert>
        );
    }

    if (channel && !isMessageChannel(channel)) {
        console.error(`Invalid send channel: ${channel}`);

        return (
            <Alert severity="warning">
                Invalid message channel: {channel || 'Channel not set'}.
                It must be either {EMessageChannel.SMS}, {EMessageChannel.EMAIL} or {EMessageChannel.FACEBOOK_MESSENGER}.
            </Alert>
        );
    }

    const { data: loggedInUser } = useLoggedInUser();
    const { data: userSettings } = useUserSettings();
    const { data: teamSettings } = useTeamSettings();
    const { data: showings } = useShowings({ whole_team: true });
    const { data: lead } = useLead(leadUuid);
    const { data: property } = useProperty(lead?.property_id);
    const { data: messages, refetch: refetchMessages } = useLeadMessages(leadUuid, { refetchInterval: REFETCH_INTERVAL });
    const { mutateAsync: updateReadStatus } = useUpdateConversationsReadStatus();

    const { mutateAsync: sendMessage } = useSendMessage();

    // Reset the conversations in local storage if the `show_navigation` param is not present
    if (!showNavigation) {
        saveConversationsToLocalStorage([]);
    }

    // Contains the list of conversations that is used when navigating back and forth from within the single conversation page
    const conversations = getConversationsFromLocalStorage();

    const currentConversationIndex = conversations.findIndex((o) => o.lead_uuid === leadUuid);
    const previousConversation = conversations[currentConversationIndex - 1];
    const nextConversation = conversations[currentConversationIndex + 1];
    const conversationAfterNextConversation = conversations[currentConversationIndex + 2];

    // Prefetch the previous and next lead's messages
    useLead(previousConversation?.lead_uuid);
    useLeadMessages(previousConversation?.lead_uuid);
    useProperty(previousConversation?.property?.id);
    useLead(nextConversation?.lead_uuid);
    useLeadMessages(nextConversation?.lead_uuid);
    useProperty(nextConversation?.property?.id);
    useLead(conversationAfterNextConversation?.lead_uuid);
    useLeadMessages(conversationAfterNextConversation?.lead_uuid);
    useProperty(conversationAfterNextConversation?.property?.id);

    const previousLead = useLead(previousConversation?.lead_uuid)?.data;
    const nextLead = useLead(nextConversation?.lead_uuid)?.data;

    const previousConversationPath = getConversationPath(channel, previousLead?.uuid);
    const nextConversationPath = getConversationPath(channel, nextLead?.uuid);

    const navigateToPreviousConversation = () => {
        if (previousConversationPath) {
            navigate(previousConversationPath);
        }
    };

    const navigateToNextConversation = () => {
        if (nextConversationPath) {
            navigate(nextConversationPath);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const isUserTyping = ['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName as string);
            if (isUserTyping) {
                return;
            }

            if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Lead messages',
                    key: event.key,
                });
            }

            switch (event.key) {
                case 'ArrowUp':
                    navigateToPreviousConversation();
                    break;
                case 'ArrowDown':
                    navigateToNextConversation();
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [navigateToPreviousConversation, navigateToNextConversation]);

    useEffect(() => {
        updateReadStatus({
            leadUuids: [leadUuid],
            status: ReadStatus.READ,
        });
    }, [leadUuid, messages]);

    const isLoading = !loggedInUser || !userSettings || !teamSettings || (lead?.property_id && !property) || !showings || !lead || !messages;
    if (isLoading) {
        return (
            <LinearProgress />
        );
    }

    if (!channel) {
        setChannel(LeadUtils.getDefaultMessageChannel(lead, messages));
    }

    const handleSendMessage = async (message: string) => {
        await sendMessage({
            leadUuid,
            channel,
            message,
        });
        await refetchMessages();
    };

    return (
        <LeadMessagesPageContext.Provider value={{
            loggedInUser,
            userSettings,
            teamSettings,
            showings,
            isFacebookConnected: !!facebookIntegration,

            lead,
            property,
            messages,

            channel,
            setChannel,
            showNavigation,

            conversations,
            previousLead,
            navigateToPreviousConversation,
            nextLead,
            navigateToNextConversation,

            sendMessage: handleSendMessage,
        }}
        >
            {children}
        </LeadMessagesPageContext.Provider>
    );
}