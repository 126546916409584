import { useEffect } from 'react';

import { isModalOpen } from 'src/utils/is-modal-open';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

/**
 * Navigate up and down the list of conversations with the arrow keys.
 */
export const useNavigateConversationsWithArrowKeys = () => {
    const {
        visibleConversations,
        focusedConversation,
        setFocusedConversation,
    } = useConversationsPageContext();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.key === 'ArrowDown' || event.key === 'ArrowUp') && !isModalOpen()) {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Conversations',
                    key: event.key,
                });

                event.preventDefault();

                const currentIndex = visibleConversations.findIndex(conversation => {
                    return conversation.lead_uuid === focusedConversation?.lead_uuid || '';
                });

                switch (event.key) {
                    case 'ArrowDown': {
                        const nextConversation = visibleConversations[currentIndex + 1];
                        const firstConversation = visibleConversations[0];
                        setFocusedConversation(nextConversation || firstConversation);
                        break;
                    }
                    case 'ArrowUp': {
                        const prevConversation = visibleConversations[currentIndex - 1];
                        const lastConversation = visibleConversations.at(-1);
                        setFocusedConversation(prevConversation || lastConversation);
                        break;
                    }
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [visibleConversations, focusedConversation, setFocusedConversation]);
};
