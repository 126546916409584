import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import NotificationIcon from '@mui/icons-material/Notifications';

import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { Flex } from 'src/components/flex';
import Responsive from 'src/components/responsive/Responsive';
import { isNotificationApiSupported } from 'src/utils/browser-api';

export default function NotificationAlert() {
    const initialPermission = isNotificationApiSupported() ? Notification.permission : undefined;
    const [permission, setPermission] = useState(initialPermission);
    const [isDismissed, setDismissed] = useState(getIsNotificationAlertDismissedFromLocalStorage);

    const dismissAlert = () => {
        setDismissed(true);
        analyticsClient.track(AnalyticsEvent.NOTIFICATION_ALERT_DISMISSED);
    };

    const requestPermission = () => {
        Notification.requestPermission().then((_permission) => {
            setPermission(_permission);

            if (_permission === 'granted') {
                analyticsClient.track(AnalyticsEvent.NOTIFICATION_REQUEST_GRANTED);
            } else if (_permission === 'denied') {
                analyticsClient.track(AnalyticsEvent.NOTIFICATION_REQUEST_DENIED);
            }
        });
    };

    useEffect(() => {
        setNotificationAlertDismissedInLocalStorage(isDismissed);
    }, [isDismissed]);

    if (!isNotificationApiSupported()) {
        return null;
    }

    if (isDismissed || permission !== 'default') {
        return null;
    }

    return (
        <Responsive desktop>
            <div>
                <Alert
                    severity="info"
                    action={(
                        <Flex alignItems="center" columnGap={2}>
                            <Button
                                variant="outlined"
                                color="dark"
                                onClick={dismissAlert}
                                size="small"
                            >
                                Dismiss
                            </Button>

                            <Button
                                variant="contained"
                                onClick={requestPermission}
                                size="small"
                                startIcon={<NotificationIcon />}
                            >
                                Enable
                            </Button>
                        </Flex>
                    )}
                >
                    You can opt in to receive desktop notifications for new messages.
                </Alert>
            </div>
        </Responsive>
    );
}

const getIsNotificationAlertDismissedFromLocalStorage = () => {
    return localStorage.getItem('isNotificationAlertDismissed') === 'true';
};

const setNotificationAlertDismissedInLocalStorage = (isDismissed: boolean) => {
    localStorage.setItem('isNotificationAlertDismissed', String(isDismissed));
};
