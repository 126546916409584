import React from 'react';
import Slide from '@mui/material/Slide';
import { type TransitionProps } from '@mui/material/transitions';

/**taken from https://frontendshape.com/post/react-mui-5-modal-dialog-example (6. react mui 5 full screen dialogs.)
 * - React forwardRef is a method that allows parent components to throw links to their child components.
 * - declaring the transmitted element parameters in Transition function
 * - passing parameters in Slide, with direction indication and return Slide element
*/
export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
){
    return <Slide direction="up" ref={ref} {...props} />;
});