import type { IStandardErrorResponse } from 'src/api/common';

/**
 * Extracts the error message from an API error.
 */
export const getApiErrorMessage = (
    arg: IStandardErrorResponse | IStandardErrorResponse[],
) => {
    if (!Array.isArray(arg)) {
        const error = arg;
        const schemaValidationErrorMessages = error.messages
            ?.map(o => o.msg)
            .join(', ');
        const generalErrorMessage = error.msg;

        return schemaValidationErrorMessages || generalErrorMessage;
    }

    const errors = arg;
    const schemaValidationErrors = Array.from(
        new Set(errors
            .map(o => o.messages)
            .map((messages) => messages?.map((message) => message.msg).join(', '))
            .filter(Boolean))
    ).join(', ');

    const generalErrorMessages = Array.from(
        new Set(errors
            .map(o => o.msg)
            .filter(Boolean))
    ).join(', ');

    return schemaValidationErrors || generalErrorMessages;
};
