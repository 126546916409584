import { useEffect } from 'react';

import { isModalOpen } from 'src/utils/is-modal-open';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';

/**
 * Unselect all conversations when escape is pressed.
 */
export const useDeselectConversationWithEscape = () => {
    const {
        focusedConversation,
        selectedConversations,
        setSelectedConversations,
    } = useConversationsPageContext();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && selectedConversations.length > 0 && !isModalOpen()) {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Conversations',
                    key: 'Escape',
                });

                event.preventDefault();
                setSelectedConversations([]);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [focusedConversation, setSelectedConversations]);
};
