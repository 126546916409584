import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';

import type { ILead } from 'src/services/api';
import { Flex } from 'src/components/flex';

interface Props {
    leads?: ILead[];
    page: number;
    setPage: (page: number) => void;
    rowsPerPage: number;
}

export default function LeadsTablePagination({
    leads,
    page,
    setPage,
    rowsPerPage,
}: Props) {
    const numberOfLeads = leads?.length || 0;

    const startIndex = (page - 1) * rowsPerPage;

    let endIndex = page * rowsPerPage;
    if (endIndex > numberOfLeads) {
        endIndex = numberOfLeads;
    }

    const totalLeads = leads?.length || 0;
    const numberOfPages = Math.ceil(totalLeads / rowsPerPage);

    return (
        <Flex justifyContent="flex-end" alignItems="center">
            <Flex alignItems="center" onClick={event => event.stopPropagation()}>
                <Typography variant="caption">
                    {startIndex + 1} - {endIndex} of {totalLeads}
                </Typography>

                <Flex
                    columnGap={0}
                    sx={{ '& .MuiIconButton-root': { p: 0 } }}
                >
                    <IconButton
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1}
                        data-testid="previous-page-button"
                    >
                        <ChevronLeftIcon />
                    </IconButton>

                    <IconButton
                        onClick={() => setPage(page + 1)}
                        disabled={page === numberOfPages}
                        data-testid="next-page-button"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Flex>
            </Flex>
        </Flex>
    );
}
