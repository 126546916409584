import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function BookOpen() {
    return (
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 10.2998V20.4998M12 10.2998C12 8.61965 12 7.77992 12.327 7.13818C12.6146 6.5737 13.0732 6.1146 13.6377 5.82698C14.2794 5.5 15.1196 5.5 16.7998 5.5H19.3998C19.9599 5.5 20.2401 5.5 20.454 5.60899C20.6422 5.70487 20.7948 5.85774 20.8906 6.0459C20.9996 6.25981 21 6.54004 21 7.1001V15.9001C21 16.4601 20.9996 16.7398 20.8906 16.9537C20.7948 17.1419 20.6425 17.2952 20.4543 17.3911C20.2406 17.5 19.961 17.5 19.402 17.5H16.5693C15.6301 17.5 15.1597 17.5 14.7334 17.6295C14.356 17.7441 14.0057 17.9317 13.701 18.1821C13.3568 18.465 13.096 18.8557 12.575 19.6372L12 20.4998M12 10.2998C12 8.61965 11.9998 7.77992 11.6729 7.13818C11.3852 6.5737 10.9263 6.1146 10.3618 5.82698C9.72004 5.5 8.87977 5.5 7.19961 5.5H4.59961C4.03956 5.5 3.75981 5.5 3.5459 5.60899C3.35774 5.70487 3.20487 5.85774 3.10899 6.0459C3 6.25981 3 6.54004 3 7.1001V15.9001C3 16.4601 3 16.7398 3.10899 16.9537C3.20487 17.1419 3.35774 17.2952 3.5459 17.3911C3.7596 17.5 4.03901 17.5 4.59797 17.5H7.43073C8.36994 17.5 8.83942 17.5 9.26569 17.6295C9.64306 17.7441 9.99512 17.9317 10.2998 18.1821C10.6426 18.4638 10.9017 18.8526 11.4185 19.6277L12 20.4998" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}