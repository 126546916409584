import { useForm } from 'react-hook-form';

import type { IProperty } from 'src/services/api';
import { isStandardErrorResponse } from 'src/common/api-utils';
import { useCreateProperty, usePropertyLabels, useUpdateProperty } from 'src/services/api';
import {
    type PropertyForm,
    propertyFormFields
} from 'src/components/create-or-update-property-modal/types';
import {
    getDefaultValues,
    getCreatePropertyPayload,
    getUpdatePropertyPayload,
} from 'src/components/create-or-update-property-modal/utils';

export const usePropertyForm = ({
    onSuccess,
    property,
    defaultValues: propsDefaultValues,
}: {
    onSuccess: (property: IProperty) => void;
    property?: IProperty;
    defaultValues?: Partial<IProperty>;
}) => {
    const { data: propertyLabels } = usePropertyLabels();

    const { mutateAsync: createProperty } = useCreateProperty();
    const { mutateAsync: updateProperty } = useUpdateProperty(property?.id);

    const form = useForm<PropertyForm>({
        mode: 'onBlur',
        defaultValues: getDefaultValues({
            ...property,
            ...propsDefaultValues,
        })
    });

    const submit = async (values: PropertyForm) => {
        if (!propertyLabels) {
            return;
        }

        try {
            if (property) {
                const payload = getUpdatePropertyPayload(property, propertyLabels, values);
                const updatedProperty = await updateProperty(payload);
                onSuccess(updatedProperty);
            } else {
                const payload = getCreatePropertyPayload(propertyLabels, values);
                const createdProperty = await createProperty(payload);
                onSuccess(createdProperty);
            }

            form.reset();
        } catch (error) {
            if (isStandardErrorResponse(error)) {
                const { messages } = error;
                if (messages) {
                    messages.forEach(({ loc, msg }) => {
                        const field = loc[0] as keyof PropertyForm;
                        if (propertyFormFields.includes(field)) {
                            form.setError(field, { message: msg });
                        } else if (['street_address', 'city', 'state', 'zipcode', 'unit'].includes(field)) {
                            // @ts-expect-error - TS doesn't update the type of `field` based on the condition above
                            form.setError(`address.${field}`, { message: msg });
                        }
                    });
                }
            }
        }
    };

    return { form, submit };
};
