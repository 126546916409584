import React from 'react';

import { Flex, FlexColumn } from 'src/components/flex';
import Responsive from 'src/components/responsive';
import Search from 'src/pages/landlord/properties/components/header/PropertiesSearch';
import FilterButton from 'src/pages/landlord/properties/components/header/PropertiesFilterButton';
import FilterModal from 'src/pages/landlord/properties/components/header/PropertiesFilterModal';
import SortButton from 'src/pages/landlord/properties/components/header/PropertiesSortButton';
import SortModal from 'src/pages/landlord/properties/components/header/PropertiesSortModal';
import Buttons from 'src/pages/landlord/properties/components/header/PropertyPageHeaderButtons';
import FilterStatus from 'src/pages/landlord/properties/components/header/PropertiesFilterStatus';
import MobileMenu from 'src/pages/landlord/properties/components/header/PropertiesMobileMenu';

export default function PropertiesPageHeader() {
    return (
        <>
            <FlexColumn p={2}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex flex={1} alignItems="center">
                        <Search />
                        <Responsive desktop>
                            <div>
                                <Flex>
                                    <FilterButton />
                                    <SortButton />
                                </Flex>
                            </div>
                        </Responsive>

                        <Responsive mobile>
                            <div>
                                <MobileMenu />
                            </div>
                        </Responsive>
                    </Flex>

                    <Buttons />
                </Flex>

                <Flex
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiChip-root': { maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis' },
                        '& .MuiSvgIcon-root': { ml: 1, fontSize: 20 }
                    }}
                >
                    <FilterStatus />
                </Flex>
            </FlexColumn>

            <FilterModal />
            <SortModal />
        </>
    );
}
