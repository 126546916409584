import React from 'react';
import Typography from '@mui/material/Typography';

import { FlexColumn } from 'src/components/flex';
import SpaceshipImage from 'src/pages/landlord/conversations/components/list/Spaceship';

export default function ConversationsNoSearchResults() {
    return (
        <FlexColumn mb={4} p={2} alignItems="center" rowGap={2}>
            <SpaceshipImage />
            <Typography>No conversations found.</Typography>
        </FlexColumn>
    );
}
