import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';

import type { ILead } from 'src/services/api';
import { Flex } from 'src/components/flex';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import TableCell from 'src/components/table/TableCell';
import TableRow from 'src/components/table/TableRow';
import LeadsTablePagination from 'src/pages/landlord/leads/components/table/LeadsTablePagination';
import DesktopLeadsTableRow from 'src/pages/landlord/leads/components/table/DesktopLeadsTableRow';
import LeadsToolbar from 'src/pages/landlord/leads/components/table/LeadsToolbar';

const LEADS_ROWS_PER_PAGE = 25;

interface Props {
    title?: string,
    leads?: ILead[],
}

export default function DesktopLeadsTable({
    title,
    leads,
}: Props) {
    const { selectedLeadUuids, setSelectedLeadUuids } = useLeadsPageContext();

    const [page, setPage] = useState(1);

    const numberOfLeads = leads?.length || 0;

    const startIndex = (page - 1) * LEADS_ROWS_PER_PAGE;

    let endIndex = page * LEADS_ROWS_PER_PAGE;
    if (endIndex > numberOfLeads) {
        endIndex = numberOfLeads;
    }

    const currentPageLeads = useMemo(() => {
        if (!leads) {
            return [];
        }

        return leads.slice(startIndex, endIndex);
    }, [leads, page, LEADS_ROWS_PER_PAGE, startIndex, endIndex]);

    // Reset page when leads change
    useEffect(() => {
        setPage(1);
    }, [leads?.map(lead => lead.uuid).join(',')]);

    const allSelected = currentPageLeads.every((lead) => {
        return selectedLeadUuids.includes(lead.uuid);
    });

    const selectAll = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (allSelected) {
            setSelectedLeadUuids([]);
        } else {
            setSelectedLeadUuids(currentPageLeads.map((lead) => lead.uuid));
        }
    };

    // Reset selected leads when page changes
    useEffect(() => {
        setSelectedLeadUuids([]);
    }, [page]);

    return (
        <div>
            <Flex
                py={1}
                justifyContent="space-between"
                borderTop="1px solid var(--border-color)"
                borderBottom="1px solid var(--border-color)"
                bgcolor="white"
            >
                <Typography variant="h3">{title}</Typography>
                <LeadsTablePagination
                    leads={leads}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={LEADS_ROWS_PER_PAGE}
                />
            </Flex>

            <TableContainer component={Paper} elevation={0} square>
                <Table sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" width={40}>
                                <Checkbox
                                    checked={allSelected}
                                    onClick={selectAll}
                                    sx={{
                                        padding: 0,
                                        width: 20,
                                        height: 20,
                                        '& svg': { fontSize: 20, fill: 'white' },
                                    }}
                                />
                            </TableCell>
                            <TableCell component="th" width="25%">Name</TableCell>
                            <TableCell component="th" width="20%">Stage</TableCell>
                            <TableCell component="th" width="20%">Source</TableCell>
                            <TableCell component="th" width="15%">Labels</TableCell>
                            <TableCell component="th" width="20%">Property</TableCell>
                            <TableCell component="th" width="20%">Contact</TableCell>
                            <TableCell component="th" width={40} padding="none" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageLeads.map((lead) => (
                            <DesktopLeadsTableRow key={lead.uuid} lead={lead} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <LeadsToolbar />
        </div>
    );
}
