import React from 'react';
import Button, { type ButtonProps } from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

interface Props {
    title: string,
    content: React.ReactNode,
    onConfirm: () => void,
    isLoading?: boolean,
    onClose: () => void,
    confirmButtonText?: string,
    confirmButtonColor?: ButtonProps['color'],
}

const ConfirmationDialog: React.FC<Props> = ({
    title,
    content,
    onConfirm,
    isLoading,
    onClose,
    confirmButtonText,
    confirmButtonColor,
}) => {
    const confirmText = confirmButtonText || 'Yes';
    const confirmColor = confirmButtonColor || 'primary';

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="dark"
                    onClick={onClose}
                    disabled={isLoading}
                    data-testid="confirmation-cancel-button"
                >
                    Cancel
                </Button>

                <LoadingButton
                    variant="contained"
                    color={confirmColor}
                    onClick={onConfirm}
                    loading={isLoading}
                    data-testid="confirmation-confirm-button"
                >
                    {confirmText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;