import React, { useMemo } from 'react';

import { useLeadLabels, usePropertyLabels } from 'src/services/api';
import AutocompleteMultiple from 'src/components/input/autocomplete-multiple/AutocompleteMultiple';

type Props = Omit<React.ComponentProps<typeof AutocompleteMultiple>, 'options'> & {
    type: 'lead' | 'property',
}

export default function SelectLabels({
    value: propsValue,
    type,
    ...props
}: Props) {
    const { data: leadLabels } = useLeadLabels();
    const { data: propertyLabels } = usePropertyLabels();

    const options = useMemo(() => {
        if (!leadLabels || !propertyLabels) {
            return [];
        }

        const labels = type === 'lead' ? leadLabels : propertyLabels;
        return labels
            .map((label) => ({
                value: String(label.id),
                label: label.name,
            }));
    }, [type, leadLabels, propertyLabels]);

    const value = propsValue ? propsValue.map(String) : propsValue;

    return (
        <AutocompleteMultiple {...props} value={value} options={options} />
    );
}
