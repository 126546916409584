import { LEAD_STAGES, SELECTABLE_LEAD_STAGES } from 'src/services/api';
import { StringFormat, formatString } from 'src/utils/format-string';

export const leadStageOptions = LEAD_STAGES.map((stage) => {
    return {
        value: stage,
        label: formatString(stage, StringFormat.SnakeCase, StringFormat.SentenceCase),
    };
});

export const selectableLeadStageOptions = SELECTABLE_LEAD_STAGES.map((stage) => {
    return {
        value: stage,
        label: formatString(stage, StringFormat.SnakeCase, StringFormat.SentenceCase),
    };
});