import React from 'react';
import Button, { type ButtonProps } from '@mui/material/Button';

import styles from './Buttons.module.css';
import './button-styles.css';

interface Props extends ButtonProps {
    // any child elements
    children: any;
    disabled?: boolean;
    // type default to button if not specified
    type?: 'button' | 'submit';
    onClick?(e: React.SyntheticEvent<HTMLButtonElement>): any;
    /**
     * Additional classnames to add
     */
    className?: string;
}

/**
 * A Stylized MUI button
 */
export const BlackButtonWhiteText: React.FC<Props> = ({children, ...props}) => {
    return (
        <Button
            variant="contained"
            color="dark"
            className={props.className}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type || 'button'}
            {...props}
        >
            {children}
        </Button>
    );
};

export const RedButtonWhiteText: React.FC<Props> = ({children, ...props}) => {
    return (
        <Button
            variant="contained"
            color="error"
            className={props.className}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type || 'button'}
            {...props}
        >
            {children}
        </Button>
    );
};

/**
 * Green button with white text
 * More square than GreenButtonWhiteText
 */
export const GreenButtonWhiteText: React.FC<Props> = ({children, ...props}) => {
    return (
        <Button
            variant="contained"
            color="primary"
            className={props.className + ' ' + styles.greenButtonWhiteText}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type || 'button'}
            {...props}
        >
            {children}
        </Button>
    );
};

/**
 * Green button with white text
 * More square than GreenButtonWhiteText to look better on modals
 */
export const ModalSuccessButton: React.FC<Props> = ({children, ...props}) => {
    let className = styles.modalSuccessButton;
    if (props.className) {
        className += ' ' + props.className;
    }
    return (
        <Button
            variant="contained"
            color="success"
            className={className}
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type || 'button'}
            {...props}
        >
            {children}
        </Button>
    );
};
