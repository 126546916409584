import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Buildings() {
    return (
        <SvgIcon>
            <svg width="22" height="19" viewBox="0 -1 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1 17.5H3M3 17.5H8M3 17.5V4.7002C3 3.58009 3 3.01962 3.21799 2.5918C3.40973 2.21547 3.71547 1.90973 4.0918 1.71799C4.51962 1.5 5.08009 1.5 6.2002 1.5H7.8002C8.9203 1.5 9.47957 1.5 9.90739 1.71799C10.2837 1.90973 10.5905 2.21547 10.7822 2.5918C11 3.0192 11 3.57899 11 4.69691V7.5002M8 17.5H19M8 17.5V11.8682C8 11.3428 8 11.08 8.063 10.835C8.11883 10.6178 8.21073 10.4118 8.33496 10.2252C8.47505 10.0147 8.67113 9.83843 9.06152 9.4877L11.3631 7.41997C12.1178 6.74192 12.4955 6.40264 12.9225 6.27393C13.2989 6.16045 13.7007 6.16045 14.0771 6.27393C14.5045 6.40275 14.8827 6.7422 15.6387 7.42139L17.9387 9.4877C18.3295 9.83881 18.5245 10.0146 18.6647 10.2252C18.7889 10.4118 18.8807 10.6178 18.9365 10.835C18.9995 11.08 19 11.3428 19 11.8682V17.5M19 17.5H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    );
}