
import { useQuery } from 'react-query';

import type { IShowing } from 'src/api/landlord-showings-api';
import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';

const PATH = 'api/landlord/showings';

type RequestParams = {
    whole_team?: boolean;
};

type Response = {
    showings: IShowing[];
};

/**
 * Showings are sorted by start time in ascending order.
 */
export const useShowings = (params?: RequestParams) => {
    return useQuery(
        [queryKeys.showings, params?.whole_team ? 'whole_team' : ''],
        async () => {
            const searchParams = new URLSearchParams();
            if (params?.whole_team) {
                searchParams.append('whole_team', 'true');
            }
            const response = await apiClient(PATH, {
                searchParams,
            }).json<Response>();

            return response.showings;
        }
    );
};