import { titleCase } from 'title-case';

const getFieldLabel = (field: string) => {
    const sanitizedField = field
        .replace(/_/g, ' ')
        .replace(/link$/i, '')
        .trim();

    return titleCase(sanitizedField);
};

const isLink = (str: string) => str.startsWith('http');

const getLinkLabel = (field: string, link: string) => {
    const url = new URL(link);

    switch (url.hostname) {
        case 'www.apartments.com':
        case 'apartments.com':
            return 'Apartments.com Listing';
        case 'zillow.com':
            return 'Zillow Listing';
        case 'showmojo.com':
            return 'ShowMojo Link';
        case 'tracking.ttusermail.com':
            return 'Tenant Turner Link';
    }

    switch (field) {
        case 'Listing_Link':
            return 'Listing Link';
        case 'Showing_Link':
            return 'Showing Link';
    }

    return 'Link';
};

export const formatSmartField = (field: string, value: string) => {
    return {
        fieldLabel: getFieldLabel(field),
        value: value,
        valueLabel: isLink(value) ? getLinkLabel(field, value) : value,
        isValueALink: isLink(value)
    };
};
