import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';

import { EMessageChannel } from 'src/services/api';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';

export default function SelectChannelButton() {
    const { lead, channel, setChannel, isFacebookConnected } = useLeadMessagesPageContext();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

    const isEmailSelected = channel === EMessageChannel.EMAIL;
    const isEmailDisabled = !lead.email;

    const isSmsSelected = channel === EMessageChannel.SMS;
    const isSmsDisabled = !lead.phone_number;

    const isFacebookMessengerSelected = channel === EMessageChannel.FACEBOOK_MESSENGER;
    const isFacebookMessengerDisabled = !isFacebookConnected || !lead?.facebook_user_id;
    let facebookMessengerDisabledReason = '';
    if (!isFacebookConnected) {
        facebookMessengerDisabledReason = 'Facebook account is not connected.';
    } else if (!lead?.facebook_user_id) {
        facebookMessengerDisabledReason = 'Lead was not ingested from Facebook.';
    }

    const handleSelectChannel = (newChannel: EMessageChannel) => {
        setChannel(newChannel);
        setAnchorEl(undefined);
    };

    return (
        <>
            <Tooltip title="Select channel">
                <IconButton sx={{ p: 0.5 }} onClick={(event) => setAnchorEl(event.currentTarget)}>
                    {isSmsSelected && <ChatBubbleOutlineOutlinedIcon />}
                    {isEmailSelected && <EmailOutlined />}
                    {isFacebookMessengerSelected && <FacebookIcon />}
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                onClick={() => setAnchorEl(undefined)}
            >
                <Tooltip
                    title={isEmailDisabled ? 'Lead has no valid email.' : ''}
                    enterTouchDelay={0}
                >
                    <div>
                        <MenuItem
                            selected={isEmailSelected}
                            disabled={isEmailDisabled}
                            onClick={() => handleSelectChannel(EMessageChannel.EMAIL)}
                        >
                            <ListItemIcon>
                                <EmailOutlined />
                            </ListItemIcon>
                            <ListItemText>
                                Email
                            </ListItemText>
                        </MenuItem>
                    </div>
                </Tooltip>

                <Tooltip
                    title={isSmsDisabled ? 'Lead has no valid phone number.' : ''}
                    enterTouchDelay={0}
                >
                    <div>
                        <MenuItem
                            selected={isSmsSelected}
                            disabled={isSmsDisabled}
                            onClick={() => handleSelectChannel(EMessageChannel.SMS)}
                        >
                            <ListItemIcon>
                                <ChatBubbleOutlineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Text Message
                            </ListItemText>
                        </MenuItem>
                    </div>
                </Tooltip>

                <Tooltip
                    title={facebookMessengerDisabledReason}
                    enterTouchDelay={0}
                >
                    <div>
                        <MenuItem
                            selected={isFacebookMessengerSelected}
                            disabled={isFacebookMessengerDisabled}
                            onClick={() => handleSelectChannel(EMessageChannel.FACEBOOK_MESSENGER)}
                        >
                            <ListItemIcon>
                                <FacebookIcon />
                            </ListItemIcon>
                            <ListItemText>
                                Facebook Messenger
                            </ListItemText>
                        </MenuItem>
                    </div>
                </Tooltip>
            </Menu>
        </>
    );
}
