import { useQuery, } from 'react-query';

import type { ILead } from 'src/services/api/leads/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';

type IGetLeadResponse = {
    lead: ILead;
};

export const useLead = (leadUuid?: string) => {

    return useQuery<ILead>(
        [queryKeys.leads, leadUuid],
        async () => {
            const path = `${GET_LEADS_PATH}/${leadUuid}`;
            const res = await apiClient(path).json<IGetLeadResponse>();
            return res.lead;
        },
        { enabled: !!leadUuid }
    );
};
