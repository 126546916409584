import { useQuery } from 'react-query';

import type { IPropertyLabel } from 'src/services/api/properties/labels/types';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { GET_PROPERTY_LABELS_PATH } from 'src/services/api/properties/labels/constants';

export type IGetPropertyLabelsResponse = {
    labels: IPropertyLabel[];
};

export const usePropertyLabels = () => {
    return useQuery(
        [queryKeys.propertyLabels],
        async () => {
            const res = await apiClient(GET_PROPERTY_LABELS_PATH).json<IGetPropertyLabelsResponse>();
            return res.labels;
        }
    );
};
