import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoRounded';
import MailIcon from '@mui/icons-material/MailRounded';
import MoreVertIcon from '@mui/icons-material/MoreVertRounded';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import type { ILead } from 'src/services/api';
import { useDeleteLead } from 'src/services/api/leads';
import { useToggle } from 'src/hooks/use-toggle';
import Responsive from 'src/components/responsive';
import ConfirmationDialog from 'src/components/confirmation-dialog';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal';

interface Props {
    lead: ILead,
}

export default function LeadsTableRowMenu({ lead }: Props) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const [isEditing, { setTrue: startEditing, setFalse: stopEditing }] = useToggle();
    const [isDeleteStarted, { setTrue: startDeleting, setFalse: stopDeleting }] = useToggle();

    const navigate = useNavigate();
    const setSearchParams = useSearchParams()[1];

    const { mutateAsync: deleteLead, isLoading: isDeleting } = useDeleteLead(lead.uuid);

    const handleCloseMenu = () => {
        setAnchorEl(undefined);
    };

    const handleOpenDetails = () => {
        setSearchParams({ uuid: lead.uuid });
        handleCloseMenu();
    };

    const handleEditClick = () => {
        startEditing();
        handleCloseMenu();
    };

    const handleDeleteClick = () => {
        startDeleting();
        handleCloseMenu();
    };

    const handleDeleteConfirm = async () => {
        await deleteLead();
        stopDeleting();
    };

    const canViewConversation = !!lead.email ||
        !!lead.phone_number ||
        !!lead.facebook_user_id;

    const handleViewConversation = () => {
        navigate(`/landlord/inner/messages/from-lead?lead_uuid=${lead.uuid}`);
        handleCloseMenu();
    };

    return (
        <>
            {isEditing && (
                <CreateOrUpdateLeadModal
                    lead={lead}
                    onClose={stopEditing}
                />
            )}

            {isDeleteStarted && lead.is_synthetic && (
                <ConfirmationDialog
                    title="Are you sure you want to delete this lead?"
                    content="This action cannot be undone."
                    onConfirm={handleDeleteConfirm}
                    isLoading={isDeleting}
                    onClose={stopDeleting}
                    confirmButtonColor="error"
                    confirmButtonText="Delete"
                />
            )}

            <IconButton
                onClick={event => setAnchorEl(event.currentTarget)}
                edge="end"
                data-testid="lead-menu-button"
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleCloseMenu}
            >
                <Responsive desktop>
                    <div>
                        <MenuItem
                            onClick={handleOpenDetails}
                            data-testid="open-details-button"
                        >
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography>Open details</Typography>
                            </ListItemText>
                        </MenuItem>

                        <Divider />
                    </div>
                </Responsive>

                <MenuItem
                    onClick={handleViewConversation}
                    disabled={!canViewConversation}
                    data-testid="view-conversation-button"
                >
                    <ListItemIcon>
                        <MailIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>View conversation</Typography>
                    </ListItemText>
                </MenuItem>

                <Divider />

                <MenuItem
                    onClick={handleEditClick}
                    data-testid="edit-lead-button"
                >
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>Edit</Typography>
                    </ListItemText>
                </MenuItem>

                {lead.is_synthetic && <Divider />}

                {lead.is_synthetic && (
                    <MenuItem
                        onClick={handleDeleteClick}
                        data-testid="delete-lead-button"
                    >
                        <ListItemIcon>
                            <DeleteIcon color="error" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography color="error.500">Delete</Typography>
                        </ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}