import type { IProperty } from 'src/services/api';
import { EPropertiesPageTab, type IPropertiesFilter } from 'src/pages/landlord/properties/provider';
import { searchProperties } from 'src/pages/landlord/properties/utils/search-properties';

export const filterProperties = (
    properties: IProperty[],
    tab: EPropertiesPageTab,
    filter: IPropertiesFilter,
    search: string,
) => {
    let filteredProperties = properties;

    if (tab !== EPropertiesPageTab.ALL) {
        filteredProperties = filteredProperties.filter((property) => property.is_vacant === (tab === EPropertiesPageTab.VACANT));
    }

    if (filter.hasApplicationLink !== null) {
        filteredProperties = filteredProperties.filter((property) => {
            if (filter.hasApplicationLink) {
                return property.application_link !== null;
            } else {
                return property.application_link === null;
            }
        });
    }

    if (filter.hasTourLink !== null) {
        filteredProperties = filteredProperties.filter((property) => {
            if (filter.hasTourLink) {
                return property.showing_scheduling_link !== null;
            } else {
                return property.showing_scheduling_link === null;
            }
        });
    }

    if (filter.propertyLabelIds.length > 0) {
        filteredProperties = filteredProperties.filter((property) => {
            return property.labels.some((label) => filter.propertyLabelIds.includes(String(label.id)));
        });
    }

    if (filter.propertyTypes.length > 0) {
        filteredProperties = filteredProperties.filter((property) => {
            return property.type && filter.propertyTypes.includes(property.type);
        });
    }

    if (search) {
        filteredProperties = searchProperties(filteredProperties, search);
    }

    return filteredProperties;
};
