import React from 'react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import Typography from '@mui/material/Typography';

import type { ILead } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import { colors } from 'src/theme/colors';
import { Flex } from 'src/components/flex';
import ButtonLink from 'src/components/button-link/ButtonLink';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';
import Tour from 'src/components/lead-sidebar/LeadSidebarTour';

dayjs.extend(dayOfYear);

type Props = {
    lead: ILead;
    showings: IShowing[],
}

export default function LeadSidebarShowingsFromOtherLeads({
    lead,
    showings,
}: Props) {
    const now = dayjs();
    const today = now.dayOfYear();
    const currentYear = now.year();

    const showingsFromOtherLeadsForSameProperty = showings
        // for the same property as the current lead
        .filter((showing) => showing.property_id === lead.property_id)
        // not for the current lead
        .filter((showing) => showing.lead_uuid !== lead.uuid)
        // not in the past
        .filter((showing) => dayjs(showing.start_time).year() == currentYear)
        .filter((showing) => dayjs(showing.start_time).dayOfYear() >= today)
        // sort by start time, most recent first
        .sort((a, b) => a.start_time.localeCompare(b.start_time));

    const numShowings = showingsFromOtherLeadsForSameProperty.length;
    const hasOtherShowings = numShowings > 0;

    if (!hasOtherShowings) {
        return null;
    }

    return (
        <ExpandableItem
            title="Other Tours for this Lead's Property"
            numShowings={numShowings}
        >
            {showingsFromOtherLeadsForSameProperty
                .slice(0, 5)
                .map((showing) => (
                    <Tour
                        key={showing.id}
                        lead={lead}
                        showing={showing}
                        color={colors.purple[500]}
                    />
                ))}

            {numShowings > 5 && (
                <Typography variant="body2" color="textSecondary">
                    and {numShowings - 5} more
                </Typography>
            )}

            <Flex justifyContent="flex-end">
                <ButtonLink
                    variant="contained"
                    href="/landlord/inner/tours"
                    size="small"
                >
                    Go to Tours
                </ButtonLink>
            </Flex>
        </ExpandableItem>
    );
}
