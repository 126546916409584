import React, { useState } from 'react';
import MarkUnreadIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import { ThemeProvider } from 'src/theme';
import { useNotifications } from 'src/notifications';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context';
import NavigationButtons from 'src/pages/landlord/leads/messages/components/header/NavigationButtons';
import Flex from 'src/components/flex/Flex';
import { useUpdateConversationsReadStatus, useUpdateLeadsStatuses } from 'src/services/api';
import { ReadStatus } from 'src/api/landlord-messages-api';
import Responsive from 'src/components/responsive';

interface Props {
    handleShowLeadDetails: (value: boolean) => void;
}

export default function LeadMessagesHeader({ handleShowLeadDetails }: Props) {
    const { lead, messages, teamSettings } = useLeadMessagesPageContext();
    const { mutateAsync: updateReadStatus } = useUpdateConversationsReadStatus();

    const navigate = useNavigate();

    const { addNotification } = useNotifications();

    const {
        mutateAsync: updateLeadsStatuses,
    } = useUpdateLeadsStatuses();

    const setConversationUnread = async () => {
        await updateReadStatus({
            leadUuids: [lead.uuid],
            status: ReadStatus.UNREAD,
        });

        navigate('/landlord/inner/messages');

        addNotification('Conversation marked unread', 'success');
    };

    const setConversationArchived = async (isArchived: boolean) => {
        await updateLeadsStatuses([{ uuid: lead.uuid, is_archived: isArchived }]);
        navigate('/landlord/inner/messages');
        if (isArchived) {
            addNotification(`${lead.name} moved to archive`, 'success');
        } else {
            addNotification(`${lead.name} removed from archive`, 'success');
        }
    };

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleCloseMenu = () => {
        setAnchorEl(undefined);
    };

    const viewLeadDetails = () => {
        handleShowLeadDetails(true);
        handleCloseMenu();
    };

    const hasRequestForApplication = messages.some((message) => {
        return message.is_rental_application_request;
    });

    const isShowZillowSettingAlert =
        !teamSettings?.auto_change_stage_zillow_application_completed &&
        hasRequestForApplication &&
        lead.ingestion_source === 'zillow';

    return (
        <ThemeProvider>
            <Flex
                height={48}
                minHeight={48}
                borderBottom="1px solid var(--border-color)"
                px={1}
                justifyContent="space-between"
                alignItems="center"
            >

                <NavigationButtons lead={lead} />

                <IconButton
                    size="small"
                    onClick={event => setAnchorEl(event.currentTarget)}
                    edge="end"
                >
                    <MoreVertIcon />
                </IconButton>

                <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseMenu}>
                    <Responsive mobile>
                        <div>
                            <MenuItem onClick={viewLeadDetails}>
                                <ListItemIcon>
                                    <DescriptionOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography>View details</Typography>
                                </ListItemText>
                            </MenuItem>

                            <Divider />

                        </div>
                    </Responsive>

                    <MenuItem onClick={setConversationUnread}>
                        <ListItemIcon>
                            <MarkUnreadIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>Mark as Unread</Typography>
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => setConversationArchived(!lead.is_archived)}>
                        <ListItemIcon>
                            <Inventory2OutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography>{lead.is_archived ? 'Unarchive Lead' : 'Archive Lead'}</Typography>
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Flex>

            {isShowZillowSettingAlert &&
                <Alert severity="info">
                    The current lead has completed the application step. If you would like to auto-change the stage of a lead once an application is complete, please enable this setting&nbsp;
                    <a href="/landlord/inner/settings?tab=lead_ingestion">here.</a>
                </Alert>
            }
        </ThemeProvider>
    );
}
