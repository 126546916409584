import type { OSMSearchResult, SearchAddress } from 'src/services/location/types';
import { getStateCodeByStateName } from 'src/services/location/constants/us';

export const transformOSMAddress = (osmSearchResult: OSMSearchResult): SearchAddress => {
    return {
        id: String(osmSearchResult.raw.place_id),
        displayName: osmSearchResult.raw.display_name,
        streetAddress: [
            osmSearchResult.raw.address.house_number,
            osmSearchResult.raw.address.road,
        ].filter(Boolean).join(' '),
        unit: null,
        city: osmSearchResult.raw.address.city || osmSearchResult.raw.address.town || '',
        zipcode: osmSearchResult.raw.address.postcode ?? '',
        state: getStateCodeByStateName(osmSearchResult.raw.address.state) || ''
    };
};
