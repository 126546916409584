import { type Option } from 'src/components/input/select/Select';

export enum StateCodeUS {
    Alabama = 'AL',
    Alaska = 'AK',
    Arizona = 'AZ',
    Arkansas = 'AR',
    California = 'CA',
    Colorado = 'CO',
    Connecticut = 'CT',
    Delaware = 'DE',
    Florida = 'FL',
    Georgia = 'GA',
    Hawaii = 'HI',
    Idaho = 'ID',
    Illinois = 'IL',
    Indiana = 'IN',
    Iowa = 'IA',
    Kansas = 'KS',
    Kentucky = 'KY',
    Louisiana = 'LA',
    Maine = 'ME',
    Maryland = 'MD',
    Massachusetts = 'MA',
    Michigan = 'MI',
    Minnesota = 'MN',
    Mississippi = 'MS',
    Missouri = 'MO',
    Montana = 'MT',
    Nebraska = 'NE',
    Nevada = 'NV',
    NewHampshire = 'NH',
    NewJersey = 'NJ',
    NewMexico = 'NM',
    NewYork = 'NY',
    NorthCarolina = 'NC',
    NorthDakota = 'ND',
    Ohio = 'OH',
    Oklahoma = 'OK',
    Oregon = 'OR',
    Pennsylvania = 'PA',
    RhodeIsland = 'RI',
    SouthCarolina = 'SC',
    SouthDakota = 'SD',
    Tennessee = 'TN',
    Texas = 'TX',
    Utah = 'UT',
    Vermont = 'VT',
    Virginia = 'VA',
    Washington = 'WA',
    WestVirginia = 'WV',
    Wisconsin = 'WI',
    Wyoming = 'WY',
}

enum StateNameUS {
    Alabama = 'Alabama',
    Alaska = 'Alaska',
    Arizona = 'Arizona',
    Arkansas = 'Arkansas',
    California = 'California',
    Colorado = 'Colorado',
    Connecticut = 'Connecticut',
    Delaware = 'Delaware',
    Florida = 'Florida',
    Georgia = 'Georgia',
    Hawaii = 'Hawaii',
    Idaho = 'Idaho',
    Illinois = 'Illinois',
    Indiana = 'Indiana',
    Iowa = 'Iowa',
    Kansas = 'Kansas',
    Kentucky = 'Kentucky',
    Louisiana = 'Louisiana',
    Maine = 'Maine',
    Maryland = 'Maryland',
    Massachusetts = 'Massachusetts',
    Michigan = 'Michigan',
    Minnesota = 'Minnesota',
    Mississippi = 'Mississippi',
    Missouri = 'Missouri',
    Montana = 'Montana',
    Nebraska = 'Nebraska',
    Nevada = 'Nevada',
    NewHampshire = 'New Hampshire',
    NewJersey = 'New Jersey',
    NewMexico = 'New Mexico',
    NewYork = 'New York',
    NorthCarolina = 'North Carolina',
    NorthDakota = 'North Dakota',
    Ohio = 'Ohio',
    Oklahoma = 'Oklahoma',
    Oregon = 'Oregon',
    Pennsylvania = 'Pennsylvania',
    RhodeIsland = 'Rhode Island',
    SouthCarolina = 'South Carolina',
    SouthDakota = 'South Dakota',
    Tennessee = 'Tennessee',
    Texas = 'Texas',
    Utah = 'Utah',
    Vermont = 'Vermont',
    Virginia = 'Virginia',
    Washington = 'Washington',
    WestVirginia = 'West Virginia',
    Wisconsin = 'Wisconsin',
    Wyoming = 'Wyoming',
}

export const stateNamesUs = {
    [StateCodeUS.Alabama]: StateNameUS.Alabama,
    [StateCodeUS.Alaska]: StateNameUS.Alaska,
    [StateCodeUS.Arizona]: StateNameUS.Arizona,
    [StateCodeUS.Arkansas]: StateNameUS.Arkansas,
    [StateCodeUS.California]: StateNameUS.California,
    [StateCodeUS.Colorado]: StateNameUS.Colorado,
    [StateCodeUS.Connecticut]: StateNameUS.Connecticut,
    [StateCodeUS.Delaware]: StateNameUS.Delaware,
    [StateCodeUS.Florida]: StateNameUS.Florida,
    [StateCodeUS.Georgia]: StateNameUS.Georgia,
    [StateCodeUS.Hawaii]: StateNameUS.Hawaii,
    [StateCodeUS.Idaho]: StateNameUS.Idaho,
    [StateCodeUS.Illinois]: StateNameUS.Illinois,
    [StateCodeUS.Indiana]: StateNameUS.Indiana,
    [StateCodeUS.Iowa]: StateNameUS.Iowa,
    [StateCodeUS.Kansas]: StateNameUS.Kansas,
    [StateCodeUS.Kentucky]: StateNameUS.Kentucky,
    [StateCodeUS.Louisiana]: StateNameUS.Louisiana,
    [StateCodeUS.Maine]: StateNameUS.Maine,
    [StateCodeUS.Maryland]: StateNameUS.Maryland,
    [StateCodeUS.Massachusetts]: StateNameUS.Massachusetts,
    [StateCodeUS.Michigan]: StateNameUS.Michigan,
    [StateCodeUS.Minnesota]: StateNameUS.Minnesota,
    [StateCodeUS.Mississippi]: StateNameUS.Mississippi,
    [StateCodeUS.Missouri]: StateNameUS.Missouri,
    [StateCodeUS.Montana]: StateNameUS.Montana,
    [StateCodeUS.Nebraska]: StateNameUS.Nebraska,
    [StateCodeUS.Nevada]: StateNameUS.Nevada,
    [StateCodeUS.NewHampshire]: StateNameUS.NewHampshire,
    [StateCodeUS.NewJersey]: StateNameUS.NewJersey,
    [StateCodeUS.NewMexico]: StateNameUS.NewMexico,
    [StateCodeUS.NewYork]: StateNameUS.NewYork,
    [StateCodeUS.NorthCarolina]: StateNameUS.NorthCarolina,
    [StateCodeUS.NorthDakota]: StateNameUS.NorthDakota,
    [StateCodeUS.Ohio]: StateNameUS.Ohio,
    [StateCodeUS.Oklahoma]: StateNameUS.Oklahoma,
    [StateCodeUS.Oregon]: StateNameUS.Oregon,
    [StateCodeUS.Pennsylvania]: StateNameUS.Pennsylvania,
    [StateCodeUS.RhodeIsland]: StateNameUS.RhodeIsland,
    [StateCodeUS.SouthCarolina]: StateNameUS.SouthCarolina,
    [StateCodeUS.SouthDakota]: StateNameUS.SouthDakota,
    [StateCodeUS.Tennessee]: StateNameUS.Tennessee,
    [StateCodeUS.Texas]: StateNameUS.Texas,
    [StateCodeUS.Utah]: StateNameUS.Utah,
    [StateCodeUS.Vermont]: StateNameUS.Vermont,
    [StateCodeUS.Virginia]: StateNameUS.Virginia,
    [StateCodeUS.Washington]: StateNameUS.Washington,
    [StateCodeUS.WestVirginia]: StateNameUS.WestVirginia,
    [StateCodeUS.Wisconsin]: StateNameUS.Wisconsin,
    [StateCodeUS.Wyoming]: StateNameUS.Wyoming,
};

export const stateOptionsUS = Object.entries(stateNamesUs)
    .map(([value, label]) => ({ value, label } as Option<StateCodeUS>));

export const getStateCodeByStateName = (stateName?: string) => {
    if (!stateName) {
        return;
    }

    return Object.entries(stateNamesUs).find(([, name]) => name === stateName)?.[0] as StateCodeUS;
};