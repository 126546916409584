import React from 'react';
import clsx from 'clsx';
import MuiSelect, { type SelectProps as MuiSelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import styles from 'src/components/input/select/Select.module.css';

const DEFAULT_CLEARABLE_TEXT = 'None';

export type Option<T = string> = { value: T, label?: React.ReactNode };
export type Options<T = string> = Option<T>[];

export type SelectProps<T> = Omit<MuiSelectProps<T>, 'value' | 'onChange' | 'children'> & {
    className?: string,
    label?: string,
    /**
     * Placeholder text to show when no value is selected.
     * As opposed to placeholders in other input components, this one is shown as normal text when no value is selected.
     * TODO: Show palceholder as a pseudo element
     */
    placeholder?: string,
    options: Options<T>,
    value: T | null,
    onChange: (value: T) => void,
    helperText?: React.ReactNode,
    error?: boolean,
    /**
     * Text to show when no value is selected.
     */
    emptyText?: string,
    /**
     * Show an option to clear the value.
     */
    isClearable?: boolean,
    /**
     * Text to show in the clearable option.
     * If `isClearable` is `false`, this prop is ignored.
     * @default "None"
     */
    clearableText?: string,
    withFadedPlaceholder?: boolean,
};

const Select = <T extends string | number>({
    variant: propsVariant,
    label,
    placeholder: propsPlaceholder,
    options,
    onChange,
    error,
    helperText,
    size,
    fullWidth,
    emptyText,
    isClearable,
    clearableText = DEFAULT_CLEARABLE_TEXT,
    withFadedPlaceholder,
    ...props
}: SelectProps<T>) => {
    const placeholder = propsPlaceholder || emptyText;

    const renderValue: SelectProps<T>['renderValue'] = (value) => {
        if (!value) {
            if (!placeholder) {
                return null;
            }

            return (
                <Typography className={clsx(withFadedPlaceholder && styles.fadedPlaceholder)}>
                    {placeholder}
                </Typography>
            );
        }

        const option = options.find((o) => o.value === value);
        return option?.label || value;
    };

    const handleChange: MuiSelectProps<T>['onChange'] = (event) => {
        onChange(event.target.value as T);
    };

    const value = props.value || '';

    const shouldShrinkLabel = !!value || !!placeholder;
    // `notched` prop is only available for outlined variant
    // We will get a warning if we pass it to other variants
    const variant = propsVariant || 'outlined';
    const notched = variant === 'outlined' ? shouldShrinkLabel : undefined;

    return (
        <FormControl error={error} size={size} fullWidth={fullWidth}>
            <InputLabel variant={propsVariant} shrink={shouldShrinkLabel}>{label}</InputLabel>

            <MuiSelect
                variant={variant}
                label={label}
                notched={notched}
                displayEmpty={!!placeholder}
                renderValue={props.renderValue || renderValue}
                {...props}
                value={value}
                onChange={handleChange}
                size={size}
            >
                {isClearable && (
                    <MenuItem value="">
                        <Typography>{clearableText}</Typography>
                    </MenuItem>
                )}

                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default Select;