import React from 'react';
import Typography from '@mui/material/Typography';

import Flex from 'src/components/flex/Flex';
import Kbd from 'src/components/kbd/Kbd';

type Props = {
    label?: string;
    children: React.ReactNode;
};

export default function Hotkey({
    label,
    children
}: Props) {
    return (
        <Flex alignItems="center">
            <Kbd>{children}</Kbd>
            {label && <Typography variant="body2" color="grey.700">{label}</Typography>}
        </Flex>
    );
}
