import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import EditIcon from '@mui/icons-material/EditRounded';

import type { ILead } from 'src/services/api';
import { type ILeadLabel, useUpdateLead } from 'src/services/api';
import { FlexColumn } from 'src/components/flex';
import ChipsRow from 'src/components/chip/ChipsRow';
import SelectLeadLabels from 'src/components/input/select-lead-labels/SelectLeadLabels';

import styles from 'src/components/input/select-lead-labels/EditableLeadLabels.module.css';

type Props = {
    lead: ILead,
}

export default function EditableLeadLabels({
    lead,
}: Props) {
    const { mutateAsync: updateLeadLabels, isLoading: isUpdatingLeadLabels } = useUpdateLead(lead.uuid);

    const selectFieldContainerRef = useRef<HTMLDivElement>(null);

    const [isEditing, setEditing] = useState(false);

    const chips = useMemo(() => {
        return lead.labels.map((o) => ({ label: o.name, color: o.color }));
    }, [lead]);

    const handleChange = async (newValue: ILeadLabel[]) => {
        await updateLeadLabels({ ...lead, labels: newValue });
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            setEditing(false);
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setEditing(false)}>
                <div className={styles.root} onClick={() => setEditing(true)}>
                    {!isEditing && <ChipsRow chips={chips} />}

                    <FlexColumn
                        className={clsx(styles.content, isEditing && styles.contentActive)}
                        borderColor="primary.main"
                        boxShadow={isEditing ? 1 : 0}
                    >
                        {isEditing && (
                            <Box ref={selectFieldContainerRef}>
                                <Box p={1}>
                                    <SelectLeadLabels
                                        lead={lead}
                                        onChange={handleChange}
                                        autoFocus
                                        openOnFocus
                                        onKeyDown={handleKeyDown}
                                    />
                                </Box>
                            </Box>
                        )}

                        {!isEditing && !chips.length && (
                            <div className={styles.editableFieldIndicator}>
                                <EditIcon fontSize="small" />
                            </div>
                        )}
                    </FlexColumn>
                </div>
            </ClickAwayListener>

            <Snackbar open={isUpdatingLeadLabels} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity="info">
                    Updating labels...
                </Alert>
            </Snackbar>
        </>
    );
}