import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ISendMessageRequest } from 'src/services/api/messages/use-send-message';
import { useNotifications } from 'src/notifications';
import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';
import { SEND_MESSAGE_PATH } from 'src/services/api/messages/use-send-message';

type ISendMessagesRequest = ISendMessageRequest[];

export const useSendMessages = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        void,
        IStandardErrorResponse[],
        ISendMessagesRequest
    >(
        async (payloads) => {
            const res = await Promise.allSettled(payloads.map((payload) => {
                const json = {
                    lead_uuid: payload.leadUuid,
                    channel: payload.channel,
                    contents: payload.message
                };

                return apiClient(SEND_MESSAGE_PATH, {
                    method: 'post',
                    json,
                });
            }));

            const errors = res.filter((r) => r.status === 'rejected');
            if (errors.length > 0) {
                throw errors.map(o => o.reason);
            }
        }, {
            onSuccess: (_data, variables) => {
                addNotification('Message sent', 'success');

                queryClient.invalidateQueries(queryKeys.conversations);
                for (const payload of variables) {
                    queryClient.invalidateQueries([queryKeys.messages, payload.leadUuid]);
                }
            },
            onError: (errors, variables) => {
                // TODO: Use getApiErrorMessage once PR 852 is merged
                const schemaValidationErrors = Array.from(
                    new Set(errors
                        .map(o => o.messages)
                        .map((messages) => messages?.map((message) => message.msg).join(', '))
                        .filter(Boolean))
                ).join(', ');

                const generalErrorMessages = Array.from(
                    new Set(errors
                        .map(o => o.msg)
                        .filter(Boolean))
                ).join(', ');

                const errorMessage = schemaValidationErrors || generalErrorMessages || 'Failed to send messages';
                addNotification(errorMessage, 'error');

                queryClient.invalidateQueries(queryKeys.conversations);
                for (const payload of variables) {
                    queryClient.invalidateQueries([queryKeys.messages, payload.leadUuid]);
                }
            }
        }
    );
};
