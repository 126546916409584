import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export function prettyifyPhoneNumber (phoneNumber: string | null) {
    if(phoneNumber) {
        try{
            const phoneNumberToFormat = parsePhoneNumber(phoneNumber, 'US');
            if(phoneNumberToFormat.countryCallingCode === '1') {
                return phoneNumberToFormat.formatNational();
            }
            return phoneNumberToFormat.formatInternational();
        } catch (err) {
            console.error(`Failed to parse phone number: ${phoneNumber}`);
            console.error(err);
            return `Invalid phone number: ${phoneNumber}`;
        }
    } else {
        return 'Not Set';
    }
}

export function validatePhoneNumber (phoneNumber: string) {
    try{
        if(phoneNumber === '') {
            return true;
        }
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'US');
        if(parsedPhoneNumber.country) {
            const countryCode = parsedPhoneNumber.country;
            return isValidPhoneNumber(phoneNumber, countryCode);
        } else {
            return isPossiblePhoneNumber(phoneNumber);
        }
    } catch (err) {
        console.error(`Failed to validate phone number: ${phoneNumber}`);
        console.error(err);
    }
}