import React, { useMemo } from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import MuiAutocomplete, { type AutocompleteProps } from '@mui/material/Autocomplete';

import { type Option } from 'src/components/input/select/Select';

type BaseProps<T = string | undefined> = AutocompleteProps<Option<T>, true, false, false>;

type Props<T = string | undefined> = Omit<BaseProps<T>, 'value' | 'onChange' | 'renderInput' | 'multiple'> & {
    variant?: TextFieldProps['variant'],
    label?: string,
    placeholder?: string,
    value?: T[],
    onChange: (value?: NonNullable<T>[]) => void,
    error?: TextFieldProps['error'],
    helperText?: TextFieldProps['helperText'],
    required?: TextFieldProps['required'],
    /**
     * Text to display for the option that clears the value.
     * If not provided, the option will not be displayed.
     */
    clearOption?: string,
}

/**
 * Same as AutoComplete, but allows multiple selections.
 * TODO: Combine into a single component with AutoComplete.
 */
export default function AutocompleteMultiple({
    variant,
    label,
    placeholder,
    options: propsOptions,
    value,
    onChange,
    error,
    helperText,
    required,
    clearOption,
    ...props
}: Props) {
    const options = useMemo(() => {
        if (!clearOption) {
            return propsOptions;
        }

        return [
            {
                label: clearOption,
                value: undefined,
            },
            ...propsOptions,
        ];
    }, [propsOptions, clearOption]);

    const selectedOptions = useMemo(() => {
        return value ? options.filter((option) => value.includes(option.value)) : [];
    }, [options, value]);

    const handleChange: BaseProps['onChange'] = (_event, newOptions) => {
        const newValue: string[] = [];
        for (const option in newOptions) {
            const _value = newOptions[option].value;
            if (_value) {
                newValue.push(_value);
            }
        }
        onChange?.(newValue);
    };

    return (
        <MuiAutocomplete
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                    required={required}
                    sx={{ '& .MuiChip-deleteIcon': { margin: '0 4px !important' } }}
                />
            )}
            {...props}
            options={options}
            value={selectedOptions}
            onChange={handleChange}
            getOptionKey={(option) => option.value || ''}
            multiple
            disableCloseOnSelect
        />
    );
}
