import {
    useNavigateConversationsWithArrowKeys,
    useOpenConversationWithEnterKey,
    useSelectConversationWithSpaceBar,
    useDeselectConversationWithEscape,
    useToggleFilterModalWithFKey
} from 'src/pages/landlord/conversations/components/key-listener/hooks';

export default function ConversationsKeyListener() {
    useNavigateConversationsWithArrowKeys();
    useSelectConversationWithSpaceBar();
    useDeselectConversationWithEscape();
    useOpenConversationWithEnterKey();
    useToggleFilterModalWithFKey();

    return null;
}
