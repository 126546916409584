import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

interface Props {
    title: string;
    children: React.ReactNode;
    numShowings?: number;
    tooltipTitle?: string;
}

export default function LeadSidebarExpandableItem({
    title,
    children,
    tooltipTitle
}: Props) {
    return (
        <Accordion
            defaultExpanded
            disableGutters
            elevation={0}
            square
            sx={{ '&::before': { display: 'none' } }}
        >
            <AccordionSummary
                expandIcon={<KeyboardArrowDownIcon />}
                sx={{ px: 0 }}
            >
                <Tooltip title={tooltipTitle}>
                    <Typography>{title}</Typography>
                </Tooltip>
            </AccordionSummary>

            <AccordionDetails
                sx={{ pl: 0, pr: 1, py: 0.5 }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
