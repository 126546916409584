import { useMutation, useQueryClient } from 'react-query';
import pluralize from 'pluralize';

import type { IStandardErrorResponse } from 'src/api/common';
import type { IProperty } from 'src/services/api/properties/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';

const PROPERTY_PATH = 'api/landlord/properties';

const deleteProperty = async (propertyId: number) => {
    const path = `${PROPERTY_PATH}/${propertyId}`;
    return apiClient(path, { method: 'delete' });
};

export const useDeleteProperties = (ids: number[]) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<IProperty[], IStandardErrorResponse[]>(
        async () => {
            const results = await Promise.allSettled(ids.map(deleteProperty));
            const errors = results.filter((r) => r.status === 'rejected');
            let remainingProperties: IProperty[] = [];

            // not all of the requests may succeed
            const deletedPropertyIds: number[] = [];
            const errorPropertyIds: number[] = [];
            for(let i = 0; i < results.length; i++) {
                const result = results[i];
                const propertyId = ids[i];
                if (result.status === 'rejected') {
                    errorPropertyIds.push(propertyId);
                } else {
                    deletedPropertyIds.push(propertyId);
                }
            }

            if (errorPropertyIds.length > 0 && deletedPropertyIds.length === 0) {
                console.warn(`Failed to delete ${errorPropertyIds.length} ${pluralize('property', errorPropertyIds.length)}`);
                throw errors.map(o => o.reason);
            }

            if (deletedPropertyIds.length > 0) {
                const oldProperties = queryClient.getQueryData<IProperty[]>(queryKeys.properties) || [];
                remainingProperties = oldProperties.filter((property) => !deletedPropertyIds.includes(property.id));
                queryClient.setQueryData<IProperty[]>(queryKeys.properties, remainingProperties);
                addNotification(`${deletedPropertyIds.length} ${pluralize('property', deletedPropertyIds.length)} deleted`, 'success');
            }

            return remainingProperties;
        }, {
            onError: (errors) => {
                const errorMessage = getApiErrorMessage(errors) || 'Failed to delete properties';
                addNotification(errorMessage, 'error');
            }
        });
};
