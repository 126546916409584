import React from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import { Flex, FlexColumn } from 'src/components/flex';
import Responsive from 'src/components/responsive';
import { isUpcomingMaintenanceWindowNotification } from 'src/services/api/notifications';

export default function MaintenanceWindowAlert() {
    const { pathname } = useLocation();

    const { notifications: allNotifications } = useAuthenticatedState();

    if (
        pathname === '/landlord/inner/unmatched-listings' ||
        pathname.startsWith('/landlord/inner/messages/from-lead') ||
        pathname.startsWith('/landlord/inner/playbooks')
    ) {
        return null;
    }

    const relevantNotifications = allNotifications
        .filter(isUpcomingMaintenanceWindowNotification);
    if (relevantNotifications.length === 0) {
        return null;
    }

    return (
        <Responsive desktop>
            <Alert
                severity="info"
                color="info"
                sx={{
                    '& .MuiAlert-message': { width: '100%' },
                    '& .MuiAlert-icon': { paddingTop: '10px' },
                }}
            >
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <FlexColumn>
                        <Typography fontWeight="bold" color="info">
                            {relevantNotifications[0].payload.maintenanceWindow.getAlertTitle()}
                        </Typography>
                        <Typography variant="body2" color="info">
                            {relevantNotifications[0].text}
                        </Typography>
                    </FlexColumn>
                </Flex>
            </Alert>
        </Responsive>
    );
}