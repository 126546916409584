import type { IProperty } from 'src/services/api';
import type { IPropertyLabel } from 'src/services/api';
import { orderedColors } from 'src/theme/colors';

export class PropertyUtils {
    static formatFullAddress(property: IProperty) {
        return `${property.street_address}, ${property.city}, ${property.state} ${property.zipcode}`;
    }

    static getLabelColor(allLabels: IPropertyLabel[], labelId: number) {
        const index = allLabels.findIndex((label) => label.id === labelId);
        if (index === -1) {
            return undefined;
        }

        return orderedColors[index % orderedColors.length];
    }
}
