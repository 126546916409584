import React from 'react';
import clsx from 'clsx';
import MuiDialog, { type DialogProps } from '@mui/material/Dialog';

import { Transition } from 'src/components/ModalTransition';

import styles from 'src/components/dialog/Dialog.module.css';

type Props = DialogProps;

export default function Dialog(props: Props) {
    const classes = {
        ...props.classes,
        paper: clsx(styles.paper, props.classes?.paper),
    };
    return (
        <MuiDialog TransitionComponent={Transition} {...props} classes={classes} />
    );
}