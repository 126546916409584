import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterListRounded';

import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

export default function PropertiesFilterButton() {
    const { setFilterOpen } = usePropertiesPageContext();

    const handleClick = () => {
        setFilterOpen(true);
    };

    return (
        <Button
            color="dark"
            startIcon={<FilterIcon />}
            onClick={handleClick}
            data-testid="filter-button"
        >
            <Typography mt={0.25} variant="body2">Filter</Typography>
        </Button>
    );
}
