import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material/utils';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import type { Address, SearchAddress } from 'src/services/location/types';
import { useSearchAddress } from 'src/services/location/hooks/use-search-address';

const addNewAddress: SearchAddress = {
    id: 'new_address',
    displayName: 'Or, add a new address manually',
    streetAddress: '',
    unit: '',
    city: '',
    state: '',
    zipcode: '',
};

interface Props {
    onChange: (value: Address | null) => void;
    error?: boolean;
    helperText?: string;
}

export default function SearchAddressField({
    onChange,
    error,
    helperText,
}: Props) {
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { data: addresses, remove, dataUpdatedAt } = useSearchAddress(searchQuery);

    const options = useMemo(() => {
        if (isLoading) {
            return [];
        }
        let allAddresses: SearchAddress[] = [addNewAddress];
        if (addresses) {
            allAddresses = [addNewAddress, ...addresses];
        }

        return allAddresses.map(({ id, displayName, ...address }) => ({
            id,
            label: displayName,
            value: address,
        }));
    }, [isLoading, addresses]);

    const searchAddress = useCallback(debounce((searchText: string) => {
        if (!searchText) {
            setSearchQuery('');
            remove();
            return;
        }

        if (searchText === addNewAddress.displayName) {
            setSearchQuery('');
            remove();
            return;
        }

        setSearchQuery(searchText);
    }, 2000), [setSearchQuery, remove]);

    const handleInputChange = (_: any, searchText: string, reason: string) => {
        if (reason === 'reset') {
            return;
        }

        if (reason === 'clear') {
            onChange(null);
            setLoading(false);
            remove();
            searchAddress('');
            return;
        }

        setLoading(true);
        remove();
        searchAddress(searchText);
    };

    const handleSelectOption = (newValue: Address | null) => {
        onChange(newValue);
    };

    useEffect(() => {
        setLoading(false);
    }, [dataUpdatedAt]);

    return (
        <Autocomplete
            size="small"
            loading={isLoading}
            onInputChange={handleInputChange}
            onChange={(_, option) => handleSelectOption(option?.value || null)}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Address"
                    placeholder="Search for an address"
                    required
                    error={error}
                    helperText={helperText}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.label}
                </li>
            )}
            filterOptions={_options => _options}
            data-testid="search-address-field"
        />
    );
}
