import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';

import { SortIcon } from 'src/icons';
import { LeadsSortColumn } from 'src/pages/landlord/leads/types/filters';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import Select from 'src/components/input/select/Select';
import { Flex, FlexColumn } from 'src/components/flex';

const sortByOptions = Object.values(LeadsSortColumn).map((value) => {
    return {
        value: value,
        label: value.toString(),
    };
});

export default function SortLeads() {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useLeadsPageContext();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const buttonRef = useRef<HTMLButtonElement>(null);



    return (
        <>
            <Button
                ref={buttonRef}
                color="dark"
                startIcon={<SortIcon />}
                onClick={() => setAnchorEl(buttonRef.current)}
                data-testid="filter-button"
            >
                <Typography mt={0.25} variant="body2">Sort</Typography>
            </Button>

            <Menu
                onClose={() => setAnchorEl(null)}
                open={!!anchorEl}
                anchorEl={anchorEl}
                slotProps={{
                    root: {
                        sx: {
                            '@media (max-width: 1400px)': {
                                p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }
                        }
                    },
                    paper: {
                        sx: {
                            width: 300,
                            maxWidth: '100%',
                            '@media (max-width: 1400px)': {
                                position: 'static', width: 500, maxWidth: '100%'
                            }
                        }
                    }
                }}
            >
                <Flex alignItems="center" p={2}>
                    <Typography>
                        Sort by
                    </Typography>
                </Flex>

                <FlexColumn px={1} py={2} rowGap={2}>
                    <Select
                        label="Sort by"
                        placeholder="Select"
                        options={sortByOptions}
                        value={sortBy}
                        onChange={(value) => setSortBy(value)}
                        fullWidth
                        size="small"
                    />

                    <Select
                        label="Direction"
                        placeholder="Select"
                        options={[
                            { value: 'asc', label: 'Ascending' },
                            { value: 'desc', label: 'Descending' },
                        ]}
                        value={sortDirection}
                        onChange={(value) => setSortDirection(value)}
                        fullWidth
                        size="small"
                    />
                </FlexColumn>

                <Divider />

                <Flex width="100%" p={1} justifyContent="end">
                    <Button
                        variant="outlined"
                        color="dark"
                        onClick={() => setAnchorEl(null)}
                        size="small"
                    >
                        Close
                    </Button>
                </Flex>
            </Menu>
        </>
    );
}