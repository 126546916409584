
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import { redirectToLandlordLogin } from 'src/api';
import { LANDLORD_HOME_PATH } from 'src/routes';
import { Flex, FlexColumn } from 'src/components/flex';
import Link from 'src/components/link/Link';
import NavigationLink from 'src/components/layout/landlord/NavigationLink';
import { LogOutIcon } from 'src/icons';
import { links } from 'src/components/layout/landlord/links';

import 'src/components/layout/landlord/LandlordLayout.css';

type Props = {
    closeNavigationDrawer: () => void;
}

export default function MobileNavigationDrawer({
    closeNavigationDrawer
}: Props) {
    const handleLogout = () => {
        closeNavigationDrawer();
        redirectToLandlordLogin();
    };

    return (
        <Drawer
            open
            variant="temporary"
            onClose={closeNavigationDrawer}
            sx={{ '& .MuiDrawer-paper': { width: '75%', maxWidth: 300 }, }}
        >
            <Box height="100vh">
                <FlexColumn height="100%" justifyContent="space-between" alignItems="stretch">
                    <FlexColumn alignItems="stretch">
                        <Box justifyContent="center" alignItems="center">
                            <Link href={LANDLORD_HOME_PATH} onClick={closeNavigationDrawer}>
                                <Flex height={60} p={1} pl={2} alignItems="center">
                                    <img
                                        src="/img/reffie-logos/SantaHat_Logo.png"
                                        alt="reffie-logo"
                                        height="40px"
                                    />
                                    <Typography color="primary">
                                        Reffie
                                    </Typography>
                                </Flex>
                            </Link>
                        </Box>

                        <List>
                            {links.map((link) => (
                                <NavigationLink
                                    key={link.text}
                                    text={link.text}
                                    icon={link.icon}
                                    link={link.link}
                                    onClick={closeNavigationDrawer}
                                />
                            ))}
                        </List>
                    </FlexColumn>

                    <List>
                        <NavigationLink
                            text="Logout"
                            icon={<LogOutIcon />}
                            onClick={handleLogout}
                        />
                    </List>
                </FlexColumn>
            </Box>
        </Drawer>

    );
}
