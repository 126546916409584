import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownwardRounded';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';

import { LabelType } from 'src/services/api/labels';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import Responsive from 'src/components/responsive/Responsive';
import { FlexColumn } from 'src/components/flex';
import Page from 'src/components/page/PageV2';
import Kbd from 'src/components/kbd/Kbd';
import LeadSidebar from 'src/components/lead-sidebar';
import LeadsPageProvider from 'src/pages/landlord/leads/provider';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import LeadsHeader from 'src/pages/landlord/leads/components/header';
import AllLeadsSection from 'src/pages/landlord/leads/sections/AllLeadsSection';
import ArchivedLeadsStatus from 'src/pages/landlord/leads/sections/ArchivedLeadsStatus';
import EmptyLeadsPage from 'src/pages/landlord/leads/EmptyLeadsPage';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal';
import EditLabelsModal from 'src/components/edit-labels-modal';

import styles from 'src/pages/landlord/leads/LeadsPage.module.css';

function LeadsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedLeadId = searchParams.get('uuid');

    const {
        allLeads,
        properties,
        showings,
        isCreateLeadModalOpen,
        setIsCreateLeadModalOpen,
        isEditLeadLabelsModalOpen,
        setIsEditLeadLabelsModalOpen,
    } = useLeadsPageContext();

    const selectedLead = allLeads.find((lead) => lead.uuid === selectedLeadId);
    const selectedProperty = properties?.find((property) => property.id === selectedLead?.property_id);

    const [isHotkeysModalOpen, setIsHotkeysModalOpen] = useState(false);

    const handleCloseSidebar = () => {
        setSearchParams({});
    };

    const navigate = useNavigate();
    const leadUuid = searchParams.get('uuid') as string;

    const currentleadDetailsIndex = allLeads.findIndex((lead) => lead.uuid === leadUuid);
    const previousLeadUuid = allLeads[currentleadDetailsIndex - 1]?.uuid;
    const nextLeadUuid = allLeads[currentleadDetailsIndex + 1]?.uuid;

    const previousLeadDetailsPath = `/landlord/inner/leads/all?uuid=${previousLeadUuid}`;
    const nextLeadDetailsPath = `/landlord/inner/leads/all?uuid=${nextLeadUuid}`;

    const navigateToPreviousLead = () => {
        if (previousLeadUuid) {
            navigate(previousLeadDetailsPath);
        }
    };
    const navigateToNextLead = () => {
        if (nextLeadUuid) {
            navigate(nextLeadDetailsPath);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const isUserTyping = document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA';
            if (isUserTyping || selectedLead === undefined) {
                return;
            }

            if (['ArrowUp', 'ArrowDown', 'Escape', '?'].includes(event.key)) {
                analyticsClient.track(AnalyticsEvent.SHORTCUT_KEY_USED, {
                    page: 'Leads',
                    key: event.key,
                });
            }


            switch (event.key) {
                case 'ArrowUp':
                    event.preventDefault();
                    navigateToPreviousLead();
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    navigateToNextLead();
                    break;
                case 'Escape':
                    handleCloseSidebar();
                    break;
                case '?':
                    setIsHotkeysModalOpen(true);
                    break;
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [navigateToPreviousLead, navigateToNextLead]);

    if (allLeads.length === 0) {
        return (
            <EmptyLeadsPage />
        );
    }

    return (
        <>
            <Page
                className={clsx(
                    styles.pageWithSidebar,
                    selectedLead && styles.pageWithOpenSidebar
                )}
                title="Reffie | Leads"
            >
                <LeadsHeader />

                <FlexColumn rowGap={2}>
                    <AllLeadsSection />
                    <ArchivedLeadsStatus />
                </FlexColumn>


                <Responsive desktop>
                    <Paper className={clsx(styles.sidebar, !!selectedLead && styles.sidebarUncollapsed)} square elevation={0}>
                        {selectedLead && (
                            <LeadSidebar
                                onClose={handleCloseSidebar}
                                lead={selectedLead}
                                property={selectedProperty}
                                showings={showings || []}
                            />
                        )}
                    </Paper>
                </Responsive>

                {/* temporary way to display hotkey shortcuts */}
                {isHotkeysModalOpen &&
                    <Dialog open={isHotkeysModalOpen} onClose={() => setIsHotkeysModalOpen(false)}>
                        <DialogTitle>
                            <div className={styles.hotkeysModalHeading}>
                                <Typography variant="h2">
                                    Hotkeys
                                </Typography>
                                <IconButton onClick={() => setIsHotkeysModalOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent className={styles.hotkeysModal}>
                            <div className={styles.hotkeysModalContent}>
                                <p><Kbd><ArrowUpwardIcon /></Kbd> Previous</p>
                                <p><Kbd><ArrowDownwardIcon /></Kbd> Next</p>
                                <p><Kbd>Esc</Kbd> Close Sidebar</p>
                            </div>
                        </DialogContent>
                    </Dialog>
                }
            </Page>

            {isCreateLeadModalOpen && (
                <CreateOrUpdateLeadModal
                    onClose={() => setIsCreateLeadModalOpen(false)}
                />
            )}

            {isEditLeadLabelsModalOpen && (
                <EditLabelsModal
                    type={LabelType.LEAD}
                    onClose={() => setIsEditLeadLabelsModalOpen(false)}
                />
            )}
        </>
    );
}

export default function LeadsPageWithProvider() {
    return (
        <LeadsPageProvider>
            <LeadsPage />
        </LeadsPageProvider>
    );
}
