import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Linkify from 'react-linkify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Alert from '@mui/material/Alert';

import type { IMessage, ICreateShowingRequest } from 'src/services/api';
import { EMessageChannel, EMessageDirection } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import ReffieTextTooltip from 'src/components/ReffieTextTooltip';
import MessageHeader from 'src/pages/landlord/leads/messages/components/messages-container/MessageHeader';
import MessageSentStatusIcon from 'src/pages/landlord/leads/messages/components/messages-container/MessageSentStatusIcon';
import OpenHouseAutomationModal from 'src/pages/landlord/leads/messages/components/messages-container/OpenHouseAutomationModal';
import { type IRegion, parseMessageLineIntoRegions } from 'src/pages/landlord/leads/messages/components/messages-container/utils/parse-message-line-into-regions';
import CreateOrUpdateTourModal from 'src/components/create-or-update-tour-modal/CreateOrUpdateTourModal';
import { PlaybookUUID } from 'src/playbooks/playbook-templates';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';

import styles from 'src/pages/landlord/leads/messages/components/messages-container/Message.module.css';

dayjs.extend(relativeTime);

interface Props {
    message: IMessage,
}

/**
 * Display a single message
 */
export default function Message({
    message,
}: Props) {
    const navigate = useNavigate();

    const { loggedInUser, lead, showings, messages } = useLeadMessagesPageContext();

    /**
     * When we have an existin upcoming showing, by default we will edit that showing instead of creating a new one
     */
    const upcomingShowingsForThisLead = showings.filter((showing: IShowing) => {
        return (showing.lead_uuid === lead.uuid &&
            showing.property_id === lead.property_id &&
            showing.start_time > new Date().toISOString());
    });
    const upcomingShowingId = upcomingShowingsForThisLead.length > 0 ? upcomingShowingsForThisLead[0].id : -1;
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [showingAutomationTarget, setShowingAutomationTarget] = useState<ICreateShowingRequest>();

    const handleCloseOpenHouseAutomationModal = (goToAutomations: boolean) => {
        if (!showingAutomationTarget) {
            throw new Error('showingAutomationTarget is null');
        }
        const propertyId = showingAutomationTarget.property_id;
        setShowingAutomationTarget(undefined);
        if (goToAutomations) {
            const playbookUuid = PlaybookUUID.ManualSendEmailNotBookedShowing;
            const path = '/landlord/inner/playbooks/new';
            const url = `${path}?template_uuid=${playbookUuid}&property_id=${propertyId}`;
            navigate(url);
        }
    };

    let className;
    if (message.direction === 'out') {
        className = [styles.messageFromAgent];
    } else {
        className = [styles.messageFromLead];
    }

    const messageLines = message.contents.split('\n').map((line: string, i: number) => {
        const regions = parseMessageLineIntoRegions(line, i);
        const spans = regions.map((region: IRegion) => {
            if (region.parsedDate) {
                return <ReffieTextTooltip key={`tooltip-${region.key}`} title="Click to schedule a showing">
                    <span key={region.key} className={styles.nlpTagDate}
                        role="button"
                        onClick={() => setSelectedDate(region.parsedDate?.toISOString() || null)}
                    >{region.text}</span>
                </ReffieTextTooltip>;
            } else {
                return <span key={region.key}>{region.text}</span>;
            }
        });
        return <div key={`line-${i}`} className={styles.messageLine}>{spans}</div>;
    });

    const firstRequestForApplicationMessage = messages.find((_message) => {
        return _message.is_rental_application_request;
    });
    const isFirstRequestForApplication = message.uuid === firstRequestForApplicationMessage?.uuid;

    const handleCloseShowingModal = () => {
        setSelectedDate(null);
        setShowingAutomationTarget(undefined);
    };

    return (
        <>
            <div className={styles.messageBlock + ' ' + className}>
                {showingAutomationTarget && (
                    <OpenHouseAutomationModal
                        open={showingAutomationTarget !== null}
                        onClose={handleCloseOpenHouseAutomationModal}
                    />
                )}

                {selectedDate && (
                    <CreateOrUpdateTourModal
                        open={selectedDate !== null}
                        onClose={handleCloseShowingModal}
                        selectedLeadUuid={lead.uuid}
                        landlordUserId={loggedInUser.id}
                        leads={[lead]}
                        initDateTime={selectedDate}
                        initPropertyId={lead.property_id}
                        showingId={upcomingShowingId === -1 ? undefined : upcomingShowingId}
                        sourceMessageUuid={message.uuid}
                        onCreated={setShowingAutomationTarget}
                    />
                )}
                <div className={styles.messageContentsOuter}>
                    <MessageHeader message={message} />
                    <div className={styles.messageBody}>
                        <Linkify>
                            {messageLines}
                        </Linkify>
                    </div>
                    <div className={styles.messageMetadata}>
                        <div className={styles.messageStatus}>
                            {(message.direction === EMessageDirection.OUT && message.channel === EMessageChannel.SMS) &&
                                <MessageSentStatusIcon message={message} />}
                        </div>
                    </div>
                </div>
            </div>

            {isFirstRequestForApplication && (
                <Alert severity="info">
                    {lead.name} has requested a rental application.
                </Alert>
            )}
        </>
    );
}
