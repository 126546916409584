
import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { apiClient } from 'src/services/api';
import { queryKeys } from 'src/services/api/constants';

const PATH = 'api/landlord/notifications';

type ISnoozeNotificationRequest = {
    snooze_until: string;
};

export const useSnoozeNotification = (id: number) => {
    const queryClient = useQueryClient();
    const path = `${PATH}/${id}/snooze`;

    return useMutation<
        void,
        IStandardErrorResponse,
        ISnoozeNotificationRequest
        >(
            [queryKeys.notifications],
            async (payload) => {
                await apiClient(path, { method: 'put', json: payload });
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(queryKeys.notifications);
                    queryClient.invalidateQueries([queryKeys.notifications, id]);
                }
            }
        );
};
