import type { IProperty } from 'src/services/api';

const getPropertySearchTokens = (properties: IProperty[]) => {
    return properties.reduce<[IProperty, string[]][]>((acc, property) => {
        const id = String(property.id);
        const name = property.name || '';
        const address = [property.street_address, property.unit, property.city, property.state, property.zipcode].filter(Boolean) as string[];
        const type = property.type || '';
        const squareFeet = property.square_feet ? String(property.square_feet) : '';
        const rent = property.rent ? String(property.rent) : '';
        const tourSchedulingLink = property.showing_scheduling_link?.link || '';
        const applicationLink = property.application_link?.link || '';
        const labels = property.labels.map((label) => label.name);

        const tokens = [
            id,
            name,
            ...address,
            type,
            squareFeet,
            rent,
            tourSchedulingLink,
            applicationLink,
            ...labels,
        ].filter(Boolean).map((token) => token.toLowerCase());

        acc.push([property, tokens]);

        return acc;
    }, []);
};

export const searchProperties = (
    properties: IProperty[] | undefined,
    searchQuery: string,
) => {
    if (!properties) {
        return [];
    }

    if (!searchQuery) {
        return properties;
    }

    const propertySearchTokens = getPropertySearchTokens(properties);

    const filteredProperties: IProperty[] = [];

    const searchQueryTokens = searchQuery
        .toLowerCase()
        .split(' ')
        .map((token) => token.trim())
        .filter(Boolean);

    for (const [property, propertyTokens] of propertySearchTokens) {
        let foundAllSearchTokens = true;

        for (const searchToken of searchQueryTokens) {
            let foundSearchToken = false;

            for (const propertyToken of propertyTokens) {
                if (propertyToken.includes(searchToken)) {
                    foundSearchToken = true;
                    break;
                }
            }

            if (!foundSearchToken) {
                foundAllSearchTokens = false;
                break;
            }
        }

        if (foundAllSearchTokens) {
            filteredProperties.push(property);
        }
    }

    return filteredProperties;
};