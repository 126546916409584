import type { IConversation } from 'src/api/landlord-messages-api';
import type { ILead } from 'src/services/api';

const getConversationSearchTokens = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[]
) => {
    return conversations.reduce<[IConversation, string[]][]>((acc, conversation) => {
        const lead = leadsByUuid.get(conversation.lead_uuid);
        if (!lead) { return acc; }

        const leadUuid = conversation.lead_uuid;
        const leadEmail = lead.email || '';
        const leadName = lead.name || '';
        const leadPhoneNumber = lead.phone_number?.toLowerCase().split('-').join('') || '';
        const leadNotes = lead.notes?.contents || '';

        const tokens = [
            leadUuid,
            leadEmail,
            leadName,
            leadPhoneNumber,
            leadNotes,
        ].filter(Boolean).map((token) => token.toLowerCase());

        acc.push([conversation, tokens]);

        return acc;
    }, []);
};

export const searchConversations = (
    leadsByUuid: Map<string, ILead>,
    conversations: IConversation[],
    searchQuery: string,
) => {
    const conversationSearchTokens = getConversationSearchTokens(
        leadsByUuid,
        conversations
    );

    if (!searchQuery) {
        return conversations;
    }

    const filteredConversations: IConversation[] = [];

    const searchQueryTokens = searchQuery
        .toLowerCase()
        .split(' ')
        .map((token) => token.trim())
        .filter(Boolean);

    for (const [conversation, conversationTokens] of conversationSearchTokens) {
        let foundAllSearchTokens = true;

        for (const searchToken of searchQueryTokens) {
            let foundSearchToken = false;

            for (const conversationToken of conversationTokens) {
                if (conversationToken.includes(searchToken)) {
                    foundSearchToken = true;
                    break;
                }
            }

            if (!foundSearchToken) {
                foundAllSearchTokens = false;
                break;
            }
        }

        if (foundAllSearchTokens) {
            filteredConversations.push(conversation);
        }
    }

    return filteredConversations;
};