import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';
import { GET_PROPERTIES_PATH } from 'src/services/api/properties/constants';
import type {
    IProperty,
    IUpdatePropertyRequest,
    IUpdatePropertyResponse
} from 'src/services/api/properties/types';

type IUpdatePropertiesRequest = Array<IUpdatePropertyRequest & { id: number }>;

export const useUpdateProperties = () => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<
        IProperty[],
        IStandardErrorResponse,
        IUpdatePropertiesRequest
    >(
        async (payload) => {
            const res = await Promise.allSettled(payload.map((property) => {
                const path = `${GET_PROPERTIES_PATH}/${property.id}`;
                return apiClient(path, {
                    method: 'patch',
                    json: { property },
                }).json<IUpdatePropertyResponse>();
            }));

            const oldProperties = queryClient.getQueryData<IProperty[]>(queryKeys.properties) || [];

            const updatedProperties = oldProperties.map((property) => {
                const updatedProperty = res
                    .find((o) => o.status === 'fulfilled' && o.value.property.id === property.id);

                if (!updatedProperty || updatedProperty.status === 'rejected') {
                    return property;
                }

                return updatedProperty.value.property;
            });

            queryClient.setQueryData<IProperty[]>(queryKeys.properties, updatedProperties);
            for (const property of updatedProperties) {
                queryClient.setQueryData<IProperty>([queryKeys.properties, property.id], property);
            }

            const errors = res.filter((r) => r.status === 'rejected');
            if (errors.length > 0) {
                throw errors.map(o => o.reason);
            } else {
                addNotification('Properties updated', 'success');
            }

            return updatedProperties;
        }, {
            onError: (errors) => {
                const errorMessage = getApiErrorMessage(errors) || 'Failed to update properties';
                addNotification(errorMessage, 'error');
            }
        });
};
