import React, { useState, useMemo } from 'react';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DownloadIcon from '@mui/icons-material/DownloadRounded';

import { useNotifications } from 'src/notifications';
import { StringFormat, formatString } from 'src/utils/format-string';
import { useCreateProperties } from 'src/services/api';
import { Flex, FlexColumn } from 'src/components/flex';
import ButtonLink from 'src/components/button-link/ButtonLink';
import FileUpload from 'src/components/input/file-upload/FileUpload';
import SimpleTable from 'src/components/table/simple-table/SimpleTable';
import { type ParsedCsv, parseCsvFromFile } from 'src/utils/csv';
import {
    type PropertyCsvRow,
    parseProperties,
    importPropertiesFields,
    getUnknownPropertyFields
} from 'src/components/bulk-import/parse-properties';

type Props = {
    info?: string;
    isOpened: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

export default function ImportProperties({
    info,
    isOpened,
    onClose,
    onSuccess
}: Props) {
    const { addNotification } = useNotifications();

    const { mutateAsync: createProperties, isLoading: isCreatingProperties } = useCreateProperties();

    const [parsedFile, setParsedFile] = useState<ParsedCsv<PropertyCsvRow>>();
    const [parsedFileCreatedAt, setParsedFileCreatedAt] = useState<string>();
    const [newProperties, setNewProperties] = useState<ReturnType<typeof parseProperties>>([]);
    const [failedPropertyNames, setFailedPropertyNames] = useState<string[]>([]);
    const [numImportedProperties, setNumImportedProperties] = useState<number>(0);
    const [unknownFields, setUnknownFields] = useState<string[]>([]);

    const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setFailedPropertyNames([]);
        setNumImportedProperties(0);

        try {
            if (e.target.files) {
                const newFile = e.target.files[0];
                const newParsedFile = await parseCsvFromFile<PropertyCsvRow>(newFile);

                setParsedFile(newParsedFile);
                setParsedFileCreatedAt(new Date().toISOString());
                setNewProperties(parseProperties(newParsedFile));

                setUnknownFields(getUnknownPropertyFields(newParsedFile));
            }
        } catch (error) {
            if (error instanceof Error) {
                addNotification(error.message, 'error');
            } else {
                addNotification('An error occurred while parsing the file', 'error');
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const properties = newProperties
                .filter(o => !o.errors?.length)
                .map(o => o.property);

            const {
                num_added: numAddedProperties,
                num_failed: numFailedProperties,
                failed_property_names: _failedPropertyNames,
            } = await createProperties({ properties });

            if (numAddedProperties > 0) {
                const nSuccess = numAddedProperties;
                onSuccess?.();
                setNumImportedProperties(nSuccess);

                if (numFailedProperties === 0) {
                    addNotification(`${nSuccess} ${pluralize('property', nSuccess)} imported successfully`, 'success');

                    setParsedFile(undefined);
                    setNewProperties([]);
                } else {
                    addNotification(
                        `Only ${nSuccess}/${newProperties.length} properties imported successfully`,
                        'warning'
                    );
                    setParsedFile(undefined);
                    setParsedFileCreatedAt(undefined);
                    setNewProperties([]);
                    setFailedPropertyNames(_failedPropertyNames);
                }
            } else {
                addNotification('Failed to import any properties', 'error');
            }
        } catch (error) {
            addNotification('Failed to import any properties', 'error');
        }
    };

    const handleRemoveFile = () => {
        setParsedFile(undefined);
        setNewProperties([]);
        setUnknownFields([]);
    };

    const previewTableData = useMemo(() => {
        if (!newProperties.length || !parsedFile) {
            return;
        }

        const rows = parsedFile.body.map((row, index) => {
            const errors = newProperties[index].errors;

            return importPropertiesFields.map((col) => {
                const label = row[col as keyof PropertyCsvRow] as string;
                const hasError = errors?.includes(col) ?? false;

                return {
                    label,
                    hasError,
                };
            });
        });

        const columns = importPropertiesFields.map(column => {
            return formatString(column, StringFormat.SnakeCase, StringFormat.SentenceCase);
        });

        const errors = newProperties
            .filter((o) => !!o.errors?.length)
            .map((o) => o.errors?.map(error => formatString(error, StringFormat.SnakeCase, StringFormat.SentenceCase)))
            .map((o, i) => [i, o] as [number, string[]]);

        return {
            columns,
            rows,
            errors,
        };
    }, [parsedFile]);

    const numValidProperties = newProperties.filter(o => !o.errors?.length).length;

    return (
        <Dialog open={isOpened} fullScreen onClose={onClose}>
            <DialogTitle textAlign="center">
                Import Properties
            </DialogTitle>

            <DialogContent>
                <FlexColumn rowGap={2}>
                    {!!info && <Alert severity="info">{info}</Alert>}
                    <FlexColumn>
                        <Typography>
                            Recommended for 5+ units or properties.
                        </Typography>
                        <Typography>
                            Download the provided CSV template, fill it out with the necessary property details, and then upload the completed CSV file back to Reffie to get started.
                        </Typography>
                    </FlexColumn>
                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel>
                                Download the CSV template
                            </StepLabel>
                            <StepContent>
                                <ButtonLink
                                    href="/csv-templates/property-import-template.csv"
                                    variant="contained"
                                    startIcon={<DownloadIcon />}
                                    download
                                    data-testid="property-csv-template-download-button"
                                >
                                    Download
                                </ButtonLink>
                            </StepContent>
                        </Step>

                        <Step active>
                            <StepLabel>
                                Upload the filled-in CSV template
                            </StepLabel>
                            <StepContent>
                                <Flex>
                                    <FileUpload
                                        key={parsedFileCreatedAt}
                                        onChange={handleUploadFile}
                                        data-testid="property-csv-upload-button"
                                    >
                                        {parsedFile ? 'Change file' : 'Upload'}
                                    </FileUpload>
                                    {parsedFile && (
                                        <Button
                                            variant="outlined"
                                            onClick={handleRemoveFile}
                                            data-testid="property-csv-remove-file-button"
                                        >
                                            Remove file
                                        </Button>
                                    )}
                                </Flex>
                            </StepContent>
                        </Step>

                        <Step active>
                            <StepLabel>
                                Preview the properties to be imported
                            </StepLabel>
                            <StepContent>
                                {unknownFields.length > 0 && (
                                    <Alert severity="warning" sx={{ mb: 2 }}>
                                        The following fields are not recognized: {unknownFields.join(', ')}
                                    </Alert>
                                )}

                                {previewTableData && (
                                    <SimpleTable key={parsedFileCreatedAt} {...previewTableData} />
                                )}

                                {failedPropertyNames.length > 0 && (
                                    <FlexColumn mt={2} rowGap={4}>
                                        <Alert severity="success">
                                            {numImportedProperties} {pluralize('property', numImportedProperties)} imported successfully.
                                        </Alert>

                                        <FlexColumn>
                                            <Typography>
                                                Failed to import the following properties
                                            </Typography>
                                            <SimpleTable
                                                columns={['Property Name']}
                                                rows={failedPropertyNames.map(name => [{ label: name, hasError: false }])}
                                            />
                                        </FlexColumn>
                                    </FlexColumn>
                                )}
                            </StepContent>
                        </Step>
                    </Stepper>
                </FlexColumn>
            </DialogContent>

            <DialogActions>
                {previewTableData && (
                    <Typography mr={2}>
                        {numValidProperties}/{newProperties.length} properties can be imported.
                    </Typography>
                )}
                <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={isCreatingProperties}
                    data-testid="property-import-cancel-button"
                >
                    Cancel
                </Button>
                <LoadingButton
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!numValidProperties}
                    loading={isCreatingProperties}
                    data-testid="property-import-submit-button"
                >
                    Import {numValidProperties ?
                        `${numValidProperties} ${pluralize('property', numValidProperties)}` : ''}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
