import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import VerticalDotsIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import FilterIcon from '@mui/icons-material/FilterListRounded';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';

import { Flex, FlexColumn } from 'src/components/flex';
import { ImportLeads } from 'src/components/bulk-import';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import Search from 'src/pages/landlord/leads/components/header/LeadsSearch';
import LeadsFilterButton from 'src/pages/landlord/leads/components/header/LeadsFilterButton';
import SelectGrouping from 'src/pages/landlord/leads/components/header/SelectGrouping';
import FilterStatus from 'src/pages/landlord/leads/components/header/LeadsFilterStatus';
import SortLeads from 'src/pages/landlord/leads/components/header/SortLeads';

export default function LeadsHeader() {
    const {
        setIsCreateLeadModalOpen,
        setIsEditLeadLabelsModalOpen,
        setFilterOpen,
    } = useLeadsPageContext();

    const [isImportLeadsModalOpen, setIsImportLeadsModalOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    return (
        <>
            <FlexColumn p={2}>
                <Flex
                    justifyContent="space-between"
                    mobileFlexDirection="column"
                >
                    <Flex width="100%" justifyContent="space-between" alignItems="center">
                        <Flex width="100%" mobileFlexDirection="column">
                            <Flex>
                                <Search />
                                <IconButton
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                    edge="end"
                                    sx={{ '@media (min-width: 901px)': { display: 'none' } }}
                                >
                                    <VerticalDotsIcon />
                                </IconButton>
                            </Flex>

                            <Flex width="100%" sx={{ '@media (min-width: 901px)': { width: 150 } }}>
                                <SelectGrouping />
                            </Flex>
                            <Flex sx={{ '@media (max-width: 1400px)': { display: 'none' } }}>
                                <LeadsFilterButton />
                                <SortLeads />
                            </Flex>
                        </Flex>

                        <IconButton
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            edge="end"
                            sx={{
                                '@media (max-width: 900px)': { display: 'none' },
                                '@media (min-width: 1401px)': { display: 'none' },
                            }}
                        >
                            <VerticalDotsIcon />
                        </IconButton>
                    </Flex>

                    <Flex
                        width="100%"
                        justifyContent="flex-end"
                        sx={{ '@media (max-width: 1400px)': { display: 'none' } }}
                    >
                        <Flex>
                            <Button
                                variant="contained"
                                onClick={() => setIsCreateLeadModalOpen(true)}
                                startIcon={<AddIcon />}
                                data-testid="new-lead-button"
                            >
                                New Lead
                            </Button>

                            <Button
                                variant="outlined"
                                onClick={() => setIsImportLeadsModalOpen(true)}
                                startIcon={<FileUploadIcon />}
                                sx={{ bgcolor: 'white' }}
                                data-testid="import-leads-button"
                            >
                                Import Leads
                            </Button>

                            <Button
                                variant="outlined"
                                onClick={() => setIsEditLeadLabelsModalOpen(true)}
                                startIcon={<LabelIcon />}
                                sx={{ bgcolor: 'white' }}
                                data-testid="lead-labels-button"
                            >
                                Labels
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiChip-root': { maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis' },
                        '& .MuiSvgIcon-root': { ml: 1, fontSize: 20 }
                    }}
                >
                    <FilterStatus />
                </Flex>
            </FlexColumn>

            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                onClick={() => setAnchorEl(undefined)}
            >
                <MenuItem onClick={() => setIsCreateLeadModalOpen(true)}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>New Lead</Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => setIsEditLeadLabelsModalOpen(true)}>
                    <ListItemIcon>
                        <LabelIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>Labels</Typography>
                    </ListItemText>
                </MenuItem>

                <MenuItem onClick={() => setFilterOpen(true)}>
                    <ListItemIcon>
                        <FilterIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>Filter</Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>

            {isImportLeadsModalOpen && (
                <ImportLeads
                    isOpened={isImportLeadsModalOpen}
                    onClose={() => setIsImportLeadsModalOpen(false)}
                />
            )}
        </>
    );
}
