import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import type { ILead } from 'src/services/api';
import { normalizeSource } from 'src/pages/landlord/analytics/utils';
import ExpandableItem from 'src/components/lead-sidebar/LeadSidebarExpandableItem';
import { FlexColumn } from 'src/components/flex';
import { CopyIcon } from 'src/icons';
import { copyToClipboard } from 'src/components/copy-text-button/utils';

dayjs.extend(relativeTime);

const normalizeIngestionMethod = (ingestionMethod: string) => {
    if (ingestionMethod === 'fb_messenger') {
        return 'Facebook Marketplace';
    }
    const _ingestionMethod = ingestionMethod.replace(/-/g, ' ') || 'Unknown';
    return capitalize(_ingestionMethod);
};

export default function LeadInfo({ lead }: { lead: ILead }) {
    const [isCopied, setCopied] = useState(false);

    const ingestionMethod = normalizeIngestionMethod(lead.ingestion_method);
    const ingestionSource = normalizeSource(lead.ingestion_source);
    const timeAdded = capitalize(dayjs(lead.inserted_at).fromNow());

    const displayedUUID = lead.uuid.slice(0, 6) + '...';

    const handleCopy = async (text: string) => {
        const isSuccess = await copyToClipboard(text);
        if (isSuccess) {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };

    let title = 'Copy to clipboard';
    if (isCopied) {
        title = 'Copied';
    }

    return (
        <ExpandableItem title="Lead Info">
            <FlexColumn gap={2}>
                <FlexColumn gap={0}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>Ingestion Method</Typography>
                    <Typography variant="body2">{ingestionMethod}</Typography>
                </FlexColumn>
                <FlexColumn gap={0}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>Ingestion Source</Typography>
                    <Typography variant="body2">{ingestionSource}</Typography>
                </FlexColumn>
                <FlexColumn gap={0}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>Time Added</Typography>
                    <Typography variant="body2">{timeAdded}</Typography>
                </FlexColumn>
                <FlexColumn gap={0}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>Reffie ID</Typography>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        {displayedUUID}
                        <Tooltip title={title}>
                            <IconButton onClick={() => handleCopy(lead.uuid)}>
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </FlexColumn>
            </FlexColumn>
        </ExpandableItem>
    );
}