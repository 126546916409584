import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { CalendarIcon, LeadsIcon, PropertiesIcon, UsersIcon } from 'src/icons';
import { StringFormat, formatString } from 'src/utils/format-string';
import { LEAD_INGESTION_METHOD_OPTIONS } from 'src/services/api/leads/constants';
import { leadDateFilterLabels } from 'src/pages/landlord/leads/provider';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import Chip from 'src/components/chip/Chip';
import { useLeadLabels } from 'src/services/api';

export default function LeadsFilterStatus() {
    const { data: leadLabels } = useLeadLabels();

    const {
        teamMembers,
        properties,
        propertyLabels,
        filters,
        setFilters
    } = useLeadsPageContext();

    const filteringByAssignees = !!filters.assigneeIds.length;
    const assigneeFilterLabel = `Assignee is ${filters.assigneeIds
        .map(assigneeId => `"${teamMembers?.find(member => member.id === Number(assigneeId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByIngestionSources = !!filters.ingestionSources.length;
    const ingestionSourcesFilterLabel = `Ingestion source is ${filters.ingestionSources
        .map(source => `"${source}"`)
        .join(' or ')}`;

    const filteringByIngestionMethods = !!filters.ingestionMethods.length;
    const ingestionMethodsFilterLabel = useMemo(() => {
        return `Ingestion method is ${filters.ingestionMethods
            .map(method => `"${LEAD_INGESTION_METHOD_OPTIONS.find(option => option.value === method)?.label || method}"`)
            .join(' or ')}`;
    }, [filters.ingestionMethods]);

    const filteringByDateRange = !!filters.dateRange;
    const dateRangeFilterLabel = filters.dateRange && leadDateFilterLabels[filters.dateRange];

    const filteringByLeadStages = !!filters.leadStages.length;
    const leadStagesFilterLabel = `Lead stage is ${filters.leadStages
        .map(stage => `"${formatString(stage, StringFormat.SnakeCase, StringFormat.SentenceCase)}"`)
        .join(' or ')}`;

    const filteringByLeadLabels = !!filters.leadLabelIds.length;
    const leadLabelsFilterLabel = `Lead label is ${filters.leadLabelIds
        .map(labelId => `"${leadLabels?.find(label => label.id === Number(labelId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByProperty = !!filters.propertyIds.length;
    const propertyFilterLabel = `Property is ${filters.propertyIds
        .map(propertyId => `"${properties?.find(property => property.id === Number(propertyId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    const filteringByPropertyLabels = !!filters.propertyLabelIds.length;
    const propertyLabelsFilterLabel = `Property label is ${filters.propertyLabelIds
        .map(labelId => `"${propertyLabels?.find(label => label.id === Number(labelId))?.name || ''}"`)
        .filter(Boolean)
        .join(' or ')}`;

    return (
        <>
            {filteringByAssignees && (
                <Tooltip title={assigneeFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={assigneeFilterLabel}
                            icon={<UsersIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, assigneeIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByIngestionSources && (
                <Tooltip title={ingestionSourcesFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={ingestionSourcesFilterLabel}
                            icon={<UsersIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, ingestionSources: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByIngestionMethods && (
                <Tooltip title={ingestionMethodsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={ingestionMethodsFilterLabel}
                            icon={<UsersIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, ingestionMethods: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByDateRange && (
                <Tooltip title={dateRangeFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={dateRangeFilterLabel}
                            icon={<CalendarIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, dateRange: undefined }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByLeadStages && (
                <Tooltip title={leadStagesFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={leadStagesFilterLabel}
                            icon={<LeadsIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, leadStages: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filteringByLeadLabels && (
                <Tooltip title={leadLabelsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={leadLabelsFilterLabel}
                            icon={<LeadsIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, leadLabelIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}

            {filters.hasNoProperty && (
                <Chip
                    variant="outlined"
                    label="Lead has no property"
                    icon={<PropertiesIcon />}
                    onDelete={() => setFilters(prev => ({ ...prev, hasNoProperty: false }))}
                />
            )}

            {filteringByProperty && (
                <Tooltip title={propertyFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={propertyFilterLabel}
                            icon={<PropertiesIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, propertyIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}



            {filteringByPropertyLabels && (
                <Tooltip title={propertyLabelsFilterLabel}>
                    <div>
                        <Chip
                            variant="outlined"
                            label={propertyLabelsFilterLabel}
                            icon={<PropertiesIcon />}
                            onDelete={() => setFilters(prev => ({ ...prev, propertyLabelIds: [] }))}
                        />
                    </div>
                </Tooltip>
            )}
        </>
    );

    return null;
}
