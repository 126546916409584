import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const LoadingBar = styled(LinearProgress)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
}));

export default LoadingBar;