import dayjs from 'dayjs';

import type { ILead, LeadStage } from 'src/services/api';
import { LEAD_STAGES } from 'src/services/api';
import { LeadsSortColumn } from 'src/pages/landlord/leads/types/filters';

type TStageNumMap = Record<LeadStage, number>;

const getStageNumberMap = (): TStageNumMap => {
    const stageNumberMap = LEAD_STAGES.reduce((acc, stage, index) => {
        acc[stage] = index;
        return acc;
    }, {} as TStageNumMap);

    return stageNumberMap;
};

const getNameComparator = (a: ILead, b: ILead, sortDirection: 'asc' | 'desc') => {
    const aName = a.name?.toLowerCase() || '';
    const bName = b.name?.toLowerCase() || '';
    return sortDirection === 'asc' ? aName.localeCompare(bName) : bName.localeCompare(aName);
};

const getSortFunction = (sortBy: LeadsSortColumn, sortDirection: 'asc' | 'desc') => {
    switch (sortBy) {
        case LeadsSortColumn.INSERTED_AT:
            // newest first
            return (a: ILead, b: ILead) => {
                const v1 = dayjs(a.inserted_at);
                const v2 = dayjs(b.inserted_at);
                const diff = sortDirection === 'asc' ? v1.diff(v2) : v2.diff(v1);

                if (diff === 0) {
                    return getNameComparator(a, b, sortDirection);
                }

                return diff;
            };
        case LeadsSortColumn.STAGE: {
            const m = getStageNumberMap();
            // highest value stage first
            return (a: ILead, b: ILead) => {
                const v1 = m[a.stage];
                const v2 = m[b.stage];

                if (v1 === v2) {
                    return getNameComparator(a, b, sortDirection);
                }

                return sortDirection === 'asc' ? v1 - v2 : v2 - v1;
            };
        }
        default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _assertNever: never = sortBy;
        }
    }
};

export const sortLeads = (
    leads: ILead[],
    sortBy: LeadsSortColumn,
    sortDirection: 'asc' | 'desc',
) => {
    const sortFn = getSortFunction(sortBy, sortDirection);
    const sortedLeads = leads.toSorted(sortFn);

    const syntheticLead = leads.find((lead) => lead.is_synthetic);
    const sortedLeadsWithoutSyntheticLead = sortedLeads.filter((lead) => !lead.is_synthetic);

    return [syntheticLead, ...sortedLeadsWithoutSyntheticLead].filter(Boolean) as ILead[];
};