import dayjs from 'dayjs';

import {
    PropertyType
} from 'src/api/landlord-properties-api';
import type { IProperty, ICreatePropertyRequest, IPropertyLabel } from 'src/services/api';
import {
    type PropertyForm,
    EPropertyFormField,
} from 'src/components/create-or-update-property-modal/types';

export const getDefaultValues = (property?: Partial<IProperty>): PropertyForm => {
    let address: PropertyForm['address'] = null;
    if (property?.street_address || property?.city || property?.state || property?.zipcode) {
        address = {
            streetAddress: property.street_address || '',
            unit: property.unit || null,
            city: property.city || '',
            state: property.state || '',
            zipcode: property.zipcode || '',
        };
    }

    return {
        [EPropertyFormField.IsVacant]: property?.is_vacant || false,
        [EPropertyFormField.VacantSince]: property?.vacant_since ? dayjs(property.vacant_since) : null,
        [EPropertyFormField.Name]: property?.name,
        [EPropertyFormField.Country]: property?.country || 'United States',
        [EPropertyFormField.Address]: address,
        [EPropertyFormField.Labels]: property?.labels ? property.labels.map((label) => String(label.id)) : [],
        [EPropertyFormField.Rent]: property?.rent != null ? String(property.rent) : '',
        [EPropertyFormField.Type]: property?.type || PropertyType.None,
        [EPropertyFormField.NumberOfBedrooms]: property?.num_bedrooms != null ? String(property.num_bedrooms) : '',
        [EPropertyFormField.NumberOfBathrooms]: property?.num_bathrooms != null ? String(property.num_bathrooms) : '',
        [EPropertyFormField.SquareFeet]: property?.square_feet != null ? String(property.square_feet) : undefined
    };
};

export const getCreatePropertyPayload = (
    propertyLabels: IPropertyLabel[],
    values: PropertyForm,
): ICreatePropertyRequest => {
    const isVacant = values[EPropertyFormField.IsVacant];
    let vacantSince = values[EPropertyFormField.VacantSince]?.format('YYYY-MM-DD') || null;
    if (!isVacant) {
        vacantSince = null;
    }

    const labels = propertyLabels.filter((label) => {
        return values[EPropertyFormField.Labels].some((id) => parseInt(id) === label.id);
    });

    return {
        is_vacant: isVacant,
        vacant_since: vacantSince,
        name: values[EPropertyFormField.Name] || null,
        country: values[EPropertyFormField.Country] || 'United States',
        street_address: values[EPropertyFormField.Address]?.streetAddress || '',
        unit: values[EPropertyFormField.Address]?.unit || null,
        city: values[EPropertyFormField.Address]?.city || '',
        state: values[EPropertyFormField.Address]?.state || '',
        labels,
        zipcode: values[EPropertyFormField.Address]?.zipcode || '',
        rent: values[EPropertyFormField.Rent] ? Number(values[EPropertyFormField.Rent]) : null,
        type: values[EPropertyFormField.Type] === PropertyType.None ? null : values[EPropertyFormField.Type],
        num_bedrooms: Number(values[EPropertyFormField.NumberOfBedrooms]),
        num_bathrooms: Number(values[EPropertyFormField.NumberOfBathrooms]),
        square_feet: values[EPropertyFormField.SquareFeet] ? Number(values[EPropertyFormField.SquareFeet]) : null,
    };
};

export const getUpdatePropertyPayload = (
    property: IProperty,
    propertyLabels: IPropertyLabel[],
    values: PropertyForm,
): ICreatePropertyRequest & { id: number } => {
    return {
        id: property.id,
        ...getCreatePropertyPayload(propertyLabels, values),
    };
};
