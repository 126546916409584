import React from 'react';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import type { IProperty } from 'src/services/api';
import type { ILead } from 'src/services/api';
import type { IShowing } from 'src/api/landlord-showings-api';
import FlexColumn from 'src/components/flex/FlexColumn';
import Dialog from 'src/components/dialog/Dialog';
import LeadSidebarSmartFields from 'src/components/lead-sidebar/LeadSidebarSmartFields';
import LeadSidebarPropertyDetails from 'src/components/lead-sidebar/LeadSidebarPropertyDetails';
import LeadSidebarUpcomingTour from 'src/components/lead-sidebar/LeadSidebarUpcomingTour';
import LeadSidebarPastTours from 'src/components/lead-sidebar/LeadSidebarPastTours';
import LeadSidebarToursFromOtherLeads from 'src/components/lead-sidebar/LeadSidebarToursFromOtherLeads';
import LeadInfo from 'src/components/lead-sidebar/LeadInfo';
import LeadSidebarNotes from 'src/components/lead-sidebar/LeadSidebarNotes';

interface Props {
    open: boolean;
    onClose: () => void;
    lead: ILead;
    property?: IProperty
    showings: IShowing[];
}

export default function SmartFieldsModal({
    open,
    onClose,
    lead,
    property,
    showings,
}: Props) {
    const validSmartFields = Object.entries(lead.smart_fields_data || {})
        .filter(([key, value]) => {
            if (typeof key !== 'string') {
                return false;
            } else if (lead.ingestion_source !== 'apartmentlist' && typeof value !== 'string') {
                // apartmentlist has a different format for smart fields
                return false;
            } else if (key.startsWith('_')) {
                return false;
            } else if (key === 'listing_name' && !!lead.property_id) {
                return false;
            }

            return true;
        });

    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogContent>
                <FlexColumn rowGap={2}>
                    <Typography variant="h3">{lead.name}</Typography>
                    <LeadSidebarPropertyDetails property={property} />
                    <LeadSidebarUpcomingTour showings={showings} lead={lead} />
                    <LeadSidebarPastTours showings={showings} lead={lead} />
                    <LeadSidebarNotes key={lead.uuid} lead={lead} />
                    <LeadSidebarToursFromOtherLeads showings={showings} lead={lead} />
                    <LeadInfo lead={lead} />
                    {!!validSmartFields.length &&
                        <LeadSidebarSmartFields lead={lead} validSmartFields={validSmartFields} />
                    }
                </FlexColumn>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}