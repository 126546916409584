import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MapMarkerIcon from '@mui/icons-material/PlaceOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BedIcon from '@mui/icons-material/BedOutlined';
import ShowerIcon from '@mui/icons-material/ShowerOutlined';

import type { IProperty } from 'src/services/api';
import { CurrencyUtils, PropertyUtils } from 'src/utils';
import { Flex, FlexColumn } from 'src/components/flex';
import TableCell from 'src/components/table/TableCell';
import Chip from 'src/components/chip/Chip';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

import styles from 'src/pages/landlord/properties/list/DesktopPropertiesListItem.module.css';

type Props = {
    property: IProperty;
}

export default function DesktopPropertiesListItem({
    property,
}: Props) {
    const {
        propertyLabels,
        selectedProperties,
        setSelectedProperties,
        focusedProperty,
    } = usePropertiesPageContext();

    const selected = selectedProperties.some((o) => o.id === property.id);

    const selectProperty = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (selected) {
            setSelectedProperties(selectedProperties.filter((o) => o.id !== property.id));
        } else {
            setSelectedProperties([...selectedProperties, property]);
        }
    };

    const isFocused = focusedProperty?.id === property.id;
    const checkboxDisplay = selectedProperties.length > 0 ? 'block' : 'none';

    let numLabelsShown = 0;

    return (
        <>
            <TableRow
                className={clsx(styles.tableRow)}
                sx={{
                    backgroundColor: isFocused ? 'var(--color-primary-50) !important' : 'inherit',
                    '& .MuiCheckbox-root': { display: checkboxDisplay },
                    '&:hover .MuiCheckbox-root': { display: 'block' },
                }}
            >
                <TableCell width={40} className={styles.tableCellWithCheckbox}>
                    <Checkbox
                        className={styles.checkbox}
                        checked={selected}
                        onClick={selectProperty}
                        data-testid="select-property-checkbox"
                    />
                </TableCell>

                <TableCell width="50%" className={styles.textWithEllipsis}>
                    <FlexColumn rowGap={0.5}>
                        <Typography fontWeight={500} data-testid="property-name">
                            {property.name}
                        </Typography>

                        <Flex alignItems="center" columnGap={0.5}>
                            <MapMarkerIcon sx={{ fontSize: 16 }} />
                            <Typography variant="body2" data-testid="property-address">
                                {PropertyUtils.formatFullAddress(property)}
                            </Typography>
                        </Flex>

                        {!!property.unit && (
                            <Flex alignItems="center" columnGap={0.5}>
                                <ApartmentIcon sx={{ fontSize: 16 }} />
                                <Typography variant="body2" data-testid="property-unit">
                                    Unit {property.unit}
                                </Typography>
                            </Flex>
                        )}

                    </FlexColumn>
                </TableCell>

                <TableCell width="30%" className={styles.textWithEllipsis}>
                    <FlexColumn rowGap={0.5}>
                        <Typography data-testid="property-type">
                            {property.type ?? 'Type not set'}
                        </Typography>

                        <Flex sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: 'grey.700' } }}>
                            <Flex alignItems="center" columnGap={0.5}>
                                <BedIcon />
                                <Typography variant="body2" data-testid="property-num-bedrooms">
                                    {`${property.num_bedrooms} bed`}
                                </Typography>
                            </Flex>

                            <Flex alignItems="center" columnGap={0.5}>
                                <ShowerIcon />
                                <Typography variant="body2" data-testid="property-num-bathrooms">
                                    {`${property.num_bathrooms} bath`}
                                </Typography>
                            </Flex>
                        </Flex>
                    </FlexColumn>
                </TableCell>

                <TableCell width="20%" className={styles.textWithEllipsis}>
                    <FlexColumn rowGap={0.5}>
                        <Typography data-testid="property-rent">
                            {property.rent != null ?
                                CurrencyUtils.format(property.rent) :
                                'Rent not set'
                            }
                        </Typography>

                        <Typography variant="body2" data-testid="property-square-feet">
                            {property.square_feet != null ?
                                `${property.square_feet} sqft` :
                                'Square footage not set'
                            }
                        </Typography>
                    </FlexColumn>
                </TableCell>

                <TableCell width={200}>
                    <Flex flexWrap="wrap" columnGap={0.5}>
                        {propertyLabels.map((propertyLabel) => {
                            const hasLabel = property.labels.some((label) => label.id === propertyLabel.id);
                            const hasReachedMaxLabels = numLabelsShown >= 2;

                            if (hasLabel && !hasReachedMaxLabels) {
                                numLabelsShown++;
                                return (
                                    <Chip
                                        key={propertyLabel.id}
                                        label={propertyLabel.name}
                                        color={PropertyUtils.getLabelColor(propertyLabels, propertyLabel.id)?.[500]}
                                        size="small"
                                    />
                                );
                            }
                        })}

                        {property.labels.length > 2 && (
                            <Chip
                                label={`+${property.labels.length - 2}`}
                                size="small"
                            />
                        )}
                    </Flex>
                </TableCell>
            </TableRow>
        </>
    );
}
