import React from 'react';
import MuiTextField, { type TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';

export type TextFieldProps = Omit<MuiTextFieldProps, 'value' | 'onChange'> & {
    value?: string;
    onChange: (value: string) => void;
};

const TextField: React.FC<TextFieldProps> = ({ onChange, ...props }) => {
    const value = props.value || '';

    const handleChange: MuiTextFieldProps['onChange'] = (event) => {
        onChange(event.target.value);
    };

    return (
        <MuiTextField {...props} value={value} onChange={handleChange} />
    );
};

export default TextField;