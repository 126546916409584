
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { LANDLORD_HOME_PATH } from 'src/routes';
import { Flex } from 'src/components/flex';
import Link from 'src/components/link/Link';
import { MenuIcon } from 'src/icons';

import 'src/components/layout/landlord/LandlordLayout.css';

type Props = {
    openNavigationDrawer: () => void;
}

export default function MobileAppBar({ openNavigationDrawer }: Props) {
    return (
        <Box component="nav" width="100%" sx={{ bgcolor: 'common.white' }}>
            <Toolbar
                component={Flex}
                height="var(--mobile-navbar-height)"
                minHeight="unset !important"
                justifyContent="space-between"
                borderBottom="1px solid var(--border-color)"
            >
                <IconButton onClick={openNavigationDrawer}>
                    <MenuIcon />
                </IconButton>

                <Link href={LANDLORD_HOME_PATH}>
                    <Flex p={1} alignItems="center">
                        <img src="/img/reffie-logos/SantaHat_Logo.png" alt="reffie-logo" height="40px" />
                        <Typography color="primary">Reffie</Typography>
                    </Flex>
                </Link>
            </Toolbar>
        </Box>
    );
}
