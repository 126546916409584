import React, { useMemo, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MarkReadIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MarkUnreadIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import PeopleIcon from '@mui/icons-material/PeopleOutlineRounded';

import {
    type ILead,
    useUpdateConversationsReadStatus,
    useUpdateLeadsStatuses
} from 'src/services/api';
import { useNotifications } from 'src/notifications';
import { useAuthenticatedState } from 'src/AuthenticatedStateProvider';
import { ReadStatus } from 'src/api/landlord-messages-api';
import { useConversationsPageContext } from 'src/pages/landlord/conversations/context';
import CreateOrUpdateLeadModal from 'src/components/create-or-update-lead-modal';
import BulkChangeLeadOwnerModal from 'src/components/bulk-change-lead-owner-modal';

export default function ConversationsMobileMenu() {
    const { addNotification } = useNotifications();

    const { loggedInUser, leads } = useAuthenticatedState();

    const { mutateAsync: updateReadStatus } = useUpdateConversationsReadStatus();
    const { mutateAsync: updateLeadsStatuses } = useUpdateLeadsStatuses();

    const {
        leadsByUuid,
        selectedConversations,
        setSelectedConversations,
        mobileMenuAnchorEl: anchorEl,
        setMobileMenuAnchorEl: setAnchorEl,
    } = useConversationsPageContext();

    const [isEditingLead, setEditingLead] = useState(false);
    const [isChangingOwner, setChangingOwner] = useState(false);

    const isAnyConversationRead = selectedConversations.some((
        conversation
    ) => conversation.num_unread_messages === 0);

    const isAnyConversationUnread = selectedConversations.some((
        conversation
    ) => conversation.num_unread_messages > 0);

    const areAllConversationsArchived = selectedConversations.every((
        conversation
    ) => leadsByUuid.get(conversation.lead_uuid)?.is_archived);
    const areAllConversationsUnarchived = selectedConversations.every((
        conversation
    ) => !leadsByUuid.get(conversation.lead_uuid)?.is_archived);

    const setReadStatus = async (status: ReadStatus) => {
        setAnchorEl(undefined);
        addNotification(status === ReadStatus.READ ? 'Marking as read...' : 'Marking as unread...', 'info');
        const leadUuids = selectedConversations.map((o) => o.lead_uuid);

        await updateReadStatus({
            leadUuids,
            status,
        });

        setSelectedConversations([]);
        addNotification(status === ReadStatus.READ ? 'Marked as read' : 'Marked as unread', 'success');
    };

    const updateArchivedStatus = async (isArchived: boolean) => {
        setAnchorEl(undefined);
        addNotification(isArchived ? 'Archiving...' : 'Unarchiving...', 'info');
        const _leads: ILead[] = [];
        selectedConversations.forEach((conversation) => {
            const lead = leadsByUuid.get(conversation.lead_uuid);
            if (lead) {
                _leads.push(lead);
            }
        });

        await updateLeadsStatuses(_leads.map((lead) => ({
            uuid: lead.uuid,
            is_archived: isArchived,
        })));

        setSelectedConversations([]);
    };

    const numberOfTeamMembers = loggedInUser?.leasing_team_members?.length || 0;
    const numberOfOtherTeamMembers = numberOfTeamMembers - 1;

    const handleClose = () => {
        setSelectedConversations([]);
        setAnchorEl(undefined);
    };

    const selectedConversationsLeads = useMemo(() => {
        const _selectedConversationsLeads: ILead[] = [];

        selectedConversations.forEach(selectedConversation => {
            const selectedConversationsLead = leads.find(lead => {
                return lead.uuid === selectedConversation.lead_uuid;
            });

            if (selectedConversationsLead) {
                _selectedConversationsLeads.push(selectedConversationsLead);
            }
        });

        return _selectedConversationsLeads;
    }, [selectedConversations, leads]);

    return (
        <>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {isAnyConversationUnread && (
                    <MenuItem onClick={() => setReadStatus(ReadStatus.READ)}>
                        <ListItemIcon>
                            <MarkReadIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Mark Read
                        </ListItemText>
                    </MenuItem>
                )}

                {isAnyConversationRead && (
                    <MenuItem onClick={() => setReadStatus(ReadStatus.UNREAD)}>
                        <ListItemIcon>
                            <MarkUnreadIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Mark Unread
                        </ListItemText>
                    </MenuItem>
                )}

                <MenuItem onClick={() => setEditingLead(true)}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Edit Lead
                    </ListItemText>
                </MenuItem>

                {numberOfOtherTeamMembers > 0 && (
                    <MenuItem
                        onClick={() => {
                            setChangingOwner(true);
                            setAnchorEl(undefined);
                        }}
                    >
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Change Owner
                        </ListItemText>
                    </MenuItem>
                )}

                {areAllConversationsUnarchived && (
                    <MenuItem onClick={() => updateArchivedStatus(true)}>
                        <ListItemIcon>
                            <ArchiveIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Archive
                        </ListItemText>
                    </MenuItem>
                )}

                {areAllConversationsArchived && (
                    <MenuItem onClick={() => updateArchivedStatus(false)}>
                        <ListItemIcon>
                            <UnarchiveIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Unarchive
                        </ListItemText>
                    </MenuItem>
                )}
            </Menu>

            {isEditingLead && (
                <CreateOrUpdateLeadModal
                    lead={leadsByUuid.get(selectedConversations[0]?.lead_uuid)}
                    onClose={() => {
                        setEditingLead(false);
                        setSelectedConversations([]);
                    }}
                    onSave={() => {
                        setEditingLead(false);
                        setSelectedConversations([]);
                    }}
                />
            )}

            <BulkChangeLeadOwnerModal
                open={isChangingOwner}
                onClose={() => {
                    setChangingOwner(false);
                    setSelectedConversations([]);
                }}
                leads={selectedConversationsLeads}
            />
        </>
    );
}
