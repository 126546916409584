import { useMutation } from 'react-query';

import { apiClient } from 'src/services/api/client';
import { SMART_REPLIES_PATH } from 'src/services/api/messages/use-smart-replies';

type Request = {
    user_selected_reply: number;
}

export const useTrackSmartRepliesUsage = (smartReplyId?: number) => {
    const path = `${SMART_REPLIES_PATH}/${smartReplyId}`;

    return useMutation<void, unknown, Request>(
        async (payload) => {
            apiClient(path, {
                method: 'patch',
                json: payload,
            });
        }
    );
};