import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/CheckRounded';
import DoneAllIcon from '@mui/icons-material/DoneAllRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';

import type { IMessage } from 'src/services/api';
import { EMessageDirection, EMessageChannel, EMessageSentStatus } from 'src/services/api';

import styles from 'src/pages/landlord/leads//messages/components/messages-container/MessageSentStatusIcon.module.css';

interface Props {
    message: IMessage,
}

export default function MessageSentStatusIcon({ message }: Props) {
    const isOutgoing = message.direction === EMessageDirection.OUT;
    const isSms = message.channel === EMessageChannel.SMS;
    if (!isOutgoing || !isSms) {
        return null;
    }

    switch (message.sent_status) {
        case EMessageSentStatus.SENT: {
            return (
                <Tooltip title="Message sent">
                    <CheckIcon fontSize="small" className={styles.icon} />
                </Tooltip>
            );
        }

        case EMessageSentStatus.UNDELIVERED: {
            return (
                <Tooltip title="Message not delivered">
                    <ClearIcon fontSize="small" color="error" />
                </Tooltip>
            );
        }

        case EMessageSentStatus.DELIVERED: {
            return (
                <Tooltip title="Message delivered">
                    <DoneAllIcon fontSize="small" className={styles.icon} />
                </Tooltip>
            );
        }

        default: {
            // in some rare cases the sent_status may not be set
            return null;
        }
    }
}