import { useQuery } from 'react-query';

import { apiClient } from 'src/services/api/client';
import { queryKeys } from 'src/services/api/constants';

export const SMART_REPLIES_PATH = 'api/landlord/messages/smart-replies';

export const getSmartRepliesQueryKey = (leadUuid: string) => [queryKeys.smartReplies, leadUuid];

type ApiResponse = {
    smart_replies: {
        id: number;
        replies: string[];
    };
}

export const useSmartReplies = (leadUuid: string, enabled?: boolean) => {
    const queryKey = getSmartRepliesQueryKey(leadUuid);

    return useQuery(
        queryKey,
        async () => {
            const response = await apiClient(SMART_REPLIES_PATH, {
                searchParams: {
                    lead_uuid: leadUuid
                }
            }).json<ApiResponse>();
            return response.smart_replies;
        }, {
            enabled
        },
    );
};