import { useMutation, useQueryClient } from 'react-query';

import type { IStandardErrorResponse } from 'src/api/common';
import type { ILead, ILeadNotes } from 'src/services/api/leads/types';
import { useNotifications } from 'src/notifications';
import { queryKeys } from 'src/services/api/constants';
import { GET_LEADS_PATH } from 'src/services/api/leads/constants';
import { apiClient } from 'src/services/api/client';
import { getApiErrorMessage } from 'src/services/api/utils';

type IUpdateLeadNotesResponse = {
    notes: ILeadNotes;
}

export const useUpdateLeadNotes = (leadUuid: string) => {
    const queryClient = useQueryClient();
    const { addNotification } = useNotifications();

    return useMutation<ILeadNotes, IStandardErrorResponse, string>(
        [queryKeys.leads, leadUuid],
        async (notes) => {
            const path = `${GET_LEADS_PATH}/${leadUuid}/notes`;

            const res = await apiClient(path, {
                method: 'patch',
                json: { notes: notes },
            }).json<IUpdateLeadNotesResponse>();
            return res.notes;
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData<ILead[]>(queryKeys.leads, (oldData) => {
                    if (!oldData) {
                        return [];
                    }

                    const updatedData = oldData?.map((lead) => {
                        if (lead.uuid === leadUuid) {
                            return {
                                ...lead,
                                notes: data,
                            };
                        }
                        return lead;
                    });
                    return updatedData;
                });

                const oldData = queryClient.getQueryData<ILead>([queryKeys.leads, leadUuid]);
                if (oldData) {
                    queryClient.setQueryData<ILead>([queryKeys.leads, leadUuid], {
                        ...oldData,
                        notes: data,
                    });
                }

                addNotification('Lead notes updated', 'success');
            },
            onError: (error) => {
                const errorMessage = getApiErrorMessage(error) || 'Failed to update lead notes';
                addNotification(errorMessage, 'error');
            },
        },
    );
};
