import React from 'react';

import Select from 'src/components/input/select/Select';
import { type LeadsGrouping, leadsGroupingOptions } from 'src/pages/landlord/leads/types';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';

const SelectGroupBy: React.FC = () => {
    const { grouping, setGrouping } = useLeadsPageContext();

    return (
        <Select
            label="Group by"
            emptyText="None"
            options={leadsGroupingOptions}
            value={grouping as string}
            onChange={(value) => setGrouping(value as LeadsGrouping)}
            isClearable
            clearableText="None"
            size="small"
            fullWidth
            sx={{ bgcolor: 'white' }}
            data-testid="leads-group-by-select"
        />
    );
};

export default SelectGroupBy;