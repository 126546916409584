import { useQuery } from 'react-query';
import ky from 'ky';

import Auth from 'src/services/api/auth/Auth';
import { queryKeys } from 'src/services/api/constants';
import { createBearerHeader } from 'src/common/api-utils';
import type { GetUserSettingsResponse, UserSettings } from 'src/services/api/users/settings/types';
import { url } from 'src/services/api/users/settings/constants';

const QUERY_KEY = [queryKeys.userSettings];

export const useUserSettings = () => {
    const accessToken = Auth.accessToken;

    return useQuery<UserSettings>(
        QUERY_KEY,
        async () => {
            if (!accessToken) {
                throw new Error('Access token is missing');
            }

            const headers = createBearerHeader(accessToken);
            const response = await ky.get(url, { headers: headers });
            const responseData = await response.json<GetUserSettingsResponse>();
            return responseData.settings;
        },
        { enabled: !!accessToken }
    );
};