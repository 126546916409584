export enum LeadsGrouping {
  Property = 'PROPERTY',
}

const leadGroupingLabels: Record<LeadsGrouping, string> = {
    [LeadsGrouping.Property]: 'Property',
};

export const leadGroupings = Object.values(LeadsGrouping) as LeadsGrouping[];

export const leadsGroupingOptions = leadGroupings.map((grouping) => ({
    value: grouping,
    label: leadGroupingLabels[grouping],
}));
