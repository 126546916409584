import React from 'react';
import Box, { type BoxProps } from '@mui/material/Box';

import styles from 'src/components/kbd/Kbd.module.css';

type Props = BoxProps;

export default function Kbd({ children, ...props }: Props) {
    return (
        <Box
            component="kbd"
            className={styles.root}
            bgcolor="grey.200"
            color="grey.700"
            {...props}
        >
            {children}
        </Box>
    );
}
