import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Flex, FlexColumn } from 'src/components/flex';
import Page from 'src/components/page/PageV2';
import NewPropertyAutomationModal from 'src/components/NewPropertyAutomationModal';
import EditLabelsModal from 'src/components/edit-labels-modal/EditLabelsModal';
import { LabelType } from 'src/services/api/labels';
import { ImportProperties } from 'src/components/bulk-import';
import CreateOrUpdatePropertyModal from 'src/components/create-or-update-property-modal';
import PropertiesPageProvider, { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';
import PropertiesPageHeader from 'src/pages/landlord/properties/components/header';
import PropertiesPageTabs from 'src/pages/landlord/properties/components/tabs';
import PropertiesList from 'src/pages/landlord/properties/list';
import PropertiesToolbar from 'src/pages/landlord/properties/components/toolbar';
import EmptyPage from 'src/components/empty-page/EmptyPage';
import Responsive from 'src/components/responsive';

function PropertiesPage() {
    const {
        properties,
        isAddingProperty,
        setAddingProperty,
        editingProperty,
        setEditingProperty,
        isImportingProperties,
        setImportingProperties,
        isEditingAllPropertyLabels,
        setEditingAllPropertyLabels,
        lastCreatedProperty,
        setLastCreatedProperty,
        setSelectedProperties,
    } = usePropertiesPageContext();

    const navigate = useNavigate();

    if (properties.length === 0) {
        return (
            <>
                {isAddingProperty && (
                    <CreateOrUpdatePropertyModal
                        opened
                        onSave={(property) => {
                            setAddingProperty(false);
                            setLastCreatedProperty(property);
                        }}
                        onClose={() => setAddingProperty(false)}
                    />
                )}

                {isImportingProperties && (
                    <ImportProperties
                        isOpened={isImportingProperties}
                        onClose={() => setImportingProperties(false)}
                    />
                )}

                {!!lastCreatedProperty && (
                    <NewPropertyAutomationModal
                        open
                        onClose={() => setLastCreatedProperty(undefined)}
                        property={lastCreatedProperty}
                        onConfirmation={(userAnswer: boolean, propertyId: number) => {
                            setLastCreatedProperty(undefined);

                            if (userAnswer) {
                                navigate(`/landlord/inner/playbooks/new?property_id=${propertyId}`);
                            }
                        }}
                        successMsg="Your property was successfully created!"
                    />
                )}

                <EmptyPage sx={{ mt: '5%' }}>
                    <Typography variant="h2" align="center">
                        You have no properties at the moment.
                    </Typography>

                    <Flex>
                        <Button
                            variant="contained"
                            onClick={() => setAddingProperty(true)}
                            color="primary"
                            size="large"
                        >
                            Create your first property
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => setImportingProperties(true)}
                            color="primary"
                            size="large"
                        >
                            Import Properties
                        </Button>
                    </Flex>
                </EmptyPage>
            </>
        );
    }

    return (
        <>
            <FlexColumn rowGap={0}>
                <PropertiesPageHeader />
                <PropertiesPageTabs />
                <PropertiesList />
                <Responsive desktop>
                    <div>
                        <PropertiesToolbar />
                    </div>
                </Responsive>
            </FlexColumn>

            {(isAddingProperty || !!editingProperty) && (
                <CreateOrUpdatePropertyModal
                    opened
                    property={editingProperty}
                    onSave={() => {
                        setAddingProperty(false);
                        setLastCreatedProperty(undefined);
                    }}
                    onClose={() => {
                        setAddingProperty(false);
                        setEditingProperty(undefined);
                        setSelectedProperties([]);
                    }}
                />
            )}

            {isImportingProperties && (
                <ImportProperties
                    isOpened={isImportingProperties}
                    onClose={() => setImportingProperties(false)}
                />
            )}

            {isEditingAllPropertyLabels && (
                <EditLabelsModal
                    type={LabelType.PROPERTY}
                    onClose={() => setEditingAllPropertyLabels(false)}
                />
            )}

            {!!lastCreatedProperty && (
                <NewPropertyAutomationModal
                    open
                    onClose={() => setLastCreatedProperty(undefined)}
                    property={lastCreatedProperty}
                    onConfirmation={(userAnswer: boolean, propertyId: number) => {
                        setLastCreatedProperty(undefined);

                        if (userAnswer) {
                            navigate(`/landlord/inner/playbooks/new?property_id=${propertyId}`);
                        }
                    }}
                    successMsg="Your property was successfully created!"
                />
            )}
        </>
    );
}

export default function PropertiesPageWithProvider() {
    return (
        <PropertiesPageProvider>
            <Page title="Reffie | Properties">
                <PropertiesPage />
            </Page>
        </PropertiesPageProvider>
    );
}
