import React, { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/HelpOutlineRounded';

import { Flex, FlexColumn } from 'src/components/flex';
import analyticsClient, { AnalyticsEvent } from 'src/services/analytics';
import {
    EMessageChannel,
    EMessageDirection,
    getSmartRepliesQueryKey,
    useSmartReplies,
    useTrackSmartRepliesUsage
} from 'src/services/api';
import { ThemeProvider } from 'src/theme';
import { isEmailValid } from 'src/utils/is-email-valid';
import { useLeadMessagesPageContext } from 'src/pages/landlord/leads/messages/context/hooks';

interface Props {
    onClick: (smartReply: string) => void,
}

// days
const SMART_REPLIES_RECENCY_THRESHOLD = 7;

export default function SmartReplies({
    onClick,
}: Props) {
    const queryClient = useQueryClient();

    const { loggedInUser, channel, lead, messages } = useLeadMessagesPageContext();

    const messagesRef = useRef(messages);

    const hasMessages = !!messages?.length;
    const lastMessage = messages?.at(-1);
    const isOwnLead = loggedInUser.id === lead.owner;
    const isLastMessageFromLead = lastMessage?.direction === EMessageDirection.IN;
    const isLeadArchived = !!lead.is_archived;
    const isLastMessageRecent = dayjs(lastMessage?.inserted_at)
        .isAfter(dayjs().subtract(SMART_REPLIES_RECENCY_THRESHOLD, 'day'));

    const canFetchSmartReplies =
        hasMessages &&
        isLastMessageFromLead &&
        isOwnLead &&
        !isLeadArchived &&
        isLastMessageRecent;

    const {
        data: smartReplies,
        isLoading: isLoadingSmartReplies,
    } = useSmartReplies(lead.uuid || '', canFetchSmartReplies);
    const { mutate: trackSmartRepliesUsage } = useTrackSmartRepliesUsage(smartReplies?.id);

    useEffect(() => {
        if (messagesRef.current?.length !== messages.length) {
            queryClient.invalidateQueries(getSmartRepliesQueryKey(lead.uuid));
        }
    }, [queryClient, messages, lead]);

    const isEmailChannelSelected = channel === EMessageChannel.EMAIL;
    const hasInvalidEmail = isEmailChannelSelected && !isEmailValid(lead.email);

    const disabled = hasInvalidEmail;

    const handleSelectSmartReply = (reply: string, index: number) => () => {
        analyticsClient.track(AnalyticsEvent.SMART_REPLY_SELECTED, {
            smartReplies,
            smartReply: reply,
        });
        trackSmartRepliesUsage({ user_selected_reply: index });
        onClick(reply);
    };

    useEffect(() => {
        if (smartReplies) {
            analyticsClient.track(AnalyticsEvent.SMART_REPLIES_VIEWED, {
                smartReplies,
            });
        }
    }, [smartReplies]);

    if (!canFetchSmartReplies || (!isLoadingSmartReplies && !smartReplies?.replies.length)) {
        return null;
    }

    return (
        <ThemeProvider>
            <FlexColumn>
                <Flex mobileFlexDirection="column">
                    {isLoadingSmartReplies && (
                        <LoadingButton loading={true} variant="outlined" disabled>
                            Loading
                        </LoadingButton>
                    )}

                    {smartReplies?.replies.map((reply, index) => (
                        <Button
                            key={reply}
                            variant="outlined"
                            onClick={handleSelectSmartReply(reply, index)}
                            disabled={disabled}
                        >
                            {reply}
                        </Button>
                    ))}

                    {!!smartReplies?.replies.length && (
                        <Tooltip title="Choosing a smart reply will add the text to your message. It will not send the message.">
                            <Button sx={{ minWidth: 'unset' }}>
                                <HelpIcon />
                            </Button>
                        </Tooltip>
                    )}
                </Flex>
            </FlexColumn>
        </ThemeProvider>
    );
}
