import React, { createContext, useContext, useEffect, useState } from 'react';

type LandlordLayoutContextType = {
    isSidebarCollapsed: boolean;
    setSidebarCollapsed: (isSidebarCollapsed: boolean) => void;
    sidebarWidth: number;
}

// @ts-expect-error lateinit
const LandlordLayoutContext = createContext<LandlordLayoutContextType>();

export const useLandlordLayoutContext = () => {
    const context = useContext(LandlordLayoutContext);

    if (!context) {
        throw new Error('useLandlordLayoutContext must be used within a LandlordLayoutContext.Provider');
    }
    return context;
};

type Props = {
    children: React.ReactNode;
}

export default function LandlordLayoutProvider({ children }: Props) {
    const [isSidebarCollapsed, _setSidebarCollapsed] = useState(getSidebarStateFromLocalStorage);
    const [sidebarWidth, setSidebarWidth] = useState(isSidebarCollapsed ? 60 : 160);

    const setSidebarCollapsed = (_isSidebarCollapsed: boolean) => {
        _setSidebarCollapsed(_isSidebarCollapsed);
        saveSidebarStateToLocalStorage(_isSidebarCollapsed);
    };

    useEffect(() => {
        if (window.innerWidth > 900) {
            setSidebarWidth(isSidebarCollapsed ? 60 : 160);
        } else {
            setSidebarWidth(0);
        }
    }, [isSidebarCollapsed]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 900) {
                setSidebarWidth(isSidebarCollapsed ? 60 : 160);
            } else {
                setSidebarWidth(0);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [isSidebarCollapsed]);

    return (
        <LandlordLayoutContext.Provider value={{
            isSidebarCollapsed,
            setSidebarCollapsed,
            sidebarWidth,
        }}
        >
            {children}
        </LandlordLayoutContext.Provider>
    );
}

export const saveSidebarStateToLocalStorage = (isSidebarCollapsed: boolean) => {
    localStorage.setItem('isSidebarCollapsed', String(isSidebarCollapsed));
};

export const getSidebarStateFromLocalStorage = () => {
    const isSidebarCollapsed = localStorage.getItem('isSidebarCollapsed');
    return isSidebarCollapsed === 'true' ? true : false;
};