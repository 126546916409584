import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import type { ILead } from 'src/services/api';
import { useLoggedInUser } from 'src/services/api';
import { FlexColumn } from 'src/components/flex';
import Switch from 'src/components/input/switch/Switch';
import Autocomplete from 'src/components/input/autocomplete/Autocomplete';
import SelectProperty from 'src/components/input/select-property/SelectProperty';
import { sharedLeadFormOptions, createLeadFormOptions } from 'src/components/create-or-update-lead-modal/utils';
import { useLeadForm } from 'src/components/create-or-update-lead-modal/use-lead-form';
import { LeadFormField } from 'src/components/create-or-update-lead-modal/types';
import SelectLeadLabels from 'src/components/input/select-lead-labels/SelectLeadLabels';

type Props = {
    lead?: ILead;
    onClose: () => void;
    onSave?: () => void;
    disableScrollLock?: boolean;
};

export default function CreateOrUpdateLeadModal({
    lead,
    onClose,
    onSave,
    disableScrollLock
}: Props) {
    const { data: loggedInUser } = useLoggedInUser();

    const [showOnlyVacantProperties, _setShowOnlyVacantProperties] = useState(false);

    const onSuccess = () => {
        onSave?.();
        onClose();
    };

    const { form, submit } = useLeadForm(onSuccess, lead);
    const { register, setValue, watch, formState: { errors } } = form;

    const phoneNumberValue = watch(LeadFormField.PhoneNumber);

    const handlePhoneNumberChange: React.ComponentProps<typeof MuiPhoneNumber>['onChange'] = (value) => {
        const phoneNumber = typeof value === 'string' ? value : value.target.value;
        // strip out non-numeric characters, except for the leading `+`
        setValue(LeadFormField.PhoneNumber, phoneNumber.replace(/[^0-9+]/g, ''));
    };

    const setShowOnlyVacantProperties = (value: boolean) => {
        _setShowOnlyVacantProperties(value);
        setValue(LeadFormField.PropertyId, '');
    };

    const teamMemberOptions = useMemo(() => {
        if (!loggedInUser?.leasing_team_members) {
            return [];
        }

        return loggedInUser.leasing_team_members.map((teamMember) => ({
            label: teamMember.name,
            value: String(teamMember.id),
        }));
    }, [loggedInUser]);

    const isEditing = !!lead;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                open
                onClose={onClose}
                PaperProps={{ sx: { width: '100%' } }}
                disableScrollLock={disableScrollLock}
            >
                <DialogTitle>
                    {lead ? 'Edit Lead' : 'Create Lead'}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={form.handleSubmit(submit)}>
                        <FlexColumn rowGap={2}>
                            {lead?.facebook_user_id && (
                                <Typography variant="body2">
                                    Facebook user ID: {lead.facebook_user_id}
                                </Typography>
                            )}

                            <TextField
                                {...register(LeadFormField.Name, sharedLeadFormOptions.name)}
                                variant="standard"
                                label="Name"
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                data-testid="lead-name-field"
                            />

                            <TextField
                                {...register(LeadFormField.Email, sharedLeadFormOptions.email)}
                                variant="standard"
                                label="Email"
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                data-testid="lead-email-field"
                            />

                            <MuiPhoneNumber
                                {...register(LeadFormField.PhoneNumber, sharedLeadFormOptions.phoneNumber)}
                                value={phoneNumberValue}
                                onChange={handlePhoneNumberChange}
                                onlyCountries={['us']}
                                defaultCountry="us"
                                variant="standard"
                                label="Phone number"
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber?.message}
                                disableAreaCodes
                                data-testid="lead-phone-number-field"
                            />

                            {!isEditing && (
                                <TextField
                                    {...register(LeadFormField.IngestionSource, createLeadFormOptions.ingestionSource)}
                                    variant="standard"
                                    label="Ingestion Source"
                                    placeholder="Apartments.com"
                                    error={!!errors.ingestionSource}
                                    helperText={errors.ingestionSource?.message}
                                    data-testid="lead-ingestion-source-field"
                                />
                            )}

                            <FlexColumn>
                                <Controller
                                    name={LeadFormField.PropertyId}
                                    control={form.control}
                                    render={({ field }) => (
                                        <SelectProperty
                                            variant="standard"
                                            label="Property"
                                            value={field.value}
                                            onChange={(value) => setValue(LeadFormField.PropertyId, value || '', { shouldValidate: true })}
                                            error={!!errors.propertyId}
                                            helperText={errors.propertyId?.message}
                                            isVacant={showOnlyVacantProperties}
                                            isClearable
                                            data-testid="lead-associated-property-select"
                                        />
                                    )}
                                />
                                <Switch
                                    label="Only show vacant properties"
                                    value={showOnlyVacantProperties}
                                    onChange={setShowOnlyVacantProperties}
                                    size="small"
                                    data-testid="only-vacant-properties-switch"
                                />
                            </FlexColumn>

                            {!isEditing && (
                                <Controller
                                    name={LeadFormField.FirstContactDate}
                                    control={form.control}
                                    rules={createLeadFormOptions.firstContactDate}
                                    render={({ field }) => (
                                        <DatePicker
                                            label="First Contact Date"
                                            value={field.value}
                                            onChange={(date) => setValue(LeadFormField.FirstContactDate, date, { shouldValidate: true })}
                                            slotProps={{
                                                textField: {
                                                    variant: 'standard',
                                                    error: !!errors.firstContactDate,
                                                    helperText: errors.firstContactDate?.message
                                                }
                                            }}
                                            data-testid="lead-first-contact-date-field"
                                        />
                                    )}
                                />
                            )}

                            <SelectLeadLabels
                                lead={lead}
                                onChange={(value) => setValue(LeadFormField.Labels, value, { shouldValidate: true })}
                                data-testid="lead-labels-select"
                            />

                            {teamMemberOptions.length > 1 && isEditing && (
                                <Controller
                                    name={LeadFormField.OwnerId}
                                    control={form.control}
                                    render={({ field, fieldState: { error } }) => (
                                        <Autocomplete
                                            variant="standard"
                                            label="Owner"
                                            options={teamMemberOptions}
                                            value={field.value}
                                            onChange={(value) => setValue(LeadFormField.OwnerId, value || '', { shouldValidate: true })}
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            )}
                        </FlexColumn>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        color="dark"
                        onClick={onClose}
                        data-testid="lead-modal-cancel-button"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={form.formState.isSubmitting}
                        onClick={form.handleSubmit(submit)}
                        data-testid="lead-modal-save-button"
                    >
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
}