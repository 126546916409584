import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddRounded';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';

import Responsive from 'src/components/responsive';
import { Flex } from 'src/components/flex';
import { usePropertiesPageContext } from 'src/pages/landlord/properties/provider';

export default function PropertiesPageHeaderButtons() {
    const {
        setAddingProperty,
        setImportingProperties,
        setEditingAllPropertyLabels,
    } = usePropertiesPageContext();

    return (
        <Responsive desktop>
            <div>
                <Flex>
                    <Button
                        variant="contained"
                        onClick={() => setAddingProperty(true)}
                        startIcon={<AddIcon />}
                        data-testid="new-property-button"
                    >
                        New Property
                    </Button>

                    <Button
                        variant="outlined"
                        onClick={() => setImportingProperties(true)}
                        startIcon={<FileUploadIcon />}
                        sx={{ bgcolor: 'white' }}
                        data-testid="import-properties-button"
                    >
                        Import Properties
                    </Button>

                    <Button
                        variant="outlined"
                        onClick={() => setEditingAllPropertyLabels(true)}
                        startIcon={<LabelIcon />}
                        sx={{ bgcolor: 'white' }}
                        data-testid="property-labels-button"
                    >
                        Labels
                    </Button>
                </Flex>
            </div>
        </Responsive>
    );
}
