const BAD_USERNAMES = ['donotreply', 'no-reply', 'noreply'];

/**
 * This should mirror the validation done on the server side.
 */
export function isEmailValid(email: string | null): boolean {
    if (email === null || email === '') {
        return false;
    }
    const count = email.match(/@/g)?.length;
    if (count !== 1) {
        return false;
    }
    const username = email.split('@', 1)[0];
    return !BAD_USERNAMES.includes(username);
}