import React from 'react';
import Typography from '@mui/material/Typography';

import { LeadsGrouping } from 'src/pages/landlord/leads/types';
import { useLeadsPageContext } from 'src/pages/landlord/leads/provider';
import { useGroupedByPropertyLeads } from 'src/pages/landlord/leads/hooks/use-grouped-by-property-leads';
import LeadsTable from 'src/pages/landlord/leads/components/table/LeadsTable';

export default function AllLeadsSection() {
    const {
        grouping,
        properties,
        leads,
        isFilterOrSearchActive,
    } = useLeadsPageContext();

    const groupedByPropertyLeads = useGroupedByPropertyLeads(leads);

    const hasFilteredLeads = leads.length > 0;

    const isGroupedByProperty = grouping === LeadsGrouping.Property;

    return (
        <>
            {!hasFilteredLeads && (
                isFilterOrSearchActive ?
                    <Typography>No leads match your filters</Typography> :
                    <Typography>You currently have no leads</Typography>
            )}

            {hasFilteredLeads && !isGroupedByProperty && (
                <LeadsTable leads={leads} />
            )}

            {hasFilteredLeads && isGroupedByProperty && (
                <>
                    {properties?.map((property) => {
                        const propertyLeads = groupedByPropertyLeads.get(property.id);
                        if (!propertyLeads?.length) {
                            return null;
                        }

                        return (
                            <LeadsTable
                                key={property.id}
                                title={property.name}
                                leads={propertyLeads}
                            />
                        );
                    })}

                    {!!groupedByPropertyLeads.get(-1)?.length && (
                        <LeadsTable
                            title="No property"
                            leads={groupedByPropertyLeads.get(-1)}
                        />
                    )}
                </>
            )}
        </>
    );
}
